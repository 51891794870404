import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ADMIN_MANAGE_COMPANY_APIS } from "../../../Apis/ManageCompany/ManageCompany";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const fetchGetAllTeamMembersByCompanyId = createAsyncThunk(
  "fetchGetAllTeamMembersByCompanyId",
  async (data: any) => {
    const response =
      await ADMIN_MANAGE_COMPANY_APIS.getAllTeamMembersByCompanyId(data);
    return response?.data;
  }
);

export const getAllTeamMembersByCompanyIdSlice = createSlice({
  name: "getAllTeamMembersByCompanyIdSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGetAllTeamMembersByCompanyId.pending, (state) => {
        state.status = "loading";
        state.data = [];
      })
      .addCase(fetchGetAllTeamMembersByCompanyId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchGetAllTeamMembersByCompanyId.rejected, (state) => {
        state.status = "failed";
        state.data = [];
      });
  },
});

export const getAllTeamMembersByCompanyIdSliceActions =
  getAllTeamMembersByCompanyIdSlice.actions;
export default getAllTeamMembersByCompanyIdSlice.reducer;
