import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import FaceBookSvg from "../../assets/svgs/FaceBookSvg";
declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}
const useStyles = makeStyles({
  container: {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    paddingBlock: "3%",
    marginBlock: "4%",
    boxSizing: "border-box",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    gap: "10px",
  },
});

const LoginButton: React.FC = () => {
  const classes = useStyles();
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "2690570337772800", // Facebook App ID
        cookie: true,
        xfbml: true,
        version: "v19.0",
      });
    };

    // Load the JavaScript SDK asynchronously
    (function (d, s, id) {
      var js: HTMLScriptElement,
        fjs = d.getElementsByTagName(s)[0];

      if (d.getElementById(id)) return;

      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";

      // Check if fjs and fjs.parentNode are not null before using insertBefore
      if (fjs && fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs);
      } else {
        // If fjs or fjs.parentNode is null, append the script to the body
        d.body.appendChild(js);
      }
    })(document, "script", "facebook-jssdk");

    const sessionInfoListener = (event: MessageEvent) => {
      if (event.origin !== "https://www.facebook.com") return;
      try {
        const data = JSON.parse(event.data);
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          if (data.event === "FINISH") {
            // const { phone_number_id, waba_id } = data.data;
            // console.log(phone_number_id, waba_id); // Use these values as needed
          } else {
            // const { current_step } = data.data;
            // console.log(current_step); // Use this value as needed
          }
        }
      } catch {
        console.log("Non JSON Response", event.data);
      }
    };

    window.addEventListener("message", sessionInfoListener);

    return () => {
      window.removeEventListener("message", sessionInfoListener);
    };
  }, []);
  const launchWhatsAppSignup = () => {
    window.FB.login(
      (response: any) => {
        if (response.authResponse) {
          const code = response.authResponse.code;
          const url = `https://fastsagewave8.conveyor.cloud/api/EmbeddedSignUp?code=${code}`;

          fetch(url, {
            method: "POST",
            headers: {
              Accept: "*/*",
            },
          })
            .then((response) => {
              if (response.ok) {
                // console.log("API call successful");
              } else {
                // console.error("API call failed");
              }
            })
            .catch((error) => {
              console.error("Network error:", error);
            });
        } else {
          //   console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "450495777451034",
        response_type: "code",
        override_default_response_type: true,
        scope: "whatsapp_business_management, whatsapp_business_messaging",
        extras: {
          setup: {
            // Prefilled data can go here
          },
        },
      }
    );
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "500px",
      }}
    >
      <Box
        className={classes.container}
        sx={{ width: { xs: "90%", sm: "70%", md: "50%", lg: "30%" } }}
        px={{ xs: 2, md: 6 }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "900" }}>
          Integrate With Facebook
        </Typography>
        <Typography
          sx={{ color: "black", fontWeight: "small", fontSize: "small" }}
        >
          The topic sentence presents the main idea of the paragraph, followed
          by supporting sentences that provide evidence, examples, or
          explanations.
        </Typography>
        <button
          onClick={launchWhatsAppSignup}
          style={{
            backgroundColor: "#1877f2",
            border: 0,
            borderRadius: "4px",
            color: "#fff",
            cursor: "pointer",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontSize: "16px",
            fontWeight: "bold",
            height: "40px",
            padding: "0 50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FaceBookSvg /> Login with Facebook
        </button>
      </Box>
    </Box>
  );
};

export default LoginButton;
