import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LaunchIcon from "@mui/icons-material/Launch";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SettingsInputSvideoIcon from "@mui/icons-material/SettingsInputSvideo";
import InfoIcon from "@mui/icons-material/Info";
import TextFieldWithBorderComponent from "../../components/common/TextFieldWithBorderComponent";
import UploadCSVDialog from "../../components/ChatSideBarComponents/CommerceSettingsComponents/uploadCSV";
import LinkComponent from "../../components/common/LinkComponent";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    // height: "100vh",
    width: "100%",
    overFlow: "hidden !important",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
    overFlow: "hidden !important",
    display: "flex",
    flexDirection: "column",
  },
  feedbackLink: {
    margin: 3,
    width: 160,
    display: "flex",
    alignItems: "center",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  accordionBox: {
    border: "1px solid black",
    marginBlock: 15,
    padding: "0px 15px",
  },
  infoLabel: {
    display: "flex",
    alignItems: "center",
    paddingBlock: 4,
    paddingInline: 8,
    backgroundColor: bgColors?.yellow2,
  },
  number: {
    fontWeight: "bold",
    backgroundColor: "black", // Background color for numbers
    color: "white",
    borderRadius: "50%", // Round border-radius
    padding: "2px 8px", // Padding to adjust the size of the circle
    display: "inline-block", // To keep the number in a circle
    alignItems: "center",
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    // width: "fit-content",
    // width: "140px",
    height: "32px",
    paddingInline: "10px",
    cursor: "pointer",
  },
});

const CommerceSettings = () => {
  const classes = useStyles();
  let templateRef = useRef<HTMLDivElement>(null);

  const [isHovered, setIsHovered] = useState<any>({
    learnMore: false,
    uploadCSV: false,
    gotFB: false,
    setUpCatalogs: false,
    setUpAutoReplies: false,
  });
  const [openUploadCSVPopover, setOpenUploadCSVPopover] =
    useState<boolean>(false);

  return (
    <Grid className={classes.mainContainer} ref={templateRef}>
      {/* {hasTemplatesLibraryPermission ? ( */}
      <Box className={classes.bgContainer}>
        <Box sx={{ height: "120px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
            px={{ xs: 1, md: 0 }}
            py={{ xs: 1, md: 3 }}
          >
            <Box
              sx={{
                // display: { xs: "block", md: "flex" },
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                ml={{ xs: 1, md: 3 }}
                className={classes.blackColor}
                sx={{ display: "flex" }}
              >
                <span style={{ marginTop: "5px" }}>
                  <SettingsInputSvideoIcon sx={{ height: "22px" }} />
                </span>
                &nbsp;Commerce Settings
              </Typography>
              <Typography fontSize={12} ml={{ xs: 1, md: 7 }}>
                Setup WhatsApp Catalog Messages for your account
              </Typography>
            </Box>
            <Box className={classes?.feedbackLink}>
              <Typography fontSize={12} color={bgColors?.blue}>
                Give your feedback
              </Typography>
              <LaunchIcon sx={{ fontSize: 12, ml: 1, color: bgColors?.blue }} />
            </Box>
          </Box>
        </Box>
        <Box
          mx={{ xs: 1, md: 3 }}
          sx={{
            // display: { xs: "block", md: "flex" },
            // width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            className={classes.blackColor}
            sx={{ display: "flex" }}
          >
            Start Selling on WhatsApp!
          </Typography>
          <Typography fontSize={14}>
            You can send Catalogs to customers as part of campaigns &
            autoreplies. They can then place orders via Carts.
          </Typography>
          <br />
          <button
            className={classes.SaveChangesButton}
            // onClick={handleOpenDialog}
            onMouseEnter={() => setIsHovered({ learnMore: true })}
            onMouseLeave={() => setIsHovered({ learnMore: false })}
            style={{
              backgroundColor: isHovered?.learnMore
                ? "rgba(68, 71, 70, 0.08)"
                : "#fff",
              cursor: isHovered?.learnMore ? "pointer" : "default", // Optional: change cursor style on hover
            }}
          >
            Learn More
          </button>
        </Box>
        <Box mx={{ xs: 1, md: 3 }} mt={4}>
          <Accordion className={classes?.accordionBox} sx={{ borderRadius: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes?.number} fontSize={12}>
                1
              </Typography>
              <Typography
                sx={{ fontSize: 14, mt: "1px", ml: 1, fontWeight: "bold" }}
              >
                Add products to your WhatsApp Store
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="body2" fontWeight="bold">
                    Create your catalog
                  </Typography>
                  <Typography variant="caption" sx={{ mt: 0 }}>
                    Add your products to the CSV and uploading it here.
                  </Typography>
                </Box>
                <button
                  className={classes.SaveChangesButton}
                  onClick={() => setOpenUploadCSVPopover(true)}
                  onMouseEnter={() => setIsHovered({ uploadCSV: true })}
                  onMouseLeave={() => setIsHovered({ uploadCSV: false })}
                  style={{
                    backgroundColor: isHovered?.uploadCSV
                      ? "rgba(68, 71, 70, 0.08)"
                      : "#fff",
                    cursor: isHovered?.uploadCSV ? "pointer" : "default", // Optional: change cursor style on hover
                  }}
                >
                  Upload CSV
                </button>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="body2" fontWeight="bold">
                    A. Provide Compliance Info
                  </Typography>
                  <Typography variant="caption" sx={{ ml: 2 }}>
                    This will appear in your WhatsApp Profile.
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="body2" fontWeight="bold">
                    B. Set up FB Catalog & Collections
                  </Typography>
                  <Typography variant="caption" sx={{ ml: 2 }}>
                    via <LinkComponent title="Google Sheets" />|
                    <LinkComponent title="Shopify" />
                  </Typography>
                </Box>
                <button
                  className={classes.SaveChangesButton}
                  onMouseEnter={() => setIsHovered({ gotoFB: true })}
                  onMouseLeave={() => setIsHovered({ gotFB: false })}
                  style={{
                    backgroundColor: isHovered?.gotoFB
                      ? "rgba(68, 71, 70, 0.08)"
                      : "#fff",
                    cursor: isHovered?.gotoFB ? "pointer" : "default", // Optional: change cursor style on hover
                  }}
                >
                  Go to FB
                  <LaunchIcon
                    sx={{ fontSize: 12, ml: 1, color: bgColors?.green }}
                  />
                </button>
              </Box>
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                }}
              >
                <Typography
                  variant="body2"
                  fontSize={12}
                  className={classes?.infoLabel}
                >
                  <InfoIcon
                    fontSize="small"
                    sx={{ color: bgColors?.yellow, mr: 1 }}
                  />
                  To send Catalog Messages, it is mandatory to add Country of
                  Origin for each product.&nbsp;
                  <LinkComponent title="See how" />
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="body2" fontWeight="bold">
                    C. Give Catalog Access to Engageto
                  </Typography>
                  <Typography variant="caption" sx={{ ml: 2 }}>
                    Add Engageto (928974617157828) as Catalog Partner
                  </Typography>
                </Box>
                <button
                  className={classes.SaveChangesButton}
                  onMouseEnter={() => setIsHovered({ gotoFB: true })}
                  onMouseLeave={() => setIsHovered({ gotFB: false })}
                  style={{
                    backgroundColor: isHovered?.gotoFB
                      ? "rgba(68, 71, 70, 0.08)"
                      : "#fff",
                    cursor: isHovered?.gotoFB ? "pointer" : "default", // Optional: change cursor style on hover
                  }}
                >
                  Go to FB
                  <LaunchIcon
                    sx={{ fontSize: 12, ml: 1, color: bgColors?.green }}
                  />
                </button>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="body2" fontWeight="bold">
                    D. Connect your Catalog to your WhatsApp account
                  </Typography>
                  <Typography variant="caption" sx={{ ml: 2 }}>
                    Go <LinkComponent title="here" /> and follow the steps shown
                    in the <LinkComponent title="video" />
                  </Typography>
                </Box>
                <button
                  className={classes.SaveChangesButton}
                  onMouseEnter={() => setIsHovered({ gotoFB: true })}
                  onMouseLeave={() => setIsHovered({ gotoFB: false })}
                  style={{
                    backgroundColor: isHovered?.gotoFB
                      ? "rgba(68, 71, 70, 0.08)"
                      : "#fff",
                    cursor: isHovered?.gotoFB ? "pointer" : "default", // Optional: change cursor style on hover
                  }}
                >
                  Go to FB
                  <LaunchIcon
                    sx={{ fontSize: 12, ml: 1, color: bgColors?.green }}
                  />
                </button>
              </Box>{" "}
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="body2" fontWeight="bold">
                    E. Enter Facebook Catalog ID
                  </Typography>
                  <Typography variant="caption" sx={{ ml: 2 }}>
                    We will fetch products from this catalog
                  </Typography>
                </Box>
                <TextFieldWithBorderComponent
                  placeholder=""
                  label="Enter Catalog ID"
                  name="catalogID"
                  sx={{ width: "20%" }}
                  value=""
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <Button
                  //         variant="contained"
                  //         sx={{
                  //           borderRadius: "4px",
                  //           backgroundColor: bgColors?.green,
                  //           color: "#fff",
                  //           padding: "2px 8px",
                  //           fontSize: "12px",
                  //           textTransform: "none",
                  //         }}
                  //       >
                  //         Connect
                  //       </Button>
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes?.accordionBox} sx={{ borderRadius: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes?.number} fontSize={12}>
                2
              </Typography>
              <Typography
                sx={{ fontSize: 14, mt: "1px", ml: 1, fontWeight: "bold" }}
              >
                Setup Messages for Product Collections & Catalogs
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Information on how to set up messages for product collections
                and catalogs.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes?.accordionBox} sx={{ borderRadius: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes?.number} fontSize={12}>
                3
              </Typography>
              <Typography
                sx={{ fontSize: 14, mt: "1px", ml: 1, fontWeight: "bold" }}
              >
                Send out Catalogs in Campaigns
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography mb={1} fontSize={14}>
                After your campaign message, add an automatic catalog flow: if
                the customer clicks on the Quick Reply Button in your campaign
                message, the Product Collections List Message will go out
                automatically. If the customer then selects a collection from
                the list, the corresponding product catalog will go out
                instantly as a catalog message!
              </Typography>
              <button
                className={classes.SaveChangesButton}
                onMouseEnter={() => setIsHovered({ setUpCatalogs: true })}
                onMouseLeave={() => setIsHovered({ setUpCatalogs: false })}
                style={{
                  backgroundColor: isHovered?.setUpCatalogs
                    ? "rgba(68, 71, 70, 0.08)"
                    : "#fff",
                  cursor: isHovered?.setUpCatalogs ? "pointer" : "default", // Optional: change cursor style on hover
                }}
              >
                Setup
              </button>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes?.accordionBox} sx={{ borderRadius: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes?.number} fontSize={12}>
                4
              </Typography>
              <Typography
                sx={{ fontSize: 14, mt: "1px", ml: 1, fontWeight: "bold" }}
              >
                Send out Catalogs in Auto Replies
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography mb={1} fontSize={14}>
                Attach your Product Collections List in your Welcome / OOO /
                Delayed Messages! As soon as the Customer selects a Collection,
                products in that Collection will automatically go out as a
                Catalog!
              </Typography>
              <button
                className={classes.SaveChangesButton}
                onMouseEnter={() => setIsHovered({ setUpAutoReplies: true })}
                onMouseLeave={() => setIsHovered({ setUpAutoReplies: false })}
                style={{
                  backgroundColor: isHovered?.setUpAutoReplies
                    ? "rgba(68, 71, 70, 0.08)"
                    : "#fff",
                  cursor: isHovered?.setUpAutoReplies ? "pointer" : "default", // Optional: change cursor style on hover
                }}
              >
                Setup
              </button>
            </AccordionDetails>
          </Accordion>
          {/* <Accordion className={classes?.accordionBox} sx={{ borderRadius: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes?.number} fontSize={10}>
                5
              </Typography>
              <Typography
                sx={{ fontSize: 12, mt: "1px", ml: 1, fontWeight: "bold" }}
              >
                Help customers place orders with Interakt's Autocheckout
                Workflow!
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Learn how customers can place orders using the autocheckout
                workflow.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes?.accordionBox} sx={{ borderRadius: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes?.number} fontSize={10}>
                6
              </Typography>
              <Typography
                sx={{ fontSize: 12, mt: "1px", ml: 1, fontWeight: "bold" }}
              >
                See all enquiries & orders you get from customers
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                View all inquiries and orders received from customers.
              </Typography>
            </AccordionDetails>
          </Accordion> */}
        </Box>
      </Box>
      <UploadCSVDialog
        open={openUploadCSVPopover}
        setOpen={setOpenUploadCSVPopover}
      />
      {/* ) : (
        <NoAccessPage />
      )} */}
    </Grid>
  );
};

export default CommerceSettings;
