import React from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import ChatUserArrowDownSvg from "../../../assets/svgs/ChatUserArrowDownSvg";
import ChatUserArrowSide from "../../../assets/svgs/ChatUserArrowSide";
import ChatUserCloseSvg from "../../../assets/svgs/ChatUserCloseSvg";
import { makeStyles } from "@mui/styles";
import { bgColors } from "../../../utils/bgColors";

const useStyles = makeStyles({
  grayColor: {
    color: `#4B5A5A !important`,
    fontWeight: "600 !important",
  },
  grayColor2: {
    color: `#4B5A5A !important`,
    opacity: "80%",
  },
  flexRowCenterPointer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  flexRowCenterSpaceBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tagContainer: {
    backgroundColor: bgColors?.gray2,
    borderRadius: "14px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "5px 10px 5px 10px",
    margin: "5px",
  },
});

interface TagsComponentProps {
  tags: boolean;
  details: any;
  handleOpenTagPopover: (event: any) => void;
  addTagTooltipOpen: boolean;
  setTags: React.Dispatch<React.SetStateAction<boolean>>;
  setAddTagTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateTagTooltipOpen: string;
  setUpdateTagTooltipOpen: React.Dispatch<React.SetStateAction<string>>;
  handleRemoveTagFromContact: (tag: string) => void;
}

const TagsDropdownComponent: React.FC<TagsComponentProps> = ({
  tags,
  details,
  handleOpenTagPopover,
  addTagTooltipOpen,
  setTags,
  setAddTagTooltipOpen,
  updateTagTooltipOpen,
  setUpdateTagTooltipOpen,
  handleRemoveTagFromContact,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Box my={2} className={classes.flexRowCenterSpaceBetween}>
        <Box
          className={classes.flexRowCenterPointer}
          onClick={() => setTags(!tags)}
        >
          {tags ? <ChatUserArrowDownSvg /> : <ChatUserArrowSide />}
          <Typography
            ml={2}
            sx={{ fontSize: "14px" }}
            className={classes.grayColor}
            style={{ cursor: "pointer" }}
          >
            Tags
          </Typography>
        </Box>

        <Tooltip
          title="Access Denied"
          placement="top"
          open={addTagTooltipOpen}
          onClose={() => setAddTagTooltipOpen(false)}
        >
          <Typography
            mr={0.5}
            sx={{ fontSize: "14px" }}
            className={classes.grayColor2}
            style={{
              cursor: "pointer",
              borderBottom: "1px solid #4B5A5A",
            }}
            onClick={handleOpenTagPopover}
          >
            + Add Tags
          </Typography>
        </Tooltip>
      </Box>

      {tags && (
        <Box>
          {details?.tags?.length > 0 ? (
            details?.tags?.map((tag: any, index: number) => (
              <Box
                key={index}
                className={classes.tagContainer}
                sx={{ justifyContent: "space-between" }}
              >
                <Typography sx={{ fontSize: "12px", padding: "5px" }}>
                  {tag}
                </Typography>
                <Tooltip
                  title="Access Denied"
                  placement="left"
                  open={updateTagTooltipOpen === tag}
                  onClose={() => setUpdateTagTooltipOpen("")}
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => handleRemoveTagFromContact(tag)}
                  >
                    <ChatUserCloseSvg />
                  </Box>
                </Tooltip>
              </Box>
            ))
          ) : (
            <Box
              className={classes.tagContainer}
              sx={{ justifyContent: "center" }}
            >
              <Typography sx={{ fontSize: "12px", padding: "5px" }}>
                No tags added yet
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TagsDropdownComponent;
