import { Variable } from "aws-sdk/clients/apptest";
import { EditorState, Modifier } from "draft-js";

const dictionary = {
  Name: "Name",
  //   CountryCode: "CountryCode",
  CountryName: "CountryName",
  Contact: "Contact",
  Email: "Email",
};

export const dictionaryOptions = Object.entries(dictionary).map(
  ([key, value]) => ({
    key,
    value,
  })
);

export const ButtonOptions = [
  { value: "", label: "None" },
  { value: "button", label: "Button" },
  { value: "list", label: "List" },
];

export const variableOptions = [
  // { value: "User Trait", label: "User Trait" },
  // { value: "Workflow Variable", label: "Workflow Variable" },
  { value: "Variable", label: "Variable" },
];

export const SaveResponseRadioOptions = [
  // { value: "trait", label: "User Trait" },
  // { value: "variable", label: "Workflow Variable" },
  { value: "Variable", label: "Variable" },
];

export const getNextVariableCount = (editorState: EditorState): number => {
  const content = editorState?.getCurrentContent()?.getPlainText();
  const variableMatches = content?.match(/\{\{(\d+)\}\}/g);
  if (!variableMatches) return 1;
  const variableNumbers = variableMatches?.map((match) =>
    parseInt(match.replace(/\{\{|\}\}/g, ""), 10)
  );
  return Math.max(...variableNumbers) + 1;
};

export const insertTextAtCursor = (
  editorState: EditorState,
  text: string
): EditorState => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const newContentState = Modifier.insertText(
    contentState,
    selectionState,
    text
  );
  return EditorState.push(editorState, newContentState, "insert-characters");
};

export const extractVariablesInWorkflows = (
  editorState: EditorState,
  existingVariables: Variable[] = []
): Variable[] => {
  const content = editorState.getCurrentContent().getPlainText();
  const variableRegex = /\{\{(\d+)\}\}/g;
  const matches: any = content.match(variableRegex);

  if (!matches)
    return existingVariables.filter((v: any) => content.includes(v.veriable));

  return matches?.map((match: any) => {
    const variable = match.replace(/\{\{|\}\}/g, "");
    const existingVar = existingVariables.find(
      (v: any) => v.veriable === `{{${variable}}}`
    );
    return (
      existingVar || {
        veriable: `{{${variable}}}`,
        type: "User Trait",
        value: "",
        fallbackValue: "",
      }
    );
  });
};

export const extractVariables = (
  editorState: EditorState,
  existingVariables: Variable[] = []
): Variable[] => {
  const content = editorState.getCurrentContent().getPlainText();
  const variableRegex = /\{\{(\d+)\}\}/g;
  const matches: any = content.match(variableRegex);

  if (!matches)
    return existingVariables.filter((v: any) => content.includes(v.veriable));

  return matches?.map((match: any) => {
    const variable = match.replace(/\{\{|\}\}/g, "");
    const existingVar = existingVariables.find(
      (v: any) => v.veriable === `{{${variable}}}`
    );
    return (
      existingVar || {
        veriable: `{{${variable}}}`,
        type: "User Trait",
        value: "",
        fallbackValue: "",
      }
    );
  });
};
