import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { INBOX_APIS } from "../../../Apis/Inbox/InboxApis";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const fetchInboxContactAssignment = createAsyncThunk(
  "fetchInboxContactAssignment",
  async (data: any) => {
    const response = await INBOX_APIS.fetchInboxContactAssignment(data);
    return response?.data;
  }
);

export const InboxContactAssignmentSlice = createSlice({
  name: "InboxContactAssignmentSlice",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInboxContactAssignment.pending, (state) => {
        state.status = "loading";
        //   state.error = ""
        state.data = [];
      })
      .addCase(fetchInboxContactAssignment.fulfilled, (state, action) => {
        state.status = "succeeded";
        //   state.error = ""
        state.data = action.payload;
      })
      .addCase(fetchInboxContactAssignment.rejected, (state) => {
        state.status = "failed";
        state.data = [];
        //   state.error = action.error.message || "";
      });
  },
});

export const inboxContactConversationsSlice =
  InboxContactAssignmentSlice.actions;
export default InboxContactAssignmentSlice.reducer;
