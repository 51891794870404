import React from "react";

const CloudSvg = () => {
  return (
    <svg
      width="76"
      height="64"
      viewBox="0 0 76 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1696 55.95C17.8441 55.6703 13.7341 54.2469 10.4265 51.883C7.11895 49.5191 4.78286 46.3355 3.75185 42.7869C2.72083 39.2383 3.04762 35.5062 4.68553 32.1233C6.32345 28.7404 9.18875 25.8798 12.8723 23.95C13.6561 18.8036 16.64 14.074 21.2656 10.6464C25.8912 7.21884 31.8415 5.32812 38.003 5.32812C44.1644 5.32812 50.1147 7.21884 54.7403 10.6464C59.3659 14.074 62.3498 18.8036 63.1336 23.95C66.8172 25.8798 69.6825 28.7404 71.3204 32.1233C72.9583 35.5062 73.2851 39.2383 72.2541 42.7869C71.2231 46.3355 68.887 49.5191 65.5794 51.883C62.2718 54.2469 58.1618 55.6703 53.8363 55.95V56.0007H22.1696V55.95ZM41.1696 34.6673H50.6696L38.003 21.334L25.3363 34.6673H34.8363V45.334H41.1696V34.6673Z"
        fill="#999999"
      />
    </svg>
  );
};

export default CloudSvg;
