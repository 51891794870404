import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WORKFLOW_API } from "../../../Apis/AdminLogin/Automation/Workflows";

export interface IData {
    workflowListStatus: "loading" | "succeeded" | "failed" | "idle";
    workflowListData: any;
  }

  const initialState: IData = {
    workflowListStatus: "idle",
    workflowListData: null,
  };

  export const getWorkflowList = createAsyncThunk(
    "workflow/getWorkflowList",
    async (payload: any, { rejectWithValue }) => {
      try {
        const response = await WORKFLOW_API.getWorkflowList(payload?.companyId);
        return response?.data;
      } catch (error: any) {
        // Provide a descriptive error message
        return rejectWithValue(error.response || 'An error occurred');
      }
    }
  );


  export const getWorkflowListSlice = createSlice({
    name: "getWorkflowList",
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
      builder
        .addCase(getWorkflowList.pending, (state) => {
          state.workflowListStatus = "loading";
          state.workflowListData = null;
        })
        .addCase(getWorkflowList.fulfilled, (state, action) => {
          state.workflowListStatus = "succeeded";
          state.workflowListData = action.payload.data;
        })
        .addCase(getWorkflowList.rejected, (state) => {
          state.workflowListStatus = "failed";
        });
    },
  });


export const getWorkflowListSliceActions = getWorkflowListSlice.actions;
export default getWorkflowListSlice.reducer;
