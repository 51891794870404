import React from "react";
import {
  validatePhoneNumber,
  validateVariablesContainedText,
} from "../../../utils/functions";

interface TemplateState {
  templateName: string;
  category: number;
  subCategory: string;
  language: number;
  mediaType: number;
  mediaFile: any;
  header: string;
  body: string;
  callButtonName: string;
  phoneNumber: string;
  countryCode: string;
  urlButtonName: string[];
  redirectUrl: string[];
  quickReply: string[];
  footer: string;
  buttons: {
    buttonType: string;
    buttonValue: string;
    countryCode: string;
    buttonName?: string;
  }[];
  variables: {
    type: string;
    id: string;
    value: string;
    field: string;
    fallBackValue: string;
  }[];
}

interface FormErrors {
  [key: string]: string;
}

interface ValidationProps {
  templateState: TemplateState;
  formErrors: FormErrors;
  setFormErrors: React.Dispatch<React.SetStateAction<FormErrors>>;
}

const useValidation = ({
  templateState,
  formErrors,
  setFormErrors,
}: ValidationProps) => {
  // console.log(templateState)

  const isFormValid = () => {
    const errors = { ...formErrors };
    let isValid = true;

    const trimmedTemplateName = templateState.templateName?.trim();

    if (!trimmedTemplateName) {
      errors.templateName = "Template name is required";
      isValid = false;
    } else if (!/^[a-z0-9_]+$/.test(trimmedTemplateName)) {
      errors.templateName =
        "Template name must contain only lowercase letters, integers, and underscore";
      isValid = false;
    } else {
      errors.templateName = ""; // Clear error if field has a value
    }

    if (templateState.category) {
      errors.category = "";
    } else {
      errors.category = "Category is required";
      isValid = false;
    }

    if (templateState.language) {
      errors.language = "";
    } else {
      errors.language = "Language is required";
      isValid = false;
    }

    if (templateState?.subCategory?.trim()) {
      errors.subCategory = "";
      if (templateState.subCategory.length > 30) {
        errors.subCategory = "Sub Category must not exceed 30 characters";
        isValid = false;
      }
    } else {
      errors.subCategory = "Sub Category is required";
      isValid = false;
    }

    if (templateState.mediaType) {
      errors.mediaType = "";
    } else {
      errors.mediaType = "Media Type is required";
      isValid = false;
    }

    if (templateState?.body?.trim()) {
      errors.body = "";

      const isValidBody = validateVariablesContainedText(
        "body",
        templateState.body
      );
      if (!isValidBody) {
        errors.body = `Click on the info icon for details regarding variable usage.`;
        isValid = false;
      }

      // Check if the body length exceeds the limit
      if (templateState.body.length > 1024) {
        errors.body = "Body must not exceed 1024 characters";
        isValid = false;
      }

      // Check for excessive spaces between lines (more than one empty line)
      const excessiveEmptyLinesPattern = /\n\s*\n\s*\n\s*\n/;
      if (excessiveEmptyLinesPattern.test(templateState.body)) {
        errors.body = "Body contains excessive spaces between lines";
        isValid = false;
      }

      // Check for excessive spaces within a line (e.g., more than 10 consecutive spaces)
      const excessiveSpacesPattern = / {10,}/;
      if (excessiveSpacesPattern.test(templateState.body)) {
        errors.body = "Body contains excessive spaces within a line";
        isValid = false;
      }
    } else {
      errors.body = "Body is required";
      isValid = false;
    }

    if (templateState.mediaType === 2) {
      if (templateState?.header?.trim()) {
        errors.header = "";
        if (templateState.header.length > 60) {
          errors.header = "Header text must not exceed 60 characters";
          isValid = false;
        }
      } else {
        errors.header = "Header is required";
        isValid = false;
      }
      const isValidHeader = validateVariablesContainedText(
        "header",
        templateState.header
      );
      if (!isValidHeader) {
        errors.header = `Click on the info icon for details regarding variable usage.`;
        isValid = false;
      }
    }

    if ([3, 4, 5].includes(templateState.mediaType)) {
      if (!templateState.mediaFile) {
        errors.mediaFile = "Media file is required for the selected media type";
        isValid = false;
      }
    }

    const phoneButtonArray = templateState?.buttons?.filter(
      (button) => button?.buttonType === "PHONE_NUMBER"
    );
    // const urlButtonArray = templateState.buttons.filter(
    //   (button) => button?.buttonType === "URL"
    // );
    const phoneButtonPresent = templateState?.buttons?.some(
      (button) => button?.buttonType === "PHONE_NUMBER"
    );
    const phoneButtonValuePresent = templateState?.buttons?.some(
      (button) =>
        button?.buttonType === "PHONE_NUMBER" && button?.buttonValue === ""
    );
    const phoneButtonLabelPresent = templateState?.buttons?.some(
      (button) =>
        button?.buttonType === "PHONE_NUMBER" && button?.buttonName === ""
    );
    const urlButtonPresent = templateState?.buttons?.some(
      (button) => button?.buttonType === "URL"
    );
    const urlButtonValuePresent = templateState?.buttons?.some(
      (button) => button?.buttonType === "URL" && button?.buttonValue === ""
    );
    const urlButtonLabelPresent = templateState?.buttons?.some(
      (button) => button?.buttonType === "URL" && button?.buttonName === ""
    );
    const quickReplyPresent = templateState?.buttons?.some(
      (button) => button?.buttonType === "QUICK_REPLY"
    );
    const quickReplyValuePresent = templateState?.buttons?.some(
      (button) =>
        button?.buttonType === "QUICK_REPLY" && button?.buttonValue === ""
    );
    // console.log(
    //   "phone and url buttons present",
    //   phoneButtonArray,
    //   urlButtonArray
    // );

    if (phoneButtonPresent && phoneButtonValuePresent) {
      //   if (!templateState.phoneNumber?.trim()) {
      errors.phoneNumber = "Phone number is required.";
      isValid = false;
    } else if (
      phoneButtonArray?.length !== 0 &&
      !validatePhoneNumber(
        phoneButtonArray[0]?.buttonValue,
        phoneButtonArray[0]?.countryCode
      )
    ) {
      errors.phoneNumber =
        "This phone number format is not recognized. Please check the country code and phone number.";
      isValid = false;
    } else {
      errors.phoneNumber = ""; // Clear error if phone number is present
    }
    if (phoneButtonPresent && phoneButtonLabelPresent) {
      //   if (!templateState.callButtonName?.trim()) {
      errors.callButtonName = "Phone button name is required.";
      isValid = false;
    } else if (
      phoneButtonArray?.length !== 0 &&
      phoneButtonArray[0]?.buttonName &&
      phoneButtonArray[0]?.buttonName?.length > 20
    ) {
      errors.callButtonName =
        "Phone button name should not exceed 20 characters.";
      isValid = false;
    } else {
      errors.callButtonName = ""; // Clear error if call button name is present
      //   }
    }

    if (urlButtonPresent && urlButtonValuePresent) {
      //   if (
      //     !templateState.redirectUrl?.length ||
      //     templateState.redirectUrl?.some((url: any) => url?.trim() === "")
      //   ) {
      errors.redirectUrl = "Redirect URL is required.";
      isValid = false;
    } else {
      errors.redirectUrl = ""; // Clear error if redirect URL is present
    }

    if (urlButtonPresent && urlButtonLabelPresent) {
      errors.urlButtonName = "URL button name is required.";
      isValid = false;
    } else {
      errors.urlButtonName = ""; // Clear error if URL button name is present
    }

    if (quickReplyPresent && quickReplyValuePresent) {
      errors.quickReply = "Quick reply is required.";
      isValid = false;
    } else {
      errors.quickReply = ""; // Clear error if quick reply is present
    }

    // Validate footer
    if (templateState?.footer?.trim()) {
      errors.footer = "";
      if (templateState?.footer?.length > 60) {
        errors.footer = "Footer text must not exceed 60 characters";
        isValid = false;
      }
    }
    // else {
    //   errors.footer = ""; // Clear error if footer is valid
    // }

    setFormErrors(errors);
    return { errors, isValid };
  };
  //   console.log("formErrors1", templateState, formErrors);

  return { isFormValid };
};

export default useValidation;
