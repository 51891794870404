import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CAMPAIGN_API } from "../../../Apis/Campaign/Campaign";

export interface IData {
    status: "loading" | "succeeded" | "failed" | "idle";
    data: any;
  }

  const initialState: IData = {
    status: "idle",
    data: null,
  };

  export const updateCampaign = createAsyncThunk(
    "updateCampaign",
    async ( payload: {campaignId: any, data1: any,}, { rejectWithValue }) => {
      try {
        const response = await CAMPAIGN_API.updateCampaign(payload.campaignId, payload.data1);
        return response?.data;
      } catch (error: any) {
        // Provide a descriptive error message
        return rejectWithValue(error.response || 'An error occurred');
      }
    }
  );


  export const updateCampaignSlice = createSlice({
    name: "updateCampaignSlice",
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
      builder
        .addCase(updateCampaign.pending, (state) => {
          state.status = "loading";
          state.data = null;
        })
        .addCase(updateCampaign.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.data = action.payload;
        })
        .addCase(updateCampaign.rejected, (state) => {
          state.status = "failed";
        });
    },
  });


export const updateCampaignActions = updateCampaignSlice.actions;
export default updateCampaignSlice.reducer;
