import React from 'react'

const DeleteSvg = ({style}:{style?:React.CSSProperties}) => {
  return (
    <svg style={style} width="16" height="16" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.3333 13C10.5333 13 10 13.5333 10 14.3333V19.6667C10 20.4667 10.5333 21 11.3333 21C12.1333 21 12.6667 20.4667 12.6667 19.6667V14.3333C12.6667 13.5333 12.1333 13 11.3333 13ZM16.6667 13C15.8667 13 15.3333 13.5333 15.3333 14.3333V19.6667C15.3333 20.4667 15.8667 21 16.6667 21C17.4667 21 18 20.4667 18 19.6667V14.3333C18 13.5333 17.4667 13 16.6667 13Z" fill='#808080'/>
<path d="M16.0625 0H11.9375C10.0125 0 8.5 1.54 8.5 3.5V4.2H4.375C3.55 4.2 3 4.76 3 5.6C3 6.44 3.55 7 4.375 7H4.5125L5.6125 22.68C5.75 25.76 8.0875 28 10.975 28H17.025C19.9125 28 22.3875 25.76 22.525 22.82L23.4875 7H23.625C24.45 7 25 6.44 25 5.6C25 4.76 24.45 4.2 23.625 4.2H19.5V3.5C19.5 1.54 17.9875 0 16.0625 0ZM11.25 3.5C11.25 3.08 11.525 2.8 11.9375 2.8H16.0625C16.475 2.8 16.75 3.08 16.75 3.5V4.2H11.25V3.5ZM20.7375 7L19.6375 22.54C19.5 24.08 18.4 25.2 16.8875 25.2H10.975C9.4625 25.2 8.3625 24.08 8.225 22.54L7.2625 7H20.7375Z" fill='#808080'/>
</svg>

  )
}

export default DeleteSvg
