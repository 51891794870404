import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WORKFLOW_API } from "../../../Apis/AdminLogin/Automation/Workflows";

export interface IData {
    updateSelectResponseStatus: "loading" | "succeeded" | "failed" | "idle";
    updateSelectResponseData: any;
}

const initialState: IData = {
    updateSelectResponseStatus: "idle",
    updateSelectResponseData: null,
};

export const updateSelectResponse = createAsyncThunk(
    "autoReply/updateSelectResponse",
    async (payload: { selectResponseId: string, response: string }, { rejectWithValue }) => {
        try {
            const response = await WORKFLOW_API.updateSelectResponse(
                payload.selectResponseId,
                payload.response
            );
            return response?.data;
        } catch (error: any) {
            // Provide a descriptive error message
            return rejectWithValue(error.response || 'An error occurred');
        }
    }
);


export const updateSelectResponseSlice = createSlice({
    name: "updateWorkflow",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(updateSelectResponse.pending, (state) => {
                state.updateSelectResponseStatus = "loading";
                state.updateSelectResponseData = null;
            })
            .addCase(updateSelectResponse.fulfilled, (state, action) => {
                state.updateSelectResponseStatus = "succeeded";
                state.updateSelectResponseData = action.payload;
            })
            .addCase(updateSelectResponse.rejected, (state) => {
                state.updateSelectResponseStatus = "failed";
            });
    },
});


export const updateSelectResponseSliceActions = updateSelectResponseSlice.actions;
export default updateSelectResponseSlice.reducer;
