import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AUTOMATION_API } from "../../../Apis/AdminLogin/Automation/Automation";

export interface IData {
    status: "loading" | "succeeded" | "failed" | "idle";
    data: any;
  }

  const initialState: IData = {
    status: "idle",
    data: null,
  };

  export const getInboxSettings = createAsyncThunk(
    "getInboxSettings",
    async (data: any, { rejectWithValue }) => {
      try {
        const response = await AUTOMATION_API.getInboxSettings(data);
        return response?.data;
      } catch (error: any) {
        // Provide a descriptive error message
        return rejectWithValue(error.response || 'An error occurred');
      }
    }
  );


  export const getInboxSettingsSlice = createSlice({
    name: "getInboxSettingsSlice",
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
      builder
        .addCase(getInboxSettings.pending, (state) => {
          state.status = "loading";
          state.data = null;
        })
        .addCase(getInboxSettings.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.data = action.payload;
        })
        .addCase(getInboxSettings.rejected, (state) => {
          state.status = "failed";
        });
    },
  });


export const getInboxSettingsActions = getInboxSettingsSlice.actions;
export default getInboxSettingsSlice.reducer;
