/* global process */

import React from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import {
  fetchAllDiscounts,
  fetchDiscountByCode,
  getWalletAndSubscription,
} from "../../redux/slices/Wallet/WalletSlice";
import { useEffect, useState } from "react";
import LoadingComponent from "../common/LoadingComponent";
import { toastActions } from "../../utils/toastSlice";
import axios from "axios";
import { fetchPlanDetailsByPlanId } from "../../redux/slices/Subscription/SubscriptionSlice";

const USER_API_URL = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "8px",
    height: "100%",
    width: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  emailContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems: "center",
    gap: "10px",
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
    // marginRight: "100px",
  },
  updateButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "50px",
    borderRadius: "12px",
    width: "100%",
    fontSize: "20px ",
    fontWeight: "100",
    cursor: "pointer",
  },
  icon: {
    fontSize: "100px",
  },
  inputField: {
    borderRadius: "60px",
    height: "50px",
    width: "100%",
  },
});
const PaymentSummary = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const loginData = useAppSelector((state: any) => state.adminLogin.data);
  // const roleId = loginData?.roleId;
  const companyId = loginData?.companyId;

  const currentPlan = useAppSelector(
    (state: any) => state?.wallet?.walletAndSubscription?.data
  );
  // console.log(currentPlan);
  const planDetailsStatus = useAppSelector(
    (state:any) => state?.Subscription?.planDetailsStatus
  );
  const planDetails = useAppSelector(
    (state:any) => state?.Subscription?.planDetailsByPlanId?.data
  );
  // console.log(planDetails);

  const expiryDate = new Date(planDetails?.expiryDate);
  const formattedDate = expiryDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const planStatus = useAppSelector(
    (state: any) => state?.wallet?.walletAndSubscriptionStatus
  );
  // console.log(planStatus)

  const discountData = useAppSelector(
    (state: any) => state?.wallet?.fetchAllDiscountsData?.data
  );
  // console.log(discountData)

  // const discountDataRes = useAppSelector(
  //   (state) => state?.wallet?.fetchDiscountByCodeData
  // );
  // // console.log(discountDataRes)

  // const currentPlanDetails = useAppSelector(
  //   (state) => state?.Subscription?.currentPlan?.data
  // );
  // console.log(currentPlanDetails)

  const getCreateOrderStatus = useAppSelector(
    (state: any) => state?.Subscription?.createOrderStatus
  );
  // console.log(getCreateOrderStatus);

  const getDiscountStatus = useAppSelector(
    (state: any) => state?.wallet?.fetchDiscountByCodeStatus
  );
  // console.log(getDiscountStatus)

  // const handleSave = () => {
  //   // handleClose();
  //   navigate("card-details");
  // };

  const loadScript = (src: string) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (
    PlanId: number,
    amount: number,
    IGST: number,
    totalAmount: number,
    planName: string,
    planType: string,
    discountId: string
  ) => {
    // console.log(amount, planName, planType)
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const getPlanDetails = await dispatch(
      fetchPlanDetailsByPlanId({
        PlanId,
        planType,
        companyId,
        isUpgradePlan: false,
      })
    );
    // console.log(getPlanDetails?.payload?.data);
    const planData = getPlanDetails?.payload?.data;

    // const amountInPaise = parseFloat(amount.toString()) * 100; // Convert to paise
    // const amountInPaise = parseFloat(amount.toString()); // Convert to paise
    const amountInPaise = parseInt(totalAmount.toString()); // Convert to paise
    // const actualAmount = parseInt(amount.toString());

    // console.log(amountInPaise)
    const data = {
      // totalAmount: amountInPaise.toString(),
      totalAmount: planData?.totalAmount,
      // igstAmount: IGST,
      igstAmount: planData?.igstAmount,
      // amount: actualAmount.toString(),
      amount: planData?.planAmount,
      subscriptionPlanId: planName,
      durationType: planType,
      discountId: discountId,
      isUpgradePlan: false,
    };
    // console.log(data)
    try {
      const rol = await axios.post(
        `${USER_API_URL}/PaymentsDetails/create-order`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      // console.log(rol, "rol");
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: amountInPaise,
        currency: "INR",
        order_id: rol?.data?.orderId,
        handler: async function (response: any) {
          const data = {
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            isUpgradePlan: false,
          };

          const result = await axios.post(
            `${USER_API_URL}/PaymentsDetails/verify-signature`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
              },
            }
          );
          // console.log(result, "result");
          if (result?.data === "Payment verification successful.") {
            dispatch(
              toastActions.setToaster({
                type: "success",
                message: `${result?.data}`,
              })
            );
            // dispatch(fetchCurrentPlanDetails(userId));
            dispatch(getWalletAndSubscription(companyId));
            navigate("/profile/subscription");
          }
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);

      paymentObject.on("payment.failed", function (response: any) {
        // console.log(response, "response");
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `${response.error.reason}`,
          })
        );
        // axios.post("https://user.engageto.com/api/PaymentsDetails/payment-failed", {
        //   code: response.error.code,
        //   description: response.error.description,
        //   source: response.error.source,
        //   step: response.error.step,
        //   reason: response.error.reason,
        //   order_id: response.error.metadata.order_id,
        //   payment_id: response.error.metadata.payment_id
        // }).then(result => {
        //   console.log(result);
        //   dispatch(toastActions.setToaster({
        //     type: "error",
        //     message: `Payment failed: ${response.error.reason}`,
        //   }));
        // });
      });

      paymentObject.on("payment.pending", function (pending: any) {
        // console.log(pending, "pending");
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `${pending.error.reason}`,
          })
        );
        // axios.post("https://user.engageto.com/api/PaymentsDetails/payment-failed", {
        //   code: response.error.code,
        //   description: response.error.description,
        //   source: response.error.source,
        //   step: response.error.step,
        //   reason: response.error.reason,
        //   order_id: response.error.metadata.order_id,
        //   payment_id: response.error.metadata.payment_id
        // }).then(result => {
        //   console.log(result);
        //   dispatch(toastActions.setToaster({
        //     type: "error",
        //     message: `Payment failed: ${response.error.reason}`,
        //   }));
        // });
      });
      paymentObject.open();
    } catch (error: any) {
      // console.log(error);

      let errorMessage = "An unexpected error occurred.";
      if (error.response && error.response.data) {
        try {
          const errorData = JSON.parse(error.response.data);
          errorMessage = errorData.message || errorMessage;
        } catch (parseError) {
          errorMessage =
            error.response.data.message || errorMessage || parseError;
        }
      }

      dispatch(
        toastActions.setToaster({
          type: "error",
          message: errorMessage,
        })
      );
    }
  };
  const [discountCode, setDiscountCode] = useState("");
  // console.log(discountCode)
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  // console.log(appliedDiscount)
  const [discountId, setDiscountId] = useState("");
  // console.log(discountId)

  const [discountError, setDiscountError] = useState(false);
  // console.log(discountError)
  const [errorMessage, setErrorMessage] = useState("");
  // console.log(errorMessage)

  const handleCouponCodeChange = (event: any) => {
    // console.log(event)
    setDiscountCode(event);
    setDiscountError(false);
    setErrorMessage("");
  };

  const handleApplyDiscount = async () => {
    // const isError =
    //   discountCode === ""
    //     ? (setDiscountError(true), setErrorMessage("Coupon Code is required."))
    //     : setDiscountError(false);
    // console.log(isError, 'isError')

    const discount = discountData?.find(
      (discount: any) => discount?.discountCode === discountCode
    );

    // console.log(discount)
    // console.log(discount)

    if (discountCode !== "") {
      if (discount) {
        if (discount.discountType === 1) {
          // Percentage discount
          // setAppliedDiscount((currentAmount * discount.discountValue) / 100);
          const data = {
            discountCode: discountCode,
            companyId: companyId,
          };
          const response: any = await dispatch(fetchDiscountByCode(data));
          // console.log(response)
          const res = response?.payload;
          // console.log(res)
          // console.log(response?.payload)

          if (response?.error?.message) {
            dispatch(
              toastActions.setToaster({
                type: "error",
                message: `${response?.payload}`,
              })
            );
          } else {
            setAppliedDiscount((currentAmount * discount.discountValue) / 100);
            setDiscountId(res?.data?.discountId);
            dispatch(
              toastActions.setToaster({
                type: "success",
                message: `${response?.payload?.message}`,
              })
            );
          }
        } else if (discount.discountType === 2) {
          // Fixed amount discount
          // setAppliedDiscount(discount.discountValue);
          const data = {
            discountCode: discountCode,
            companyId: companyId,
          };
          const response: any = await dispatch(fetchDiscountByCode(data));
          // console.log(response)
          const res = response?.payload;
          // console.log(response?.payload)
          if (response?.error?.message) {
            dispatch(
              toastActions.setToaster({
                type: "error",
                message: `${response?.payload}`,
              })
            );
          } else {
            setAppliedDiscount(discount.discountValue);
            setDiscountId(res?.data?.discountId);
            dispatch(
              toastActions.setToaster({
                type: "success",
                message: `${response?.payload?.message}`,
              })
            );
          }
        }
      } else {
        setAppliedDiscount(0);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `Invalid Coupon Code`,
          })
        );
      }
      // Optionally, you can display a message to the user that the code is invalid or inactive
    }
  };

  const calculateIGST = (amount: any) => {
    const IGST_RATE = 0.18;
    return amount * IGST_RATE;
  };

  // const calculateNewExpirationDate = (currentEndDate: any, planType: any) => {
  //   const endDate = new Date(currentEndDate);

  //   if (planType === "Monthly") {
  //     endDate.setMonth(endDate.getMonth() + 1);
  //   } else if (planType === "Quarterly") {
  //     endDate.setMonth(endDate.getMonth() + 3);
  //   } else if (planType === "Annually") {
  //     endDate.setFullYear(endDate.getFullYear() + 1);
  //   }

  //   // Subtract one day from the end date
  //   endDate.setDate(endDate.getDate() - 1);
  //   // console.log(endDate);
  //   return endDate;
  // };

  const currentAmount =
    currentPlan?.subscriptionPlan?.currentSubscriptionPlanAmount || 0;
  // const currentPlanType = currentPlan?.subscriptionPlan?.planType;
  // // console.log(currentPlanType)
  // const planEndDate = currentPlan?.subscriptionPlan?.planEndDate;
  // // console.log(planEndDate);

  // const newExpirationDate = planEndDate
  //   ? calculateNewExpirationDate(planEndDate, currentPlanType)
  //   : null;
  // const formattedNewExpirationDate = newExpirationDate
  //   ? newExpirationDate.toLocaleDateString()
  //   : "";

  // const currentAmount = currentPlanDetails?.planDetails?.price || 0;

  const IGST = calculateIGST(currentAmount - appliedDiscount); // Calculate IGST after applying discount
  const discountedAmount = currentAmount - appliedDiscount;
  // const totalAmount = discountedAmount + IGST;
  const totalAmount = discountedAmount;

  // console.log('Current Amount:', currentAmount);
  // console.log('Applied Discount:', appliedDiscount);
  // console.log('Discounted Amount:', discountedAmount);
  // console.log('IGST:', IGST);
  // console.log('Total Amount:', totalAmount);

  useEffect(() => {
    // dispatch(fetchCurrentPlanDetails(companyId));
    // dispatch(getWalletAndSubscription(companyId))
    dispatch(fetchAllDiscounts());
    const data = {
      PlanId: currentPlan?.subscriptionPlan?.planId,
      planType: currentPlan?.subscriptionPlan?.planType,
      companyId,
      isUpgradePlan: false,
    };
    dispatch(fetchPlanDetailsByPlanId(data));
    // dispatch(fetchDiscountByCode())
  }, []);

  return (
    <Grid>
      <Box>
        <Typography
          variant="subtitle1"
          style={{ marginBottom: "18px", fontWeight: "bold" }}
        >
          Payment Method
        </Typography>
        <Box p={2} className={classes.mainBorderStyles}>
          <Box marginBottom={5}>
            <Typography variant="h6" className={classes.blackColor}>
              Summary
            </Typography>
            {/* {newExpirationDate && ( */}
            <Typography
              variant="body1"
              sx={{
                fontSize: "15px",
                color: "green",
                marginTop: "10px",
                //  marginBottom: "20px"
              }}
            >
              After extending the plan, you will have validity until {""}
              {planDetailsStatus === "loading"
                ? // <LoadingComponent height="100%" color={bgColors.blue} />
                  "..."
                : // : newExpirationDate && formattedNewExpirationDate}
                  formattedDate}
            </Typography>
            {/* )} */}
          </Box>

          <Box className={classes.emailContainer} mb={3}>
            <Typography variant="body1" sx={{ fontSize: "15px" }}>
              Add Balance
            </Typography>
            <Box>
              {planStatus !== "loading" ? (
                <Typography
                  variant="body2"
                  style={{
                    color: "gray",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CurrencyRupeeIcon
                    style={{ fontSize: "1.2em", marginRight: "1px" }}
                  />
                  {currentAmount.toLocaleString()}
                </Typography>
              ) : (
                <LoadingComponent height="100%" color={bgColors.blue} />
              )}
            </Box>
          </Box>
          <Box className={classes.emailContainer} mb={3}>
            <TextField
              margin="dense"
              id="discountCode"
              size="small"
              label="Gift or discount code"
              type="text"
              fullWidth
              value={discountCode}
              // onChange={(e) => setDiscountCode(e.target.value)}
              onChange={(e) => {
                handleCouponCodeChange(e.target.value);
              }}
              error={discountError}
              helperText={errorMessage}
            />
            <button
              style={{
                backgroundColor: "gray",
                color: "white",
                height: "42px",
                borderRadius: "12px",
                padding: "20spx",
                cursor: "pointer",
                marginTop: "8px",
              }}
              onClick={handleApplyDiscount}
            >
              {getDiscountStatus === "loading" ? "Applying" : "Apply"}
            </button>
          </Box>
          <Box className={classes.emailContainer} mb={3}>
            <Typography variant="body1" sx={{ fontSize: "15px" }}>
              Subtotal
            </Typography>
            <Box>
              <Typography
                variant="body2"
                style={{ color: "gray", display: "flex", alignItems: "center" }}
              >
                <CurrencyRupeeIcon
                  style={{ fontSize: "1.2em", marginRight: "1px" }}
                />
                {currentAmount.toLocaleString()}
              </Typography>
            </Box>
          </Box>
          {appliedDiscount > 0 && (
            <Box className={classes.emailContainer} mb={3}>
              <Typography variant="body1" sx={{ fontSize: "15px" }}>
                Discount Applied
              </Typography>
              <Box>
                <Typography
                  variant="body2"
                  style={{
                    color: "gray",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CurrencyRupeeIcon
                    style={{ fontSize: "1.2em", marginRight: "1px" }}
                  />
                  {appliedDiscount.toLocaleString()}
                </Typography>
              </Box>
            </Box>
          )}
          {planDetailsStatus === "loading" ? (
            <LoadingComponent height="180px" color={bgColors?.blue} />
          ) : (
            <>
              <Box className={classes.emailContainer} mb={3}>
                <Typography variant="body1" sx={{ fontSize: "15px" }}>
                  IGST ({planDetails?.igst})
                </Typography>
                <Box>
                  <Typography
                    variant="body2"
                    style={{
                      color: "gray",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CurrencyRupeeIcon
                      style={{ fontSize: "1.2em", marginRight: "1px" }}
                    />
                    {planDetails?.igstAmount?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.emailContainer} mb={8}>
                <Typography variant="body1" sx={{ fontSize: "15px" }}>
                  Total
                </Typography>
                <Box>
                  <Typography
                    variant="body2"
                    style={{
                      color: "black",
                      fontSize: "20px",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <CurrencyRupeeIcon
                      style={{
                        fontSize: "0.6em",
                        marginRight: "1px",
                        color: "gray",
                      }}
                    />
                    {planDetails?.totalAmount?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          <Box width="100%">
            {getCreateOrderStatus != "loading" ? (
              <button
                onClick={() => {
                  displayRazorpay(
                    currentPlan?.subscriptionPlan?.planId,
                    currentAmount,
                    IGST,
                    totalAmount,
                    currentPlan?.subscriptionPlan?.planName,
                    currentPlan?.subscriptionPlan?.planType,
                    discountId
                  );
                }}
                className={classes.updateButtonStyles}
              >
                Make Payment
              </button>
            ) : (
              <LoadingComponent height="auto" color={bgColors?.blue} />
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default PaymentSummary;
