import { Box, Grid, Typography } from "@mui/material";
import EditSvg from "../../assets/svgs/EditSvg";
import { makeStyles } from "@mui/styles";
import { bgColors } from "../../utils/bgColors";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { billingDetails } from "../../redux/slices/Wallet/WalletSlice";
import LoadingComponent from "../../components/common/LoadingComponent";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100%",
    width: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  editButtonContainer: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    // backgroundColor: bgColors.blue,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "90px",
    height: "32px",
    // padding: "10px",
    gap: "0px",
  },
  editButton: {
    backgroundColor: "transparent",
    color: bgColors.green,
    cursor: "pointer",
    // borderRadius: "px",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
  },
  profileContainer: {
    display: "flex",
    flexDirection: "row",
  },
  emailContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "200px",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
    fontWeight: "500 !important",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "500 !important",
  },

  singleLine: {
    whiteSpace: "nowrap",
  },
});

const BillingDetails = () => {
  // const isMobile = useMediaQuery("(max-width:600px)");
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleEditClick = () => {
    navigate("/billing-details/edit");
  };

  const loginData = useAppSelector((state: any) => state.adminLogin.data);
  // console.log(loginData)
  const companyId = loginData?.companyId;
  const billingDetailsData = useAppSelector(
    (state:any) => state.wallet.billingDetailsData.company
  );
  // console.log(billingDetailsData);
  const billingDetailsstatus = useAppSelector(
    (state:any) => state?.wallet?.billingDetailsstatus
  );
  // console.log(billingDetailsstatus)
  useEffect(() => {
    dispatch(billingDetails(companyId));
  }, []);

  return (
    <Grid className={classes.mainContainer}>
      <Grid className={classes.bgContainer}>
        <Box className={classes.manageContainer}>
          <Typography
            m={4}
            variant="h6"
            className={classes.blackColor}
            sx={{ display: "flex" }}
          >
            <span style={{ marginTop: "4px" }}>
              <ReceiptLongIcon sx={{ height: "22px" }} />
            </span>
            &nbsp;Billing Details
          </Typography>
          <Box
            m={2}
            mt={3}
            className={classes.editButtonContainer}
            onClick={handleEditClick}
            sx={{
              "&:hover": {
                backgroundColor: "rgba(68, 71, 70, 0.08)",
                cursor: "pointer",
              },
            }}
          >
            <button className={classes.editButton}>Edit</button>
            <Box className={classes.flexCenter}>
              <EditSvg
                style={{
                  fill: bgColors.green,
                }}
              />
            </Box>
          </Box>
        </Box>
        <>
          <Box m={4}>
            <Box p={3} className={classes.mainBorderStyles}>
              {billingDetailsstatus !== "loading" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", lg: "row" },
                      // gap:18
                    }}
                  >
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        mb={1}
                        className={classes.blackColor}
                      >
                        Legal name of Company
                      </Typography>
                      <Typography variant="body2" className={classes.grayColor}>
                        {/* Dhinwa Solutions Private Limited */}
                        {billingDetailsData?.companyLegalName}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} ml={20}>
                      <Typography
                        variant="body2"
                        mb={1}
                        className={classes.blackColor}
                      >
                        Company Email
                      </Typography>
                      <Typography variant="body2" className={classes.grayColor}>
                        {/* Private Company */}
                        {billingDetailsData?.businessEmail}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} ml={20}>
                      <Typography
                        variant="body2"
                        mb={1}
                        className={classes.blackColor}
                      >
                        GST
                      </Typography>
                      <Typography variant="body2" className={classes.grayColor}>
                        {/* 154985184656 */}
                        {billingDetailsData?.gstNumber}
                      </Typography>
                    </Grid>
                  </Box>
                  <Box mt={3}>
                    <Typography
                      variant="body2"
                      mb={1}
                      className={classes.blackColor}
                    >
                      Company Address
                    </Typography>
                    <Typography variant="body2" className={classes.grayColor}>
                      {/* 612 Shadowmar Drive, New Orleans, LA 70115 */}
                      {billingDetailsData?.companyAddress}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <LoadingComponent height="auto" color={bgColors?.blue} />
              )}
            </Box>
          </Box>
        </>
      </Grid>
    </Grid>
  );
};
export default BillingDetails;
