import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography, InputLabel } from "@mui/material";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { makeStyles } from "@mui/styles";
import { useAppDispatch } from "../../utils/redux-hooks";
import { postHelpCenter, fetchHelpCenter } from "../../redux/slices/HelpCenter/HelpCenterSlice";
import { toastActions } from "../../utils/toastSlice";
import LoadingComponent from "../common/LoadingComponent";
import { bgColors } from "../../utils/bgColors";
import { DraftEditorComponent } from "../common/DraftEditorComponent";
import { EditorState, convertToRaw } from "draft-js";
import { reactDraftWysiwygToolbarOptionsarticle } from "../../utils/react-draft-wysiwyg-options";
import draftToHtml from 'draftjs-to-html';

const useStyles = makeStyles({
  updateButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    borderRadius: "8px",
    width: "100%",
    cursor: "pointer",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "600 !important",
  },
  answerTextarea: {
    width: "100%",
    padding: "8px",
    fontSize: "14px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1.4375,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "8px",
    marginTop: "8px",
    '&::placeholder': { // Styling the placeholder
      color: 'rgba(0, 0, 0, 0.87)', // Placeholder text color
      opacity: 0.65 // Ensure the color isn't transparent
    }
  },
});

const NewFaqPopup = ({ open, handleClose }:any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [faqData, setFaqData] = useState({ Question: "", Answer: "" });
  const [errors, setErrors] = useState({ Question: "", Answer: "" });
  const [isNewFaqLoading, setIsNewFaqLoading] = useState(false);
  const [questionEditorState, setQuestionEditorState] = useState(EditorState.createEmpty());
  const [answerEditorState, setAnswerEditorState] = useState(EditorState.createEmpty());

  const handleCloseAll = () => {
    setFaqData({ Question: "", Answer: "" });
    setErrors({ Question: "", Answer: "" });
    setQuestionEditorState(EditorState.createEmpty());
    setAnswerEditorState(EditorState.createEmpty());
    handleClose();
  };

  const validateInput = (name:any, value:any) => {
    let errorMsg = '';
    if (!value || value === '<p></p>') {
      errorMsg = 'This field is required.';
    }
    setErrors(prev => ({ ...prev, [name]: errorMsg }));
  };

  const handleSave = async () => {
    validateInput('Question', faqData.Question);
    validateInput('Answer', faqData.Answer);

    if (!errors.Question && !errors.Answer && faqData.Question && faqData.Answer) {
      setIsNewFaqLoading(true);
      try {
        const response = await dispatch(postHelpCenter(faqData));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(toastActions.setToaster({
            type: "success",
            message: `${response?.payload?.message}`,
          }));
        } else {
          dispatch(toastActions.setToaster({
            type: "error",
            message: `${response?.payload}`,
          }));
        }
        handleCloseAll();
        await dispatch(fetchHelpCenter());
      } catch (error) {
        console.log("error", error);
        dispatch(toastActions.setToaster({
          type: "error",
          message: `Failed to add.`,
        }));
      }
      setIsNewFaqLoading(false);
    }
  };

  const handleEditorStateChange = (state:any, fieldName:any) => {
    const contentState = state.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlContent = draftToHtml(rawContentState);

    setFaqData(prev => ({ ...prev, [fieldName]: htmlContent }));
    validateInput(fieldName, htmlContent);

    if (fieldName === 'Question') {
      setQuestionEditorState(state);
    } else {
      setAnswerEditorState(state);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ style: { width: '40%', borderRadius: "20px" } }}>
      <DialogTitle>
        <Box m={2} mb={0} display="flex" justifyContent="space-between">
          <Typography sx={{ color: "#000000", fontWeight: "600", fontSize: { xs: "14px", md: "20px" } }}>Add FAQ</Typography>
          <Box onClick={handleCloseAll} sx={{ cursor: "pointer" }}>
            <CloseSvg />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box m={2} mb={0} mt={0}>
          <Box mb={2}>
            <InputLabel className={classes.blackColor}>Question</InputLabel>
            <DraftEditorComponent
              editorState={questionEditorState}
              handleEditorStateChange={(state:any) => handleEditorStateChange(state, 'Question')}
              handleSaveInboxSettings={() => {}}
              reactDraftWysiwygToolbarOptionsarticle={reactDraftWysiwygToolbarOptionsarticle}
              blockRendererFn={() => {}}
              file={null}
              handleFileUpload={() => {}}
              chatAreaRef={null}
              borderColor="#262738"
              handleAddVariable={null}
              placeholder="Enter the question"
            />
            {errors.Question && (
              <Typography variant="caption" display="block" style={{ color: "red", marginTop: "3px" }}>
                {errors.Question}
              </Typography>
            )}
          </Box>
          <Box>
            <InputLabel className={classes.blackColor}>Answer</InputLabel>
            <DraftEditorComponent
              editorState={answerEditorState}
              handleEditorStateChange={(state:any) => handleEditorStateChange(state, 'Answer')}
              handleSaveInboxSettings={() => {}}
              reactDraftWysiwygToolbarOptionsarticle={reactDraftWysiwygToolbarOptionsarticle}
              blockRendererFn={() => {}}
              file={null}
              handleFileUpload={() => {}}
              chatAreaRef={null}
              borderColor="#262738"
              handleAddVariable={null}
               placeholder="Enter the answer"
            />
            {errors.Answer && (
              <Typography variant="caption" display="block" style={{ color: "red", marginTop: "3px" }}>
                {errors.Answer}
              </Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box width="100%" ml={4} mr={4} mb={4}>
          {isNewFaqLoading ? (
            <LoadingComponent height="auto" color={bgColors?.blue} />
          ) : (
            <button onClick={handleSave} className={classes.updateButtonStyles}>
              Add FAQ
            </button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default NewFaqPopup;