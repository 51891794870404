import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import EditIconSvg from "../../assets/svgs/EditIconSvg";
import DeleteIconSvg from "../../assets/svgs/DeleteIconSvg";
import DeletePopUp from "../common/DeletePopup";
import EditPopOver from "../ContactsComponents/EditPopOver";
import { bgColors } from "../../utils/bgColors";
import NewTagMember from "../ContactsComponents/NewTagMember";
import { CONTACT_TAGS_APIS } from "../../Apis/Contacts/ContactTagsApis";
import { toastActions } from "../../utils/toastSlice";
import { getContactTags } from "../../redux/slices/Contacts/getContactTags";
import LoadingComponent from "../common/LoadingComponent";

const useStyles = makeStyles({
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    overflow: "100%",
    // height:"100vh !important",
    height: "calc(100vh - 3px)",
    width: "100%",
    // overflowY:"hidden",
    // scrollbarWidth:"none"
  },
  manageTeamContainer: {
    display: "flex",
    alignItems: "center",
    width: "full",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    // fontWeight: "600 !important",
  },
  searchField: {
    width: "100%",
    borderRadius: "12px",
    // height: "38px",
    // backgroundColor: bgColors.white2,
    backgroundColor: "white",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "inter",
      color: "#000000 !important",
    },
  },
  SaveChangesButton: {
    // backgroundColor: bgColors.green,
    // color: bgColors.white,
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    // width: "fit-content",
    width: "140px",
    height: "32px",
    // padding: "10px",
    cursor: "pointer",
  },
  messageCount: {
    backgroundColor: bgColors?.gray5,
    color: bgColors?.black,
    borderRadius: "24px",
    height: "24px",
    width: "34px",
    fontSize: "10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tagsCount: {
    backgroundColor: "#F4F4F4",
    borderRadius: "24px",
    padding: "3px",
    height: "25px",
    width: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
  messageCountContainer: {
    border: "1px solid #F2F2F2",
    cursor: "pointer",
    borderRadius: "5px",
    paddingInline: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  MuiLoadingButtonRoot: {
    backgroundColor: "#FFF",
  },
  campaignCountContainer: {
    // border: `1px solid ${bgColors.green}`,
    cursor: "pointer",
    color: bgColors.black,
    borderRadius: "8px",
    paddingInline: "8px",
    display: "flex",
    flexDirection: "row",
    height: "34px",
    alignItems: "center",
  },
  campaignCountContainer1: {
    border: "1px solid #F2F2F2",
    color: bgColors.green,
    borderRadius: "20px",
    paddingInline: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  messageInnerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  grayColor: {
    color: "#303030",
    opacity: "60%",
    fontSize: "13px",
    // padding:"5px"
  },
  grayColor1: {
    color: "#303030",
    opacity: "90%",
    fontSize: "13px",
    // padding:"5px"
  },
  campaignColor: {
    color: bgColors.green,
    // color: "#007aff",
    fontSize: "13px",
    fontWeight: "Semi Bold",
    // padding:"5px"
  },
  iconStyles: {
    cursor: "pointer",
    paddingLeft: "5px",
    alignItems: "center",
    // marginTop: "2px",
    color: "#ffffff",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0",
    textAlign: "center",
    borderColor: "lightgray",
    "& th, & td": {
      // borderTop: '1px solid gray',
      borderBottom: "1px solid #f0f0f0",
      padding: "4px",
    },
    "& th:first-child, & td:first-child": {
      borderLeft: "none",
    },
    "& th:last-child, & td:last-child": {
      borderRight: "none",
    },
  },
  teamProfileContainer: {
    display: "flex",
    alignItems: "center",
  },
  editButtonContainer: {
    border: "2px solid #DBDBDB",
    padding: "8px",
    borderRadius: "12px",
    backgroundColor: "#F4F4F4",
    width: "50px",
    // paddingBottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
});

const TagsTableData = ({ data, newTagDialog, filteredTags }: any) => {
  // console.log(data);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const getTagsData = useAppSelector((state: any) => state?.getContactTagsData);
  const getContactTag = getTagsData?.data;
  // console.log(getContactTag);
  const getTagStatus = getTagsData?.status;
  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const filteredOptions = getContactTag?.filter(
    (option: { tag: string }) => option?.tag?.trim() !== ""
  );
  // console.log(filteredOptions);
  const tagsData = [
    {
      name: "#_VISHU💜",
      createdBy: "Ravindra",
      createdAt: "2024-08-16T08:05:58.2109662",
      updatedAt: "2024-08-18T08:05:58.2109662",
    },
    {
      name: "#_SUMMER2024",
      createdBy: "Aarav",
      createdAt: "2024-07-10T14:20:45.1109235",
      updatedAt: "2024-07-12T10:15:30.1209537",
    },
    {
      name: "#_FESTIVALJOY",
      createdBy: "Sneha",
      createdAt: "2024-12-01T09:30:22.6548791",
      updatedAt: "2024-12-05T11:45:10.7592301",
    },
    {
      name: "#_WORKOUTGRIND",
      createdBy: "Rohan",
      createdAt: "2024-03-05T07:20:00.5461231",
      updatedAt: "2024-03-07T09:30:15.4523452",
    },
    {
      name: "#_TRAVELDIARIES",
      createdBy: "Meera",
      createdAt: "2024-06-15T12:45:33.8897542",
      updatedAt: "2024-06-20T13:50:47.6674321",
    },
    {
      name: "#_FOODIEDELIGHTS",
      createdBy: "Ananya",
      createdAt: "2024-04-22T11:55:42.3409756",
      updatedAt: "2024-04-25T14:22:10.2246784",
    },
    {
      name: "#_BOOKWORM",
      createdBy: "Priya",
      createdAt: "2024-05-08T15:30:25.4467809",
      updatedAt: "2024-05-10T18:45:32.9905437",
    },
    {
      name: "#_MUSICLOVER",
      createdBy: "Kunal",
      createdAt: "2024-09-19T09:10:05.1298764",
      updatedAt: "2024-09-21T10:20:38.6542309",
    },
    {
      name: "#_NATUREPHOTOGRAPHY",
      createdBy: "Aditi",
      createdAt: "2024-02-14T13:55:49.3401221",
      updatedAt: "2024-02-18T16:30:11.9805400",
    },
    {
      name: "#_TECHTRENDS",
      createdBy: "Vikram",
      createdAt: "2024-01-25T08:40:30.4456782",
      updatedAt: "2024-01-28T10:15:40.3301245",
    },
  ];

  const attributesData = [
    {
      id: "aa313ba9-7203-44e3-11d0-08dc6b35e4d8",
      tag: "neww",
      isActive: true,
    },
    {
      id: "e002ec95-ebc7-4019-5cd6-08dc6b76a329",
      tag: "newtag",
      isActive: true,
    },
    {
      id: "c9c7f63d-2151-4d17-183c-08dc6db9ebd3",
      tag: "Career Growth",
      isActive: true,
    },
    {
      id: "39ee860f-63c9-4f8a-183d-08dc6db9ebd3",
      tag: "Freelancer",
      isActive: true,
    },
    {
      id: "d4f7f864-4ffa-4530-183e-08dc6db9ebd3",
      tag: "Rap",
      isActive: false,
    },
    {
      id: "24b10c50-67cf-4bb9-183f-08dc6db9ebd3",
      tag: "tagscreated",
      isActive: false,
    },
    {
      id: "63a1b3c0-cd87-4600-1840-08dc6db9ebd3",
      tag: "news",
      isActive: false,
    },
  ];

  const getuserPermissionData = useAppSelector(
    (state: any) => state.getUserPermissions?.data
  );
  const manageContactsObject = getuserPermissionData?.contacts;

  const formatDate = (datetime: any) => {
    const date = new Date(datetime);
    const year = date.getFullYear();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const day = ("0" + date.getDate()).slice(-2);
    return `${day} ${month} ${year}`;
  };
  const [tableData, setTableData] = useState(filteredOptions);
  // console.log(tableData);
  const [deleteTag, setDeleteTag] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleteLoading, setIsDeleteTagLoading] = useState(false);
  const [deleteTagsTooltip, setDeleteTagsTooltip] = useState(null);
  const [editTagPopup, setEditTagPopup] = useState(false);
  const [editTag, setEditTag] = useState(null);
  const [tagId, seTagId] = useState(null);
  // console.log(tagId, "tagId");

  const handleOpenDelete = () => {};
  const handleCloseDelete = () => {
    setDeleteTag(false);
  };
  const hasDeleteTagPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "deleteTags")) {
        return true;
      }
    }
  };
  const handleDeleteTag = async (tagId: any) => {
    const hasPermission = hasDeleteTagPermission(manageContactsObject);
    if (hasPermission) {
      setDeleteTag(true);
      const data = {
        businessId: userData?.companyId,
        userId: userData?.userId,
        tagIds: deleteId,
      };
      setIsDeleteTagLoading(true);
      const getDelRes = await CONTACT_TAGS_APIS.deleteTags(data);
      if (getDelRes?.status === 200) {
        handleCloseDelete();
        setIsDeleteTagLoading(false);
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: "Tag Deleted Successfully",
          })
        );
        setIsDeleteTagLoading(false);
        const body = {
          businessId: userData?.companyId,
          userId: userData?.userId,
          search: "",
        };
        // await dispatch(getContactTags(body));
        await dispatch(getContactTags(body));
      }
    } else {
      setDeleteTagsTooltip(tagId);
      setTimeout(() => {
        setDeleteTagsTooltip(null);
      }, 2000);
    }
  };
  // const isDeleteLoading=()=>{

  // }
  const handleCloseEditTagPopover = () => {
    setEditTagPopup(false);
  };
  useEffect(() => {
    // if (data === "Attributes") {
    //   setTableData(attributesData);
    // } else {
    //   setTableData(filteredOptions);
    // }
    setTableData(filteredTags);
  }, [data, filteredTags]);

  return (
    <div>
      <Table className={classes.table} sx={{ width: "100%" }}>
        <TableHead className={classes.grayColor}>
          <TableRow>
            <TableCell>
              <span style={{ marginLeft: "10px" }}>So.no</span>
            </TableCell>
            <TableCell>Tag id</TableCell>
            <TableCell>Tag name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        {getTagStatus === "loading" ? (
          <TableBody
            sx={{
              position: "relative",
              margin: "20px",
              height: "500px",
            }}
          >
            <TableRow
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                paddingLeft: "50px",
                top: "20px",
              }}
            >
              <LoadingComponent height="500px" color={bgColors.blue} />
            </TableRow>
          </TableBody>
        ) : (
          <>
            {tableData?.length !== 0 && tableData !== null ? (
              <TableBody>
                {tableData?.map((row: any, index: number) => {
                  // const { label, color } = getStatusButton(row?.isActive);
                  // const isSelected = row.contactId === selectedRow;
                  // const isHovered = row.contactId === hoveredRow;
                  return (
                    <TableRow
                      key={row.id}
                      // onMouseEnter={() => handleRowHover(row.contactId)}
                      // onMouseLeave={() => setHoveredRow(null)}
                      // onClick={() => handleCheckboxClick(row.contactId)}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: row?.isChecked ? "#DBDBDB" : "inherit",
                        transition: "box-shadow 0.3s ease",
                        "&:hover": {
                          boxShadow:
                            "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
                        },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        //   handleOpenEditDialog(row);

                        {
                          data === "Tags" && setEditTagPopup(true);
                          setEditTag(row?.tag);
                          seTagId(row?.id);
                        }
                      }}
                    >
                      <TableCell className={classes.blackColor}>
                        {/* {row?.id} */}
                        <Box sx={{ marginLeft: "10px" }}>{index + 1}</Box>
                      </TableCell>

                      <TableCell className={classes.blackColor}>
                        {/* <Box sx={{ display: "flex", marginLeft: "10px" }}> */}
                        {/* <Tooltip
                        title={
                          <div>
                            <Typography style={{ fontSize: "14px" }}>
                              {row.id}
                            </Typography>
                          </div>
                        }
                        placement="top"
                      > */}
                        <Box className={classes.teamProfileContainer}>
                          <span
                            className={classes.blackColor}
                            // style={{
                            //   overflow: "hidden",
                            //   textOverflow: "ellipsis",
                            //   whiteSpace: "nowrap",
                            //   display: "inline-block",
                            //   textAlign: "left",
                            //   maxWidth: "200px",
                            // }}
                          >
                            {row.id}
                          </span>
                        </Box>
                        {/* </Tooltip> */}
                        {/* </Box> */}
                      </TableCell>

                      <TableCell className={classes.blackColor}>
                        <Box className={classes.teamProfileContainer}>
                          <Tooltip
                            title={
                              <div>
                                <Typography style={{ fontSize: "14px" }}>
                                  {row.tag}
                                </Typography>
                              </div>
                            }
                            placement="top"
                          >
                            <span
                              className={classes.blackColor}
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                display: "inline-block",
                                textAlign: "left",
                                maxWidth: "150px",
                              }}
                            >
                              {row.tag}
                            </span>
                          </Tooltip>
                        </Box>
                      </TableCell>

                      <TableCell className={classes.blackColor}>
                        {/* {formatDate(row.createdAt)} */}

                        <span
                          style={{
                            color: row.isActive ? "#4caf50" : "#ff9800",
                          }}
                        >
                          {row.isActive ? "Active" : "Inactive"}
                        </span>
                      </TableCell>

                      <TableCell>
                        <Box sx={{ display: "flex", gap: "5px" }}>
                          <Tooltip
                            title="Edit"
                            placement="top"
                            //   open={editContactsTooltip === row}
                            //   onClose={() => setEditContactsTooltip(null)}
                          >
                            <Box
                              onClick={(e) => {
                                e.stopPropagation();
                                //   handleOpenEditDialog(row);

                                {
                                  data === "Tags" && setEditTagPopup(true);
                                  setEditTag(row?.tag);
                                  seTagId(row?.id);
                                }
                              }}
                              sx={{ cursor: "pointer" }}
                            >
                              <EditIconSvg />
                            </Box>
                          </Tooltip>
                          <Tooltip
                            title="Delete"
                            placement="top"
                            // open={deleteContactsTooltip === row?.contactId}
                            // onClose={() => setDeleteContactsTooltip(null)}
                          >
                            <Box
                              onClick={(e) => {
                                e.stopPropagation();

                                {
                                  data === "Tags" && setDeleteTag(true);
                                  // handleDeleteTag(row?.id);
                                  setDeleteId(row?.id);
                                }
                              }}
                              sx={{ cursor: "pointer" }}
                            >
                              <DeleteIconSvg />
                            </Box>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <DeletePopUp
                  title="selected tag"
                  open={deleteTag}
                  handleClose={handleCloseDelete}
                  handleDelete={handleDeleteTag}
                  handleLoad={isDeleteLoading}
                />
                <NewTagMember
                  open={editTagPopup}
                  handleClose={handleCloseEditTagPopover}
                  isEdit={editTag}
                  tagId={tagId}
                />
                {/* {editContact && (
                <EditPopOver
                  open={openEditDialog}
                  handleClose={handleCloseEditDialog}
                  editContact={editContact}
                  setTeamData={setTeamData}
                  setPage={setPage}
                  isEditContactLoading={isEditContactLoading}
                  setIsEditContactLoading={setIsEditContactLoading}
                />
              )} */}
              </TableBody>
            ) : (
              <>
                {/* <TableRow
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    sx={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translateX(-50%)",
                      fontSize: "16px",
                      paddingLeft: "15px",
                      // top: "20px",
                    }}
                  >
                    No Data Found
                  </Typography>
                </TableRow> */}
                <TableRow sx={{ height: "60vh" }}>
                  <TableCell
                    colSpan={12}
                    align="center"
                    sx={{
                      width: "100%",
                      border: "none !important",
                      fontSize: "16px",
                    }}
                  >
                    No Data Found
                  </TableCell>
                </TableRow>
              </>
            )}
          </>
        )}
      </Table>
    </div>
  );
};

export default TagsTableData;
