import React from "react";

const ThunderSvg = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8" clipPath="url(#clip0_1365_479)">
        <path
          d="M26.9736 14.7705C26.936 14.6104 26.8596 14.4621 26.751 14.3387C26.6424 14.2152 26.5051 14.1204 26.3512 14.0627L19.1474 11.3613L20.9806 2.19512C21.0233 1.98177 20.9952 1.76033 20.9006 1.56438C20.8061 1.36844 20.6502 1.20868 20.4566 1.10934C20.263 1.01001 20.0423 0.976506 19.828 1.01393C19.6137 1.05135 19.4174 1.15764 19.269 1.3167L5.26895 16.3167C5.15677 16.4369 5.07596 16.5829 5.03366 16.7418C4.99136 16.9006 4.98889 17.0675 5.02646 17.2275C5.06403 17.3876 5.14048 17.5359 5.24905 17.6594C5.35762 17.7828 5.49495 17.8776 5.64889 17.9354L12.8527 20.6368L11.0194 29.8029C10.9768 30.0163 11.0048 30.2377 11.0994 30.4337C11.1939 30.6296 11.3498 30.7894 11.5434 30.8888C11.737 30.9881 11.9577 31.0216 12.172 30.9842C12.3864 30.9467 12.5827 30.8404 12.7311 30.6813L26.7311 15.6813C26.8433 15.5611 26.9241 15.4151 26.9664 15.2563C27.0087 15.0974 27.0112 14.9306 26.9736 14.7705Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1365_479">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ThunderSvg;
