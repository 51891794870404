import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import { LuWorkflow } from "react-icons/lu";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import EditIconSvg from "../../../assets/svgs/EditIconSvg";
import { convertFromRaw } from "draft-js";
import { parseTextToHtml } from "../../../utils/functions";

const ChatComponent = ({
  workflowData,
  workflowName,
  onWorkflowNameChange,
}: any) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedName, setEditedName] = useState<string>(workflowName);

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedName(workflowName);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedName(workflowName);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    onWorkflowNameChange(editedName);
  };

  const formatContentForDisplay = (rawContent: string) => {
    try {
      const contentState = convertFromRaw(JSON.parse(rawContent));
      return contentState.getPlainText();
    } catch (error) {
      console.error("Error parsing content:", error);
      return rawContent;
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 0 }}>
      <Box
        sx={{
          backgroundColor: "white",
          p: 1,
          border: "1px solid #d9d9d9",
          // mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LuWorkflow style={{ height: "30px", width: "30px" }} />
          {isEditing ? (
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <TextField
                value={editedName}
                onChange={(e) => {
                  onWorkflowNameChange(e.target.value);
                  setEditedName(e.target.value);
                }}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ marginLeft: "10px", fontSize: 12 }}
              />
              <IconButton onClick={handleSaveClick}>
                <DoneIcon />
              </IconButton>
              <IconButton onClick={handleCancelClick}>
                <ClearIcon />
              </IconButton>
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <Typography
                variant="h6"
                sx={{ padding: "10px", flexGrow: 1, fontSize: "14px" }}
              >
                {workflowName || "Unnamed Workflow"}
              </Typography>
              <IconButton onClick={handleEditClick}>
                <EditIconSvg />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ backgroundColor: "#f0eded" }}>
        <Box
          sx={{
            height: "67vh",
            py: 2,
            overflowY: "auto",
          }}
        >
          {workflowData?.map((workFlow: any, index: number) => (
            <Box key={index} sx={{ mb: 2, mx: 2 }}>
              <Typography
                // variant="subtitle2"
                gutterBottom
                sx={{ fontSize: "14px" }}
              >
                Step {index + 1}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                <Paper
                  elevation={1}
                  sx={{ py: 1, px: 2, bgcolor: "#e8f5e9", maxWidth: "80%" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: parseTextToHtml(
                          formatContentForDisplay(workFlow?.title) ||
                            "No content"
                        ),
                      }}
                    />
                    <EditIconSvg />
                  </Typography>
                  {workFlow?.autoReplyWorkflowButtons &&
                    workFlow?.autoReplyWorkflowButtons?.map(
                      (button: any, i: number) =>
                        button && (
                          <Button
                            key={i}
                            variant="outlined"
                            size="small"
                            sx={{
                              m: 0.5,
                              height: 22,
                              fontSize: 10,
                              textTransform: "none",
                            }}
                          >
                            {button}
                          </Button>
                        )
                    )}
                </Paper>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 1,
                    borderRadius: 2,
                    bgcolor: "#f5f5f5",
                    maxWidth: "80%",
                  }}
                >
                  <Typography sx={{ fontSize: 12 }}>
                    &lt;User input&gt;
                  </Typography>
                </Paper>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ChatComponent;
