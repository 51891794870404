import React from 'react'

const AutomationSvg = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1874_154)">
<path d="M26.9736 14.8769C26.936 14.7169 26.8596 14.5686 26.751 14.4451C26.6424 14.3216 26.5051 14.2269 26.3512 14.1691L19.1474 11.4677L20.9806 2.30156C21.0233 2.08821 20.9952 1.86677 20.9006 1.67083C20.8061 1.47489 20.6502 1.31513 20.4566 1.21579C20.263 1.11645 20.0423 1.08295 19.828 1.12037C19.6137 1.15779 19.4174 1.26409 19.269 1.42314L5.26895 16.4231C5.15677 16.5433 5.07596 16.6893 5.03366 16.8482C4.99136 17.0071 4.98889 17.1739 5.02646 17.334C5.06403 17.494 5.14048 17.6424 5.24905 17.7658C5.35762 17.8893 5.49495 17.9841 5.64889 18.0418L12.8527 20.7432L11.0194 29.9093C10.9768 30.1227 11.0048 30.3442 11.0994 30.5401C11.1939 30.7361 11.3498 30.8959 11.5434 30.9952C11.737 31.0945 11.9577 31.128 12.172 31.0906C12.3864 31.0532 12.5827 30.9468 12.7311 30.7878L26.7311 15.7878C26.8433 15.6676 26.9241 15.5216 26.9664 15.3627C27.0087 15.2038 27.0112 15.037 26.9736 14.8769Z" fill="#4B5A5A"/>
</g>
<defs>
<clipPath id="clip0_1874_154">
<rect width="32" height="32" fill="white" transform="translate(0 0.106445)"/>
</clipPath>
</defs>
</svg>
  )
}

export default AutomationSvg