import React from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import ChatUserArrowDownSvg from "../../../assets/svgs/ChatUserArrowDownSvg";
import { makeStyles } from "@mui/styles";
import ChatUserArrowSide from "../../../assets/svgs/ChatUserArrowSide";

const useStyles = makeStyles({
  grayColor: {
    color: `#4B5A5A !important`,
    fontWeight: "600 !important",
  },

  flexRowCenterPointer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  flexRowCenterSpaceBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  variableBox: {
    display: "flex",
    minWidth: 250,
    width: "50%",
    justifyContent: "space-between",
  },
  variableText: {
    textAlign: "left",
    minWidth: 100,
    padding: "5px",
  },
});

interface WorkflowVariablesProps {
  workflowVariables: boolean;
  details: any;
  setWorkflowVariables: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkflowVariablesDropdownComponent: React.FC<WorkflowVariablesProps> = ({
  workflowVariables,
  details,
  setWorkflowVariables,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Box my={2} className={classes.flexRowCenterSpaceBetween}>
        <Box
          className={classes.flexRowCenterPointer}
          onClick={() => setWorkflowVariables(!workflowVariables)}
        >
          {workflowVariables ? <ChatUserArrowDownSvg /> : <ChatUserArrowSide />}
          <Typography
            ml={2}
            sx={{ fontSize: "14px" }}
            className={classes.grayColor}
            style={{ cursor: "pointer" }}
          >
            Workflow variables
          </Typography>
        </Box>
      </Box>

      {workflowVariables && (
        <Box>
          {details?.workflowVariables?.length > 0 ? (
            details?.workflowVariables?.map((variable: any, index: number) => (
              <Box key={index} className={classes.variableBox} my={0.5}>
                <Tooltip title={variable?.variable}>
                  <Typography
                    sx={{ fontSize: "12px" }}
                    className={`${classes.variableText} ${classes.textEllipsis}`}
                  >
                    {variable?.variable}
                  </Typography>
                </Tooltip>
                <Tooltip title={variable?.value}>
                  <Typography
                    sx={{ fontSize: "12px" }}
                    className={`${classes.variableText} ${classes.textEllipsis}`}
                  >
                    :&nbsp;{variable?.value}
                  </Typography>
                </Tooltip>
              </Box>
            ))
          ) : (
            <Typography sx={{ fontSize: "12px", padding: "5px" }}>
              No variables available
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default WorkflowVariablesDropdownComponent;
