import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET_MANAGE_ACCOUNT } from "../../../Apis/ManageAccountApis/GetManangeAccount";

export interface IChangePassword {
  email: string;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
  error: string;
  isError: boolean;
}

const initialState: IData = {
  status: "idle",
  data: null,
  error: "",
  isError: false
};

export const fetchChangePassword = createAsyncThunk(
  "fetchChangePassword",
  async (requestedData: IChangePassword, { rejectWithValue }) => {
    try {
      const response = await GET_MANAGE_ACCOUNT.changePassword(requestedData);
      //console.log('fetchDisactivateUser==>',response)
      // console.log("response", response);
      return response
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);


export const changePasswordSlice = createSlice({
  name: "changePasswordSlice",
  initialState,
  reducers: {
    clearError: (state) => {
      state.isError = false;
      state.error = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchChangePassword.pending, (state) => {
        state.status = "loading";
        //   state.error = ""
        state.data = [];
        state.isError = false;
        state.error = ""
      })
      .addCase(fetchChangePassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        //   state.error = ""
        state.data = action.payload;
        state.isError = false;
        state.error = action?.payload?.data?.message
        //   state.EstaActivo = true;
        //   state.filterData = action.payload.filter((each:any) => _.get(each,'EstaActivo'))
      })
      .addCase(fetchChangePassword.rejected, (state, action:any) => {
        // console.log(action.payload)
        state.status = "failed";
        state.data = [];
        state.isError = true;
        state.error = action?.payload;
      });
  },
});

export const changePasswordSliceActions = changePasswordSlice.actions;
export default changePasswordSlice.reducer;
