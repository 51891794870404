import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import TextFeildWithBorderComponet from "../common/TextFieldWithBorderComponent";
import PasswordTextFeildWithBorders from "../common/PasswordTextFeildWithBorders";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { makeStyles } from "@mui/styles";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { fetchChangePassword } from "../../redux/slices/ManageAccount/ChangePasswordSlice";
import ErrorMessageComponent from "../common/ErrorMessageComponent";
import { toastActions } from "../../utils/toastSlice";
import type { DialogProps } from "@mui/material";
import LoadingComponent from "../common/LoadingComponent";
import { bgColors } from "../../utils/bgColors";

const useStyles = makeStyles({
  changePasswordContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // cursor: "pointer",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "600 !important",
  },
  updateButtonStyles: {
    cursor: "pointer",
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    fontWeight: "Bold",
    fontSize: "16px",
    borderRadius: "12px",
    width: "100%",
  },
});
const ChangePasswordDialog = ({ open, handleClose }: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const errorText = useAppSelector((state: any) => state.changePassword?.error);
  // console.log(errorText, 'errorText')
  // const errorTextt = useAppSelector((state) => state.changePassword?.data);
  // console.log(errorTextt, 'errorTextt')
  // console.log(err)
  const loginData = useAppSelector((state: any) => state.adminLogin?.data);
  // console.log(loginData,'loginData');
  const currentEmail = loginData?.email;
  const error = useAppSelector((state: any) => state.changePassword?.isError);
  // console.log(currentEmail, 'currentEmail')

  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  // console.log(!!emailError)
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(error);
  // console.log(error, 'error')

  // const changePasswordData=useAppSelector((state)=>state.changePasswordData);

  // useEffect(() => {
  //   if (error) {
  //     dispatch(toastActions.setToaster({ type: "error", message: errorText }));
  //   } else if (errorText === "Password updated successfully.") {
  //     setEmail("");
  //     setCurrentPassword("");
  //     setNewPassword("");
  //     setConfirmPassword("");
  //     handleClose();
  //     dispatch(
  //       toastActions.setToaster({
  //         type: "success",
  //         message: "Password Changed Successfully",
  //       })
  //     );
  //   }
  // }, [error]);

  const handleSave = async () => {
    const isEmailValid = validateEmail(email);
    const isCurrentPasswordValid = validateCurrentPassword(currentPassword);
    const isNewPasswordValid = validatePassword(currentPassword, newPassword);
    const isConfirmPasswordValid = validateConfirmPassword(
      newPassword,
      confirmPassword
    );

    setLoading(true);

    if (
      isEmailValid &&
      isCurrentPasswordValid &&
      isNewPasswordValid &&
      isConfirmPasswordValid
    ) {
      setEmailError("");
      setCurrentPasswordError("");
      setPasswordError("");
      setConfirmPasswordError("");
      setErr(false);

      try {
        const response: any = await dispatch(
          fetchChangePassword({
            email,
            currentPassword,
            newPassword,
            confirmPassword,
          })
        );

        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: `${response?.payload?.data?.message}`,
            })
          );
          handleClose();
          setEmail("");
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: `${response?.payload}`,
            })
          );
        }
      } catch (error) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message:
              "An error occurred while changing the password. Please try again.",
          })
        );
        console.error("Error during password change:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const validateEmail = (email: string) => {
    if (!email) {
      setEmailError("Email is required");
      return false;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email.toLowerCase())) {
      setEmailError("Invalid email format.");
      return false;
    }
    if (currentEmail !== email) {
      setEmailError("Please enter a valid registered email");
      return false;
    }
    setEmailError("");
    return true;
  };

  const validateCurrentPassword = (password: string) => {
    // const passwordPattern = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    if (
      !password
      // || !passwordPattern.test(password)
    ) {
      setCurrentPasswordError("Curent password is required.");
      return false;
    }
    // const passwordPattern = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    // if (!passwordPattern.test(password)) {
    //   setCurrentPasswordError("Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character.");
    //   return false;
    // }
    setCurrentPasswordError("");
    return true;
  };

  const validatePassword = (currentPassword: string, password: string) => {
    if (!password.trim()) {
      setPasswordError("Password is required.");
      return false;
    }
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    if (!passwordPattern.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character."
      );
      // setPasswordError("Please enter a valid password.");

      return false;
    }
    if (currentPassword === password) {
      setPasswordError(
        "New password should not be the same as the old password."
      );
      return false;
    }
    setPasswordError("");
    return true;
  };

  const validateConfirmPassword = (
    newPassword: string,
    confirmPassword: string
  ) => {
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm password is required.");
      return false;
    }
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return false;
    }
    setConfirmPasswordError("");
    return true;
  };

  // const ValidateErr = (error: any) => {
  //   // console.log(error)
  //   if (!error) {
  //     setErr(error);
  //     return true;
  //   }
  //   if (error) {
  //     setErr(error);
  //     return false;
  //   }
  // };

  const handleCloseClick = () => {
    setEmail("");
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setErr(false);
    setEmailError("");
    setCurrentPasswordError("");
    setPasswordError("");
    setConfirmPasswordError("");
    handleClose();
  };

  const handleCloseBackdrop: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    handleClose();
  };

  useEffect(() => {
    setErr(error); // Sync err with error from Redux
  }, [error]);

  return (
    <Dialog
      open={Boolean(open)}
      onClose={handleCloseBackdrop}
      PaperProps={{ style: { minWidth: "35%", borderRadius: "20px" } }}
    >
      <DialogTitle m={2} mb={0}>
        <Box className={classes.changePasswordContainer}>
          <Typography variant="h6">Change Password</Typography>
          <Box onClick={handleCloseClick} sx={{ cursor: "pointer" }}>
            <CloseSvg />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box m={2} mt={0}>
          <Box mb={2}>
            <InputLabel className={classes.blackColor}>
              Email Address
            </InputLabel>
            <TextFeildWithBorderComponet
              label="Enter your email address"
              name="email"
              placeholder=""
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
              error={!!emailError}
              helperText={emailError}
              sx={{
                "& input": {
                  fontWeight: "600",
                },
              }}
            />
          </Box>
          <Box mb={2}>
            <InputLabel className={classes.blackColor}>
              Current Password
            </InputLabel>
            <PasswordTextFeildWithBorders
              label="Enter your password"
              name="currentPassword"
              placeholder=""
              value={currentPassword || ""}
              onChange={(e) => setCurrentPassword(e.target.value)}
              error={!!currentPasswordError}
              helperText={currentPasswordError}
              sx={{
                "& input": {
                  fontWeight: "600",
                },
              }}
            />
          </Box>
          <Box mb={2}>
            <InputLabel className={classes.blackColor}>New Password</InputLabel>
            <PasswordTextFeildWithBorders
              label="Enter your new password"
              name="newPassword"
              placeholder=""
              value={newPassword || ""}
              onChange={(e) => setNewPassword(e.target.value)}
              error={!!passwordError}
              helperText={passwordError}
              sx={{
                "& input": {
                  fontWeight: "600",
                },
              }}
            />
          </Box>
          <InputLabel className={classes.blackColor}>
            Confirm New Password
          </InputLabel>
          <PasswordTextFeildWithBorders
            label="Confirm your new password"
            name="confirmPassword"
            placeholder=""
            value={confirmPassword || ""}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!!confirmPasswordError}
            helperText={confirmPasswordError}
            sx={{
              "& input": {
                fontWeight: "600",
              },
            }}
          />
        </Box>
        {err && <ErrorMessageComponent text={errorText} />}
      </DialogContent>
      <DialogActions>
        <Box width="100%" mx={{ xs: 1, md: 4 }} mb={4}>
          {loading ? (
            <Grid item xs={12} textAlign="center">
              <LoadingComponent height="100%" color={bgColors.blue} />
            </Grid>
          ) : (
            <button onClick={handleSave} className={classes.updateButtonStyles}>
              Update Password
            </button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
