import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
} from "@mui/material";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../utils/redux-hooks";
import { getWorkflowNames } from "../../redux/slices/Workflows/getWorkflowNamesSlice";
import { toastActions } from "../../utils/toastSlice";
import LoadingComponent from "../../components/common/LoadingComponent";
import { bgColors } from "../../utils/bgColors";

const WorkflowLibraryPopup = ({ open, onClose, onSave,selectedWorkflow,setSelectedWorkflow }: any) => {
  // const [workflows, setWorkflows] = useState([]);
  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const dispatch = useAppDispatch();
  const { workflowNamesData, workflowNamesStatus } = useAppSelector(
    (state: any) => state.getWorkflowNames
  );

  useEffect(() => {
    const fetchWorkflowNames = async () => {
      const payload = { companyId: userData?.companyId };
      try {
        dispatch(getWorkflowNames(payload)).unwrap();
        // if (result.success) {
        //   // setWorkflows(result.data);
        //   // dispatch(
        //   //   toastActions.setToaster({
        //   //     message: result.message,
        //   //     type: "success",
        //   //   })
        //   // );
        // } else {
        //   dispatch(
        //     toastActions.setToaster({
        //       message: result.message || "Failed to fetch workflow list",
        //       type: "error",
        //     })
        //   );
        // }
      } catch (error) {
        // dispatch(
        //   toastActions.setToaster({
        //     message: "An error occurred while fetching the workflow list",
        //     type: "error",
        //   })
        // );
      }
    };

    if (userData?.companyId) {
      fetchWorkflowNames();
    }
  }, [userData?.companyId]);

  // Handle checkbox selection
  const handleCheckboxChange = (workflowName: string) => {
    setSelectedWorkflow(workflowName);
  };
  const handleSave = () => {
    onSave(selectedWorkflow);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        borderRadius: "16px",
        "& .MuiPaper-root": {
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: "bold", color: "black" }}>
        Workflow Library
      </DialogTitle>
      {workflowNamesStatus === "loading" ? (
        <LoadingComponent height="auto" color={bgColors?.blue}/>
      ) : (
        <DialogContent>
          <List>
            {workflowNamesData?.map((workflow: any, index: number) => (
              <ListItem key={index} sx={{ padding: "0px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedWorkflow === workflow.workflowName}
                      onChange={() =>
                        handleCheckboxChange(workflow.workflowName)
                      }
                      color="primary"
                    />
                  }
                  label={<ListItemText primary={workflow.workflowName} />}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      )}
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <Button
            onClick={onClose}
            style={{
              color: "green",
              fontWeight: "600",
              border: "1px solid green",
              borderRadius: "8px",
              textTransform: "capitalize",
              fontSize: "14px",
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            Close
          </Button>
          <Button
            onClick={handleSave}
            style={{
              color: "green",
              fontWeight: "600",
              border: "1px solid green",
              borderRadius: "8px",
              textTransform: "capitalize",
              fontSize: "14px",
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            Save
          </Button>
        </Box>
      </DialogActions>

      {selectedWorkflow && (
        <Box
          sx={{
            padding: "16px",
            backgroundColor: "#f1f1f1",
            textAlign: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", color: "green" }}
          >
            Selected Workflow: {selectedWorkflow}
          </Typography>
        </Box>
      )}
    </Dialog>
  );
};

export default WorkflowLibraryPopup;
