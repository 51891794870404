import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import setManageAccountDataReducer from "./ManageAccount/ManageAccountSlice";
import getAllLanguage from "./ManageAccount/GetAllLanguages";
import ChangePasswordSlice from "./ManageAccount/ChangePasswordSlice";
import { toastReducer } from "../../utils/toastSlice";
import getAllCountriesSlice from "./ManageAccount/GetAllCountryDetails";
import getAllPaymentsCardsSlice from "./ManageAccount/GetPaymentsCards";
import getAllCountryCodesSlice from "./ManageAccount/GetAllCountryCode";
import getAllMangeCompanySlice from "./ManageCompany/GetAllMangeCompanySlice";
import adminLoginSlice from "./AdminLoginSlice";
import getAllTeamMembersSlice from "./ManageCompany/GetAllTeamMembers";
import getAllRolesSlice from "./ManageCompany/DisplayAllRoles";
import AccountDetailsSlice from "./ManageAccount/AccountDetailsSlice";
import editedCompanyDetailsSlice from "./ManageCompany/UpdateCompanyDetails";
import CompanyDetailsSlice from "./ManageCompany/CompanyDetailsSlice";
import allContactsSlice from "./Contacts/AllContactsSlice";
import addContactSlice from "./Contacts/CreateContactSlice";
import addContactTagsSlice from "./Contacts/CreateContactTags";
import ManageNotificationsSlice from "./ManageNotifications/ManageNotificationsSlice";
import HelpCenterSlice from "./HelpCenter/HelpCenterSlice";
import InboxContactsSlice from "./Inbox/InboxContactsSlice";
import getContactTagsSlice from "./Contacts/getContactTags";
import InboxContactConversationsSlice from "./Inbox/InboxContactConversations";
import ManagePermissionsSlice from "./ManagePermissions/ManagePermissionsSlice";
import getAllTeamMembersByCompanyIdSlice from "./ManageCompany/GetAllTeamMembersByCompanyId";
import InboxContactAssignmentSlice from "./Inbox/InboxContactAssignment";
import InboxContactDetailsSlice from "./Inbox/InboxContactDetails";
import getAllCientSlice from "./ManageClients/getAllClientSlice";
import editContactTagsSlice from "./Contacts/UpdateContactTags";
import SubscriptionSlice from "./Subscription/SubscriptionSlice";
import allTemplatesByCompanyIdSlice from "./Templates/AllTemplatesSlice";
import createCampaignSlice from "./Campaign/CreateCampaignSlice";
import GetCampaignSlice from "./Campaign/GetCampaignSlice";
import createTemplateSlice from "./Templates/CreateTemplateSlice";
import updateTemplateSlice from "./Templates/UpdateTemplateSlice";
import removeTemplateSlice from "./Templates/DeleteTemplateSlice";
import getUserPermissionSlice from "./ManagePermissions/GetUserPermissionSlice";
import templateByIdSlice from "./Templates/TemplateById";
import WalletSlice from "./Wallet/WalletSlice";
import uploadFileSlice from "./Templates/uploadFileSlice";
import updateCampaignSlice from "./Campaign/EditCampaignSlice";
import GetScheduledCampaignSlice from "./Campaign/GetScheduledCampaignSlice";
import updateInboxSettingsSlice from "./Automation/UpdateInboxSettingsSlice";
import getInboxSettingsSlice from "./Automation/GetInboxSettingsSlice";
import getContactColumnsSlice from "./Automation/GetContactColumnNames";
import getWorkingHoursSlice from "./Automation/GetWorkingHoursSlice";
import createWorkingHoursSlice from "./Automation/createWorkingHoursSlice";
import deleteContactTagsSlice from "./Contacts/DeleteContactTags";
import addContactNotesSlice from "./Contacts/CreateContactNotes";
import deleteContactNotesSlice from "./Contacts/DeleteContactNotes";
import markedAsSpamSlice from "./Inbox/markContactAsSpam";
import deleteContactSlice from "./Contacts/DeleteContactSlice";
import updateContactSlice from "./Contacts/UpdateContactSlice";
import autoReplySlice from "./Automation/autoReplySlice";
import getAutoReplySlice from "./Automation/getAutoReplySlice";
import updateAutoReplySlice from "./Automation/updateAutoReplySlice";
import getCampaignByIdSlice from "./Campaign/GetCampaignByIdSlice";
import deleteAutoReplySlice from "./Automation/deleteAutoReplySlice";
import getWorkflowListSlice from "./Workflows/getWorkflowListSlice";
import getCampaignTitleSlice from "./Campaign/GetCampaignTitleSlice";
import templateNameSlice from "./Templates/GetTemplateNameSlice";
import getVariableNamesSlice from "./Workflows/getVariableNamesSlice";
import createWorkflowCustomMessageSlice from "./Workflows/createWorkflowCustomMessageSlice";
import updateWorkflowListSlice from "./Workflows/updateWorkflowListSlice";
import createWorkflowSlice from "./Workflows/createWorkflowSlice";
import getWorkflowNamesSlice from "./Workflows/getWorkflowNamesSlice";
import getWorkflowSlice from "./Workflows/getWorkflowSlice";
import addVariableNameSlice from "./Workflows/addVariableNamesSlice";
import optedManagementDataSlice from "./Automation/OptedKeywordsSlice";
import optoutKeywordsSlice from "./Automation/GetOptoutKeywords";
import deleteOptedKeywordsSlice from "./Automation/deleteOptedKeywords";
import optinKeywordsSlice from "./Automation/GetOptinKeywords";
import getSelectResponseSlice from "./Workflows/getSelectResponse";
import updateWorkflow from "./Workflows/updateWorkflowSlice";
import agentPerformanceSlice from "./Analytics/AgentAnalyticsSlice";
import agentOverviewSlice from "./Analytics/OverviewAnalyticsSlice";
import inboxAnalysysSlice from "./Analytics/InboxAnalyticsSlice";
import downloadWorkflowByNameSlice from "./Workflows/downloadWorkflowSlice";
import createSelectResponseSlice from "./Workflows/createSelectResponseSlice";
import deleteSelectResponseSlice from "./Workflows/deleteSelectResponseSlice";
import AccountMetaStatusSlice from "./ManageAccount/AccountMetaStatusSlice";
import editTagSlice from "./Contacts/editTagSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  adminLogin: adminLoginSlice,
  toastState: toastReducer,
  autoReply: autoReplySlice,
  getAutoReply: getAutoReplySlice,
  updateAutoReply: updateAutoReplySlice,
  deleteAutoReply: deleteAutoReplySlice,
  getWorkflowList: getWorkflowListSlice,
  getVariableNames: getVariableNamesSlice,
  createWorkflowCustomMessage: createWorkflowCustomMessageSlice,
  updateWorkflowList: updateWorkflowListSlice,
  createWorkflow: createWorkflowSlice,
  getWorkflowNames: getWorkflowNamesSlice,
  addVaribleName: addVariableNameSlice,
  getSelectResponseNames: getSelectResponseSlice,
  updateWorkflow: updateWorkflow,
  downloadWorkflowByName: downloadWorkflowByNameSlice,
  createSelectResponse: createSelectResponseSlice,
  deleteSelectResponse: deleteSelectResponseSlice,

  /*profile/ManageAcoount*/
  accountData: AccountDetailsSlice,
  manageAccountData: setManageAccountDataReducer,
  getAllLanguages: getAllLanguage,
  changePassword: ChangePasswordSlice,
  getAllCountries: getAllCountriesSlice,
  getAllPaymentsCard: getAllPaymentsCardsSlice,
  getAllCoutryCodes: getAllCountryCodesSlice,
  accountMetaStatus: AccountMetaStatusSlice,

  /*profile/ManageCompany*/
  getManageCompanies: getAllMangeCompanySlice,
  getAllTeamMembers: getAllTeamMembersSlice,
  getAllTeamMembersByCompanyId: getAllTeamMembersByCompanyIdSlice,
  getAllRoles: getAllRolesSlice,
  updatedCompanyData: editedCompanyDetailsSlice,
  companyData: CompanyDetailsSlice,

  /*contacts*/
  contactsData: allContactsSlice,
  createContactData: addContactSlice,
  updateContactData: updateContactSlice,
  removeContactData: deleteContactSlice,
  createContactTagsData: addContactTagsSlice,
  updateContactTagsData: editContactTagsSlice,
  getContactTagsData: getContactTagsSlice,
  removeContactTagsFromContactData: deleteContactTagsSlice,
  createContactNotesData: addContactNotesSlice,
  removeContactNotesData: deleteContactNotesSlice,
  getWorkflow: getWorkflowSlice,
  editTagData: editTagSlice,

  /*profile/ManageNotifications*/
  manageNotifications: ManageNotificationsSlice,

  /*profile/ManagePermissions*/
  managePermissions: ManagePermissionsSlice,
  getUserPermissions: getUserPermissionSlice,
  getAllClients: getAllCientSlice,

  /*profile/HelpCenter*/
  helpCenter: HelpCenterSlice,

  /*Inbox*/
  inboxContactsData: InboxContactsSlice,
  inboxContactConversationsData: InboxContactConversationsSlice,
  inboxContactAssignment: InboxContactAssignmentSlice,
  inboxContactDetails: InboxContactDetailsSlice,
  markedAsSpamData: markedAsSpamSlice,

  /*Subscription*/
  Subscription: SubscriptionSlice,

  /*Analytics*/
  agentPerformance: agentPerformanceSlice,
  overViewAnalysis: agentOverviewSlice,
  inboxAnalysis: inboxAnalysysSlice,

  /*Templates*/
  allTemplatesData: allTemplatesByCompanyIdSlice,
  createTemplateData: createTemplateSlice,
  updateTemplateData: updateTemplateSlice,
  removeTemplateData: removeTemplateSlice,
  templateByIdData: templateByIdSlice,
  uploadFileData: uploadFileSlice,
  templateNameData: templateNameSlice,

  /*Campaigns*/
  addCampaign: createCampaignSlice,
  updateCampaign: updateCampaignSlice,
  getCampaign: GetCampaignSlice,
  getScheduledCampaign: GetScheduledCampaignSlice,
  getCampaignById: getCampaignByIdSlice,
  getCampaignTitle: getCampaignTitleSlice,

  /*Wallet*/
  wallet: WalletSlice,

  /*Automation*/
  getWorkingHours: getWorkingHoursSlice,
  getContactColumnsData: getContactColumnsSlice,
  createWorkingHours: createWorkingHoursSlice,
  updateInboxSettings: updateInboxSettingsSlice,
  getInboxSettings: getInboxSettingsSlice,
  optedManagement: optedManagementDataSlice,
  optoutKeywords: optoutKeywordsSlice,
  optinKeywords: optinKeywordsSlice,
  deleteOptedKeywords: deleteOptedKeywordsSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
