import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { bgColors } from "../../utils/bgColors";
import ArrowDownSvg from "../../assets/svgs/ArrowDownSvg";
// import CampaignFilterPopover from "../../components/CampaingnsComponents/CampaignFilterPopOver";
import CampaignFilterPopOvers from "../../components/CampaingnsComponents/CampaignFilterPopovers";
// import AddNewCampaign from "../../components/CampaingnsComponents/AddNewCampaign";
// import ThreeDots from "../../assets/svgs/ThreeDots";
// import EditDeletePopover from "../../components/ScheduledComponents/EditDeletePopOver";
import DeletePopUp from "../../components/common/DeletePopup";
import EditCampaign from "../../components/ScheduledComponents/EditCampaign";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIconSvg from "../../assets/svgs/EditIconSvg";
import DeleteIconSvg from "../../assets/svgs/DeleteIconSvg";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
// import { fetchAllContacts } from "../../redux/slices/Contacts/AllContactsSlice";
// import { getCampaign } from "../../redux/slices/Campaign/GetCampaignSlice";
import LoadingComponent from "../../components/common/LoadingComponent";
// import moment from "moment";
import { CAMPAIGN_API } from "../../Apis/Campaign/Campaign";
import { fetchInboxContacts } from "../../redux/slices/Inbox/InboxContactsSlice";
import { getContactTags } from "../../redux/slices/Contacts/getContactTags";
// import EditSvg from "../../assets/svgs/EditSvg";
// import PaginationComponent from "../../components/common/PaginationComponent";
import { getScheduledCampaign } from "../../redux/slices/Campaign/GetScheduledCampaignSlice";
import { toastActions } from "../../utils/toastSlice";
import SearchIconSvg2 from "../../assets/svgs/SearchIconSvg2";
import useDebouncedFetch from "../../utils/debounceHook";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { Height } from "@mui/icons-material";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import EventIcon from "@mui/icons-material/Event";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import { checkSceduledCampaignsPermission } from "../../utils/permissions";
import NoAccessPage from "../../components/common/NoAccess";
import { getCampaignById } from "../../redux/slices/Campaign/GetCampaignByIdSlice";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          // Adjust the default padding for all TableCell components
          padding: "2px", // Set your desired padding value here
        },
      },
    },
  },
});

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100vh",
    width: "100%",
    overFlow: "hidden !important",
  },
  searchField: {
    width: "100%",
    borderRadius: "12px",
    // height: "38px",
    // backgroundColor: bgColors.white2,
    backgroundColor: "white",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "inter",
      color: "#000000 !important",
    },
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
    overFlow: "hidden !important",
    display: "flex",
    flexDirection: "column",
  },
  manageTeamContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "full",
    gap: "30px",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    // fontWeight: "600 !important",
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    // backgroundColor: bgColors.green,
    borderRadius: "8px",
    width: "120px",
    height: "32px",
    // padding: "10px",
    cursor: "pointer",
  },
  messageCountContainer: {
    // border: "1px solid #F2F2F2",
    // borderRadius: "6px",
    // padding: "8px",
    // width: "30px",
    // height: "20px",
    // paddingBottom: 6,
  },
  messageInnerContainer: {
    border: "2px solid #F2F2F2",
    borderRadius: "6px",
    paddingInline: "4px",
    // width: "10px",
    // paddingBottom: 0,
    // minWidth: "130px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  grayColor: {
    color: "#303030",
    opacity: "60%",
    fontSize: "20px",
    // padding:"5px"
  },
  spaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  iconStyles: {
    cursor: "pointer",
    paddingLeft: "5px",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0",
    textAlign: "center",
    borderColor: "lightgray",
    "& th, & td": {
      // borderTop: '1px solid gray',
      borderBottom: "1px solid #f0f0f0",
      height: "35.8px",
    },
    "& th:first-child, & td:first-child": {
      borderLeft: "none",
    },
    "& th:last-child, & td:last-child": {
      borderRight: "none",
    },
  },
  teamProfileContainer: {
    display: "flex",
    alignItems: "center",
  },
  threedots: {
    border: "2px solid #F2F2F2",
    padding: "10px",
    borderRadius: "12px",
    // paddingBottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  editButtonContainer: {
    border: "2px solid #DBDBDB",
    padding: "8px",
    borderRadius: "12px",
    backgroundColor: "#F4F4F4",
    width: "50px",
    // paddingBottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
});

// const options = [
//   {
//     id: 1,
//     option: "All",
//   },
//   {
//     id: 2,
//     option: "completed",
//   },
//   {
//     id: 3,
//     option: "Incompleted",
//   },
//   {
//     id: 4,
//     option: "Scheduled",
//   },
// ];

const options1 = [
  {
    id: 1,
    option: "All",
  },
  {
    id: 2,
    option: "Last 7 days",
  },
  {
    id: 3,
    option: "Last 14 days",
  },
  {
    id: 4,
    option: "Last 30 days",
  },
];

const Scheduled = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const debouncedFetchCampaigns = useDebouncedFetch(getScheduledCampaign, 1500);
  const userInfoSlice = useAppSelector((state:any) => state.adminLogin);
  // console.log(userInfoSlice);
  const accountInfo = useAppSelector((state:any) => state?.accountData?.data);
  // console.log(accountInfo);
  const userInfo = userInfoSlice?.data;
  // const data = getManageAccount?.data;
  const getuserPermissionData = useAppSelector(
    (state:any) => state.getUserPermissions?.data
  );
  const getMc = getuserPermissionData?.campaigns;
  // const scheduledObject = getMc?.find((item: any) =>
  //   item.hasOwnProperty("scheduled")
  // );
  const scheduledObject = getMc?.find((item: any) =>
    Object?.prototype?.hasOwnProperty?.call(item, "scheduled")
  );
  // console.log(scheduledObject, "scheduledObject");

  const scheduledActions = scheduledObject ? scheduledObject.scheduled : [];

  // const getCampaignData = useAppSelector((state) => state.getCampaign.data?.data1)
  const getCampaignSlice = useAppSelector(
    (state:any) => state.getScheduledCampaign
  );
  const getCampaignData = getCampaignSlice?.data?.data;

  const hasScheduledCampaignsPermission =
    checkSceduledCampaignsPermission(getMc);

  // console.log(getCampaignData)
  const getTotalCampaign = useAppSelector(
    (state:any) => state.getScheduledCampaign.data?.total
  );
  const [filterData, setFilterData] = useState<any>("");
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [searchCampaignQuery, setSearchCampaignQuery] = useState<string>("");
  // console.log(searchCampaignQuery)
  const [page, setPage] = React.useState(1);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  // const [selectedFilter, setSelectedFilter] = React.useState("View All");
  const [isHovered, setIsHovered] = useState(false);
  const [selectedFilter1, setSelectedFilter1] = React.useState("Last 7 Days");
  // console.log(selectedFilter1)
  const [originalOption, setOriginalOption] = useState("Last 7 Days");
  const [openDialog, setOpenDialog] = React.useState(false);
  // const [anchorElACT, setAnchorElACT] = React.useState(null);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  // const [clientDetail, setClientDetail] = React.useState(null);
  const [chatStatusFilter, setChatStatusFilter] = useState<any>("");
  const [title, setTitle] = useState("");
  const [addNewCampaignTooltip, setAddNewCampaignTooltip] = useState(false);
  const [editScheduledCampaignTooltip, setEditScheduledCampaignTooltip] =
    useState("");
  // console.log(editScheduledCampaignTooltip);
  const [deleteScheduledCampaignTooltip, setDeleteScheduledCampaignTooltip] =
    useState("");
  const [scheduledCampaignTooltipMesage, setScheduledCampaignTooltipMesage] =
    useState("");
  const [editData, setEditData] = useState<any>(null);
  // console.log(editData)
  const [getCampaignId, setGetCampaignId] = useState<any>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [pageData, setPageData] = useState(getCampaignData || []);
  // console.log(pageData);
  const [hoveredRow, setHoveredRow] = useState(null);
  // const status = useAppSelector(
  //   (state: any) => state.getScheduledCampaign.status
  // );
  // console.log(status)
  // const getuserPermissionData = useAppSelector(
  //   (state) => state.getUserPermissions?.data
  // );

  useEffect(() => {
    const bodyData = {
      businessId: userInfo?.companyId,
      userId: userInfo?.userId,

      filtering: {
        filtering: {
          filterType: "and",
          conditions: [
            {
              column: chatStatusFilter ? "ChatStatus" : "",
              operator: "equals",
              value: chatStatusFilter === "All" ? "" : chatStatusFilter,
            },
            {
              column: filterData ? "Tags" : "",
              operator: filterData ? "contain" : "",
              value: filterData,
            },
          ],
        },
      },
    };
    dispatch(fetchInboxContacts(bodyData));
  }, [filterData, chatStatusFilter]);

  useEffect(() => {
    const body = {
      businessId: userInfo?.companyId,
      userId: userInfo?.userId,
      search: "",
    };
    dispatch(getContactTags(body));
  }, []);

  const hasAcess = (permission: any) => {
    if (scheduledActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  const handleDeleteDialog = (campaignId: string, createdBy: string) => {
    const hasPermission =
      userInfo?.roleName === "Owner" || userInfo?.roleName === "Admin"
        ? hasAcess("deleteCampaign")
        : hasAcess("deleteCampaign") && createdBy === accountInfo?.name;
    if (hasPermission) {
      setDeleteDialog(true);
      setGetCampaignId(campaignId);
    } else {
      if (createdBy !== accountInfo?.name) {
        setScheduledCampaignTooltipMesage("You are not authorized");
      } else {
        setScheduledCampaignTooltipMesage("Access Denied");
      }
      setDeleteScheduledCampaignTooltip(campaignId);
      setTimeout(() => {
        setDeleteScheduledCampaignTooltip("");
      }, 2000);
    }
  };

  // console.log("campaignId", getCampaignId);
  const handleDeleteCloseDialog = () => {
    setDeleteDialog(false);
  };

  // const handleFilterClick = (event: any) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleFilterClick1 = (event: any) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleOptionClick = (option: string) => {
    // console.log(option)
    const formattedOption = option.toLowerCase().replace(/\s+/g, "");
    // console.log(formattedOption)
    // console.log(option);
    setPageData([]);
    setPage(1);
    // setSelectedFilter(option);
    setSelectedFilter1(formattedOption);
    setOriginalOption(option);
    handleClose1();
  };

  // const handleOptionClick1 = (option: string) => {
  //   // console.log(option)
  //   setSelectedFilter1(option);
  //   handleClose1();
  // };

  const handleOpenDialog = (title: any) => {
    const hasPermission = hasAcess("newScheduledCampaign");
    setTitle(title);
    if (hasPermission) {
      setOpenDialog(true);
    } else {
      setAddNewCampaignTooltip(true);
      setTimeout(() => {
        setAddNewCampaignTooltip(false);
      }, 2000);
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // const handlePopoverOpen = (event: any) => {
  //   setAnchorElACT(event.currentTarget);
  // };

  // const handlePopoverClose = () => {
  //   setAnchorElACT(null);
  // };

  const handleEdit = async (client: any, id: any, createdBy: string) => {
    const hasPermission =
      userInfo?.roleName === "Owner" || userInfo?.roleName === "Admin"
        ? hasAcess("editCampaign")
        : hasAcess("editCampaign") && createdBy === accountInfo?.name;
    // console.log(hasPermission);
    if (hasPermission) {
      // setClientDetail(client);
      handleOpenDialog(client);
      try {
        const data = {
          businessId: userInfo?.companyId,
          userId: userInfo?.userId,
          campaignId: id,
        };

        // const getDetailsRes = await CAMPAIGN_API.getCampaignByDetails(data);
        const getDetailsRes = await dispatch(getCampaignById(data));

        // console.log(getDetailsRes.payload)
        // console.log(getDetailsRes?.data)
        setEditData(getDetailsRes?.payload);
      } catch (error: any) {
        console.log("error", error);
      }
    } else {
      if (createdBy !== accountInfo?.name) {
        setScheduledCampaignTooltipMesage("You are not authorized");
      } else {
        setScheduledCampaignTooltipMesage("Access Denied");
      }
      setEditScheduledCampaignTooltip(id);
      setTimeout(() => {
        setEditScheduledCampaignTooltip("");
      }, 2000);
    }
    // history(`/profile/manageClients/edit/${clientDetails.id}`,{ state: { clientDetails: clientDetails } });
  };

  // console.log("cmtotal", getTotalCampaign)
  const handleDelete = async () => {
    setIsDeleteLoading(true);
    try {
      const data = {
        businessId: userInfo?.companyId,
        userId: userInfo?.userId,
        campaignId: getCampaignId,
      };
      const getDeleteRes = await CAMPAIGN_API.deleteScheduleCampaign(data);
      if (getDeleteRes?.status === 200) {
        setDeleteDialog(false);
        // console.log("getDeleteRes", getDeleteRes?.data?.message)

        dispatch(
          getScheduledCampaign({
            businessId: userInfo?.companyId,
            userId: userInfo?.userId,
            page: page,
            perPage: 40,
          })
        );
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: `${getDeleteRes?.data?.message}`,
          })
        );
      } else {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `${getDeleteRes?.data?.message}`,
          })
        );
        setDeleteDialog(false);
      }
    } catch (error) {
      console.log("Delete Campaign error", error);
    }
    setIsDeleteLoading(false);
  };

  useEffect(() => {
    if (searchCampaignQuery || filterData) {
      setPage(1);
    }
  }, [searchCampaignQuery, filterData]);

  React.useEffect(() => {
    const data = {
      businessId: userInfo?.companyId,
      userId: userInfo?.userId,
      page: page,
      perPage: 40,
      filters: {
        searching: {
          value: searchCampaignQuery?.length > 0 ? searchCampaignQuery : "",
        },
        filtering: {
          filterType: "and",
          conditions: [
            {
              column: "dateSetLive",
              operator: selectedFilter1,
              // value: new Date(),
              value: new Date().toISOString().slice(0, 10),
            },
          ],
        },
      },
    };
    if (searchCampaignQuery) {
      debouncedFetchCampaigns(data);
    } else {
      dispatch(getScheduledCampaign(data));
    }
  }, [page, debouncedFetchCampaigns, searchCampaignQuery, selectedFilter1]);

  // React.useEffect(() => {
  //   const data = {
  //     businessId: userInfo?.companyId,
  //     userId: userInfo?.userId,
  //     page: 1,
  //     perPage: 10,
  //     filters: {
  //       searching: {
  //         value: searchCampaignQuery,
  //       },
  //       filtering: {
  //         filterType: "and",
  //         conditions: [
  //           {
  //             column: "dateSetLive",
  //             operator: selectedFilter1,
  //             value: new Date(),
  //           },
  //         ],
  //       },
  //     },
  //   };
  //   // dispatch(getScheduledCampaign(data));
  //   if (searchCampaignQuery?.length > 0) {
  //     debouncedFetchCampaigns(data);
  //   } else {
  //     dispatch(getScheduledCampaign(data));
  //   }
  // }, [
  //   searchCampaignQuery, debouncedFetchCampaigns
  // ]);

  const handleSearchCampaigns = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchCampaignQuery(event.target.value);
  };

  const handleRowHover = (rowId: any) => {
    setHoveredRow(rowId);
  };

  // const formatDate = (datetime: any) => {
  //   const date = new Date(datetime);
  //   const year = date.getFullYear();
  //   const monthNames = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];
  //   const month = monthNames[date.getMonth()];
  //   const day = ("0" + date.getDate()).slice(-2);
  //   const hours = ("0" + date.getHours()).slice(-2);
  //   const minutes = ("0" + date.getMinutes()).slice(-2);
  //   const seconds = ("0" + date.getSeconds()).slice(-2);
  //   return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
  // };

  const formatDate = (datetime: any) => {
    const date = new Date(datetime);
    // Convert UTC to IST by adding 5 hours and 30 minutes
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);

    const year = date.getFullYear();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
  };

  const tableContainerRef = useRef(null);
  const handleScroll = () => {
    if (tableContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        tableContainerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 20 &&
        !isLoadingMore &&
        pageData.length !== getTotalCampaign
      ) {
        handleLoadMore();
      }
    }
  };
  const handleLoadMore = useCallback(() => {
    if (
      !isLoadingMore &&
      pageData.length !== getTotalCampaign &&
      page <= Math.ceil(getTotalCampaign / 40)
    ) {
      setIsLoadingMore(true);
      setPage((prevPage) => prevPage + 1);
    }
  }, [isLoadingMore, pageData.length, getTotalCampaign]);
  useEffect(() => {
    if (getCampaignData) {
      if (page === 1) {
        setPageData(getCampaignData);
      } else {
        setPageData((prevPageData: any) => [
          ...prevPageData,
          ...getCampaignData,
        ]);
      }
      setIsLoadingMore(false);
    }
  }, [getCampaignData]);

  // console.log("accountInfo", accountInfo);

  return (
    <>
      {hasScheduledCampaignsPermission ? (
        <Grid sx={{ height: "100%" }}>
          <Grid className={classes.mainContainer}>
            <Box className={classes.bgContainer}>
              <Box sx={{ height: "120px" }}>
                <Box className={classes.manageTeamContainer}>
                  <Box ml={2}>
                    <Typography
                      variant="h6"
                      className={classes.blackColor}
                      sx={{ display: "flex" }}
                    >
                      <span style={{ paddingTop: "3px" }}>
                        <ScheduleSendOutlinedIcon sx={{ height: "22px" }} />
                      </span>
                      Scheduled
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: { xs: "block", md: "flex" },
                      width: "100%",
                      alignItems: "center",
                    }}
                    m={{ xs: 1, md: 0 }}
                  >
                    <TextField
                      className={classes.searchField}
                      variant="standard"
                      size="small"
                      // id="password"
                      fullWidth
                      onChange={handleSearchCampaigns}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "12px",
                          padding: "7px",
                          height: "38px",
                          margin: 0,
                        },
                        startAdornment: (
                          <IconButton
                            sx={{ p: 0, color: "inherit" }}
                            aria-label="search"
                          >
                            {/* <SearchIconSvg /> */}
                            <SearchIconSvg2 />
                          </IconButton>
                        ),
                      }}
                      inputProps={{
                        style: {
                          // Additional style for placeholder
                          // fontWeight: "600 !important",
                          paddingTop: "3px", // Apply font weight here
                        },
                      }}
                      placeholder="Search scheduled campaigns"
                    />
                  </Box>

                  <Box style={{ marginLeft: "auto" }} m={3}>
                    <Tooltip
                      title="Access Denied"
                      placement="left"
                      open={addNewCampaignTooltip}
                      onClose={() => setAddNewCampaignTooltip(false)}
                    >
                      <button
                        className={classes.SaveChangesButton}
                        onClick={() => handleOpenDialog("Add")}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{
                          backgroundColor: isHovered
                            ? "rgba(68, 71, 70, 0.08)"
                            : "#fff",
                          cursor: isHovered ? "pointer" : "default", // Optional: change cursor style on hover
                        }}
                      >
                        + Add Campaign
                      </button>
                    </Tooltip>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Box className={classes.messageCountContainer}></Box>
                  <Box
                    mx={2}
                    className={classes.messageCountContainer}
                    sx={{ cursor: "pointer" }}
                    onClick={handleFilterClick1}
                  >
                    <Box className={classes.messageInnerContainer}>
                      <Typography
                        variant="body2"
                        className={classes.grayColor}
                        style={{ padding: "5px" }}
                      >
                        {selectedFilter1 === "All" ? (
                          <span
                            style={{
                              display: "flex",
                              fontSize: 12,
                              alignItems: "center",
                            }}
                          >
                            <ChecklistRtlIcon sx={{ height: "15px" }} />{" "}
                            {selectedFilter1}
                          </span>
                        ) : (
                          <span
                            style={{
                              display: "flex",
                              fontSize: 12,
                              alignItems: "center",
                            }}
                          >
                            <EventIcon sx={{ height: "15px" }} />{" "}
                            {originalOption}
                          </span>
                        )}
                      </Typography>
                      <Box
                        className={classes.iconStyles}
                        sx={{ marginRight: "5px" }}
                      >
                        <ArrowDownSvg />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box style={{ flex: "1", overflow: "hidden" }}>
                <ThemeProvider theme={theme}>
                  <TableContainer
                    component={Box}
                    mt={2}
                    sx={{
                      height: "100%",
                      scrollBehavior: "smooth",
                    }}
                    onScroll={handleScroll}
                    ref={tableContainerRef}
                  >
                    <Table className={classes.table} sx={{ fontSize: "14px" }}>
                      <TableHead className={classes.grayColor}>
                        <TableRow>
                          <TableCell sx={{ width: "45%", paddingLeft: "20px" }}>
                            Title
                          </TableCell>
                          <TableCell>Created by</TableCell>
                          <TableCell>Date Set Live</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getCampaignSlice?.status === "loading" &&
                        !isLoadingMore ? (
                          // <LoadingComponent height="100%" color={bgColors.blue} />
                          <TableBody
                            sx={{
                              // position: "relative",
                              margin: "20px",
                              // height: "500px",
                            }}
                          >
                            <TableRow
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                position: "absolute",
                                left: "60%",
                                transform: "translateX(-50%)",
                                paddingLeft: "50px",
                                top: "20px",
                                height: "100%",
                              }}
                            >
                              <LoadingComponent
                                height="500px"
                                color={bgColors.blue}
                              />
                            </TableRow>
                          </TableBody>
                        ) : (
                          <>
                            {pageData?.length ? (
                              <>
                                {pageData?.map((row: any, index: number) => {
                                  const isHovered = index === hoveredRow;
                                  return (
                                    <TableRow
                                      key={index}
                                      onMouseEnter={() => handleRowHover(index)}
                                      onMouseLeave={() => setHoveredRow(null)}
                                      sx={{
                                        cursor: "pointer",
                                        boxShadow: isHovered ? 3 : 0,
                                        transition: "box-shadow 0.3s",
                                      }}
                                    >
                                      <TableCell sx={{ maxWidth: "45%" }}>
                                        <Tooltip
                                          title={
                                            <Typography
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row?.campaignTitle}
                                            </Typography>
                                          }
                                          placement="top"
                                        >
                                          <Box>
                                            <Box
                                              sx={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                maxWidth: "250px",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  marginLeft: "20px",
                                                  fontSize: "14px !important",
                                                }}
                                              >
                                                {row?.campaignTitle}
                                              </span>
                                            </Box>
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell className={classes.blackColor}>
                                        {row?.createdby}
                                      </TableCell>
                                      <TableCell className={classes.blackColor}>
                                        {formatDate(row?.dateSetLive)}
                                      </TableCell>

                                      <TableCell>
                                        <Box
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            height: "15px",
                                          }}
                                        >
                                          {/* Edit or Duplicate Box */}
                                          <Tooltip
                                            title={
                                              editScheduledCampaignTooltip ===
                                              row?.campaignId
                                                ? scheduledCampaignTooltipMesage
                                                : "Edit"
                                            }
                                            placement="top"
                                            // open={
                                            //   editScheduledCampaignTooltip ===
                                            //   row?.campaignId
                                            // }
                                            // onClose={() =>
                                            //   setEditScheduledCampaignTooltip(
                                            //     ""
                                            //   )
                                            // }
                                          >
                                            <Box
                                              mr={1}
                                              onClick={() =>
                                                handleEdit(
                                                  row?.state === 3
                                                    ? "Edit"
                                                    : "Duplicate",
                                                  row?.campaignId,
                                                  row?.createdby
                                                )
                                              }
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                              // onMouseEnter={() =>
                                              //   setEditScheduledCampaignTooltip(
                                              //     row?.campaignId
                                              //   )
                                              // }
                                              // onMouseLeave={() =>
                                              //   setEditScheduledCampaignTooltip(
                                              //     ""
                                              //   )
                                              // }
                                            >
                                              {row?.state === 3 ? (
                                                <EditIconSvg />
                                              ) : (
                                                <></> // Add duplicate icon if needed
                                              )}
                                            </Box>
                                          </Tooltip>

                                          {/* Delete Box */}
                                          <Tooltip
                                            title={
                                              deleteScheduledCampaignTooltip ===
                                              row?.campaignId
                                                ? scheduledCampaignTooltipMesage
                                                : "Delete"
                                            }
                                            placement="top"
                                            // open={
                                            //   deleteScheduledCampaignTooltip ===
                                            //   row?.campaignId
                                            // }
                                            // onClose={() =>
                                            //   setDeleteScheduledCampaignTooltip(
                                            //     ""
                                            //   )
                                            // }
                                          >
                                            <Box
                                              onClick={() =>
                                                handleDeleteDialog(
                                                  row?.campaignId,
                                                  row?.createdby
                                                )
                                              }
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                              // onMouseEnter={() =>
                                              //   setDeleteScheduledCampaignTooltip(
                                              //     row?.campaignId
                                              //   )
                                              // }
                                              // onMouseLeave={() =>
                                              //   setDeleteScheduledCampaignTooltip(
                                              //     ""
                                              //   )
                                              // }
                                            >
                                              <DeleteIconSvg />
                                            </Box>
                                          </Tooltip>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </>
                            ) : (
                              <TableRow
                                sx={{
                                  position: "relative",
                                  height: "60vh",
                                }}
                              >
                                <TableCell
                                  colSpan={12}
                                  sx={{
                                    width: "100%",
                                    fontSize: "16px",
                                    textAlign: "center",
                                    top: "20px",
                                    border: "none !important",
                                  }}
                                >
                                  No Campaign Found
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        )}

                        <EditCampaign
                          open={openDialog}
                          title={title}
                          handleClose={handleCloseDialog}
                          data={title === "Edit" ? editData : null}
                          setFilterData={setFilterData}
                          setChatStatusFilter={setChatStatusFilter}
                        />

                        <DeletePopUp
                          title="Schedule Campaign"
                          open={deleteDialog}
                          handleClose={handleDeleteCloseDialog}
                          handleDelete={handleDelete}
                          handleLoad={isDeleteLoading}
                        />
                      </TableBody>
                    </Table>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "20px",
                      }}
                    >
                      {pageData?.length !== 0 &&
                        pageData !== null &&
                        pageData.length !== getTotalCampaign &&
                        !isLoadingMore && (
                          <Typography
                            // onClick={handleLoadMore}
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                color: "blue",
                              },
                            }}
                          >
                            {" "}
                            {getCampaignSlice?.status !== "loading" ? (
                              // <button
                              //   style={{
                              //     cursor: 'pointer',
                              //     fontSize: "14px",
                              //     borderRadius: '5px',
                              //     border: `1px solid ${bgColors.green}`,
                              //     backgroundColor: bgColors.white,
                              //     color: bgColors.green,
                              //     padding: '5px'
                              //   }}
                              // >
                              //   Load More...
                              // </button>
                              <></>
                            ) : (
                              ""
                            )}
                          </Typography>
                        )}
                      {isLoadingMore && (
                        <LoadingComponent height="50px" color={bgColors.blue} />
                      )}
                    </Box>
                  </TableContainer>
                </ThemeProvider>
              </Box>
            </Box>
          </Grid>
          {/* <Box sx={{ paddingBottom: "15px", paddingLeft: "10px" }}>
        <PaginationComponent setPage={setPage} total={getTotalCampaign} />
      </Box> */}
          <CampaignFilterPopOvers
            anchorEl={anchorEl1}
            handleClose={handleClose1}
            options={options1}
            handleOptionClick={handleOptionClick}
          />
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

export default Scheduled;
