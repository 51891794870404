import React from "react";
import VerificationFormContainer from "../../components/VerificationComponents/VerificationFormContainer";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EngagetoLogoSvg from "../../assets/svgs/EngagetoLogoSvg";

const useStyles = makeStyles({
  background: {
    backgroundImage: `url("/images/new-pwd-bg.png")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const VerificationCode = () => {
  const classes = useStyles();
  return (
    <Box className={classes.background}>
      <Box>
        <EngagetoLogoSvg />
      </Box>
      <Box className={classes.formContainer}>
        <VerificationFormContainer loginPromptText="Verification Code" />
      </Box>
    </Box>
  );
};

export default VerificationCode;
