import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  // MenuItem,
  // Select,
  TextField,
  Typography,
} from "@mui/material";
// import TextFeildWithBorderComponet from "../common/TextFieldWithBorderComponent";
// import PasswordTextFeildWithBorders from "../common/PasswordTextFeildWithBorders";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { makeStyles } from "@mui/styles";
import PhoneInput from "../common/PhoneInput";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import NewTagMember from "./NewTagMember";
// import { CONTACTS_APIS } from "../../Apis/Contacts/ContactsApis";
import { fetchAllContacts } from "../../redux/slices/Contacts/AllContactsSlice";
import { toastActions } from "../../utils/toastSlice";
import TextFieldWithBorderComponent from "../common/TextFieldWithBorderComponent";
// import LoadingComponent from "../common/LoadingComponent";
// import { bgColors } from "../../utils/bgColors";
import ContactsIconSvg from "../../assets/svgs/ContactsIconSvg";
import { updateContact } from "../../redux/slices/Contacts/UpdateContactSlice";
import { validatePhoneNumber } from "../../utils/functions";
import { getContactTags } from "../../redux/slices/Contacts/getContactTags";

interface ContactForm {
  name: string;
  countryCode: string;
  contact: string;
  countryName: string;
  email: string;
  tags: string[];
}

const useStyles = makeStyles({
  changePasswordContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
    fontWeight: "Semi Bold !important",
  },
  blackColor: {
    color: "#303030 !important",
    // fontWeight: "600 !important",
  },
  updateButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "36px",
    borderRadius: "8px",
    width: "100%",
    fontSize: "14px ",
    fontWeight: "600",
    cursor: "pointer",
  },
  icon: {
    cursor: "pointer",
    textAlign: "right",
  },
  signOutButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    borderRadius: "8px",
    cursor: "pointer",
    // width: "190%",
    padding: "8px",
    fontWeight: "Bold",
    fontSize: "14px",
  },
});

// const renderTag = (props: any, option: any, selected: any) => (
//   <li
//     {...props}
//     style={{
//       backgroundColor: selected ? "#e0e0e0" : "transparent",
//       fontWeight: selected ? "normal" : "normal",
//     }}
//   >
//     {option.tag}
//   </li>
// );

const EditPopOver = ({
  open,
  handleClose,
  editContact,
  setTeamData,
  setPage,
  setIsEditContactLoading,
}: any) => {
  // console.log(editContact, "editContacttt");
  const classes = useStyles();
  // const [editData, setEditData] = useState(editContact);
  const dispatch = useAppDispatch();
  const getContactTag = useAppSelector(
    (state: any) => state?.getContactTagsData?.data
  );
  // console.log(getContactTag)
  const getAllCountries = useAppSelector(
    (state: any) => state.getAllCountries?.data
  );

  const getAllSortedCountries = [...getAllCountries].sort((a, b) =>
    a.countryName.localeCompare(b.countryName)
  );

  const countryCodeArray = useAppSelector(
    (state: any) => state.getAllCoutryCodes?.data
  );
  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  // const countryCode = parsePhoneNumberFromString(
  //   editContact?.contact
  // )?.countryCallingCode;
  // const contactNumber = parsePhoneNumberFromString(
  //   editContact?.contact
  // )?.nationalNumber;
  const [, setEmail] = useState(null);
  const [, setName] = useState(null);
  const [newTagDialog, setNewTagDialog] = useState(false);
  const [newTagAdded, setNewTagAdded] = useState(false);
  const [formData, setFormData] = useState<ContactForm>({
    name: editContact?.name,
    countryCode: editContact?.countryCode || "+91",
    contact: editContact?.contact,
    countryName: editContact?.country,
    email: editContact?.email,
    tags: editContact?.tags || [],
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    countryCode: "",
    contact: "",
    countryName: "",
    email: "",
  });

  const isFormValid = () => {
    const errors = { ...formErrors };
    let isValid = true;

    // if (!formData?.name) {
    //   errors.name = "Contact name is required";
    //   isValid = false;
    // }
    // console.log(
    //   "formerrors",
    //   !formData?.name,
    //   formData.name.trim().length === 0,
    //   /\s{2,}/.test(formData.name)
    // );

    if (
      !formData?.name ||
      formData.name.trim().length === 0
      // ||
      // /\s{2,}/.test(formData.name)
    ) {
      errors.name = "Contact name is required";
      isValid = false;
    }
    // console.log("name error", errors);
    const isValidPhone = /^\d+$/.test(formData?.contact);
    if (!formData?.contact && !formData?.countryCode) {
      errors.contact = "Phone Number & Country Code are required.";
      isValid = false;
    } else if (!formData?.contact && formData?.countryCode) {
      errors.contact = "phone number is required.";
      isValid = false;
    } else if (!formData?.countryCode && formData?.contact) {
      errors.contact = "Country code is required.";
      isValid = false;
    } else if (!validatePhoneNumber(formData?.contact, formData?.countryCode)) {
      errors.contact =
        "This phone number format is not recognized. Please check the country code and phone number.";
      isValid = false;
    } else if (!isValidPhone) {
      errors.contact = "Phone number should only contain numbers.";
      isValid = false;
    } else if (formData?.contact && formData?.countryCode) {
      isValid = true;
    }

    if (
      formData?.email &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      errors.email = "Invalid email address";
      isValid = false;
    }
    // if (!formData?.countryName) {
    //     errors.countryName = "Country name is required";
    //     isValid = false;
    // }

    setFormErrors(errors);
    return isValid;
  };

  const handleDialogueClose = () => {
    setEmail(editContact?.email);
    setName(editContact?.name);
    handleClose();
    setFormErrors({
      name: "",
      countryCode: "",
      contact: "",
      countryName: "",
      email: "",
    });
    setNewTagAdded(false);
    // setFormData({
    //   name: '',
    //   countryCode: '',
    //   contact: '',
    //   countryName: '',
    //   email: '',
    //   tags: [''],
    // });
  };

  const openNewTagPopover = () => {
    setNewTagDialog(true);
  };
  // console.log(parsePhoneNumberFromString(editContact?.contact), "editContact");

  // const renderOption = (props: any, option: any, { selected }: any) => {
  //   const isSelected = formData.tags.some((tag: any) => tag.id === option.id);
  //   return (
  //     <li {...props} style={{
  //       backgroundColor: isSelected ? '#e0e0e0' : 'transparent',
  //       // fontWeight: isSelected ? 'bold' : 'normal'
  //     }}>
  //       {option.tag}
  //     </li>
  //   );
  // };
  const renderOption = (props: any, option: any, { selected }: any) => {
    return (
      <li
        {...props}
        style={{
          backgroundColor: selected ? "#e0e0e0" : "transparent",
        }}
      >
        {option.tag}
      </li>
    );
  };

  useEffect(() => {
    // Update formData when editContact prop changes
    setFormData({
      name: editContact?.name || "",
      countryCode: editContact?.countryCode || "+91",
      contact: editContact?.contact || "",
      email: editContact?.email || "",
      countryName: editContact?.country || "",
      tags: editContact?.tags || [""],
    });
  }, [editContact, open]);

  // console.log("formData", formData.countryName, editContact?.country);
  const handlePhoneChange = (value: string) => {
    // Validate phone number format
    // const phoneRegex = /^(\+\d{1,3})?\s?\d{10}$/; // Updated regex to handle optional country code
    // if (!phoneRegex.test(value)) {
    //   setErrors({ ...errors, contact: "Phone number must be 10 digits" });
    //   // console.log(errors.contact, "current error message");
    // } else {
    //   setErrors({ ...errors, contact: "" });
    // }

    setFormData({
      ...formData,
      contact: value,
    });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      contact: "",
    }));
  };
  const handleCountryCodeChange = (value: string) => {
    // console.log("value", value);
    setFormData({ ...formData, countryCode: value });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      contact: "",
    }));
  };
  // console.log("a fklwjbgowqrbfbjbhbihsbdfvier", formData?.tags);
  const handleSave = async () => {
    // console.log("errors", formData, formErrors);

    if (isFormValid()) {
      setIsEditContactLoading(true);
      // const tags = formData.tags.map((tagObj: any) => tagObj.tag);
      if (formData?.name.trim().length > 0 && formData?.name !== "") {
        const data = {
          businessId: userData?.companyId,
          userId: userData?.userId,
          contactId: editContact?.contactId,
          data: {
            ...formData,
            tags: formData?.tags?.map((tag: any) => tag?.id),
          },
        };
        try {
          // console.log("calles1")
          // setIsEditContactLoading(true);
          setTeamData([]);
          setPage(1);
          const response: any = await dispatch(updateContact(data));
          // console.log(response, "response");
          if (response?.meta?.requestStatus === "fulfilled") {
            dispatch(
              toastActions.setToaster({
                type: "success",
                message: `${response?.payload?.message}`,
              })
            );
            handleClose();
            // setIsEditContactLoading(false);
          } else {
            dispatch(
              toastActions.setToaster({
                type: "error",
                message: `${response?.payload}`,
              })
            );
            // setIsEditContactLoading(false);
          }
        } catch (error: any) {
          // console.log(error, "error");
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: `${error?.response?.data?.message}`,
              // message: `err`,
            })
          );
          // setIsEditContactLoading(false);
        } finally {
          const bodyData = {
            businessId: userData?.companyId,
            userId: userData?.userId,
            page: 1,
            per_page: 40,
            filters: {},
          };
          await dispatch(fetchAllContacts(bodyData));
        }
        //  console.log("updateContact", response)
        // console.log("calles2");
      }
      setIsEditContactLoading(false);
    }
  };

  // const handleChange = (event: any) => {
  //   setCountry(event.target.value);
  // };

  const handleFormChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const codeArray =
    countryCodeArray &&
    countryCodeArray?.map((item: any) => ({
      countryCode: item?.countryCode,
      countryImage: item?.countryImage,
    }));

  const handleCloseTagPopover = () => {
    setNewTagDialog(false);
  };
  const handleAddedNewTag = async (status: boolean, newTag: string) => {
    setNewTagAdded(status);
    const body = {
      businessId: userData?.companyId,
      userId: userData?.userId,
      search: "",
    };
    const tagsListData: any = await dispatch(getContactTags(body));
    const newTagData = tagsListData?.payload?.filter(
      (item: any) => item?.tag === newTag
    );
    if (status && newTag) {
      setFormData((prevData: any) => ({
        ...prevData,
        tags: [...prevData.tags, newTagData[0]],
      }));
    }
  };

  const selectedCountry = formData?.countryName
    ? getAllSortedCountries?.find(
        (country) =>
          country?.countryName?.toLowerCase() ===
          formData?.countryName?.toLowerCase()
      )
    : null;

  const handleSelectChange = (event: any, newValue: { countryName: any }) => {
    const customEvent = {
      target: {
        name: "countryName",
        value: newValue ? newValue.countryName : "",
      },
    };
    handleFormChange(customEvent);
  };

  // console.log("formData", formData);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { minWidth: "35%", maxWidth: "35%", borderRadius: "20px" },
      }}
    >
      <DialogTitle>
        <Box m={1} className={classes.changePasswordContainer}>
          <Typography
            // variant="h6"
            sx={{
              color: "#000000",
              // fontWeight: "600",
              fontSize: { xs: "14px", md: "20px" },
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <ContactsIconSvg />
            Edit Contact
          </Typography>
          <Box sx={{ cursor: "pointer" }} onClick={handleDialogueClose}>
            <CloseSvg />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box m={1} mt={0}>
          <Box mb={2}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 } }}
            >
              Name*
            </InputLabel>
            <TextFieldWithBorderComponent
              placeholder=""
              size="small"
              fullWidth
              name="name"
              label="Enter contact name"
              value={formData?.name}
              onChange={handleFormChange}
              sx={{
                "& input": {
                  // fontWeight: "600"
                },
              }}
              // InputLabelProps={{
              //   style: {
              //     fontSize: 16,
              //   },
              // }}
              // inputProps={{
              //   style: {
              //     fontSize: 14,
              //   },
              // }}
              error={!!formErrors?.name}
              helperText={formErrors?.name}
            />
            {/* {formErrors?.name && (
              <Typography variant="body2" color="error">
                {formErrors?.name}
              </Typography>
            )} */}
          </Box>
          <Box mb={2}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 } }}
            >
              Contact*
            </InputLabel>
            <Grid xs={12}>
              <PhoneInput
                width="130px"
                gapRequired="48px"
                phoneNumWidth="270px"
                countryCodeArray={codeArray}
                name="contact"
                onChange={handlePhoneChange}
                onCountryCodeChange={handleCountryCodeChange}
                propsValue={formData?.contact || ""}
                countryCode={formData?.countryCode || ""}
                error={!!formErrors?.contact}
                // helperText={formErrors?.contact}
              />
              {formErrors?.contact && (
                <Typography variant="body2" color="error" sx={{ fontSize: 12 }}>
                  {formErrors?.contact}
                </Typography>
              )}
              {/* {formErrors?.countryCode && (
              <Typography variant="body2" color="error">
                {formErrors?.countryCode}
              </Typography>
            )} */}
            </Grid>
          </Box>
          <Box mb={2}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 } }}
            >
              Email
            </InputLabel>
            <TextFieldWithBorderComponent
              variant="outlined"
              // required
              type="text"
              size="small"
              placeholder=""
              fullWidth
              name="email"
              onChange={handleFormChange}
              label="Enter email address"
              sx={{
                "& input": {
                  // fontWeight: "600"
                },
              }}
              // placeholder="Enter template name"
              value={formData?.email}
              error={!!formErrors?.email}
              helperText={formErrors?.email}
            />
            {/* {formErrors?.email && (
              <Typography variant="body2" color="error">
                {formErrors?.email}
              </Typography>
            )} */}
          </Box>
          {/* <Box mb={2}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
            >
              Country
            </InputLabel>
            <FormControl fullWidth size="small">
              <InputLabel
                id="demo-simple-select-label"
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Select
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Country"
                name="countryName"
                inputProps={{ style: { fontSize: 14 } }}
                value={formData?.countryName}
                // value={formData?.countryName}
                onChange={handleFormChange}
                sx={{ fontSize: 14, borderRadius: "8px", fontWeight: "600", }}
              >
                <MenuItem value={formData.countryName}>
                  {formData.countryName}
                </MenuItem>
                {getAllSortedCountries &&
                  getAllSortedCountries?.map((item: any) => (
                    <MenuItem
                      key={item?.countryName}
                      value={item?.countryName}
                      sx={{ fontSize: 14 }}
                    >
                      {item.countryName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box> */}
          <Box mb={2}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
            >
              Country
            </InputLabel>
            <FormControl fullWidth size="small">
              <Autocomplete
                id="country-autocomplete"
                options={getAllSortedCountries || []}
                getOptionLabel={(option) => option.countryName}
                value={selectedCountry}
                onChange={handleSelectChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    sx={{
                      "& .MuiInputLabel-root": {
                        fontSize: { xs: 12, md: 14 },
                        top: "-5px",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        top: "0px",
                      },
                      "& .MuiFormLabel-filled": {
                        top: "0px",
                      },
                      "& .MuiOutlinedInput-root": {
                        height: "38px",
                        borderRadius: "8px",
                        fontSize: "14px",
                        "& .MuiSelect-select": {
                          // fontWeight: "600",
                        },
                        "& input": {
                          // fontWeight: "600"
                        },
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        fontSize: 14,
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight:
                          option.countryName === formData?.countryName
                            ? "bold"
                            : "normal",
                      }}
                    >
                      {option.countryName}
                    </Typography>
                  </li>
                )}
              />
              {formErrors?.countryName && (
                <Typography variant="body2" color="error">
                  {formErrors?.countryName}
                </Typography>
              )}
            </FormControl>
          </Box>
          <Box mb={2}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
            >
              Tags
            </InputLabel>
            <Box
              sx={{
                display: "flex",
                // flexDirection:"row",
                // alignItems:"center",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <FormControl fullWidth size="small">
                <Autocomplete
                  id="Select Tags"
                  multiple
                  options={
                    getContactTag.filter(
                      (each: any) => each.isActive && each.tag.trim() != ""
                    ) || []
                  }
                  getOptionLabel={(option: any) => {
                    // console.log("option", option, formData?.tags); // Add this console.log statement
                    // Return the label here
                    // For example:
                    return option?.tag;
                  }}
                  autoHighlight
                  value={formData.tags || []}
                  // value={
                  //   newTagAdded
                  //     ? [
                  //         ...getContactTag?.filter((tag: any) =>
                  //           formData?.tags?.includes(tag?.id)
                  //         ),
                  //       ]
                  //     : formData?.tags?.map((id: any) =>
                  //         getContactTag?.find((tag: any) => tag?.id === id)
                  //       )
                  // }
                  // defaultValue={editContact.tags || []}
                  // defaultValue={getContactTag?.find((item: any) => item.tag === formData?.tags) || {}}
                  // onChange={(event: any, newValue: any) => {
                  //   setFormData((prevState: any) => ({
                  //     ...prevState,
                  //     tags: newValue,
                  //   }));
                  // }}
                  onChange={(event: any, newValue: any) => {
                    setFormData((prevState: any) => ({
                      ...prevState,
                      tags: newValue,
                    }));
                  }}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.id === value?.id
                  }
                  // renderOption={(props, option, { selected }) => renderTag(props, option, selected)}
                  renderOption={renderOption}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Tags"
                      variant="outlined"
                      size="small"
                      sx={{
                        fontSize: 14,
                        borderColor: "gray",
                        width: "100%",
                        "& input": {
                          // fontWeight: "600",
                        },
                        "& .MuiOutlinedInput-root": {
                          // maxHeight: '37px',
                          borderRadius: "8px",
                          // padding:'10px 14px !important'
                        },
                        "& .MuiFormLabel-root-MuiInputLabel-root": {
                          // padding: '0px 4px 0px 0px',
                          backgroundColor: "#fff",
                        },
                        "& .MuiFormLabel-root": {
                          backgroundColor: "#fff",
                          padding: "0px 4px 0px 0px",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "14px",
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            <Button
                              onClick={openNewTagPopover}
                              color="success"
                              sx={{ textTransform: "none", height: "20px" }}
                            >
                              + Add new tag
                            </Button>
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
              <NewTagMember
                open={newTagDialog}
                handleClose={handleCloseTagPopover}
                addedNewTag={handleAddedNewTag}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box width="100%" mx={3} mb={2}>
          {/* {isEditContactLoading ? (
            <LoadingComponent height="auto" color={bgColors?.blue} />
          ) : ( */}
          <button onClick={handleSave} className={classes.updateButtonStyles}>
            Save Contact
          </button>
          {/* )} */}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditPopOver;
