import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import LeftSidebar from "../../components/AutomationComponents/WorkflowComponents/LeftSidebar";
import CloseIcon from "@mui/icons-material/Close";
import ChatComponent from "../../components/AutomationComponents/WorkflowComponents/ChatComponent";
import RightSidebar from "../../components/AutomationComponents/WorkflowComponents/RightSidebar";
import { LuWorkflow } from "react-icons/lu";
import { ContentState, EditorState } from "draft-js";
import { useAppSelector } from "../../utils/redux-hooks";
import { createWorkflow } from "../../redux/slices/Workflows/createWorkflowSlice";
import { useDispatch } from "react-redux";
import { toastActions } from "../../utils/toastSlice";
import { getWorkflowNames } from "../../redux/slices/Workflows/getWorkflowNamesSlice";
import { dataTool } from "echarts";
import { updateWorkflow } from "../../redux/slices/Workflows/updateWorkflowSlice";
import { CgExport } from "react-icons/cg";
import { downloadWorkflowByName } from "../../redux/slices/Workflows/downloadWorkflowSlice";
import { WORKFLOW_API } from "../../Apis/AdminLogin/Automation/Workflows";
import { getWorkflow } from "../../redux/slices/Workflows/getWorkflowSlice";
import { formatContent } from "../../utils/functions";

const WorkflowDialog = ({
  open,
  setOpen,
  initialWorkflowData = null,
  isWorkflowEditing,
  setIsWorkflowEditing,
}: any) => {
  const [chatMessages, setChatMessages] = useState<any>([]);
  const [steps, setSteps] = useState([1]);
  const [expandedStep, setExpandedStep] = useState(1);
  const [workflowData, setWorkflowData] = useState<any[]>([]);
  const [editorStates, setEditorStates] = useState<EditorState[]>([]);
  // console.log(editorStates[0].getCurrentContent().getPlainText())
  const [variables, setVariables] = useState<any[]>([]);
  const [buttons, setButtons] = useState<any[]>([]);
  const [selectedButtonValues, setSelectedButtonValues] = useState<string[]>(
    []
  );
  const [selectedRadioValue, setSelectedRadioValue] = useState(2);
  const [selectedLists, setSelectedLists] = useState<any[]>([]);
  const [showSaveUserResponses, setShowSaveUserResponses] = useState<boolean[]>(
    []
  );
  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const [saveResponseTypes, setSaveResponseTypes] = useState<string[]>([]);
  const { downloadWorkflowStatus, downloadWorkflowData } = useAppSelector(
    (state: any) => state.downloadWorkflowByName
  );
  // console.log(initialWorkflowData);
  const dispatch = useDispatch();

  const handleExportWorkflow = async () => {
    if (workflowData[0]?.workflowName && userProfileSlice?.data?.companyId) {
      try {
        const payload = {
          workflowName: workflowData[0].workflowName,
          companyId: userProfileSlice.data.companyId,
        };
        const response = await WORKFLOW_API.downloadWorkflowResponseListByName(
          payload
        );
        if (response.data) {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");

          link.href = url;
          link.download = "export_workflows.xlsx";
          link.style.display = "none";
          document.body.appendChild(link);

          link.click();

          // Cleanup
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } else {
          dispatch(
            toastActions.setToaster({
              message: "Failed to download the workflow data.",
              type: "error",
            })
          );
        }
      } catch (error) {
        dispatch(
          toastActions.setToaster({
            message: "An error occurred while exporting the workflow.",
            type: "error",
          })
        );
        console.error("Error exporting workflow:", error);
      }
    } else {
      dispatch(
        toastActions.setToaster({
          message: "Workflow name and company ID are required for export.",
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (initialWorkflowData) {
      setWorkflowData(initialWorkflowData);
      setSteps(initialWorkflowData.map((_: any, index: number) => index + 1));
    } else {
      setSteps([1]);
      setWorkflowData([
        {
          id: "",
          step: 1,
          workflowName: "",
          companyId: userData?.companyId || "",
          userId: userData?.userId || "",
          title: "",
          stepType: 2,
          flowResponseType: 0,
          autoReplyWorkflowButtons: null,
          workflowListId: null,
          responseCustomerMessage: {
            veriableNameEntityId: "",
            responseMessage: null,
          },
          veriables: [],
        },
      ]);
    }
  }, [initialWorkflowData, setOpen, open]);

  // console.log("workflowData111", workflowData);

  const handleClose = () => {
    setWorkflowData([]);
    setSteps([1]);
    setOpen(false);
    setIsWorkflowEditing(false);
  };

  const addChatMessage = (message: any) => {
    setChatMessages([...chatMessages, message]);
  };

  const handleAddStep = () => {
    const newStep = steps.length + 1;
    setSteps([...steps, newStep]);
    setExpandedStep(newStep);
    setWorkflowData([...workflowData, createNewStepData(newStep)]);
    setEditorStates([...editorStates, EditorState.createEmpty()]);
    setVariables([...variables, []]);
    setButtons([...buttons, []]);
    setSelectedButtonValues([...selectedButtonValues, ""]);
    setSelectedLists([...selectedLists, null]);
    setShowSaveUserResponses([...showSaveUserResponses, false]);
    setSaveResponseTypes([...saveResponseTypes, "trait"]);
  };

  const createNewStepData = (stepNumber: number) => {
    // Create a new step data object
    return {
      id: "",
      step: stepNumber,
      workflowName: workflowData[0]?.workflowName || "",
      companyId: workflowData[0]?.companyId || "",
      userId: workflowData[0]?.userId || "",
      title: "",
      stepType: 2,
      flowResponseType: 0,
      autoReplyWorkflowButtons: null,
      workflowListId: null,
      responseCustomerMessage: {
        veriableNameEntityId: "",
        responseMessage: null,
      },
      veriables: [],
    };
  };

  // useEffect(() => {
  //   setWorkflowData((prevData: any[]) =>
  //     prevData.map((step) => ({
  //       ...step,
  //       stepType: selectedRadioValue,
  //     }))
  //   );
  // }, [selectedRadioValue]);

  const handleStepDataChange = (stepNumber: number, data: any) => {
    setWorkflowData((prevData: any[]) => {
      const updatedData = [...prevData];
      updatedData[stepNumber - 1] = {
        ...updatedData[stepNumber - 1],
        ...data,
        responseCustomerMessage: {
          ...updatedData[stepNumber - 1]?.responseCustomerMessage,
          ...data.responseCustomerMessage,
        },
      };
      // If title is being updated, also update it in the main object
      if (data.title !== undefined) {
        updatedData[stepNumber - 1].title = data.title;
      }
      return updatedData;
    });
  };

  const handleEditorStateChange = (
    stepNumber: number,
    newEditorState: EditorState
  ) => {
    const formattedContent = formatContent(newEditorState?.getCurrentContent());
    setWorkflowData((prevData: any[]) => {
      const newData = [...prevData];
      newData[stepNumber - 1] = {
        ...newData[stepNumber - 1],
        title: formattedContent,
      };
      return newData;
    });
  };

  const handleButtonsChange = (stepNumber: number, newButtons: string[]) => {
    setWorkflowData((prevData) => {
      const newData = [...prevData];
      newData[stepNumber - 1] = {
        ...newData[stepNumber - 1],
        autoReplyWorkflowButtons: newButtons,
      };
      return newData;
    });
  };

  const handleVariablesChange = (stepNumber: number, newVariables: any[]) => {
    setVariables((prevVariables) => {
      const newVars = [...prevVariables];
      newVars[stepNumber - 1] = newVariables;
      return newVars;
    });
    handleStepDataChange(stepNumber, { veriables: newVariables });
  };

  const handleSelectedButtonValueChange = (
    stepNumber: number,
    value: string
  ) => {
    setSelectedButtonValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[stepNumber - 1] = value;
      return newValues;
    });
  };

  const handleSelectedListChange = (stepNumber: number, list: any) => {
    setSelectedLists((prevLists) => {
      const newLists = [...prevLists];
      newLists[stepNumber - 1] = list;
      return newLists;
    });
    handleStepDataChange(stepNumber, {
      workflowListId: list?.id || null,
    });
  };

  const handleShowSaveUserResponseChange = (
    stepNumber: number,
    show: boolean
  ) => {
    setShowSaveUserResponses((prevShows) => {
      const newShows = [...prevShows];
      newShows[stepNumber - 1] = show;
      return newShows;
    });
  };

  const handleSaveResponseTypeChange = (stepNumber: number, type: string) => {
    setSaveResponseTypes((prevTypes) => {
      const newTypes = [...prevTypes];
      newTypes[stepNumber - 1] = type;
      return newTypes;
    });
  };

  const handleWorkflowNameChange = (name: string) => {
    setWorkflowData((prevData: any[]) =>
      prevData.map((step) => ({
        ...step,
        workflowName: name,
      }))
    );
  };

  const handleSave = async (step: any) => {
    if (!workflowData[step - 1].title || workflowData[step - 1].title === "") {
      dispatch(
        toastActions.setToaster({
          message: "Custom response a required field",
          type: "error",
        })
      );
    } else if (
      !workflowData[step - 1].workflowName ||
      workflowData[step - 1].workflowName === ""
    ) {
      dispatch(
        toastActions.setToaster({
          message: "workflow name is a required field",
          type: "error",
        })
      );
    } else {
      try {
        let payload = {
          isNew: false,
          workflowData: workflowData[step - 1],
        };
        console.log("payload", payload);

        const result =
          workflowData[step - 1].id == "" || !workflowData[step - 1]?.id
            ? await dispatch(createWorkflow(payload)).unwrap()
            : await dispatch(updateWorkflow(payload)).unwrap();
        if (result) {
          const data: any = {
            companyId: userData?.companyId,
            workflowName: workflowData[step - 1]?.workflowName,
          };
          const payload2 = {
            companyId: userData?.companyId,
          };
          dispatch(getWorkflowNames(payload2));
          dispatch(getWorkflow(data));
          // setOpen(false);
          dispatch(
            toastActions.setToaster({
              message: result.message || "Workflow saved successfully",
              type: "success",
            })
          );
        } else {
          // Show error toast if workflow creation failed
          dispatch(
            toastActions.setToaster({
              message: result.message || "Failed to save workflow",
              type: "error",
            })
          );
        }
      } catch (error: any) {
        // Handle any errors that occur during workflow creation
        dispatch(
          toastActions.setToaster({
            message:
              error.message || "An error occurred while saving the workflow",
            type: "error",
          })
        );
      }
    }
    // }
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      maxWidth="lg"
      fullWidth
      sx={{
        // p: 2,
        borderRadius: "16px",
        "& .MuiPaper-root": {
          borderRadius: "16px",
          // overflow: "scroll",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "90vh",
        }}
      >
        {/* Navbar Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "white",
            color: "black",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            padding: "16px",
            zIndex: 1,
          }}
        >
          <Box
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              flexGrow: 1,
              fontSize: "18px",
            }}
          >
            <span style={{ marginRight: "8px" }}>
              <LuWorkflow style={{ height: "20px", width: "20px" }} />
            </span>
            {isWorkflowEditing ? "Update" : "Create"} Your Workflow
          </Box>
          {workflowData[0]?.workflowName != "" ? (
            <Tooltip title="Export" placement="left">
              {downloadWorkflowStatus === "loading" ? (
                <CircularProgress size={16} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "gray",
                    marginRight: "10px",
                    cursor: "pointer",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                    },
                  }}
                  onClick={handleExportWorkflow}
                >
                  <CgExport size={20} />
                </Box>
              )}
            </Tooltip>
          ) : (
            <></>
          )}
          <IconButton
            aria-label="close"
            sx={{ color: "black" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {/* Content Section */}
        <Box sx={{ flexGrow: 1 }}>
          <Grid container gap={0} justifyContent="space-evenly">
            <Grid item xs={2.75}>
              <LeftSidebar />
            </Grid>
            <Grid item xs={6}>
              <ChatComponent
                workflowData={workflowData}
                workflowName={workflowData[0]?.workflowName || ""}
                onWorkflowNameChange={handleWorkflowNameChange}
                isWorkflowEditing={isWorkflowEditing}
              />
            </Grid>
            <Grid item xs={2.75}>
              <RightSidebar
                steps={steps}
                expandedStep={expandedStep}
                setExpandedStep={setExpandedStep}
                handleAddStep={handleAddStep}
                handleStepDataChange={handleStepDataChange}
                addChatMessage={addChatMessage}
                workflowData={workflowData}
                editorStates={editorStates}
                handleEditorStateChange={handleEditorStateChange}
                variables={variables}
                handleVariablesChange={handleVariablesChange}
                buttons={buttons}
                handleButtonsChange={handleButtonsChange}
                selectedButtonValues={selectedButtonValues}
                handleSelectedButtonValueChange={
                  handleSelectedButtonValueChange
                }
                selectedLists={selectedLists}
                handleSelectedListChange={handleSelectedListChange}
                showSaveUserResponses={showSaveUserResponses}
                handleShowSaveUserResponseChange={
                  handleShowSaveUserResponseChange
                }
                saveResponseTypes={saveResponseTypes}
                handleSaveResponseTypeChange={handleSaveResponseTypeChange}
                handleSave={handleSave}
                isWorkflowEditing={isWorkflowEditing}
                setSelectedRadioValue={setSelectedRadioValue}
                selectedRadioValue={selectedRadioValue}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
};

export default WorkflowDialog;
