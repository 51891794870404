import * as firebase from "firebase/app";
import "firebase/messaging";
import { getMessaging } from "firebase/messaging";

export const firebaseConfig = {
  apiKey: "AIzaSyDc0_07hmfzPnov43At6ZhH85Q8jsfsqgg",
  authDomain: "ahex-demo.firebaseapp.com",
  projectId: "ahex-demo",
  storageBucket: "ahex-demo.appspot.com",
  messagingSenderId: "1002605836270",
  appId: "1:1002605836270:web:3a45dd32493173d973c0ff",
  measurementId: "G-VM413N1R92",
};

const app = firebase.initializeApp(firebaseConfig);
export const messaging: any = getMessaging(app);

export const generateToken = async () => {
  try {
    const notify = await Notification.requestPermission();
    // console.log("notify", notify);
    if (notify === "granted") {
      // const token = await getToken(messaging, {
      //   vapidKey : "BB16mQkoXx-xtP0zeVUZbonYGdNYTQkEvcwAvuJZH7iYZaal2djY-NxXvnliYi43GITU-Zmc9H_OueLR0QOo4dc"
      // })
      // console.log("token", token)
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
};

// export default firebase

export const firbase = () => {};
