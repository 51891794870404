/* global process */

import axios from "axios";

const USER_API_URL = process.env.REACT_APP_BASE_URL;

const getAllNotifications = (data: any) => {
  return axios({
    url: `${USER_API_URL}/NotificationPreference/get-notificationsList?userId=${data?.userId}&companyId=${data?.companyId}`,

    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const postAllNotifications = (data: any) => {
  return axios({
    url: `${USER_API_URL}/NotificationPreference/update-notifications?userId=${data?.userId}&companyId=${data?.companyId}&notificationName=${data?.notificationName}&isActive=${data?.isActive}`,

    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    // data: JSON.stringify({ data: data }),
    // data: JSON.stringify(data),
    // data: data,
  });
};

export const MANAGE_NOTIFICATIONS_APIS = {
  getAllNotifications,
  postAllNotifications,
};
