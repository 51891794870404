import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TEMPLATE_APIS } from "../../../Apis/Template/TemplateApis";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const updateTemplate = createAsyncThunk(
  "updateTemplate",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await TEMPLATE_APIS.editTemplate(data);
      return response?.data;
    } catch (error: any) {
      // console.log("error updating template", error);
      // Provide a descriptive error message
      return rejectWithValue(
        error?.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const updateTemplateSlice = createSlice({
  name: "updateTemplateSlice",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateTemplate.pending, (state) => {
        state.status = "loading";
        //   state.error = ""
        state.data = [];
      })
      .addCase(updateTemplate.fulfilled, (state, action) => {
        state.status = "succeeded";
        //   state.error = ""
        state.data = action.payload;
      })
      .addCase(updateTemplate.rejected, (state) => {
        state.status = "failed";
        state.data = [];
        //   state.error = action.error.message || "";
      });
  },
});

export const editTemplateActions = updateTemplateSlice.actions;
export default updateTemplateSlice.reducer;
