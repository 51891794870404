// profile permissions

const hasManageAccountPermission = (permissions: any) => {
  return permissions?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "manageAccount")
  );
};

const hasManageCompanyPermission = (permissions: any) => {
  return permissions?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "manageCompany")
  );
};

const hasManageClientsPermission = (permissions: any) => {
  return permissions?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "manageClients")
  );
};

const hasManagePermissionPermission = (permissions: any) => {
  return permissions?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "managePermissions")
  );
};

const hasManageNotificationsPermission = (permissions: any) => {
  return permissions?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "manageNotifications")
  );
};

const hasManageSubscriptionPermission = (permissions: any) => {
  return permissions?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "subscription")
  );
};

const hasHelpCenterPermission = (permissions: any) => {
  return permissions?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "helpCenter")
  );
};

// campaigns permissions

const checkOnetimeCampaignsPermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "oneTime")
  );
};

const checkSceduledCampaignsPermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "scheduled")
  );
};

//templates permissions

const checkTemplatesLibraryPermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "library")
  );
};

const checkAllTemplatesPermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "allTemplates")
  );
};

//automation permissions

const checkInboxSettingsPermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "inboxSettings")
  );
};

const checkAutoReplyPermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "autoReply")
  );
};

const checkWorkflowsPermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "workflows")
  );
};

//analytics permissions

const checkAnalyticsOverviewPermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "analyticsOverview")
  );
};

const checkAgentPerformancePermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "agentPerfomance")
  );
};

const checkInboxAnalyticsPermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "inboxAnalytics")
  );
};

const checkAdPerfomancePermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "adPerformance")
  );
};

//commerce permissions

const checkCommerceSettingsPermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "commerceSettings")
  );
};

const checkCatalogPermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "catalog")
  );
};

const checkOrderPanelPermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "orderPanel")
  );
};

const checkAutoCheckoutFlowPermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "autoCheckoutFlow")
  );
};

//integrations permissions
const checkDiscoverPermission = (permission: any) => {
  return permission?.some((profileItem: any) =>
    Object?.prototype?.hasOwnProperty?.call(profileItem, "discover")
  );
};

export {
  hasManageAccountPermission,
  hasManageCompanyPermission,
  hasManageClientsPermission,
  hasManagePermissionPermission,
  hasManageNotificationsPermission,
  hasManageSubscriptionPermission,
  hasHelpCenterPermission,
  checkOnetimeCampaignsPermission,
  checkSceduledCampaignsPermission,
  checkTemplatesLibraryPermission,
  checkAllTemplatesPermission,
  checkInboxSettingsPermission,
  checkAutoReplyPermission,
  checkWorkflowsPermission,
  checkAnalyticsOverviewPermission,
  checkAgentPerformancePermission,
  checkInboxAnalyticsPermission,
  checkAdPerfomancePermission,
  checkCommerceSettingsPermission,
  checkCatalogPermission,
  checkOrderPanelPermission,
  checkAutoCheckoutFlowPermission,
  checkDiscoverPermission,
};
