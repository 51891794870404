import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PhoneIcon from "@mui/icons-material/Phone";
import LaunchIcon from "@mui/icons-material/Launch";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import Button from "@mui/material/Button";
import { parseTextToHtml } from "../../../utils/functions";
import { bgColors } from "../../../utils/bgColors";
interface WhatsAppMessageProps {
  header?: string;
  body?: any;
  footer?: string;
  mediaType?: any;
  mediaFile?: string | File;
  buttons?: {
    buttonType: string;
    buttonValue?: string;
    buttonName?: string;
  }[];
}

const TemplatePreviewLayout: React.FC<WhatsAppMessageProps> = ({
  header,
  body,
  footer,
  mediaType,
  mediaFile,
  buttons,
}) => {
  // console.log(body,"body")
  // console.log(header,"header")
  // console.log(mediaType,"mediaType")
  // console.log(mediaFile,"mediaFile")
  const [bodyText, setBodyText] = useState("");

  // const parseTextToDraft:any = (text: any) => {
  //   // Replace markdown-like symbols with HTML tags
  //   const htmlText = text
  //     .replace(/\*(.*?)\*/g, '<b>$1</b>')
  //     .replace(/_(.*?)_/g, '<i>$1</i>')
  //     .replace(/~(.*?)~/g, '<strike>$1</strike>');

  //   return htmlText;
  // };
  // const parseTextToDraft = (text: string) => {
  //   // Replace markdown-like symbols with HTML tags for inline styles
  //   let htmlText = text
  //     ?.replace(/\*(.*?)\*/g, "<b>$1</b>")
  //     ?.replace(/_(.*?)_/g, "<i>$1</i>")
  //     ?.replace(/~(.*?)~/g, "<strike>$1</strike>");

  //   // Replace number points with HTML list items for ordered list
  //   htmlText = htmlText?.replace(/^(\d+\.\s.*)$/gm, "<li>$1</li>");
  //   htmlText = htmlText?.replace(/<li>(\d+\.\s)/g, "<li>"); // Remove the numbers inside the <li> tags
  //   if (htmlText?.includes("<li>")) {
  //     htmlText = `<ol>${htmlText}</ol>`;
  //   }

  //   // Replace bullet points with HTML list items for unordered list
  //   htmlText = htmlText?.replace(/^-\s+(.*)$/gm, "<li>$1</li>");
  //   if (htmlText?.includes("<li>") && !htmlText?.includes("<ol>")) {
  //     htmlText = `<ul>${htmlText}</ul>`;
  //   }

  //   // Preserve line breaks in paragraphs by replacing new lines with <br> tags
  //   htmlText = htmlText.replace(/\n/g, "<br>");

  //   return htmlText;
  // };

  useEffect(() => {
    const htmlText: any = parseTextToHtml(body && body);
    setBodyText(htmlText);
  }, [body]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "400px",
        minWidth: "200px",
        margin: "0 auto",
        textAlign: "center",
        // border: "1px solid #cdcdcd",
        borderRadius: "4px",
        overflow: "hidden",
        backgroundColor: bgColors?.white,
      }}
    >
      <Box sx={{ padding: "10px 6px", width: "100%" }}>
        {header ? (
          <Typography
            mx={1}
            sx={{
              fontSize: 14,
              fontWeight: "bold",
              color: "#075e54",
              textAlign: "justify",
            }}
          >
            {header}
          </Typography>
        ) : mediaType === 3 ? (
          <img
            src={
              mediaFile instanceof File
                ? URL.createObjectURL(mediaFile)
                : mediaFile
            }
            alt="Template Preview"
            style={{
              width: "100%",
              height: "auto",
              // maxHeight: "400px",
              objectFit: "fill",
            }}
          />
        ) : mediaType === 4 ? (
          <video
            style={{
              width: "100%",
              height: "auto",
              // maxHeight: "400px",
              objectFit: "contain",
            }}
            src={
              mediaFile instanceof File
                ? URL.createObjectURL(mediaFile)
                : mediaFile
            }
            controls={true}
          />
        ) : mediaType === 5 ? (
          <iframe
            style={{
              width: "100%",
              height: "300px",
              maxHeight: "300px",
              objectFit: "contain",
            }}
            src={
              mediaFile instanceof File
                ? URL.createObjectURL(mediaFile)
                : mediaFile
            }
          ></iframe>
        ) : (
          ""
        )}
      </Box>
      <Box
        style={{
          padding: "6px",
          width: "100%",
          // objectFit: "contain",
        }}
      >
        <Typography
          mx={1}
          sx={{ textAlign: "justify", fontSize: 12, color: "#000" }}
          dangerouslySetInnerHTML={{ __html: bodyText || "" }}
        />
      </Box>
      <Box style={{ padding: "10px 6px", width: "100%" }}>
        <Typography
          mx={1}
          sx={{ textAlign: "justify", fontSize: 12, color: "#888" }}
        >
          {footer && footer}
        </Typography>
      </Box>
      <Box
        style={{
          padding: "10px 6px",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {buttons?.map((button, index) => (
          <Button
            key={index}
            startIcon={
              button?.buttonType === "PHONE_NUMBER" ? (
                <PhoneIcon />
              ) : button?.buttonType === "URL" ? (
                <LaunchIcon />
              ) : button?.buttonType === "QUICK_REPLY" ? (
                <ReplyAllIcon />
              ) : undefined
            }
            sx={{
              fontSize: 12,
              width: "100%",
              justifyContent: "center",
              textTransform: "none",
              borderTop: "0.5px solid #cdcdcd",
              "&:hover": {
                color: "#075e54",
              },
            }}
          >
            {button?.buttonType === "PHONE_NUMBER"
              ? button?.buttonName
              : button?.buttonType === "URL"
              ? button?.buttonName
              : button?.buttonType === "QUICK_REPLY"
              ? button?.buttonValue || button?.buttonName
              : ""}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default TemplatePreviewLayout;
