import { Dispatch } from "redux";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";

type ApiCallFunction = (...args: any[]) => any;

const useDebouncedFetch = (apiCall: ApiCallFunction, delay: number = 1500) => {
  const dispatch: Dispatch = useDispatch();

  const debouncedFetch = useCallback(
    debounce((...args: Parameters<ApiCallFunction>) => {
      dispatch(apiCall(...args));
    }, delay),
    [dispatch, apiCall, delay]
  );

  return debouncedFetch;
};

export default useDebouncedFetch;

// export const useDebouncedWebSocketCall = (
//   connection: any,
//   chatsPageNumber:number,
//   delay: number = 1500
// ) => {
//   const debouncedWebSocketCall = useCallback(
//     debounce(
//       (
//         userId: string,
//         businessId: string,
//         operations: any,
//         setContacts: Function
//       ) => {
//         // console.log("chatsPageNoInDebounceHook", chatsPageNumber);

//         if (connection) {
//           connection.invoke(
//             "SendUserData",
//             userId,
//             businessId,
//             operations,
//             chatsPageNumber
//           );
//           connection.on("SendContacts", (data: any) => {
//             setContacts(() => ({
//               currentPage: data.currentPage,
//               totalPages: data.totalPages,
//               totalCount: data.totalCount,
//               itemsPerPage: data.pageSize,
//               data: data.data,
//             }));
//           });
//         }
//       },
//       delay
//     ),
//     [connection, delay]
//   );

//   return debouncedWebSocketCall;
// };
