import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WORKFLOW_API } from "../../../Apis/AdminLogin/Automation/Workflows";

export interface IData {
    updateWorkflowStatus: "loading" | "succeeded" | "failed" | "idle";
    updateWorkflowData: any;
}

const initialState: IData = {
    updateWorkflowStatus: "idle",
    updateWorkflowData: null,
};

export const updateWorkflow = createAsyncThunk(
    "autoReply/updateWorkflow",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await WORKFLOW_API.updateWorkflow(data);
            return response?.data;
        } catch (error: any) {
            // Provide a descriptive error message
            return rejectWithValue(error.response || 'An error occurred');
        }
    }
) as any;


export const updateWorkflowSlice = createSlice({
    name: "updateWorkflow",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(updateWorkflow.pending, (state) => {
                state.updateWorkflowStatus = "loading";
                state.updateWorkflowData = null;
            })
            .addCase(updateWorkflow.fulfilled, (state, action) => {
                state.updateWorkflowStatus = "succeeded";
                state.updateWorkflowData = action.payload;
            })
            .addCase(updateWorkflow.rejected, (state) => {
                state.updateWorkflowStatus = "failed";
            });
    },
});


export const updateWorkflowSliceActions = updateWorkflowSlice.actions;
export default updateWorkflowSlice.reducer;
