import {
  Popover,
  ListItem,
  ListItemText,
  Checkbox,
  Typography,
  Box,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { bgColors } from "../../utils/bgColors";
import React, { useState } from "react";
import MoveNewTagMember from "./MoveNewTagMember";
import { CONTACT_TAGS_APIS } from "../../Apis/Contacts/ContactTagsApis";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { toastActions } from "../../utils/toastSlice";
import { fetchAllContacts } from "../../redux/slices/Contacts/AllContactsSlice";
import { getContactTags } from "../../redux/slices/Contacts/getContactTags";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import RedDeleteIconSvg from "../../assets/svgs/RedDeleteIconSvg";
import LoadingComponent from "../common/LoadingComponent";
import SearchIconSvg2 from "../../assets/svgs/SearchIconSvg2";
import { IoMdPricetag } from "react-icons/io";
import DeleteSvg from "../../assets/svgs/DeleteSvg";

const useStyles = makeStyles({
  searchField: {
    width: "100%",
    borderRadius: "12px",
    height: "38px",
    fontSize: "18px",
    // backgroundColor: bgColors.white2,
    backgroundColor: "white",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "Inter",
      color: "#4B5A5A !important",
      fontWeight: "500 !important",
    },
  },
  lineStyles: {
    border: "none",
    borderTop: "3px solid #4B5A5A",
    width: "80%",
    margin: "0 auto",
    opacity: "20%",
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "150px",
    height: "32px",
    // padding: "5px",
    cursor: "pointer",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "rgba(68, 71, 70, 0.08)",
    },
  },
  deleteIcon: {
    color: "red",
    visibility: "hidden",
    cursor: "pointer",
  },
  listItem: {
    position: "relative",
    "&:hover $deleteIcon": {
      visibility: "visible",
    },
    padding: "0px",
  },
});

const MoveToTagsPopOver = ({
  anchorEl,
  selectedContacts,
  handleClose,
  options,
  handleSearchChange,
}: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const getuserPermissionData = useAppSelector(
    (state:any) => state.getUserPermissions?.data
  );
  const userProfileSlice = useAppSelector((state:any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const manageContactsObject = getuserPermissionData?.contacts;

  const [dialog, setDialog] = useState(false);
  const [checked, setChecked] = useState<any>([]);
  // console.log(options)
  // console.log(checked, 'checked')
  const [createTagsTooltip, setCreateTagsTooltip] = useState(false);
  const [deleteTagsTooltip, setDeleteTagsTooltip] = useState(null);
  const [deleteOptionId, setDeleteOptionId] = useState<any>(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<any>(false);
  const [isDeleteTagLoading, setIsDeleteTagLoading] = useState<any>(false);
  const [loadingTags, setLoagingTags] = useState(false);
  const [editContactsTooltip, setEditContactsTooltip] = useState(false);

  // const handleDialogOpen = (option: any) => {
  //   setOpenConfirmationDialog(true);
  //   setDeleteOptionId(option);
  // };

  const handleDialogClose = () => {
    setOpenConfirmationDialog(false);
  };
  const handleOpen = () => {
    const hasPermission = hasCreateTagPermission(manageContactsObject);
    if (hasPermission) {
      setDialog(true);
    } else {
      setCreateTagsTooltip(true);
      setTimeout(() => {
        setCreateTagsTooltip(false);
      }, 2000);
    }
  };
  const handleClosed = () => {
    setDialog(false);
  };

  const hasCreateTagPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "createTags")) {
        return true;
      }
    }
  };

  const hasDeleteTagPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "deleteTags")) {
        return true;
      }
    }
  };

  const handleChecked = (tagId: any) => {
    // Updated argument name to tagId
    const currentIndex = checked.indexOf(tagId);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(tagId);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const hasEditContactPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "editContacts")) {
        return true;
      }
    }
  };

  const handleAddTags = async () => {
    const hasPermission = hasEditContactPermission(manageContactsObject);
    if (hasPermission) {
      const selectedContactIds = selectedContacts?.map(
        (contact: any) => contact?.contactId
      );
      if (checked.length > 0) {
        try {
          setLoagingTags(true);

          const data = {
            businessId: userData?.companyId,
            userId: userData?.userId,
            tagIds: checked,
            contactIds: selectedContactIds,
          };
          const getRes = await CONTACT_TAGS_APIS.updateContactTags(data);
          if (getRes?.status === 200) {
            handleClose();
            dispatch(
              toastActions.setToaster({
                type: "success",
                message: `${getRes?.data?.message}`,
              })
            );
            setLoagingTags(false);

            // window?.location?.reload();
            const bodyData = {
              businessId: userData?.companyId,
              userId: userData?.userId,
              page: 1,
              per_page: 40,
            };
            dispatch(fetchAllContacts(bodyData));
          } else {
            dispatch(
              toastActions.setToaster({
                type: "error",
                message: `${getRes?.data?.message}`,
              })
            );
            setLoagingTags(false);
          }
        } catch (e: any) {
          // console.log("e", e);
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: `${e?.response?.message}`,
            })
          );
          setLoagingTags(false);
        }
      } else {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `Please select atleast one tag`,
          })
        );
      }
    } else {
      setEditContactsTooltip(true);
      setTimeout(() => {
        setEditContactsTooltip(false);
      }, 2000);
    }
    setLoagingTags(false);
  };

  const handleDeleteTag = async (tagId: any) => {
    const hasPermission = hasDeleteTagPermission(manageContactsObject);
    if (hasPermission) {
      const data = {
        businessId: userData?.companyId,
        userId: userData?.userId,
        tagIds: tagId,
      };
      setIsDeleteTagLoading(true);
      const getDelRes = await CONTACT_TAGS_APIS.deleteTags(data);
      if (getDelRes?.status === 200) {
        handleClose();
        setIsDeleteTagLoading(false);
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: "Tag Deleted Successfully",
          })
        );
        setIsDeleteTagLoading(false);
        const body = {
          businessId: userData?.companyId,
          userId: userData?.userId,
          search: "",
        };
        await dispatch(getContactTags(body));
      }
    } else {
      setDeleteTagsTooltip(tagId);
      setTimeout(() => {
        setDeleteTagsTooltip(null);
      }, 2000);
    }
  };

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          left: "160px !important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "70vh",
            width: "280px", // Adjust this width as needed
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              scrollBehavior: "smooth",
              "&::-webkit-scrollbar": {
                cursor: "pointer",
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                cursor: "pointer",
                backgroundColor: "#cdcdcd",
                borderRadius: "8px",
                transition: "background-color 0.2s ease-in-out",
              },
            }}
          >
            <Box m={2} width="230px">
              <Box
                my={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IoMdPricetag />
                  &nbsp; Tags
                </Typography>
                <Tooltip
                  title="Access Denied"
                  open={createTagsTooltip}
                  placement="top"
                  onClose={() => setCreateTagsTooltip(false)}
                >
                  <Typography
                    variant="body2"
                    color={`${bgColors.green}`}
                    style={{ cursor: "pointer", fontWeight: "600" }}
                    onClick={handleOpen}
                  >
                    + Create
                  </Typography>
                </Tooltip>
                <MoveNewTagMember open={dialog} handleClose={handleClosed} />
              </Box>
              <Box>
                <TextField
                  className={classes.searchField}
                  variant="standard"
                  size="small"
                  onChange={handleSearchChange}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "10px",
                      fontWeight: "600 !important",
                      height: "38px",
                    },
                    startAdornment: (
                      <IconButton
                        sx={{ p: 0, color: "inherit" }}
                        aria-label="search"
                      >
                        <SearchIconSvg2 />
                      </IconButton>
                    ),
                  }}
                  inputProps={{
                    style: {
                      fontWeight: "600 !important",
                      padding: "0px",
                    },
                  }}
                  placeholder="Search active tags"
                />
              </Box>
            </Box>
            <Box m={{ xs: 1 }}>
              <div>
                {options
                  ?.filter((each: any) => each?.isActive)
                  ?.map((option: { id: number; tag: string }) => (
                    <ListItem
                      key={option.id}
                      className={classes.listItem}
                      sx={{ p: 0 }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          marginBottom: "-5px",
                        }}
                      >
                        <>
                          <Checkbox
                            onChange={() => {
                              handleChecked(option?.id);
                            }}
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 17 },
                              color: "gray",
                              "&.Mui-checked": {
                                color: "gray",
                              },
                            }}
                          />
                          <ListItemText
                            primary={
                              <Box
                                sx={{
                                  fontSize: "14px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip title={option?.tag} placement="top">
                                  <span
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      maxWidth: "150px",
                                      verticalAlign: "middle",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {option.tag}
                                  </span>
                                </Tooltip>
                              </Box>
                            }
                          />
                        </>
                        <Tooltip
                          title="Access Denied"
                          open={deleteTagsTooltip === option?.id}
                          placement="top"
                          onClose={() => setDeleteTagsTooltip(null)}
                          sx={{ marginLeft: "14px" }}
                        >
                          <div
                            className={classes.deleteIcon}
                            onClick={() => {
                              setOpenConfirmationDialog(true);
                              setDeleteOptionId(option?.id);
                            }}
                          >
                            <DeleteSvg />
                          </div>
                        </Tooltip>
                      </Box>
                    </ListItem>
                  ))}
              </div>
            </Box>
          </Box>
          {loadingTags ? (
            <LoadingComponent height="auto" color={bgColors?.blue} />
          ) : (
            <Box
              sx={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "white",
                padding: "10px 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // borderTop: '1px solid #e0e0e0',
              }}
            >
              <Tooltip
                title="Access Denied"
                placement="top"
                open={editContactsTooltip}
                onClose={() => setEditContactsTooltip(false)}
              >
                <button
                  className={classes.SaveChangesButton}
                  onClick={handleAddTags}
                >
                  Add Selected Tags
                </button>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Popover>
      <Dialog
        open={openConfirmationDialog}
        // onClick={handleDialogClose}
        PaperProps={{
          style: { minWidth: "18%", borderRadius: "20px", padding: "0px 40px" },
        }}
      >
        <Box
          mt={3}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RedDeleteIconSvg />
        </Box>
        <DialogTitle>
          <Box>
            <Typography
              textAlign="center"
              variant="h6"
              style={{
                color: `${bgColors.black1} !important`,
                fontWeight: "600 !important",
              }}
            >
              Are you sure
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box textAlign="center">
              <Typography
                variant="body2"
                style={{
                  color: `${bgColors.black1} !important`,
                  opacity: "60% !important",
                  fontWeight: "600 !important",
                }}
              >
                Do you really want to delete the tag ?
              </Typography>
            </Box>
          </Box>
        </DialogContent>

        <Box
          mb={3}
          display="flex"
          flexDirection="row"
          gap={2}
          justifyContent={"center"}
          //  width="100%"
        >
          <button
            style={{
              backgroundColor: "#ffffff",
              color: "#000000",
              height: "40px",
              fontSize: "14px",
              borderRadius: "8px",
              cursor: "pointer",
              border: `1px solid ${bgColors.gray3}`,
              width: "180%",
              padding: "8px",
              fontWeight: 500,
            }}
            onClick={handleDialogClose}
          >
            Cancel
          </button>
          {isDeleteTagLoading ? (
            <LoadingComponent height="auto" color={bgColors?.blue} />
          ) : (
            <button
              style={{
                backgroundColor: "#3C3C3C",
                color: "#ffffff",
                height: "40px",
                borderRadius: "8px",
                cursor: "pointer",
                width: "190%",
                padding: "8px",
                fontWeight: 500,
                fontSize: "14px",
              }}
              onClick={() => handleDeleteTag(deleteOptionId)}
            >
              Delete
            </button>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default MoveToTagsPopOver;
