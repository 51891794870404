import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import WhatsAppTools from "./whatsappTools";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    marginTop: "20px",
    height: "95%",
    width: "100%",
  },
  chatArea: {
    padding: "20px",
  },
  chatTextBox: {
    position: "relative",
    border: "1px solid #262738",
    borderRadius: 8,
  },
  toolBarStyle: {
    border: "none",
    backgroundColor: "rgb(247, 244, 244)",
    padding: "10px 0",
    marginBottom: 0,
    order: 2,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  wrapperClassName: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  editorClassName: {
    padding: "10px",
    minHeight: "60px",
    maxHeight: "200px",
    fontSize: "14px",
    order: 1,
  },
  fileUploadInput: {
    display: "none",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textColor: {
    color: bgColors.black1,
    fontSize: "16px",
    opacity: "80%",
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
  },
  emailContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  resetBtnContainer: {
    backgroundColor: bgColors.white,
    // border: `2px solid ${bgColors.gray5}`,
    borderRadius: "8px",
    width: "100px",
    padding: "8px",
    textAlign: "center",
    cursor: "pointer",
  },
  variable: {
    color: `${bgColors.green} !important`,
    fontWeight: "600 !important",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  changePasswordText: {
    color: bgColors.black,
    fontWeight: "600 !important",
  },
  welcomeContainer: {
    backgroundColor: bgColors.white,
    border: `2px solid ${bgColors.gray5}`,
    borderRadius: "8px",
    width: "90px",
    padding: "8px",
    textAlign: "center",
    cursor: "pointer",
  },
  cursor: {
    cursor: "pointer",
  },
  welcometext: {
    color: bgColors.black,
    fontWeight: "600 !important",
  },
  delayedContainer: {
    backgroundColor: bgColors.white,
    border: `2px solid ${bgColors.gray5}`,
    borderRadius: "8px",
    width: "90px",
    padding: "8px",
    textAlign: "center",
    cursor: "pointer",
  },
  grayColor: {
    color: "#303030",
    opacity: "60%",
    fontSize: "20px",
    // padding:"5px"
  },
  delayedtext: {
    color: bgColors.black,
    fontWeight: "600 !important",
  },
  hours: {
    color: bgColors.black,
    opacity: "80%",
    fontSize: "15px !important",
  },
  time: {
    color: bgColors.black,
    opacity: "80%",
    fontSize: "16px !important",
    // fontWeight: "Bold !important",
  },
  lineStyles: {
    border: "none",
    borderTop: "3px solid #4B5A5A",
    width: "100%",
    margin: "0 auto",
    opacity: "20%",
    marginTop: "5px",
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    // backgroundColor: bgColors.blue,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "120px",
    height: "32px",
    gap: "0px",
  },
});

const Discover = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.mainContainer}>
      <Grid className={classes.bgContainer}>
        <Box className={classes.manageContainer}>
          <Box ml={4} mt={2} mb={1}>
            <Typography variant="h6" className={classes.blackColor}>
              App Store
            </Typography>
          </Box>
        </Box>
        <WhatsAppTools />
      </Grid>
    </Grid>
  );
};

export default Discover;
