/* global process */
import {
  Box,
  Button,
  Grid,
  // IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import EditSvg from "../../../assets/svgs/EditSvg";
import ChangePasswordDialog from "../../../components/ManageAccountComponents/ChangePasswordPopup";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
// import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { bgColors } from "../../../utils/bgColors";
// import RightArrowSvg from "../../../assets/svgs/RightArrowSvg";
import DownArraowSvg from "../../../assets/svgs/DownArraowSvg";
// import LanguageSelectPopover from "../../../components/ManageAccountComponents/LanguageSelectPopup";
// import { GET_MANAGE_ACCOUNT } from "../../../Apis/AdminLogin/ManageAccountApis/GetManangeAccount";
import LoadingComponent from "../../../components/common/LoadingComponent";
import { useAppSelector } from "../../../utils/redux-hooks";
// import { GET_MANAGE_ACCOUNT } from "../../../Apis/ManageAccountApis/GetManangeAccount";
// import { useTranslation } from "react-i18next";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}

const useStyles = makeStyles({
  mainContainer: {
    // backgroundColor: bgColors.white1,
    padding: "14px",
    width: "100%",
    height: "100vh",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100% !important",
    width: "100%",
    paddingBottom: "24px",
    overflow: "auto",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  editButtonContainer: {
    borderRadius: "8px",
    border: `1px solid ${bgColors.green}`,
    height: "32px",
    display: "flex",
    flexDirection: "row",
    // alignItems: "center",
    justifyContent: "space-evenly",
    width: "130px",
    cursor: "pointer",
  },
  editButton: {
    cursor: "pointer",
    backgroundColor: "transparent",
    color: bgColors.green,

    borderRadius: "8px",
    marginLeft: 10,
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
  },
  profileContainer: {
    display: "flex",
    flexDirection: "row",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    // fontWeight: "600 !important",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
  },
  designationStyle: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
    fontWeight: "600 !important",
  },
  emailContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  resetBtnContainer: {
    border: `2px solid ${bgColors.red}`,
    borderRadius: "20px",
    minWidth: "80px",
    maxWidth: "150px",
    padding: "5px",
    textAlign: "center",
    cursor: "pointer",
  },
  changePasswordText: {
    color: bgColors.red,
    fontWeight: "600",
  },
  changePasswordText1: {
    color: "#303030",
    fontWeight: "600",
    height: "fit-content",
  },
  LanguagePreferenceContainer: {
    borderRadius: "15px",
    border: `2px solid ${bgColors.gray5}`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paymentContainer: {
    borderRadius: "15px",
    border: `2px solid ${bgColors.gray5}`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  LanguageContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    border: `2px solid ${bgColors.gray5}`,
    padding: "6px 18px",
    borderRadius: "20px",
    cursor: "pointer",
    columnGap: "8px",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  badge: {
    position: "sticky",
    top: 0,
    zIndex: 1000,
    width: "100%",
    color: "#fff",
    textAlign: "center",
    padding: "8px 0",
    fontSize: "14px",
  },
});

const ManageAccount = ({ t }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();

  const loginData = useAppSelector((state: any) => state?.adminLogin?.data);
  // console.log(loginData);
  const companyId = useAppSelector(
    (state: any) => state.adminLogin?.data?.companyId
  );
  const getManageAccount = useAppSelector((state: any) => state?.accountData);
  const getAccountMetaStatusSlice = useAppSelector(
    (state: any) => state?.accountMetaStatus
  );
  const getAccountMetaStatus = getAccountMetaStatusSlice?.data;
  const currentPlanDetails = useAppSelector(
    (state: any) => state?.wallet?.walletAndSubscription?.data
  );
  const data = getManageAccount?.data;
  const getuserPermissionData = useAppSelector(
    (state: any) => state.getUserPermissions?.data
  );
  const getMc = getuserPermissionData?.profile;

  const manageAccountObject = getMc?.find((item: any) =>
    Object?.prototype?.hasOwnProperty?.call(item, "manageAccount")
  );
  const manageAccountActions = manageAccountObject
    ? manageAccountObject.manageAccount
    : [];

  const [openDialog, setOpenDialog] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);
  const [
    selectedLanguage,
    //  setSelectedLanguage
  ] = useState(null);
  const [editTooltip, setEditTooltip] = useState(false);
  const [changePasswordTooltip, setChangeTooltip] = useState(false);
  // const [payementReferenceTooltip, setPaymentReferenceTooltip] =
  //   useState(false);
  const [languageTooltip, setLanguageTooltip] = useState(false);

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  // console.log("getMAP", manageAccountActions);

  const formattedDate = data?.creationDate ? formatDate(data.creationDate) : "";

  const hasAcess = (permission: any) => {
    if (manageAccountActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  const handleOpenDialog = () => {
    const hasPermission = hasAcess("changePassword");
    if (hasPermission) {
      setOpenDialog(true);
    } else {
      setChangeTooltip(true);
      setTimeout(() => {
        setChangeTooltip(false);
      }, 2000);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEditClick = () => {
    const hasPermission = hasAcess("editDetails");
    if (hasPermission) {
      navigate(`/profile/manage-account/${data?.id}/edit`);
    } else {
      setEditTooltip(true);
      setTimeout(() => {
        setEditTooltip(false);
      }, 2000);
    }
  };

  // const handlePayments = () => {
  //   const hasPermission = hasAcess("paymentPreferences");
  //   if (hasPermission) {
  //     navigate("/profile/manage-account/payments");
  //   } else {
  //     setPaymentReferenceTooltip(true);
  //     setTimeout(() => {
  //       setPaymentReferenceTooltip(false);
  //     }, 2000);
  //   }
  // };

  const handleLanguageButtonClick = () => {
    const hasPermission = hasAcess("languagePreferences");
    if (hasPermission) {
      // setAnchorEl(event.currentTarget);
    } else {
      setLanguageTooltip(true);
      setTimeout(() => {
        setLanguageTooltip(false);
      }, 2000);
    }
  };

  // const handleLanguageClose = () => {
  //   setAnchorEl(null);
  // };

  // const setLanguageApi = async (selectedLanguage: string) => {
  //   try {
  //     const response = await GET_MANAGE_ACCOUNT.setPrefferedLanguage(
  //       selectedLanguage
  //     );
  //     // console.log("response", response);
  //   } catch (err) {
  //     // console.log("err", err);
  //   } finally {
  //   }
  // };

  // const handleLanguageSelect = (language: any) => {
  //   setSelectedLanguage(language.languageName);
  //   setLanguageApi(language.languageName);
  //   // console.log("language", language?.languageCode);
  //   i18next.changeLanguage(language.languageCode);
  //   handleLanguageClose();
  // };
  const handleBackdropClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation(); // Prevents the dialog from closing
  };

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "2690570337772800", // Facebook App ID
        cookie: true,
        xfbml: true,
        version: "v19.0",
      });
    };

    // Load the JavaScript SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      // @ts-expect-error: External SDK script requires direct assignment
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      // @ts-expect-error: Inserting script into the DOM without type checks
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    const sessionInfoListener = (event: MessageEvent) => {
      if (event.origin !== "https://www.facebook.com") return;
      try {
        const data = JSON.parse(event.data);
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          if (data.event === "FINISH") {
            // const { phone_number_id, waba_id } = data.data;
            // console.log(phone_number_id, waba_id); // Use these values as needed
          } else {
            // const { current_step } = data.data;
            // console.log(current_step); // Use this value as needed
          }
        }
      } catch {
        // console.log('Non JSON Response', event.data);
      }
    };

    window.addEventListener("message", sessionInfoListener);

    return () => {
      window.removeEventListener("message", sessionInfoListener);
    };
  }, []);

  const launchWhatsAppSignup = () => {
    window?.FB?.login(
      (response: any) => {
        // console.log(response, "FaceBook response");
        if (response.authResponse) {
          const code = response.authResponse.code;
          // console.log(code, "FaceBook Code");
          // const url = `https://fastsagewave8.conveyor.cloud/api/EmbeddedSignUp?code=${code}`;
          // const url = `https://webhooks.engageto.com/api/EmbeddedSignUp?BusinessId=${companyId}&code=${code}`;
          const url = `${process.env.REACT_APP_BASE_URL}/EmbeddedSignUp?BusinessId=${companyId}&code=${code}`;

          fetch(url, {
            method: "POST",
            // headers: {
            //   'Accept': '*/*'
            // }
            // @ts-expect-error: Doing api call without checking type errors
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          })
            .then((response) => {
              if (response.ok) {
                console.log("API call successful");
              } else {
                console.error("API call failed");
              }
            })
            .catch((error) => {
              console.error("Network error:", error);
            });
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "450495777451034",
        response_type: "code",
        override_default_response_type: true,
        scope: "whatsapp_business_management, whatsapp_business_messaging",
        extras: {
          setup: {
            // Prefilled data can go here
          },
        },
      }
    );
  };
  // console.log("datamanageaccount", getManageAccount, params);
  // console.log("getAccountMetaStatus", getAccountMetaStatus);

  return (
    <>
      {data?.companyVerificationStatus === false &&
        data?.isMetaEnabled === false &&
        currentPlanDetails?.subscriptionPlan?.planName === "Intro" &&
        currentPlanDetails?.subscriptionPlan?.isActive === true && (
          <Box
            className={classes.badge}
            sx={{ backgroundColor: bgColors?.yellow }}
          >
            <Typography>
              You are under free trail. Please link your account with Meta.
            </Typography>
          </Box>
        )}
      <Grid
        className={classes.mainContainer}
        // sx={{ height: getManageAccount?.status === "loading" ? "100%" : "auto" }}
      >
        {getManageAccount?.status === "loading" ? (
          <Box sx={{ height: "100%" }}>
            <LoadingComponent height="100%" color={bgColors.blue} />
          </Box>
        ) : (
          <Grid className={classes.bgContainer}>
            <Box
              pt={{ xs: 1, md: 4 }}
              px={{ xs: 1, md: 4 }}
              className={classes.manageContainer}
            >
              <Typography
                variant="h6"
                className={classes.blackColor}
                sx={{ display: "flex" }}
              >
                <span style={{ marginTop: "5px" }}>
                  <ManageAccountsIcon
                    sx={{ height: "25px", marginTop: "-2px" }}
                  />
                </span>
                &nbsp;Manage Account&nbsp;
                {data?.companyVerificationStatus === true &&
                  data?.isMetaEnabled === false && (
                    <Box ml={4} sx={{ maxWidth: 650 }}>
                      {getAccountMetaStatus?.data?.entities?.map(
                        (item: any, index: number) => (
                          <Typography fontSize={12} color={bgColors?.red1}>
                            {(item?.errorMessage || item?.possibleSolution) &&
                              `${index + 1}.`}
                            &nbsp;
                            {item?.errorMessage}&nbsp;
                            {item?.possibleSolution}
                          </Typography>
                        )
                      )}
                    </Box>
                  )}
              </Typography>
            </Box>

            <>
              <Box m={{ xs: 1, md: 3 }}>
                <Box p={{ xs: 2, md: 3 }} className={classes.mainBorderStyles}>
                  <Box
                    sx={{
                      display: { xs: "block", md: "flex" },
                      justifyContent: "space-between",
                    }}
                  >
                    <Box className={classes.profileContainer}>
                      <img
                        alt="profile"
                        src={data?.image || "/images/profile.png"}
                        style={{
                          width: "106px",
                          height: "106px",
                          borderRadius: "14px",
                        }}
                      />
                      <Box ml={3} mt={2}>
                        <Typography
                          variant="body2"
                          className={classes.blackColor}
                        >
                          {data?.name ? data?.name : ""}
                        </Typography>
                        <Typography
                          variant="body2"
                          mt={1}
                          className={classes.designationStyle}
                        >
                          {data?.designation ? data?.designation : ""}
                        </Typography>
                      </Box>
                    </Box>
                    <Tooltip
                      title="Access Denied"
                      placement="top"
                      open={editTooltip}
                      onClose={() => setEditTooltip(false)}
                    >
                      <Box
                        className={classes.editButtonContainer}
                        onClick={handleEditClick}
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                            backgroundColor: "rgba(68, 71, 70, 0.08)",
                          },
                        }}
                      >
                        <button className={classes.editButton}>
                          Edit Details
                        </button>
                        <Box className={classes.flexCenter}>
                          <EditSvg
                            style={{
                              fill: bgColors.green,
                            }}
                          />
                        </Box>
                      </Box>
                    </Tooltip>
                  </Box>

                  <Box
                    mt={{ xs: 2, md: 3 }}
                    // mr={3}
                    sx={{
                      justifyContent: "space-between",
                      display: { xs: "block", md: "flex" },
                    }}
                  >
                    <Box
                      mb={{ xs: 2, md: 0 }}
                      sx={{
                        width: "38%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        className={classes.blackColor}
                      >
                        Email
                      </Typography>
                      <Typography variant="body2" className={classes.grayColor}>
                        {data?.emailAddress ? data?.emailAddress : ""}
                      </Typography>
                    </Box>

                    <Box
                      mb={{ xs: 2, md: 0 }}
                      mx={{ xs: 2, md: 4 }}
                      sx={{
                        width: "30%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        className={classes.blackColor}
                      >
                        Phone
                      </Typography>
                      <Typography variant="body2" className={classes.grayColor}>
                        {data?.countryCode && data?.phoneNumber
                          ? data?.countryCode + " " + data?.phoneNumber || ""
                          : ""}
                      </Typography>
                    </Box>
                    <Box
                      mb={{ xs: 2, md: 0 }}
                      mr={{ xs: 0, md: 0 }}
                      sx={{
                        width: "23%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        className={classes.blackColor}
                      >
                        Country
                      </Typography>
                      <Typography variant="body2" className={classes.grayColor}>
                        {data?.countryName ? data?.countryName : ""}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    mt={{ xs: 2, md: 3 }}
                    // mr={3}
                    sx={{
                      justifyContent: "space-between",
                      display: { xs: "block", md: "flex" },
                    }}
                  >
                    <Box
                      mt={{ xs: 2, md: 0 }}
                      sx={{
                        width: "38%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        className={classes.blackColor}
                      >
                        Address
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.grayColor}
                        // sx={{ width: "130px" }}
                      >
                        {data?.address ? data?.address : ""}
                      </Typography>
                    </Box>

                    <Box
                      mt={{ xs: 2, md: 0 }}
                      mx={{ xs: 2, md: 4 }}
                      sx={{
                        width: "30%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        className={classes.blackColor}
                      >
                        Creation date
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.grayColor}
                        // sx={{ width: "130px" }}
                      >
                        {/* {data?.creationDate} */}
                        {formattedDate}
                      </Typography>
                    </Box>
                    <Box
                      mt={{ xs: 2, md: 0 }}
                      sx={{
                        width: "23%",
                      }}
                    ></Box>
                  </Box>
                  <Box mt={{ xs: 2, md: 3 }}>
                    <Typography variant="body2" className={classes.blackColor}>
                      About
                    </Typography>
                    <Typography variant="body2" className={classes.grayColor}>
                      {data?.about ? data?.about : ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* <Box m={{ xs: 1, md: 3 }}>
              <Tooltip
                title="Access Denied"
                placement="top"
                open={payementReferenceTooltip}
                onClose={() => setPaymentReferenceTooltip(false)}
              >
                <Box p={{ xs: 2, md: 3 }} className={classes.paymentContainer}>
                  <Box>
                    <Typography variant="body2" className={classes.blackColor}>
                      Payment Preferences
                    </Typography>
                    <Typography variant="body2" className={classes.grayColor}>
                      Saved cards, net banking, add new cards
                    </Typography>
                  </Box>
                  <Box onClick={handlePayments} sx={{ mt: 1 }}>
                    <RightArrowSvg />
                  </Box>
                </Box>
              </Tooltip>
            </Box> */}
              {!loginData?.isPlatform && loginData?.roleName === "Owner" && (
                <Box m={{ xs: 1, md: 3 }}>
                  <Box
                    p={{ xs: 2, md: 3 }}
                    className={classes.paymentContainer}
                  >
                    <Box>
                      <Typography
                        variant="body2"
                        className={classes.blackColor}
                      >
                        Integrate With Facebook
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.grayColor}
                        style={{ maxWidth: "400px", whiteSpace: "normal" }}
                      >
                        {data?.companyVerificationStatus
                          ? "Your account has been successfully verified with Meta."
                          : "Please click on Link button to verify your accoutn with Meta."}
                      </Typography>
                    </Box>
                    {/* <Box
                    className={classes.resetBtnContainer}
                    onClick={handleOpenDialog}
                  > */}

                    <Box>
                      <Button
                        onClick={launchWhatsAppSignup}
                        sx={{
                          border: `2px solid ${
                            data?.companyVerificationStatus
                              ? bgColors.green
                              : bgColors.blue2
                          }`,
                          color: `${
                            data?.companyVerificationStatus
                              ? bgColors.green
                              : bgColors.blue2
                          } !important`,
                          borderRadius: "20px",
                          width: "80px",
                          padding: "5px",
                          cursor: "pointer",
                        }}
                        disabled={data?.companyVerificationStatus} // Disable button if companyVerificationStatus is true
                      >
                        {data?.companyVerificationStatus ? "Verified" : "Link"}
                      </Button>
                    </Box>

                    {/* </Box> */}
                  </Box>
                </Box>
              )}
              <Tooltip
                title="Access Denied"
                placement="top"
                open={changePasswordTooltip}
                onClose={() => setChangeTooltip(false)}
              >
                <Box m={{ xs: 1, md: 3 }}>
                  <Box
                    p={{ xs: 2, md: 3 }}
                    className={classes.mainBorderStyles}
                  >
                    <Box className={classes.emailContainer}>
                      <Typography
                        variant="body2"
                        className={classes.blackColor}
                      >
                        Change Password
                      </Typography>
                      <Box
                        className={classes.resetBtnContainer}
                        onClick={handleOpenDialog}
                      >
                        <Typography
                          variant="body2"
                          className={classes.changePasswordText}
                        >
                          Reset
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Tooltip>
              <Box m={{ xs: 1, md: 3 }}>
                <Box
                  p={{ xs: 2, md: 3 }}
                  className={classes.LanguagePreferenceContainer}
                >
                  <Box>
                    <Typography variant="body2" className={classes.blackColor}>
                      Language Preference
                    </Typography>
                    <Typography variant="body2" className={classes.grayColor}>
                      {/* English (India) */}
                      {selectedLanguage ? selectedLanguage : "English (India) "}
                    </Typography>
                  </Box>
                  {/* <Grid xs={3}> */}
                  <Tooltip
                    title="Access Denied"
                    placement="top"
                    open={languageTooltip}
                    onClose={() => setLanguageTooltip(false)}
                  >
                    <Box
                      onClick={handleLanguageButtonClick}
                      className={classes.LanguageContainer}
                    >
                      <Typography variant="body2">Change</Typography>
                      <DownArraowSvg />
                    </Box>
                  </Tooltip>
                  {/* <LanguageSelectPopover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  handleClose={handleLanguageClose}
                  handleLanguageSelect={handleLanguageSelect}
                /> */}
                  {/* </Grid> */}
                </Box>
              </Box>

              <ChangePasswordDialog
                open={openDialog}
                handleClose={handleCloseDialog}
                onBackDropClick={handleBackdropClick}
              />
            </>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ManageAccount;
