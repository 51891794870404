import React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { bgColors } from "../../utils/bgColors";
import UserDetails from "./UserDetails";
import ChatInput from "./inboxDetailsComponents/ChatInput";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import LoadingComponent from "../common/LoadingComponent";
import { INBOX_APIS } from "../../Apis/Inbox/InboxApis";
import { toastActions } from "../../utils/toastSlice";
import MediaPopover from "./userDetailsComponents/MediaPopover";
import TemplatePopUp from "./inboxDetailsComponents/TemplatePopUp";
import { fetchInboxContactDetails } from "../../redux/slices/Inbox/InboxContactDetails";
import ChatInterface from "./inboxDetailsComponents/ChatInterface";
import ChatHeader from "./inboxDetailsComponents/ChatHeader";
import StepByStepGuide from "./inboxDetailsComponents/InboxOpeningPage";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  bgContainer: {
    height: "100vh",
    width: "100%",
    backgroundColor: "#EFEAE2",
    backgroundImage: `url("/images/inboxOpeningBG.png")`,
    backgroundSize: "fit",
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "110px",
    // padding: "10px",
    display: "flex",
    height: "32px",
    flexDirection: "row",
    alignItems: "center",
  },
  assigned: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "60px",
    justifyContent: "space-evenly",
    display: "flex",
    height: "32px",
    flexDirection: "row",
    alignItems: "center",
  },
  resetBtnContainer: {
    border: `1px solid ${bgColors.red}`,
    borderRadius: "8px",
    padding: "10px",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    height: "32px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  resetCloseBtnContainer: {
    border: `1px solid ${bgColors.red}`,
    borderRadius: "8px",
    width: "180px",
    backgroundColor: "transparent",
    paddingRight: "8px",
    textAlign: "center",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    height: "32px",
  },
  changePasswordText: {
    color: bgColors.red,
    fontWeight: "600",
  },
  assignButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    color: bgColors.green,
  },
  loadMoreContainer: {
    fontSize: 12,
    marginInline: "auto",
    maxWidth: "200px",
    textAlign: "center",
    backgroundColor: bgColors?.gray4,
    borderRadius: "14px",
    padding: "4px",
    marginBottom: "8px",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "2px 2px 4px #CDCDCD",
    },
  },
  jumpToBottom: {
    fontSize: 12,
    marginInline: "auto",
    maxWidth: "160px",
    textAlign: "center",
    backgroundColor: "#4B5A5A",
    color: bgColors?.white,
    borderRadius: "14px",
    padding: "4px 10px",
    marginBottom: "8px",
    cursor: "pointer",
    boxShadow: "2px 2px 4px #CDCDCD",
    "&:hover": {
      boxShadow: "2px 2px 4px #999999",
    },
  },
  messageContainer: {
    flex: 1,
    width: "100%",
    overflowY: "hidden" /* Initially hide the scrollbar */,
    padding: "10px 25px",
    position: "relative" /* Needed for absolute positioning of scrollbar */,
    "&:hover": {
      overflowY: "scroll" /* Show scrollbar on hover */,
      paddingRight: "17px" /* Reserve space for the scrollbar */,
    },
    /* Scrollbar styling */
    "&::-webkit-scrollbar": {
      cursor: "pointer",
      width: "8px" /* Width of the scrollbar */,
    },
    "&::-webkit-scrollbar-thumb": {
      cursor: "pointer",
      backgroundColor: "#999999" /* Color of the scrollbar thumb */,
      borderRadius: "6px" /* Rounded corners for the scrollbar thumb */,
      transition:
        "background-color 0.2s ease-in-out" /* Optional: Add a transition effect for smooth hover */,
    },
  },
  senderMessage: {
    color: "#4B5A5A",
    padding: "6px 10px 6px 10px",
    fontSize: "13px",
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "1px 1px #cdcdcd",
  },
  receiverMessage: {
    color: "#4B5A5A",
    padding: "6px 10px 6px 10px",
    fontSize: "13px",
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "1px 1px #cdcdcd",
  },
  arrowMarkRight: {
    marginBlock: "auto",
    padding: "0px 2px 0px 0px",
  },
  arrowMarkLeft: {
    marginBlock: "auto",
    padding: "0px 0px 0px 2px",
  },
  replyMessage: {
    paddingBlock: "10px",
    paddingInline: "5px",
  },
  flexCenterCursor: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  overlay: {
    paddingTop: "5px",
  },
  dateContainer: {
    backgroundColor: "#E8E8E8",
    borderRadius: "14px",
    padding: "10px",
  },

  userDetailsClass: {
    backgroundColor: bgColors.white1,
    width: "80%",
  },
  proPlanButton: {
    backgroundColor: bgColors.yellow2,
    color: bgColors.yellow,
    fontWeight: "700",
    paddingTop: 13,
    paddingRight: 30,
    paddingBottom: 13,
    paddingLeft: 30,
    borderRadius: 25,
  },
  enterPricePlanButton: {
    backgroundColor: bgColors.red2,
    color: bgColors.red1,
    fontWeight: "700",
    paddingTop: 13,
    paddingRight: 30,
    paddingBottom: 13,
    paddingLeft: 30,
    borderRadius: 25,
  },
});

const InboxDetails = ({
  walletBalance,
  messages,
  setMessages,
  chat,
  contactNumber,
  contacts,
  hubConnection,
  onLoadMore,
  setChatsPageNumber,
}: {
  walletBalance: number;
  messages: any;
  setMessages: (message: any) => void;
  chat: any;
  contactNumber: string | undefined;
  contacts: any;
  hubConnection: any;
  onLoadMore: (pageNumber: number) => void;
  setChatsPageNumber: (pageNumber: number) => void;
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const params = useParams();
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const prevScrollRef = useRef(0);

  const ContactsArray = contacts?.data;
  const contactData = ContactsArray?.find(
    (item: any) => item?.contact === contactNumber
  );
  const contactChatStatus = contactData?.chatStatus;

  const userData = useAppSelector((state: any) => state?.adminLogin?.data);
  const currentPlanDetails = useAppSelector(
    (state: any) => state?.wallet?.walletAndSubscription?.data
  );
  const accountData = useAppSelector((state: any) => state.accountData.data);
  const userDetails = useAppSelector(
    (state: any) => state?.inboxContactDetails
  );
  const getuserPermissionData = useAppSelector(
    (state: any) => state.getUserPermissions?.data
  );

  const [scrolledUp, setScrolledUp] = useState<boolean>(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [currentResultIndex, setCurrentResultIndex] = useState(0);
  const [reply, setReply] = useState<any>({ toggle: false, messageObj: null });
  const [media, setMedia] = useState({ mediaUrl: "", mediaMimeType: "" });
  const [openDialog, setOpenDialog] = useState(false);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [assignTooltipOpen, setAssignTooltipOpen] = useState(false);
  const [closeChatTooltipOpen, setCloseChatTooltipOpen] = useState(false);
  const [userDetailsTooltipOpen, setUserDetailsTooltipOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(false);

  const setSendTemplatePayload = (
    templateMessagePayload: any,
    templateState: any
  ) => {
    handleSendTemplateMessage(templateMessagePayload, templateState);
    setOpenTemplateDialog(false);
  };

  const hasAssignPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "assignTo")) {
        return true;
      }
    }
    return false;
  };

  const hasCloseChatPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "closeChat")) {
        return true;
      }
    }
    return false;
  };

  const hasInboxDetailsPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (
        Object.prototype.hasOwnProperty.call(profileItem, "inboxUserDetails")
      ) {
        return true;
      }
    }
    return false;
  };

  const hasSendMessagePermission = (permission: any) => {
    for (const profileItem of permission) {
      if (
        Object.prototype.hasOwnProperty.call(
          profileItem,
          "sendMessageAndEmojies"
        )
      ) {
        return true;
      }
    }
    return false;
  };

  const hasSendMediaPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (
        Object.prototype.hasOwnProperty.call(profileItem, "sendMediaMessage")
      ) {
        return true;
      }
    }
    return false;
  };

  const hasSendTemplatePermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "sendTemplates")) {
        return true;
      }
    }
    return false;
  };

  const handleAssignClick = (event: any) => {
    const hasPermission = hasAssignPermission(getuserPermissionData?.inbox);
    if (hasPermission) {
      setAnchorEl(event.currentTarget);
    } else {
      setAssignTooltipOpen(true);
      setTimeout(() => {
        setAssignTooltipOpen(false);
      }, 2000);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const handleOpenTemplatePopover = () => {
    setOpenTemplateDialog(true);
  };
  const handleCloseTemplatePopup = () => {
    setOpenTemplateDialog(false);
  };

  const handleOpenMediaPopover = (event: any) => {
    setOpenDialog(true);
    setMedia(event);
  };

  const handleReplyMessage = (msg: any) => {
    setReply({ toggle: true, messageObj: msg });
  };

  const handleCloseMediaPopover = () => {
    setOpenDialog(false);
  };

  const handleSendMessage = async (
    messageState: any,
    resetState: () => void
  ) => {
    const hasMessagePermission = hasSendMessagePermission(
      getuserPermissionData?.inbox
    );
    const hasMediaPermission = hasSendMediaPermission(
      getuserPermissionData?.inbox
    );
    const hasPermission = hasMessagePermission || hasMediaPermission;

    if (hasPermission) {
      if (
        messageState?.message?.trim().length !== 0 ||
        messageState?.selectedFile
      ) {
        const newMessage = {
          id: crypto.randomUUID(), // Generate a unique ID for the message
          whatsAppMessageId: null, // Initially set to null
          from: null,
          to: contactNumber,
          reply: reply?.messageObj ? reply?.messageObj : null,
          status: "sending", // Indicate that this message is being sent
          createdAt: new Date(new Date()?.getTime())?.toISOString(),
          textMessage: messageState?.mediaUrl ? null : messageState?.message,
          mediaFileName: messageState?.selectedFile?.name || null,
          mediaMimeType: messageState?.selectedFile?.type || null,
          mediaUrl: messageState?.mediaUrl ? messageState?.mediaUrl : null, // Will be set after sending
          mediaCaption: messageState?.message || null,
          templateMediaType: "",
          templateMediaUrl: null,
          templateHeader: null,
          templateBody: null,
          templateFooter: null,
          callButtonName: null,
          phoneNumber: null,
          urlButtonNames: [],
          redirectUrls: [],
          quickReplies: [],
        };

        // Update the messages array to display the message with access time icon
        setMessages((prevMessages: any) => [...prevMessages, newMessage]);

        if (
          messageState?.message?.trim().length !== 0 &&
          (!messageState?.selectedFile || messageState?.selectedFile === null)
        ) {
          const textData = {
            Contact: [contactNumber], // List of contacts
            TextMessage: messageState?.message,
            MessageId: reply?.messageObj?.whatsAppMessageId,
          };

          if (hubConnection) {
            console.log("Text message method hit successfully.");
            hubConnection
              .invoke("SendTextOrEmojiMessage", textData, userData?.companyId)
              .then(() => {
                // console.log("Sent text message");
              })
              .catch((error: any) => {
                console.error("Error sending text message:", error);
              });

            setReply({ toggle: false, messageObj: null });
            resetState();

            return () => hubConnection.stop();
          }
        } else if (
          messageState?.selectedFile ||
          (messageState?.selectedFile &&
            messageState?.message?.trim()?.length !== 0)
        ) {
          const mediaData = {
            Contact: [contactNumber], // List of contacts
            MediaFile: messageState?.mediaUrl ? messageState?.mediaUrl : "", // File object obtained from file input element
            MediaFileName: messageState?.selectedFile
              ? messageState?.selectedFile?.name
              : "", // File object obtained from file input element
            Caption: messageState?.message, // Caption for the media message (optional)
            BusinessId: userData?.companyId,
            MessageId: reply?.messageObj?.whatsAppMessageId || null,
          };

          if (hubConnection) {
            console.log("Media message method hit successfully.");
            hubConnection
              .invoke(
                "SendMediaMessage",
                mediaData?.Contact,
                mediaData?.MediaFile,
                mediaData?.MediaFileName,
                mediaData?.Caption,
                mediaData?.BusinessId,
                mediaData?.MessageId
              )
              .then(() => {
                console.log("Sent media message successfully.");
                if (contactNumber !== "help") {
                  const data = {
                    businessId: userData?.companyId,
                    contactId: contactNumber,
                  };
                  dispatch(fetchInboxContactDetails(data));
                }
              })
              .catch((error: any) => {
                console.error("Error sending media message:", error);
              });

            resetState();

            return () => hubConnection.stop();
          }
        }
      } else {
        alert("Please Type a Message or Select a File");
      }
    }
  };

  const handleSendTemplateMessage = async (
    templateMessagePayload: any,
    templateState: any
  ) => {
    const hasPermission = hasSendTemplatePermission(
      getuserPermissionData?.inbox
    );
    if (hasPermission) {
      if (templateMessagePayload) {
        // console.log("templateMessage", templateState);
        const newTemplateMessage = {
          id: crypto.randomUUID(), // Generate a unique ID for the message
          whatsAppMessageId: null, // Initially set to null
          from: null,
          to: contactNumber,
          replyId: null,
          status: "sending", // Indicate that this message is being sent
          createdAt: new Date(
            new Date().getTime() - (5 * 60 + 30) * 60000
          ).toISOString(),
          textMessage: null,
          mediaFileName: null,
          mediaMimeType: null,
          mediaUrl: null, // Will be set after sending
          mediaCaption: null,
          templateMediaType: templateState?.mediaType || "", // Set template media type
          templateMediaFile: templateState?.mediaAwsUrl || null, // Set template media file if any
          templateHeader: templateState?.header || null, // Set template header if any
          templateBody: templateState?.body || null, // Set template body
          templateFooter: templateState?.footer || null, // Set template footer if any
          callButtonName: templateState?.callButtonName || null, // Set call button name if any
          phoneNumber: templateState?.phoneNumber || null, // Set phone number if any
          urlButtonNames: templateState?.urlButtonName || [], // Set URL button names if any
          redirectUrls: templateState?.redirectUrl || [], // Set redirect URLs if any
          quickReplies: templateState?.quickReply || [], // Set quick replies if any
          name: null,
          chatStatus: null,
        };

        // Update the messages array to display the message with access time icon
        setMessages((prevMessages: any) => [
          ...prevMessages,
          newTemplateMessage,
        ]);
        if (hubConnection) {
          console.log("Template message method hit successfully.");
          hubConnection
            .invoke("SendTemplate", templateMessagePayload)
            .then(() => {
              console.log("Template message sent successfully.");
              // setReply({ toggle: false, messageObj: null });
            })
            .catch((error: any) => {
              console.error("Error sending template message:", error);
            });

          return () => hubConnection.stop();
        }
      } else {
        alert("Please Type a Message or Select a File");
      }
    }
  };

  const toggleUserDetails = () => {
    const hasPermission = hasInboxDetailsPermission(
      getuserPermissionData?.inbox
    );
    if (hasPermission) {
      setShowUserDetails((prev) => !prev);
    } else {
      setUserDetailsTooltipOpen(true);
      setTimeout(() => {
        setUserDetailsTooltipOpen(false);
      }, 2000);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  const handleCloseChat = async () => {
    const hasPermission = hasCloseChatPermission(getuserPermissionData?.inbox);
    if (hasPermission) {
      const postData = {
        businessId: userData?.companyId,
        userId: userData?.userId,
        contact: contactNumber,
      };
      const getResponse = await INBOX_APIS.closeChat(postData);
      if (getResponse?.status === 200) {
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: getResponse?.data?.message,
          })
        );
      } else {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: getResponse?.data?.message,
          })
        );
      }
    } else {
      setCloseChatTooltipOpen(true);
      setTimeout(() => {
        setCloseChatTooltipOpen(false);
      }, 2000);
    }
  };

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      const container = messageContainerRef.current;
      const previousHeight = container.scrollHeight;
      setTimeout(() => {
        if (container.scrollHeight !== previousHeight) {
          container.scrollTo({
            top: container.scrollHeight + 100,
          });
        } else {
          container.scrollTo({
            top: container.scrollHeight + 100,
          });
        }
      }, 1);
    }
  };

  const handleScroll = () => {
    if (messageContainerRef.current) {
      if (messageContainerRef.current.scrollTop === 0) {
        prevScrollRef.current = messageContainerRef.current.scrollHeight;
        setPageNumber(pageNumber + 1);
        onLoadMore(pageNumber + 1);
        setShowLoadMore(true);
      } else {
        const { scrollTop, scrollHeight, clientHeight } =
          messageContainerRef.current;
        const scrolledToBottom = scrollHeight - scrollTop <= clientHeight + 1;
        setScrolledUp(!scrolledToBottom);
        setShowLoadMore(false);
      }
    }
  };

  useEffect(() => {
    if (contactNumber !== "help") {
      const data = {
        businessId: userData?.companyId,
        contactId: contactNumber,
      };
      dispatch(fetchInboxContactDetails(data));
      setPageNumber(1);
    }
  }, [contactNumber]);

  useEffect(() => {
    if (showLoadMore && messageContainerRef.current) {
      const newScrollHeight = messageContainerRef.current.scrollHeight;
      const heightDifference = newScrollHeight - prevScrollRef.current;
      messageContainerRef.current.scrollTo({
        top: heightDifference + 1, // Adjust this value as needed
        behavior: "smooth",
      });
    } else {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (messageContainerRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        messageContainerRef.current;
      const scrolledToBottom = scrollHeight - scrollTop <= clientHeight + 1;
      setScrolledUp(!scrolledToBottom);
      messageContainerRef.current.addEventListener("scroll", handleScroll);
      return () =>
        messageContainerRef.current?.removeEventListener(
          "scroll",
          handleScroll
        );
    }
  }, [messages]);

  return (
    <Grid className={classes.mainContainer}>
      {params?.id === "help" || params?.id !== contactNumber ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            alignItems: "center",
            my: "auto",
            backgroundColor: "#EFEAE2",
            backgroundImage: `url("/images/inboxOpeningBG.png")`,
            backgroundSize: "fit",
            backgroundPosition: "center",
            backgroundRepeat: "repeat",
          }}
        >
          <StepByStepGuide />
        </Box>
      ) : (
        <Box
          className={classes.bgContainer}
          sx={{
            display: "flex",
          }}
        >
          {chat?.contact !== contactNumber ? (
            <Box sx={{ height: "100vh", width: "100%" }}>
              <LoadingComponent height="100%" color={bgColors.blue} />
            </Box>
          ) : (
            <Box
              sx={{ width: "100%", display: "flex", flexDirection: "column" }}
            >
              <Box sx={{ height: "75px" }}>
                <ChatHeader
                  open={open}
                  chatData={chat}
                  handleCloseDrawer={handleCloseDrawer}
                  handleDrawerOpen={handleDrawerOpen}
                  chat={chat}
                  showUserDetails={showUserDetails}
                  setUserDetailsTooltipOpen={setUserDetailsTooltipOpen}
                  userDetailsTooltipOpen={userDetailsTooltipOpen}
                  toggleUserDetails={toggleUserDetails}
                  classes={classes}
                  bgColors={bgColors}
                  handleAssignClick={handleAssignClick}
                  assignTooltipOpen={assignTooltipOpen}
                  setAssignTooltipOpen={setAssignTooltipOpen}
                  anchorEl={anchorEl}
                  handleClosePopover={handleClosePopover}
                  contactChatStatus={contactChatStatus}
                  closeChatTooltipOpen={closeChatTooltipOpen}
                  setCloseChatTooltipOpen={setCloseChatTooltipOpen}
                  handleCloseChat={handleCloseChat}
                  contacts={contacts}
                />
              </Box>
              <Box
                className={classes.messageContainer}
                ref={messageContainerRef}
                id="scrollableDiv"
              >
                {showLoadMore && pageNumber < chat?.totalPages && (
                  <Box className={classes.loadMoreContainer}>
                    Loading older messages...
                  </Box>
                )}
                {messages?.map((msg: any, index: number) => (
                  <Box key={index}>
                    {new Date(msg?.createdAt)?.getDate() !==
                      new Date(messages[index - 1]?.createdAt)?.getDate() && (
                      <Box
                        sx={{
                          // my: 2,
                          mx: "auto",
                          display: "flex",
                          flexDirection: "row",
                          width: "80px",
                          justifyContent: "center",
                          textAlign: "center",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "14px",
                          padding: "4px",
                        }}
                      >
                        <Typography sx={{ fontSize: "10px" }}>
                          {new Date(msg?.createdAt)?.getDate() +
                            "/" +
                            (new Date(msg?.createdAt)?.getMonth() + 1) +
                            "/" +
                            new Date(msg?.createdAt)?.getFullYear()}
                        </Typography>
                      </Box>
                    )}

                    {msg?.to === contactNumber ? (
                      <ChatInterface
                        alignment="right"
                        messages={messages}
                        msg={msg}
                        index={index}
                        currentResultIndex={currentResultIndex}
                        searchText={searchText}
                        classes={classes}
                        handleOpenMediaPopover={handleOpenMediaPopover}
                        handleReplyMessage={handleReplyMessage}
                        bgColors={bgColors}
                      />
                    ) : (
                      <ChatInterface
                        alignment="left"
                        msg={msg}
                        messages={messages}
                        index={index}
                        currentResultIndex={currentResultIndex}
                        searchText={searchText}
                        classes={classes}
                        handleOpenMediaPopover={handleOpenMediaPopover}
                        handleReplyMessage={handleReplyMessage}
                        bgColors={bgColors}
                      />
                    )}
                  </Box>
                ))}
              </Box>
              <Box sx={{ height: "60px", position: "relative" }}>
                {scrolledUp && (
                  <Box
                    onClick={scrollToBottom}
                    sx={{
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                      zIndex: 10,
                      bottom: 60,
                    }}
                    className={classes.jumpToBottom}
                  >
                    Jump to bottom
                  </Box>
                )}
                {accountData?.companyVerificationStatus &&
                currentPlanDetails?.subscriptionPlan?.isActive &&
                walletBalance > 0 &&
                chat?.isOptIn === 1 ? (
                  <Box
                    sx={{
                      position: "absolute",
                      textAlign: "center",
                      bottom: 10,
                      width: "100%",
                    }}
                  >
                    {contactChatStatus?.includes("open") ? (
                      <ChatInput
                        walletBalance={walletBalance}
                        openTemplateDialog={openTemplateDialog}
                        handleSendMessage={handleSendMessage}
                        hasSendTextMessagePermission={hasSendMessagePermission}
                        hasSendMediaPermission={hasSendMediaPermission}
                        hasSendTemplatePermission={hasSendTemplatePermission}
                        handleOpenTemplatePopup={handleOpenTemplatePopover}
                        reply={reply?.messageObj}
                        replyToggle={reply?.toggle}
                        setReply={setReply}
                      />
                    ) : (
                      <Button
                        sx={{
                          justifyContent: "center",
                          backgroundColor: bgColors?.white,
                          border: `0.5px solid ${bgColors?.gray5}`,
                          "&:hover": {
                            backgroundColor: bgColors?.white,
                            border: `1px solid ${bgColors?.black2}`,
                          },
                          color: bgColors?.green,
                          fontSize: 10,
                          px: 2,
                          mt: 2,
                        }}
                        onClick={handleOpenTemplatePopover}
                      >
                        Send Template
                      </Button>
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      position: "absolute",
                      textAlign: "center",
                      bottom: 10,
                      minHeight: 40,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      backgroundColor: "rgba(255, 235, 238, 0.8)",
                      borderRadius: "12px",
                    }}
                  >
                    {walletBalance <= 0 && (
                      <Typography
                        variant="h6"
                        color="error"
                        gutterBottom
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <ErrorOutlineIcon /> &nbsp; Low Balance Alert
                      </Typography>
                    )}
                    {accountData?.companyVerificationStatus !== true && (
                      <Typography sx={{ fontSize: "10px" }}>
                        Please link your account with meta.
                      </Typography>
                    )}
                    {currentPlanDetails?.subscriptionPlan?.isActive !==
                      true && (
                      <Typography sx={{ fontSize: "10px" }}>
                        Your plan has been expired , please subscribe to
                        continue
                      </Typography>
                    )}
                    {chat?.isOptIn !== 1 && (
                      <Typography sx={{ fontSize: "10px" }}>
                        This contact has opted out from chat services
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          )}
          <Box className={`${showUserDetails ? classes.userDetailsClass : ""}`}>
            {showUserDetails && (
              <UserDetails
                details={userDetails?.data}
                status={userDetails?.status}
                messages={messages}
                currentResultIndex={currentResultIndex}
                setCurrentResultIndex={setCurrentResultIndex}
                onClickClose={setShowUserDetails}
                setSearchText={setSearchText}
                contactNumber={contactNumber}
                setChatsPageNumber={setChatsPageNumber}
              />
            )}
          </Box>
        </Box>
      )}

      <TemplatePopUp
        contactNumber={contactNumber}
        open={openTemplateDialog}
        handleCloseTemplatePopup={handleCloseTemplatePopup}
        setSendTemplatePayload={setSendTemplatePayload}
      />
      <MediaPopover
        open={openDialog}
        media={media}
        sharedMedia={false}
        onClose={handleCloseMediaPopover}
      />
    </Grid>
  );
};

export default InboxDetails;
