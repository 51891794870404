import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { bgColors } from "../../utils/bgColors";
import CloseRedSvg from "../../assets/svgs/CloseRedSvg";
import FileUpload from "./FileUpload";
import { CONTACTS_APIS } from "../../Apis/Contacts/ContactsApis";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { toastActions } from "../../utils/toastSlice";
import { fetchAllContacts } from "../../redux/slices/Contacts/AllContactsSlice";
import { importContacts } from "../../redux/slices/Contacts/importContactsSlice";
import LoadingComponent from "../common/LoadingComponent";

const useStyles = makeStyles({
  signOutButtonStyles: {
    backgroundColor: "#3C3C3C",
    opacity: "60%",
    color: "#ffffff",
    height: "40px",
    borderRadius: "8px",
    // width: "190%",
    paddingInline: "27px ",
    padding: "8px",
    // fontWeight: "Bold",
    fontSize: "18px",
    // cursor: "pointer",
  },
  signOutButtonStylesContainer: {
    backgroundColor: "#000",
    opacity: "60%",
    color: "#ffffff",
    height: "40px",
    borderRadius: "8px",
    // width: "190%",
    paddingInline: "27px ",
    padding: "8px",
    // fontWeight: "Bold",
    fontSize: "18px",
    cursor: "pointer",
  },
  cancelButtonStyles: {
    backgroundColor: "#ffffff",
    color: "#000000",
    height: "40px",
    fontSize: "18px",
    borderRadius: "8px",
    border: `1px solid ${bgColors.gray3}`,
    // width: "180%",
    paddingInline: "27px ",
    padding: "8px",
    // fontWeight: "Bold",
    cursor: "pointer",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
    fontWeight: "600 !important",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
});

const ImportContactsPopUp = ({ open, handleClose }: any) => {
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const contactsSlice: any = useAppSelector(
    (state: any) => state?.contactsData
  );
  // console.log("contactsSlice", contactsSlice?.data?.exampleForImport);
  const userData = userProfileSlice?.data;
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const handleImport = async () => {
    setIsLoading(true);
    try {
      // Check if a file is selected
      if (selectedFiles.length === 0) {
        // console.error("No file selected");
        return;
      }
      const file = selectedFiles[0];

      // Check if the file is an Excel file
      const validMimeTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      if (!validMimeTypes.includes(file.type)) {
        // console.error("Unsupported file type");
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: "File is not supported, only upload Excel files",
          })
        );
        return;
      }
      // Create a new FileReader object
      const reader = new FileReader();

      // Set up a callback function for when the file is fully loaded
      reader.onload = async (event) => {
        try {
          // Get the result of the file reading operation
          const binaryString = event.target?.result;

          // Check if the binary string is valid
          if (!binaryString) {
            // console.error("Error reading file");
            return;
          }

          const bytes = new Uint8Array(binaryString as ArrayBuffer);

          const blob = new Blob([bytes], { type: selectedFiles[0].type });

          // console.log("blob", blob);
          const data = {
            businessId: userData?.companyId,
            userId: userData?.userId,
            data: blob,
          };

          const getRes = await CONTACTS_APIS.importContacts(data);
          // const getRes = await dispatch(importContacts(data))
          // console.log(getRes, 'getRes')
          if (getRes.data.message === "Successfully contacts are Imported.") {
            // console.log(getRes, 'getRes')
            setIsLoading(false);
            handleClose();
            dispatch(
              toastActions.setToaster({
                type: "success",
                message: `${getRes?.data?.message}`,
              })
            );
            const bodyData = {
              businessId: userData?.companyId,
              userId: userData?.userId,
              page: 1,
              per_page: 10,
              filters: {},
            };
            dispatch(fetchAllContacts(bodyData));
          }
        } catch (error: any) {
          // console.log("Error handling file reading:", error);
          setIsLoading(false);
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: `${error?.response?.data?.message}`,
            })
          );
        }
      };

      // Read the selected file as a binary string
      reader.readAsArrayBuffer(selectedFiles[0]);
    } catch (error) {
      // console.error("Error handling import:", error);
    }
  };

  // console.log("selectedFiles", selectedFiles);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { minWidth: "25%", borderRadius: "20px", padding: "10px" },
      }}
    >
      <Box
        mt={3}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          textAlign="center"
          variant="h5"
          className={classes.blackColor}
        >
          Import Contacts
        </Typography>
      </Box>
      <DialogTitle>
        <Box></Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="body2" className={classes.grayColor}>
              Select the CSV or xlsx format file with name(mandatory),
            </Typography>
            <Typography variant="body2" className={classes.grayColor}>
              {" "}
              country, contact number(mandatory), email id of file size{" "}
            </Typography>
            <Typography variant="body2" className={classes.grayColor}>
              {"< 10 MB to upload."}
            </Typography>
          </Box>
          {contactsSlice?.data?.exampleForImport && (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <img
                style={{ width: "30px", height: "30px" }}
                src="/images/xlsxIcon.png"
                alt="excel-file"
              />
              <a
                className={classes.blackColor}
                href={contactsSlice?.data?.exampleForImport}
                download="excel_file.xlsx"
              >
                Click here to download a sample file
              </a>
            </Box>
          )}
          <Box mt={3}>
            <FileUpload
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
            />
          </Box>
        </Box>
      </DialogContent>

      <Box
        mb={3}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        gap={2}
        // alignSelf="center"
      >
        {!isLoading ? (
          <>
            <button
              className={classes.cancelButtonStyles}
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              className={
                selectedFiles?.length
                  ? classes.signOutButtonStylesContainer
                  : classes.signOutButtonStyles
              }
              onClick={handleImport}
              disabled={isLoading}
            >
              Import
            </button>
          </>
        ) : (
          <LoadingComponent height="auto" color={bgColors?.blue} />
        )}
      </Box>
    </Dialog>
  );
};

export default ImportContactsPopUp;
