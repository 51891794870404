import React from "react";

const AddUserIcon = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_480_15775)">
        <path
          style={style}
          d="M26.3334 6H7.66675C6.60588 6 5.58847 6.42143 4.83832 7.17157C4.08818 7.92172 3.66675 8.93913 3.66675 10V30C3.66716 30.2363 3.73033 30.4682 3.84982 30.672C3.9693 30.8758 4.14079 31.0442 4.34675 31.16C4.54602 31.2729 4.77103 31.3326 5.00008 31.3333C5.23939 31.3333 5.47427 31.2688 5.68008 31.1467L11.6667 27.52C11.888 27.3883 12.1427 27.3234 12.4001 27.3333H26.3334C27.3943 27.3333 28.4117 26.9119 29.1618 26.1618C29.912 25.4116 30.3334 24.3942 30.3334 23.3333V10C30.3334 8.93913 29.912 7.92172 29.1618 7.17157C28.4117 6.42143 27.3943 6 26.3334 6ZM11.6667 18C11.403 18 11.1453 17.9218 10.926 17.7753C10.7067 17.6288 10.5358 17.4205 10.4349 17.1769C10.334 16.9333 10.3076 16.6652 10.359 16.4065C10.4105 16.1479 10.5375 15.9103 10.7239 15.7239C10.9104 15.5374 11.148 15.4104 11.4066 15.359C11.6653 15.3075 11.9334 15.3339 12.177 15.4348C12.4206 15.5357 12.6289 15.7066 12.7754 15.9259C12.9219 16.1452 13.0001 16.403 13.0001 16.6667C13.0001 17.0203 12.8596 17.3594 12.6096 17.6095C12.3595 17.8595 12.0204 18 11.6667 18ZM17.0001 18C16.7364 18 16.4786 17.9218 16.2593 17.7753C16.0401 17.6288 15.8692 17.4205 15.7682 17.1769C15.6673 16.9333 15.6409 16.6652 15.6924 16.4065C15.7438 16.1479 15.8708 15.9103 16.0573 15.7239C16.2437 15.5374 16.4813 15.4104 16.74 15.359C16.9986 15.3075 17.2667 15.3339 17.5103 15.4348C17.754 15.5357 17.9622 15.7066 18.1087 15.9259C18.2552 16.1452 18.3334 16.403 18.3334 16.6667C18.3334 17.0203 18.1929 17.3594 17.9429 17.6095C17.6928 17.8595 17.3537 18 17.0001 18ZM22.3334 18C22.0697 18 21.8119 17.9218 21.5927 17.7753C21.3734 17.6288 21.2025 17.4205 21.1016 17.1769C21.0007 16.9333 20.9743 16.6652 21.0257 16.4065C21.0771 16.1479 21.2041 15.9103 21.3906 15.7239C21.5771 15.5374 21.8147 15.4104 22.0733 15.359C22.3319 15.3075 22.6 15.3339 22.8437 15.4348C23.0873 15.5357 23.2955 15.7066 23.442 15.9259C23.5885 16.1452 23.6667 16.403 23.6667 16.6667C23.6667 17.0203 23.5263 17.3594 23.2762 17.6095C23.0262 17.8595 22.687 18 22.3334 18Z"
          fill="white"
        />
        <path
          style={style}
          d="M31.9493 10.0505V7.94888H34.05C34.5672 7.94888 35.0632 7.74348 35.4289 7.37787C35.7946 7.01227 36 6.5164 36 5.99935C36 5.4823 35.7946 4.98643 35.4289 4.62083C35.0632 4.25522 34.5672 4.04982 34.05 4.04982H31.9493V1.94953C31.9493 1.43248 31.7439 0.936612 31.3782 0.571004C31.0125 0.205397 30.5165 0 29.9993 0C29.4822 0 28.9862 0.205397 28.6205 0.571004C28.2548 0.936612 28.0494 1.43248 28.0494 1.94953V4.04982H25.95C25.4328 4.04982 24.9368 4.25522 24.5711 4.62083C24.2054 4.98643 24 5.4823 24 5.99935C24 6.5164 24.2054 7.01227 24.5711 7.37787C24.9368 7.74348 25.4328 7.94888 25.95 7.94888H28.0494V10.0505C28.0494 10.5675 28.2548 11.0634 28.6205 11.429C28.9862 11.7946 29.4822 12 29.9993 12C30.5165 12 31.0125 11.7946 31.3782 11.429C31.7439 11.0634 31.9493 10.5675 31.9493 10.0505Z"
          fill="white"
          stroke="#00934F"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_480_15775">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddUserIcon;
