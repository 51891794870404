import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MANAGE_PERMISSIONS_APIS } from "../../../Apis/AdminLogin/ManagePermissions/ManagePermissions";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
  admin: any;
  owner: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
  admin: null,
  owner: null,
};

export const fetchManagePermissions = createAsyncThunk(
  "fetchManagePermissions",
  async (
    { roleId, companyId }: { roleId: any; companyId: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await MANAGE_PERMISSIONS_APIS.getAllPermissions(
        roleId,
        companyId
      );
      // console.log("response", response);
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const PostManagePermissions = createAsyncThunk(
  "PostManagePermissions",
  async ({
    roleId,
    companyId,
    data,
    menuName,
    status,
  }: {
    roleId: any;
    companyId: any;
    data: any;
    menuName: any;
    status: any;
  }) => {
    const response = await MANAGE_PERMISSIONS_APIS.postPermissions(
      roleId,
      data,
      companyId,
      menuName,
      status
    );
    // console.log("response", response);
    return response?.data;
  }
);

export const ManagePermissionsSlice = createSlice({
  name: "ManagePermissionsSlice",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManagePermissions.pending, (state) => {
        state.status = "loading";
        // state.data = [];
      })
      .addCase(fetchManagePermissions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        if (action?.payload[0]?.roleName === "Admin") {
          state.admin = action.payload;
        }
        if (action?.payload[0]?.roleName === "Owner") {
          state.owner = action.payload;
        }
      })
      .addCase(fetchManagePermissions.rejected, (state) => {
        state.status = "failed";
        state.data = [];
      })
      .addCase(PostManagePermissions.pending, (state) => {
        state.status = "loading";
        // state.data = [];
      })
      .addCase(PostManagePermissions.fulfilled, (state) => {
        state.status = "succeeded";
        // state.data = action.payload;
      })
      .addCase(PostManagePermissions.rejected, (state) => {
        state.status = "failed";
        state.data = [];
      });
  },
});

export const ManageNotificationsActions = ManagePermissionsSlice.actions;
export default ManagePermissionsSlice.reducer;
