import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ADMIN_MANAGE_COMPANY_APIS } from "../../../Apis/ManageCompany/ManageCompany";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const fetchAllRoles = createAsyncThunk(
  "fetchAllRoles",
  async (data: any) => {
    const response = await ADMIN_MANAGE_COMPANY_APIS.displayAllroles(data);
    return response?.data;
  }
);

export const postCreatedRoles = createAsyncThunk(
  "postCreatedRoles",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await ADMIN_MANAGE_COMPANY_APIS.createRoles(data);
      return response?.data;
    } catch (error: any) {
      // console.log(error)
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const getAllRolesSlice = createSlice({
  name: "getAllRolesSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllRoles.pending, (state) => {
        state.status = "loading";
        state.data = [];
      })
      .addCase(fetchAllRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchAllRoles.rejected, (state) => {
        state.status = "failed";
        state.data = [];
      })
      .addCase(postCreatedRoles.pending, (state) => {
        state.status = "loading";
        // state.data = [];
      })
      .addCase(postCreatedRoles.fulfilled, (state) => {
        state.status = "succeeded";
        // state.data = action.payload;
      })
      .addCase(postCreatedRoles.rejected, (state) => {
        state.status = "failed";
        // state.data = [];
      });
  },
});

export const getAllRolesSliceActions = getAllRolesSlice.actions;
export default getAllRolesSlice.reducer;
