import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WORKFLOW_API } from "../../../Apis/AdminLogin/Automation/Workflows";

export interface IData {
    createWorkflowCustomMessageStatus: "loading" | "succeeded" | "failed" | "idle";
    createWorkflowCustomMessageData: any;
  }

  const initialState: IData = {
    createWorkflowCustomMessageStatus: "idle",
    createWorkflowCustomMessageData: null,
  };

  export const createWorkflowCustomMessage = createAsyncThunk(
    "workflow/createWorkflowCustomMessage",
    async (data: any, { rejectWithValue }) => {
      try {
        const response = await WORKFLOW_API.createWorkflowCustomMessage(data);
        return response?.data;
      } catch (error: any) {
        // Provide a descriptive error message
        return rejectWithValue(error.response || 'An error occurred');
      }
    }
  );


  export const createWorkflowCustomMessageSlice = createSlice({
    name: "createWorkflowCustomMessage",
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
      builder
        .addCase(createWorkflowCustomMessage.pending, (state) => {
          state.createWorkflowCustomMessageStatus = "loading";
          state.createWorkflowCustomMessageData = null;
        })
        .addCase(createWorkflowCustomMessage.fulfilled, (state, action) => {
          state.createWorkflowCustomMessageStatus = "succeeded";
          state.createWorkflowCustomMessageData = action.payload;
        })
        .addCase(createWorkflowCustomMessage.rejected, (state) => {
          state.createWorkflowCustomMessageStatus = "failed";
        });
    },
  });


export const createWorkflowCustomMessageActions = createWorkflowCustomMessageSlice.actions;
export default createWorkflowCustomMessageSlice.reducer;
