import React from "react";
import { Button, ButtonProps } from "@mui/material";

const ButtonComponent: React.FC<ButtonProps> = ({
  title = "",
  variant = "contained",
  style,
  fullWidth = true,
  ...rest
}) => {
  const buttonStyle: React.CSSProperties = {
    ...style,
    fontFamily: "Helvetica",
    textTransform: "none" as React.CSSProperties["textTransform"], // Provide the correct type for textTransform
  };
  return (
    <Button variant={variant} fullWidth={fullWidth} {...rest} style={buttonStyle}>
      {title}
    </Button>
  );
};

export default ButtonComponent;
