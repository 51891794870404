import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import TextFeildWithBorderComponet from "../common/TextFieldWithBorderComponent";
// import PasswordTextFeildWithBorders from "../common/PasswordTextFeildWithBorders";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { makeStyles } from "@mui/styles";
// import PhoneInput from "../common/PhoneInput";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
// import { fetchAllCountryCodes } from "../../redux/slices/ManageAccount/GetAllCountryCode";
import { MANAGE_CLIENTS } from "../../Apis/AdminLogin/ManageClients/ManageClient";
// import { fetchGetAllCountries } from "../../redux/slices/ManageAccount/GetAllCountryDetails";
import { toastActions } from "../../utils/toastSlice";
import { fetchGetAllClients } from "../../redux/slices/ManageClients/getAllClientSlice";
// import { fetchGetAllTeamMembersByCompanyId } from "../../redux/slices/ManageCompany/GetAllTeamMembersByCompanyId";
import LoadingComponent from "../common/LoadingComponent";
import { bgColors } from "../../utils/bgColors";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const useStyles = makeStyles({
  changePasswordContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // cursor: "pointer",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "500 !important",
  },
  updateButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    borderRadius: "12px",
    width: "100%",
    fontWeight: "600",
    cursor: "pointer",
  },
  icon: {
    cursor: "pointer",
    textAlign: "right",
  },
});

const AddNewCompany = ({ open, handleClose }: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const getAllCountries = useAppSelector(
    (state:any) => state.getAllCountries?.data
  );
  const [isAddNewCompanyLoading, setIsAddNewCompanyLoading] = useState(false);
  const [formData, setFormData] = useState({
    companyName: "",
    clientName: "",
    email: "",
    businessEmail: "",
    website: "",
    // countryCode: "",
    // phoneNumber: "",
    address: "",
    country: "",
  });
  const [formErrors, setFormErrors] = useState({
    companyName: "",
    clientName: "",
    email: "",
    businessEmail: "",
    website: "",
    // phoneNumber: "",
    // countryCode: "",
    address: "",
    country: "",
  });
  const isFormValid = () => {
    const errors = { ...formErrors };
    let isValid = true;

    if (!formData.companyName.trim()) {
      errors.companyName = "Company name is required.";
      isValid = false;
    } else if (formData.companyName.length > 100) {
      errors.companyName = "Company name cannot be more than 100 characters.";
      isValid = false;
    }

    if (!formData.clientName.trim()) {
      errors.clientName = "Client name is required.";
      isValid = false;
    } else if (formData.clientName.length > 100) {
      errors.clientName = "Client name cannot be more than 100 characters.";
      isValid = false;
    }
    if (!formData.email.trim()) {
      errors.email = "Email address is required.";
      isValid = false;
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      errors.email = "Invalid email address.";
      isValid = false;
    }

    if (!formData.website.trim()) {
      errors.website = "Website is required.";
      isValid = false;
    } else if (
      !/^(https?:\/\/)?(www\.)?[\w-]+(\.[\w-]+)+(\.[a-z]{2,6})(\/[\w-.]*)*\/?$/.test(
        formData.website.trim()
      )
    ) {
      errors.website = "Invalid website URL.";
      isValid = false;
    }

    // if (!formData.phoneNumber) {
    //   errors.phoneNumber = "Phone number is required";
    //   isValid = false;
    // }
    // if (!formData?.countryCode) {
    //   errors.countryCode = "Country code is required";
    //   isValid = false;
    // }

    // const isValidPhone = /^\d+$/.test(formData?.phoneNumber);
    // if (!formData?.phoneNumber && !formData?.countryCode) {
    //   errors.phoneNumber = "Phone Number & Country Code are required.";
    //   isValid = false;
    // }
    // else if (!formData?.phoneNumber && formData?.countryCode) {
    //   errors.phoneNumber = "phone number is required.";
    //   isValid = false;
    // }
    // else if (formData.phoneNumber.length !== 10) {
    //   errors.phoneNumber = "Phone number must be 10 digits.";
    //   isValid = false;
    // }
    // else if (!formData?.countryCode && formData?.phoneNumber) {
    //   errors.phoneNumber = "Country code is required.";
    //   isValid = false;
    // }
    // else if (!isValidPhone) {
    //   errors.phoneNumber = "Phone number should only contain numbers.";
    //   isValid = false;
    // }
    if (!formData.address.trim()) {
      errors.address = "Address is required";
      isValid = false;
    }
    if (!formData.country) {
      errors.country = "Country is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSave = async () => {
    if (isFormValid()) {
      setIsAddNewCompanyLoading(true);
      try {
        const data = {
          companyName: formData.companyName,
          clientName: formData.clientName,
          email: formData.email,
          businessEmail: formData.businessEmail,
          website: formData.website,
          // countryCode: formData.countryCode,
          // phoneNumber: formData.phoneNumber,
          address: formData.address,
          country: formData.country,
        };
        const getRes = await MANAGE_CLIENTS.addClient(data);
        // console.log(getRes, 'getRes')
        if (getRes?.status === 200) {
          handleClose();
          const getData = {
            search: "",
            includeInactive: true,
            sortBy: "",
            isSortAscending: true,
          };
          dispatch(fetchGetAllClients(getData));
          setFormData({
            companyName: "",
            clientName: "",
            email: "",
            businessEmail: "",
            website: "",
            // countryCode: "",
            // phoneNumber: "",
            address: "",
            country: "",
          });
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: getRes?.data?.message,
            })
          );
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: getRes?.data?.message,
            })
          );
          handleCloseAll();
        }
      } catch (error: any) {
        // console.log("error", error);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: error?.response?.data?.message,
          })
        );
        handleCloseAll();
      }
      setIsAddNewCompanyLoading(false);
    }
  };

  //  console.log("formData:",formData?.website);
  // const getAllCountryCodes = useAppSelector((state) => state.getAllCoutryCodes);
  // const getAllCountryCodesData = getAllCountryCodes?.data;
  // const codeArray =
  //   getAllCountryCodesData &&
  //   getAllCountryCodesData?.map((item: any) => ({
  //     countryCode: item?.countryCode,
  //     countryImage: item?.countryImage,
  //   }));

  // useEffect(() => {
  //   // dispatch(fetchGetAllCountries());
  //   // dispatch(fetchAllCountryCodes());
  // }, [dispatch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // console.log("name", name, "value", value);
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  // const handleSelectChange = (event: any) => {
  //   const value = event.target.value as string;
  //   setFormData({ ...formData, country: value });
  //   setFormErrors({ ...formErrors, country: "" });
  // };
  const handleSelectChange = (event: any, newValue: { countryName: any }) => {
    const value = newValue ? newValue.countryName : "";
    setFormData({ ...formData, country: value });
    setFormErrors({ ...formErrors, country: "" });
  };

  // const handlePhoneChange = (value: string) => {
  //   // Validate phone number format
  //   const phoneRegex = /^(\+\d{1,3})?\s?\d{10}$/; // Updated regex to handle optional country code
  //   if (!phoneRegex.test(value)) {
  //     // setErrors({ ...errors, contact: "Phone number must be 10 digits" });
  //     // console.log(errors.contact, "current error message");
  //   } else {
  //     // setErrors({ ...errors, contact: "" });
  //   }

  //   // setFormData({
  //   //   ...formData,
  //   //   phoneNumber: value,
  //   // });
  //   // setFormErrors((prevErrors) => ({
  //   //   ...prevErrors,
  //   //   phoneNumber: "",
  //   // }));
  // };
  // const handleCountryCodeChange = (value: string) => {
  //   // console.log("value", value);
  //   setFormData({ ...formData, countryCode: value });
  //   setFormErrors((prevErrors) => ({
  //     ...prevErrors,
  //     phoneNumber: "",
  //   }));
  // };

  const handleCloseAll = () => {
    handleClose();
    setFormData({
      companyName: "",
      clientName: "",
      email: "",
      businessEmail: "",
      website: "",
      // countryCode: "",
      // phoneNumber: "",
      address: "",
      country: "",
    });
    setFormErrors({
      companyName: "",
      clientName: "",
      email: "",
      businessEmail: "",
      website: "",
      // countryCode: "",
      // phoneNumber: "",
      // countryCode: "",
      address: "",
      country: "",
    });
  };
  const selectedCountry = formData?.country
    ? getAllCountries?.find(
        (country: { countryName: string }) =>
          country?.countryName === formData?.country
      )
    : null;
  return (
    <Dialog
      open={open}
      onClose={handleCloseAll}
      PaperProps={{ style: { minWidth: "35%", borderRadius: "20px" } }}
    >
      <DialogTitle>
        <Box m={1} className={classes.changePasswordContainer}>
          <Typography
            // variant="h6"
            sx={{
              color: "#000000",
              fontWeight: "600",
              fontSize: { xs: "14px", md: "20px" },
              display: "flex",
            }}
          >
            <span style={{ marginTop: "3px" }}>
              <PersonAddIcon sx={{ height: "22px" }} />
            </span>
            &nbsp;Add Client
          </Typography>
          <Box sx={{ cursor: "pointer" }} onClick={handleCloseAll}>
            <CloseSvg />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box m={1} mt={0}>
          <Box mb={{ xs: 1, md: 2 }}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 } }}
            >
              Company name*
            </InputLabel>
            <TextFeildWithBorderComponet
              label="Enter company name"
              name="companyName"
              placeholder=""
              value={formData?.companyName}
              onChange={handleInputChange}
              sx={{
                "& input": {
                  // fontWeight: "600",
                },
              }}
              error={!!formErrors?.companyName}
              helperText={formErrors?.companyName}
            />
            {/* {formErrors?.companyName && (
              <Typography variant="body2" color="error">
                {formErrors?.companyName}
              </Typography>
            )} */}
            {/* <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                Enter company name
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                label="Enter company name"
                size="small"
                sx={{ height: "38px", borderRadius: "8px",fontSize:"14px" }}
                // onChange={handleChange}
                inputProps={{
                  style: {
                    fontSize: "14px",
                  },
                }}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
            }}
          >
            <Box mb={{ xs: 1, md: 2 }}>
              <InputLabel
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 }, mb: 0 }}
              >
                Client name*
              </InputLabel>
              <TextFeildWithBorderComponet
                label="Enter client name"
                name="clientName"
                value={formData?.clientName}
                onChange={handleInputChange}
                placeholder=""
                sx={{
                  "& input": {
                    // fontWeight: "600",
                  },
                }}
                error={!!formErrors?.clientName}
                helperText={formErrors?.clientName}
              />
              {/* {formErrors?.clientName && (
                <Typography variant="body2" color="error">
                  {formErrors?.clientName}
                </Typography>
              )} */}
            </Box>

            <Box mb={{ xs: 1, md: 2 }}>
              <InputLabel
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Official website*
              </InputLabel>
              <TextFeildWithBorderComponet
                label="Enter company website"
                name="website"
                value={formData?.website}
                onChange={handleInputChange}
                placeholder=""
                sx={{
                  "& input": {
                    // fontWeight: "600",
                  },
                }}
                error={!!formErrors?.website}
                helperText={formErrors?.website}
              />
              {/* {formErrors?.website && (
                <Typography variant="body2" color="error">
                  {formErrors?.website}
                </Typography>
              )} */}
            </Box>
          </Box>

          {/* </Box> */}
          <Box mb={{ xs: 1, md: 2 }}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 } }}
            >
              Email* (Owner)
            </InputLabel>
            <TextFeildWithBorderComponet
              label="Enter owner email address"
              name="email"
              value={formData?.email}
              onChange={handleInputChange}
              placeholder=""
              sx={{
                "& input": {
                  // fontWeight: "600",
                },
              }}
              error={!!formErrors?.email}
              helperText={formErrors?.email}
            />
          </Box>
          <Box mb={{ xs: 1, md: 2 }}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 } }}
            >
              Company email
            </InputLabel>
            <TextFeildWithBorderComponet
              label="Enter company email address"
              name="businessEmail"
              value={formData?.businessEmail}
              onChange={handleInputChange}
              placeholder=""
              sx={{
                "& input": {
                  // fontWeight: "600",
                },
              }}
              error={!!formErrors?.businessEmail}
              helperText={formErrors?.businessEmail}
            />
          </Box>
          <Box mb={{ xs: 1, md: 2 }}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 } }}
            >
              Address*
            </InputLabel>
            <TextFeildWithBorderComponet
              label="Enter company address"
              name="address"
              placeholder=""
              value={formData?.address}
              onChange={handleInputChange}
              sx={{
                "& input": {
                  // fontWeight: "600",
                },
              }}
              error={!!formErrors?.address}
              helperText={formErrors?.address}
            />
          </Box>

          <Box mb={{ xs: 1, md: 2 }}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
            >
              Country*
            </InputLabel>
            <FormControl fullWidth size="small" error={!!formErrors?.country}>
              <Autocomplete
                id="country-autocomplete"
                options={getAllCountries || []}
                getOptionLabel={(option) => option.countryName}
                value={selectedCountry}
                onChange={handleSelectChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select"
                    error={!!formErrors?.country}
                    sx={{
                      "& .MuiInputLabel-root": {
                        fontSize: { xs: 12, md: 14 },
                        top: "-5px",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        top: "0px",
                      },
                      "& .MuiFormLabel-filled": {
                        top: "0px",
                      },
                      "& .MuiOutlinedInput-root": {
                        height: "38px",
                        borderRadius: "8px",
                        fontSize: "14px",
                        "& .MuiSelect-select": {
                          // fontWeight: "600",
                        },
                        "& input": {
                          // fontWeight: "600",
                        },
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        fontSize: 14,
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight:
                          option.countryName === formData?.country
                            ? "normal"
                            : "normal",
                      }}
                    >
                      {option.countryName}
                    </Typography>
                  </li>
                )}
              />
              {/* {formErrors?.country && (
                <Typography variant="body2" color="error">
                  {formErrors?.country}
                </Typography>
              )} */}
              {formErrors?.country && (
                <FormHelperText>{formErrors?.country}</FormHelperText>
              )}
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box width="100%" m={3} mr={5}>
          {isAddNewCompanyLoading ? (
            <LoadingComponent height="auto" color={bgColors?.blue} />
          ) : (
            <button onClick={handleSave} className={classes.updateButtonStyles}>
              Add Company
            </button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewCompany;
