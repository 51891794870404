import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AUTOMATION_API } from "../../../Apis/AdminLogin/Automation/Automation";

export interface IData {
    status: "loading" | "succeeded" | "failed" | "idle";
    data: any;
  }

  const initialState: IData = {
    status: "idle",
    data: null,
  };

  export const createWorkingHours = createAsyncThunk(
    "createWorkingHours",
    async (data: any, { rejectWithValue }) => {
      try {
        const response = await AUTOMATION_API.createWorkingHours(data);
        return response?.data;
      } catch (error: any) {
        // Provide a descriptive error message
        return rejectWithValue(error.response || 'An error occurred');
      }
    }
  );


  export const createWorkingHoursSlice = createSlice({
    name: "createWorkingHoursSlice",
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
      builder
        .addCase(createWorkingHours.pending, (state) => {
          state.status = "loading";
          state.data = null;
        })
        .addCase(createWorkingHours.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.data = action.payload;
        })
        .addCase(createWorkingHours.rejected, (state) => {
          state.status = "failed";
        });
    },
  });


export const createWorkingHoursActions = createWorkingHoursSlice.actions;
export default createWorkingHoursSlice.reducer;
