/* global process */

import axios from "axios";

const USER_API_URL = process.env.REACT_APP_BASE_URL;

const getAllSubscriptionPlans = () => {
  return axios({
    // url: `${USER_API_URL}/SubscriptionPlan/get-all-subscription-plans`,
    url: `${USER_API_URL}/plans/get-all-plans`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const postSubscriptionPlan = (data: any) => {
  // console.log(data, "dataa");
  return axios({
    // url: `${USER_API_URL}/PaymentsDetails/create-order`,
    url: `${USER_API_URL}/plans/add-new-plan`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    // data: JSON.stringify({ data: data }),
    data: JSON.stringify(data),
    // data: data,
  });
};

// const getCurrentplanDetails = (userId: any) => {
//   return axios({
//     // url: `${USER_API_URL}/PaymentsDetails/plan-details/${userId}`,
//     url: `${USER_API_URL}/PaymentsDetails/plan-details/${userId}`,
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: localStorage.getItem("token"),
//     },
//   });
// };

// 'https://dev.engageto.com/api/Plans/GetCurrentPlan?companyId=99969011-7b1d-4c2d-92a6-fba9ca31a261' \

const getCurrentplanDetails = (companyId: any) => {
  return axios({
    // url: `${USER_API_URL}/PaymentsDetails/plan-details/${userId}`,
    url: `${USER_API_URL}/Plans/GetCurrentPlan?companyId=${companyId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const subscriptionDeductions = () => {
  return axios({
    url: `${USER_API_URL}/PaymentsDetails/SubscriptionDeductions`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const subscriptionInvoice = (id: any) => {
  return axios({
    url: `${USER_API_URL}/PaymentsDetails/DownloadSubscriptionDeductionsInvoice/${id}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getAllPlans = (planType: string, companyId: string) => {
  return axios({
    url: `${USER_API_URL}/Plans/get-all-plans?planType=${planType}&companyId=${companyId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getFreeSubscriptionPlan = (data: any) => {
  return axios({
    url: `${USER_API_URL}/PaymentsDetails/FreeSubscriptionPlan`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  });
};

const getCreateOrder = (data: any) => {
  return axios({
    url: `${USER_API_URL}/PaymentGateway/CreateOrder`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  });
};

const getPlanDetailsByPlanId = (
  PlanId: number,
  planType: string,
  companyId: string,
  isUpgradePlan: boolean
) => {
  return axios({
    url: `${USER_API_URL}/Plans/get-planDtails-by-planId?planId=${PlanId}&planType=${planType}&companyId=${companyId}&isUpgrade=${isUpgradePlan}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

export const MANAGE_SUBSCRIPTION_APIS = {
  getAllSubscriptionPlans,
  postSubscriptionPlan,
  getCurrentplanDetails,
  subscriptionDeductions,
  subscriptionInvoice,
  getAllPlans,
  getFreeSubscriptionPlan,
  getCreateOrder,
  getPlanDetailsByPlanId,
};
