import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET_MANAGE_ACCOUNT } from "../../../Apis/ManageAccountApis/GetManangeAccount";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const fetchAllLanguages = createAsyncThunk(
  "fetchAllLanguages",
  async () => {
    const response = await GET_MANAGE_ACCOUNT.getAllLanguages();

    return response?.data;
  }
);

export const getAllLanguageSlice = createSlice({
  name: "getAllLanguageSlice",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllLanguages.pending, (state) => {
        state.status = "loading";
        //   state.error = ""
        state.data = [];
      })
      .addCase(fetchAllLanguages.fulfilled, (state, action) => {
        state.status = "succeeded";
        //   state.error = ""
        state.data = action.payload;
        //   state.EstaActivo = true;
        //   state.filterData = action.payload.filter((each:any) => _.get(each,'EstaActivo'))
      })
      .addCase(fetchAllLanguages.rejected, (state) => {
        state.status = "failed";
        state.data = [];
        //   state.error = action.error.message || "";
      });
  },
});

export const getAllLanguagesDataActions = getAllLanguageSlice.actions;
export default getAllLanguageSlice.reducer;
