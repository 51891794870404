import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  IconButton,
  MenuItem,
  Select,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import TextFieldWithBorderComponent from "../../common/TextFieldWithBorderComponent";
import { SaveResponseRadioOptions } from "./functions";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import AddVariablePopup from "./AddVariablePopup";
import { getVariableNames } from "../../../redux/slices/Workflows/getVariableNamesSlice";
import ButtonsAndListComponent from "./ButtonsAndListComponent";

interface Header {
  key: string;
  value: string;
}

const TriggerWebhookComponent = ({
  expandedStep,
  handleStepDataChange,
  workflowData,
  handleSelectedListChange,
  handleButtonsChange,
}: any) => {
  const dispatch = useAppDispatch();

  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const variableNames = useAppSelector((state: any) => state.getVariableNames);
  //   const { workflowListData, workflowListStatus } = useAppSelector(
  //     (state: any) => state.getWorkflowList
  //   );

  const [isAddNewPopupOpen, setIsAddNewPopupOpen] = useState(false);
  const [newVariableName, setNewVariableName] = useState("");
  const [requestType, setRequestType] = useState("GET");
  const [requestUrl, setRequestUrl] = useState("");
  const [headers, setHeaders] = useState<Header[]>([
    { key: "Content-Type", value: "application/json" },
  ]);
  const [responseOption, setResponseOption] = useState("trait");
  //   const [selectedButtonValue, setSelectedButtonValue] = useState(() => {
  //     const flowResponseType = workflowData[expandedStep - 1]?.flowResponseType;
  //     if (flowResponseType === 1) return "button";
  //     if (flowResponseType === 2) return "list";
  //     return "";
  //   });
  //   const [selectedList, setSelectedList] = useState<any>(null);
  // const [openListDialog, setOpenListDialog] = useState(false);
  const [errorDescription, setErrorDescription] = useState(
    "We are sorry. Unable to process your request at this time. Please try again later."
  );
  const [bodyDescription, setBodyDescription] = useState("{}");
  const [showSaveUserResponse, setShowSaveUserResponse] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState("");

  const handleAddNewClick = () => {
    setIsAddNewPopupOpen(true);
  };
  const handlePopupClose = () => {
    setIsAddNewPopupOpen(false);
    setNewVariableName("");
  };
  const handlePopupSuccess = () => {
    // Refresh the variable list
    // fetchWorkflowVariableNames();
  };
  // Handle request URL changes
  const handleRequestTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRequestType(event.target.value);
  };

  const handleRequestUrlChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRequestUrl(event.target.value);
  };

  // Handle header changes
  const handleHeaderChange = (index: number, field: string, value: string) => {
    const updatedHeaders = headers.map((header, i) =>
      i === index ? { ...header, [field]: value } : header
    );
    setHeaders(updatedHeaders);
  };

  // Add a new header field
  const handleAddHeader = () => {
    setHeaders([...headers, { key: "", value: "" }]);
  };

  // Remove a header
  const handleRemoveHeader = (index: number) => {
    setHeaders(headers.filter((_, i) => i !== index));
  };

  const toggleSaveUserResponse = () => {
    setShowSaveUserResponse(!showSaveUserResponse);
  };

  const handleErrorDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setErrorDescription(event.target.value);
  };

  const handleBodyDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBodyDescription(event.target.value);
  };

  const handleSaveUserVariableChange = (event: any) => {
    const selectedVariableId = event.target.value;
    setSelectedVariable(selectedVariableId);

    // Update the workflow data with the selected variable id
    const updatedWorkflowData = {
      ...workflowData[expandedStep - 1],
      responseCustomerMessage: {
        ...workflowData[expandedStep - 1].responseCustomerMessage,
        veriableNameEntityId: selectedVariableId,
      },
    };
    handleStepDataChange(expandedStep, updatedWorkflowData);
  };

  //   const handleButtonChange = (event: any) => {
  //     const value = event.target.value as string;
  //     setSelectedButtonValue(value);

  //     let flowResponseType = 0;
  //     let autoReplyWorkflowButtons = null;
  //     let workflowListId = null;

  //     if (value === "button") {
  //       flowResponseType = 1;
  //       autoReplyWorkflowButtons = workflowData[expandedStep - 1]
  //         ?.autoReplyWorkflowButtons || [""];
  //     } else if (value === "list") {
  //       flowResponseType = 2;
  //       workflowListId = workflowData[expandedStep - 1]?.workflowListId;
  //     }
  //     // console.log(
  //     //   "buttons",
  //     //   flowResponseType,
  //     //   autoReplyWorkflowButtons,
  //     //   workflowListId
  //     // );

  //     handleStepDataChange(expandedStep, {
  //       flowResponseType,
  //       autoReplyWorkflowButtons,
  //       workflowListId,
  //     });
  //   };

  //   const handleListSelect = async (listData: any) => {
  //     // console.log("Selected list data:", listData);
  //     setSelectedList(listData);
  //     setOpenListDialog(false);

  //     // Prepare the payload for the API
  //     const payload = {
  //       id: listData.id,
  //       companyId: userData?.companyId,
  //       listName: listData.listName,
  //       buttonName: listData.buttonName,
  //       inputs: listData.inputs,
  //     };
  //     try {
  //       const result = await dispatch(updateWorkflowList(payload)).unwrap();
  //       if (result.success) {
  //         dispatch(
  //           toastActions.setToaster({
  //             message: "Workflow list updated successfully",
  //             type: "success",
  //           })
  //         );
  //       } else {
  //         dispatch(
  //           toastActions.setToaster({
  //             message: result.message || "Failed to update workflow list",
  //             type: "error",
  //           })
  //         );
  //       }
  //     } catch (error) {
  //       dispatch(
  //         toastActions.setToaster({
  //           message: "An error occurred while updating the workflow list",
  //           type: "error",
  //         })
  //       );
  //     }
  //   };

  //   const handleRemoveButton = (index: number) => {
  //     const updatedButtons = (
  //       workflowData[expandedStep - 1]?.autoReplyWorkflowButtons || []
  //     ).filter((_: any, i: number) => i !== index);
  //     handleButtonsChange(expandedStep, updatedButtons);
  //   };

  //   const handleAddButton = () => {
  //     const currentButtons =
  //       workflowData[expandedStep - 1]?.autoReplyWorkflowButtons || [];
  //     const updatedButtons = [...currentButtons, ""];
  //     handleButtonsChange(expandedStep, updatedButtons);
  //   };

  //   const handleButtonTextChange = (index: number, value: string) => {
  //     const updatedButtons = [
  //       ...(workflowData[expandedStep - 1]?.autoReplyWorkflowButtons || []),
  //     ];
  //     updatedButtons[index] = value;
  //     handleButtonsChange(expandedStep, updatedButtons);
  //     // console.log("updatedButtons", updatedButtons);
  //   };

  useEffect(() => {
    const payload = {
      companyId: userData?.companyId,
    };
    dispatch(getVariableNames(payload));
  }, [dispatch]);

  //   console.log("steps", variableNames?.data);

  return (
    <div>
      {/* First Accordion: Define URL */}
      <Accordion sx={{ padding: "0px", marginBottom: "8px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ minHeight: "30px", padding: "1px 6px", margin: "0px" }}
        >
          <Typography sx={{ fontSize: "14px" }}>Define URL</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px" }}>
          <div style={{ marginBottom: "8px" }}>
            <Select
              //   label="Request Type"
              //   placeholder=""
              value={requestType}
              onChange={(e) => handleRequestTypeChange(e as any)}
              fullWidth
              variant="outlined"
              sx={{
                height: "36px",
                fontSize: "12px",
                borderRadius: "6px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "6px",
                },
              }}
            >
              <MenuItem sx={{ fontSize: "12px" }} value="GET">
                GET
              </MenuItem>
              <MenuItem sx={{ fontSize: "12px" }} value="POST">
                POST
              </MenuItem>
              <MenuItem sx={{ fontSize: "12px" }} value="PUT">
                PUT
              </MenuItem>
              <MenuItem sx={{ fontSize: "12px" }} value="DELETE">
                DELETE
              </MenuItem>
            </Select>
            <TextFieldWithBorderComponent
              label="Request URL"
              variant="outlined"
              placeholder=""
              name="key"
              fullWidth
              sx={{
                marginTop: "8px",
                fontSize: "12px",
              }}
              value={requestUrl}
              onChange={handleRequestUrlChange}
            />
          </div>
          <Typography
            color="green"
            variant="body2"
            sx={{ cursor: "pointer", fontSize: "12px" }}
          >
            + Add variable
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Second Accordion: Customize Header */}
      <Accordion sx={{ padding: "0px", marginBottom: "8px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ minHeight: "30px", padding: "1px 6px", margin: "0px" }}
        >
          <Typography sx={{ fontSize: "14px" }}>Customize Header</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px" }}>
          {headers.map((header, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <TextFieldWithBorderComponent
                label="Key"
                variant="outlined"
                placeholder=""
                name="key"
                value={header.key}
                onChange={(e) =>
                  handleHeaderChange(index, "key", e.target.value)
                }
                sx={{ marginRight: "8px", fontSize: "12px" }}
                fullWidth
              />
              <TextFieldWithBorderComponent
                label="Value"
                variant="outlined"
                placeholder=""
                name="value"
                value={header.value}
                onChange={(e) =>
                  handleHeaderChange(index, "value", e.target.value)
                }
                sx={{ marginRight: "8px", fontSize: "12px" }}
                fullWidth
              />
              {index > 0 && (
                <IconButton
                  color="secondary"
                  onClick={() => handleRemoveHeader(index)}
                  aria-label="remove header"
                  size="small"
                >
                  <RemoveCircleOutlineIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          ))}
          <Button
            variant="text"
            color="success"
            onClick={handleAddHeader}
            sx={{ fontSize: "12px" }}
          >
            + Add another header
          </Button>
          {headers?.length > 1 && headers[0]?.value === "application/json" && (
            <Typography
              color="green"
              variant="body2"
              sx={{ cursor: "pointer", fontSize: "12px", marginTop: "6px" }}
            >
              + Add variable
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Third Accordion: Customize Body */}
      <Accordion sx={{ padding: "0px", marginBottom: "8px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            minHeight: "30px",
            padding: "1px 6px",
            margin: "0px",
          }}
        >
          <Box sx={{ display: "block" }}>
            <Typography sx={{ fontSize: "14px" }}>Customize Body</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px" }}>
          <Typography variant="body2" sx={{ fontSize: "12px", color: "gray" }}>
            Request Body (JSON only)
          </Typography>
          <TextFieldWithBorderComponent
            label="Body"
            variant="outlined"
            placeholder=""
            name="body"
            value={bodyDescription}
            onChange={handleBodyDescriptionChange}
            fullWidth
            multiline
            rows={4}
            sx={{
              fontSize: "12px",
            }}
          />
          <Typography
            color="primary"
            variant="body2"
            sx={{ cursor: "pointer", fontSize: "12px", marginTop: "6px" }}
          >
            + Add variable
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Fourth Accordion: Save Response */}
      <Accordion sx={{ padding: "0px", marginBottom: "8px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ minHeight: "30px", padding: "1px 6px", margin: "0px" }}
        >
          <Box sx={{ display: "block" }}>
            <Typography sx={{ fontSize: "14px" }}>Save Response</Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "gray" }}
            >
              Select where you want to save this user response
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px" }}>
          <Box sx={{ mt: 1, mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                onClick={toggleSaveUserResponse}
                style={{
                  color: "green",
                  fontWeight: "600",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                + Save user response
              </Typography>
              <Box>
                <IconButton size="small" onClick={toggleSaveUserResponse}>
                  {showSaveUserResponse ? (
                    <CancelOutlinedIcon fontSize="small" color="error" />
                  ) : (
                    <CheckCircleOutlineIcon fontSize="small" color="primary" />
                  )}
                </IconButton>
              </Box>
            </Box>

            {showSaveUserResponse && (
              <Box
                sx={{
                  mt: 2,
                  px: 1,
                  bgcolor: "white",
                  //   border: "1px solid gray",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 2,
                    right: 0,
                    display: "flex",
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      /* Add save functionality */
                    }}
                    sx={{ mr: 1 }}
                  >
                    <CheckCircleOutlineIcon fontSize="small" color="primary" />
                  </IconButton>
                </Box>
                <RadioGroup
                  value={responseOption}
                  onChange={(e) => setResponseOption(e.target.value)}
                >
                  {SaveResponseRadioOptions.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": { color: "green" },
                            "& .MuiSvgIcon-root": { fontSize: 16 },
                          }}
                        />
                      }
                      label={option.label}
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "14px" },
                      }}
                    />
                  ))}
                </RadioGroup>
                {responseOption === "Variable" && (
                  <Select
                    fullWidth
                    displayEmpty
                    value={
                      workflowData[expandedStep - 1]?.responseCustomerMessage
                        ?.veriableName?.id || selectedVariable
                    }
                    onChange={handleSaveUserVariableChange}
                    sx={{
                      mt: 1,
                      height: "36px",
                      fontSize: "14px",
                      borderRadius: "10px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 150,
                          overflowY: "auto",
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <Typography style={{ fontSize: "14px" }}>
                        Select a variable
                      </Typography>
                    </MenuItem>
                    {variableNames?.data?.map((item: any) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        sx={{ fontSize: 14 }}
                      >
                        {item.veriableName}
                      </MenuItem>
                    ))}
                    <Box
                      sx={{
                        position: "sticky",
                        bottom: 0,
                        backgroundColor: "background.paper",
                        borderTop: "1px solid",
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "center",
                        p: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "green",
                          fontWeight: 600,
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleAddNewClick()}
                      >
                        + Add New
                      </Typography>
                    </Box>
                  </Select>
                )}
                <AddVariablePopup
                  open={isAddNewPopupOpen}
                  onClose={handlePopupClose}
                  onSuccess={handlePopupSuccess}
                  setNewVariableName={setNewVariableName}
                  newVariableName={newVariableName}
                />
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Fifth Accordion: Error Handling */}
      <Accordion sx={{ padding: "0px", marginBottom: "8px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ minHeight: "30px", padding: "1px 6px", margin: "0px" }}
        >
          <Box sx={{ display: "block" }}>
            <Typography sx={{ fontSize: "14px" }}>Error Handling</Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "gray" }}
            >
              Inform end users how to proceed in case of errors
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px" }}>
          <Typography sx={{ fontSize: "12px" }}>
            Default Error Response
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "11px", color: "gray" }}>
            Short description of default response
          </Typography>
          <TextFieldWithBorderComponent
            label="Default Error Response"
            variant="outlined"
            placeholder=""
            value={errorDescription}
            onChange={handleErrorDescriptionChange}
            name="defaultError"
            fullWidth
            multiline
            rows={4}
            sx={{
              fontSize: "10px",
            }}
          />

          <ButtonsAndListComponent
            expandedStep={expandedStep}
            handleStepDataChange={handleStepDataChange}
            workflowData={workflowData}
            handleSelectedListChange={handleSelectedListChange}
            handleButtonsChange={handleButtonsChange}
          />

          {/* <Typography sx={{ fontSize: 14, mt: 2, mb: 2 }}>
            Add buttons or lists to your response
          </Typography>
          <Select
            fullWidth
            displayEmpty
            value={selectedButtonValue}
            onChange={handleButtonChange}
            sx={{
              mb: 2,
              height: "36px",
              fontSize: "14px",
              borderRadius: "10px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
              },
            }}
          >
            {ButtonOptions.map((option) => (
              <MenuItem
                key={option.value}
                sx={{ fontSize: 12 }}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>

          {selectedButtonValue === "button" && (
            <Box>
              {workflowData[expandedStep - 1]?.autoReplyWorkflowButtons?.map(
                (buttonText: string, index: number) => (
                  <React.Fragment key={index}>
                    <Typography sx={{ mr: 2, fontSize: "14px" }}>
                      Button {index + 1}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 2,
                        mt: 1,
                      }}
                    >
                      <TextFieldWithBorderComponent
                        label={`Button text ${index + 1}`}
                        value={buttonText}
                        name="buttonText"
                        onChange={(e) =>
                          handleButtonTextChange(index, e.target.value)
                        }
                        placeholder={`Button text ${index + 1}`}
                        fullWidth
                        size="small"
                        // sx={{
                        //   width: "80%",
                        //   height: "36px",
                        //   fontSize: "14px",
                        //   borderRadius: "10px",
                        //   "& .MuiOutlinedInput-root": {
                        //     borderRadius: "10px",
                        //   },
                        // }}
                      />
                      <IconButton
                        onClick={() => handleRemoveButton(index)}
                        size="small"
                        sx={{ ml: 1 }}
                      >
                        <DeleteIconSvg />
                      </IconButton>
                    </Box>
                  </React.Fragment>
                )
              )}
              {workflowData[expandedStep - 1]?.autoReplyWorkflowButtons
                ?.length < 3 && (
                <Typography
                  onClick={handleAddButton}
                  style={{
                    color: "green",
                    fontWeight: "600",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  + Add another button
                </Typography>
              )}
            </Box>
          )}

          {selectedButtonValue === "list" && (
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              {workflowListStatus === "loading" ? (
                <LoadingComponent height="100%" color={bgColors?.blue} />
              ) : (
                <Typography
                  onClick={() => setOpenListDialog(true)}
                  style={{
                    color: "green",
                    fontWeight: "600",
                    fontSize: "14px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <span style={{ fontSize: "20px" }}>≡</span>
                  {selectedList ? selectedList.listName : "Select a List"}
                </Typography>
              )}
            </Box>
          )}

          <SelectListDialog
            open={openListDialog}
            onClose={() => setOpenListDialog(false)}
            onSelect={handleListSelect}
            workflowListData={workflowListData}
            // selectedList={selectedList}
            handleSelectedListChange={handleSelectedListChange}
            expandedStep={expandedStep}
          /> */}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TriggerWebhookComponent;
