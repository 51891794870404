import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import InfoIcon from "@mui/icons-material/Info"; // Make sure you're importing this from MUI

// Use default parameters instead of defaultProps
const InfoIconComponent = ({ tooltip = "Info" }: { tooltip?: string }) => {
  return (
    <Tooltip title={<div>{tooltip}</div>} arrow>
      <IconButton size="small" sx={{ marginBottom: 1 }}>
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default InfoIconComponent;
