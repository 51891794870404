import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OPTED_MANAGEMENT_APIS } from "../../../Apis/AdminLogin/Automation/OptinManagement";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const deleteOptedKeywords = createAsyncThunk(
  "deleteOptedKeywords",
  async (
    {
      id,
      businessId,
      keyword,
    }: { id: string; businessId: string; keyword: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await OPTED_MANAGEMENT_APIS.deleteKeywords(
        id,
        businessId,
        keyword
      );
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error.response || "An error occurred");
    }
  }
);

export const deleteOptedKeywordsSlice = createSlice({
  name: "deleteOptedKeywords",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteOptedKeywords?.pending, (state) => {
        state.status = "loading";
        state.data = [];
      })
      .addCase(deleteOptedKeywords?.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(deleteOptedKeywords?.rejected, (state) => {
        state.status = "failed";
        state.data = [];
      });
  },
});

export const deleteOptedKeywordsActions = deleteOptedKeywordsSlice?.actions;
export default deleteOptedKeywordsSlice.reducer;
