import React from "react";
import { Avatar, Box, IconButton, TextField } from "@mui/material";
import ReactDevicePreview from "react-device-preview";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/system";

const MessageInputField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "20px",
    backgroundColor: "#f6f6f6",
    padding: "5px 10px",
    "& fieldset": {
      //   borderColor: "transparent",
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: "#e0e0e0",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#e0e0e0",
    },
    "& input::placeholder": {
      color: "#b0b0b0",
    },
  },
});

const MessageBubble = styled(Box)(({ theme }) => ({
  maxWidth: "70%",
  padding: "8px 12px",
  borderRadius: "12px",
  backgroundColor: "#e1ffc7", // Light green color for sent messages
  alignSelf: "flex-end",
  marginBottom: "8px",
  boxShadow: "0 1px 0.5px rgba(0, 0, 0, 0.13)",
  wordWrap: "break-word",
}));

const WhatsAppPreview = ({
  countryCode,
  phoneNumber,
  message,
  isTyping,
}: any) => {
  return (
    <ReactDevicePreview device="iphonex" color="black" scale="0.5">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          border: "1px solid #e0e0e0",
          borderRadius: "10px",
          backgroundColor: "#fafafa",
        }}
      >
        {/* Header with Avatar and Phone Number */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            backgroundColor: "#f6f6f6",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Avatar />
          <Box sx={{ marginLeft: "10px", fontWeight: "bold" }}>
            {countryCode} {phoneNumber}
          </Box>
        </Box>

        {/* Message Area */}
        <Box
          sx={{
            flex: 1,
            backgroundColor: "#eee5dc",
            padding: "10px",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end", // This pushes content to the bottom
          }}
        >
          {isTyping && message && <MessageBubble>{message}</MessageBubble>}
        </Box>

        {/* Message Input and Send Button */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            borderTop: "1px solid #e0e0e0",
            backgroundColor: "#f6f6f6",
          }}
        >
          <MessageInputField
            placeholder="Type a message"
            variant="outlined"
            size="small"
            fullWidth
            value={message}
          />
          <IconButton>
            <SendIcon sx={{ color: "black" }} />
          </IconButton>
        </Box>
      </Box>
    </ReactDevicePreview>
  );
};

export default WhatsAppPreview;
