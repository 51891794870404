import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import { useAppDispatch } from "../../utils/redux-hooks";
import TextFieldWithBorderComponent from "../../components/common/TextFieldWithBorderComponent";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    marginTop: "20px",
    height: "90%",
    width: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "500 !important",
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    // backgroundColor: bgColors.blue,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "120px",
    height: "32px",
    gap: "0px",
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
  },
});

const Bot = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [botName, setBotName] = useState("");
  const [url, setUrl] = useState("");
  const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBotName(e.target.value);
  };
  const handleUrlInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };
  return (
    <Grid className={classes.mainContainer}>
      <Grid className={classes.bgContainer}>
        <Box pt={3}>
          <Box className={classes.manageContainer}>
            <Typography
              // ml={4}
              variant="h6"
              sx={{ display: "flex", alignItems: "center", ml: 3 }}
            >
              &nbsp;BOT
            </Typography>
            <Box sx={{ mr: 3, mt: -1 }}>
              <button
                // onClick={handleSubmit}
                className={classes.SaveChangesButton}
              >
                Save
              </button>
            </Box>
          </Box>
        </Box>
        <Box ml={3} mr={2} mt={3}>
          <Box p={4} className={classes.mainBorderStyles}>
            <Box
              // className={classes.emailContainer}
              sx={{
                display: "flex",
                gap: "10px",
                flexDirection: {
                  lg: "row",
                  sm: "row",
                  xs: "column",
                  md: "row",
                },
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: { xs: 0, lg: 5 },
              }}
            >
              <Box sx={{ width: { lg: "50%", xs: "100%" } }}>
                <Typography variant="body1" className={classes.blackColor}>
                  Name of the BOT
                </Typography>
                <TextFieldWithBorderComponent
                  margin="dense"
                  id="botName"
                  size="small"
                  label="Enter the name"
                  name="botName"
                  placeholder=""
                  value={botName}
                  //   fullWidth
                  onChange={handleNameInputChange}
                  //   error={!!isError.companyName}
                  //   helperText={isError.companyName}
                  sx={{
                    "& input": {
                      //   fontWeight: "600",
                    },
                  }}
                  //   value={expiryDate}
                  //   onChange={(e) => setExpiryDate(e.target.value)}
                />
              </Box>
            </Box>
            <Box
              //  className={classes.emailContainer}
              sx={{
                display: "flex",
                gap: "10px",
                // flexDirection: "row",
                flexDirection: {
                  lg: "row",
                  sm: "row",
                  xs: "column",
                  md: "row",
                },
                justifyContent: "space-between",
                alignItems: "center",
                // gap: "10px"
              }}
            >
              <Box
                // sx={{ width: '50%' }}
                sx={{ width: { lg: "50%", xs: "100%" } }}
              >
                <Typography variant="body1" className={classes.blackColor}>
                  {/* <Menu:c></Menu:c> */}
                  Url
                </Typography>
                <TextFieldWithBorderComponent
                  margin="dense"
                  id="url"
                  size="small"
                  label="Enter the url"
                  name="url"
                  placeholder=""
                  value={url}
                  //   fullWidth
                  onChange={handleUrlInputChange}
                  //   error={!!isError.gstNumber}
                  //   helperText={isError.gstNumber}
                  sx={{
                    "& input": {
                      //   fontWeight: "600",
                    },
                  }}
                  //   value={expiryDate}
                  //   onChange={(e) => setExpiryDate(e.target.value)}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Bot;
