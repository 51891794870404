import React from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import ChatUserArrowDownSvg from "../../../assets/svgs/ChatUserArrowDownSvg";
import ChatUserArrowSide from "../../../assets/svgs/ChatUserArrowSide";
import CustomAudioPlayer from "./AudioComponent";
import SharedMedia from "./sharedmedia";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  grayColor: {
    color: `#4B5A5A !important`,
    fontWeight: "600 !important",
  },
  grayColor2: {
    color: `#4B5A5A !important`,
    opacity: "80%",
  },
  flexRowCenterPointer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  flexRowCenterSpaceBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  imageStyles: {
    width: "125px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "8px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    border: "1px solid #cdcdcd",
  },
});

interface SharedMediaProps {
  media: boolean;
  seeAllTooltipOpen: boolean;
  handleOpenRightSidebar: () => void;
  handleOpenMediaPopover: (media: any) => void;
  rightSidebarOpen: boolean;
  handleCloseRightSidebar: () => void;
  filteredMedia: any[];
  sharedDocuments: any[];
  filteredLinks: any[];
  setMedia: React.Dispatch<React.SetStateAction<boolean>>;
  setSeeAllTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>;
  details: any;
}

const SharedMediaDropdownComponent: React.FC<SharedMediaProps> = ({
  media,
  seeAllTooltipOpen,
  handleOpenRightSidebar,
  handleOpenMediaPopover,
  rightSidebarOpen,
  handleCloseRightSidebar,
  filteredMedia,
  sharedDocuments,
  filteredLinks,
  setMedia,
  setSeeAllTooltipOpen,
  details,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Box my={4} className={classes.flexRowCenterSpaceBetween}>
        <Box
          className={classes.flexRowCenterPointer}
          onClick={() => setMedia(!media)}
        >
          {media ? <ChatUserArrowDownSvg /> : <ChatUserArrowSide />}
          <Typography
            ml={2}
            sx={{ fontSize: "14px" }}
            className={classes.grayColor}
            style={{ cursor: "pointer" }}
          >
            Shared media
          </Typography>
        </Box>

        {media && (
          <Tooltip
            title="See all"
            placement="top"
            open={seeAllTooltipOpen}
            onClose={() => setSeeAllTooltipOpen(false)}
          >
            <Box>
              <Typography
                ml={2}
                sx={{ fontSize: "14px" }}
                className={classes.grayColor2}
                style={{
                  cursor: "pointer",
                  borderBottom: "1px solid #4B5A5A",
                }}
                onClick={handleOpenRightSidebar}
              >
                See all
              </Typography>
            </Box>
          </Tooltip>
        )}
      </Box>
      {media && (
        <Box
          sx={{
            width: "100%",
            overflow: "hidden",
            display: "flex",
          }}
        >
          {details?.shareMedia?.length > 0 ? (
            <Box
              className={classes.flexRowCenter}
              sx={{
                flexWrap: "nowrap",
                gap: "10px",
                width: "300px",
                flexGrow: 1,
                overflowX: "hidden",
              }}
            >
              {filteredMedia?.map((mediaItem, index) => {
                const isVideo = mediaItem?.mediaUrl?.endsWith(".mp4");
                const isAudio =
                  mediaItem?.mediaUrl?.endsWith(".mp3") ||
                  mediaItem?.mediaUrl?.endsWith(".ogg") ||
                  mediaItem?.mediaUrl?.endsWith(".amr") ||
                  mediaItem?.mediaUrl?.endsWith(".arr");

                return (
                  <Box key={index} className={classes.flexRowCenter}>
                    {isVideo ? (
                      <video
                        src={mediaItem?.mediaUrl}
                        className={classes.imageStyles}
                        controls
                        onClick={() => handleOpenMediaPopover(mediaItem)}
                      />
                    ) : isAudio ? (
                      <CustomAudioPlayer mediaUrl={mediaItem?.mediaUrl} />
                    ) : (
                      <img
                        src={mediaItem?.mediaUrl}
                        alt={`media-${index}`}
                        className={classes.imageStyles}
                        onClick={() => handleOpenMediaPopover(mediaItem)}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          ) : (
            <p>No media shared</p>
          )}
          <SharedMedia
            open={rightSidebarOpen}
            onClose={handleCloseRightSidebar}
            sharedMedia={filteredMedia}
            sharedDocuments={sharedDocuments}
            sharedLinks={filteredLinks}
          />
        </Box>
      )}
    </Box>
  );
};

export default SharedMediaDropdownComponent;
