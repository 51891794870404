import React from 'react'

const ContactsIconSvg = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8" clipPath="url(#clip0_1246_1448)">
<path d="M25.895 6.54581C25.895 3.48387 23.4699 1 20.5 1C17.5301 1 15.105 3.48387 15.105 6.54581C15.105 9.59866 17.5301 12.0825 20.5 12.0825C23.4699 12.0825 25.895 9.59867 25.895 6.54581ZM10 24.4387V27.6452C10 28.3948 10.5887 29 11.318 29H29.682C30.4113 29 31 28.3948 31 27.6452V24.4387C31 18.4864 26.2904 13.6452 20.5 13.6452C14.7096 13.6452 10 18.4864 10 24.4387Z" fill="black"/>
<path d="M18.895 9.54581C18.895 6.48387 16.4699 4 13.5 4C10.5301 4 8.10502 6.48387 8.10502 9.54581C8.10502 12.5987 10.5301 15.0825 13.5 15.0825C16.4699 15.0825 18.895 12.5987 18.895 9.54581ZM3 27.4387V30.6452C3 31.3948 3.58868 32 4.31799 32H22.682C23.4113 32 24 31.3948 24 30.6452V27.4387C24 21.4864 19.2904 16.6452 13.5 16.6452C7.7096 16.6452 3 21.4864 3 27.4387Z" fill="black"/>
<path fillRule="evenodd" clipRule="evenodd" d="M13.5 2C17.624 2 20.895 5.4295 20.895 9.54581C20.895 11.9497 19.7749 14.1202 18.0202 15.5077C22.7068 17.3716 26 22.0448 26 27.4387V30.6452C26 32.447 24.5676 34 22.682 34H4.31799C2.43244 34 1 32.447 1 30.6452V27.4387C1 22.0448 4.29321 17.3716 8.97983 15.5077C7.22505 14.1202 6.10502 11.9497 6.10502 9.54581C6.10502 5.4295 9.37602 2 13.5 2ZM11.7231 14.7733C9.62078 14.0173 8.10502 11.9594 8.10502 9.54581C8.10502 6.48387 10.5301 4 13.5 4C16.4699 4 18.895 6.48387 18.895 9.54581C18.895 11.9594 17.3792 14.0173 15.2769 14.7733C14.72 14.9736 14.1219 15.0825 13.5 15.0825C12.8781 15.0825 12.28 14.9736 11.7231 14.7733ZM11.5798 16.826C6.70339 17.7558 3 22.1605 3 27.4387V30.6452C3 31.3948 3.58868 32 4.31799 32H22.682C23.4113 32 24 31.3948 24 30.6452V27.4387C24 22.1605 20.2966 17.7558 15.4202 16.826C14.7975 16.7072 14.1557 16.6452 13.5 16.6452C12.8443 16.6452 12.2025 16.7072 11.5798 16.826Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1246_1448">
<rect width="32" height="32" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default ContactsIconSvg
