import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import { bgColors } from "../../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import NewTagMember from "../../ContactsComponents/NewTagMember";
import { toastActions } from "../../../utils/toastSlice";
import { updateContactTags } from "../../../redux/slices/Contacts/UpdateContactTags";
import SearchIconSvg2 from "../../../assets/svgs/SearchIconSvg2";
import { fetchInboxContactDetails } from "../../../redux/slices/Inbox/InboxContactDetails";
import { IoMdPricetag } from "react-icons/io";

const useStyles = makeStyles({
  searchField: {
    width: "100%",
    borderRadius: "12px",
    height: "38px",
    fontSize: "18px",
    backgroundColor: "white",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "Roboto Slab",
      color: "#4B5A5A !important",
      Opacity: "60%",
    },
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "140px",
    height: "32px",
    // padding: "5px",
    cursor: "pointer",
    backgroundColor: "#fff",
  },
  lineStyles: {
    border: "none",
    borderTop: "3px solid #4B5A5A",
    margin: "0 auto",
    opacity: "20%",
  },
});

const TagPopover = ({
  anchorEl,
  onClose,
  onSelectTags,
  onRemoveTag,
  contactDetails,
  contactNumber,
}: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [newTagDialog, setNewTagDialog] = useState(false);
  const tagsData = useAppSelector((state:any) => state.getContactTagsData.data);
  const userProfileSlice = useAppSelector((state:any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  const manageInboxObject = userData?.permissions?.inbox;
  const inboxUserObject = manageInboxObject?.find((item: any) =>
    Object.prototype.hasOwnProperty.call(item, "inboxUserDetails")
  );
  const inboxUserActions = inboxUserObject
    ? inboxUserObject?.inboxUserDetails
    : [];
  const [addTagTooltipOpen, setAddTagTooltipOpen] = useState(false);

  const hasAcess = (permission: any) => {
    if (inboxUserActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  const filteredTags = tagsData?.filter(
    (tag: any) =>
      tag?.tag?.trim().toLowerCase().includes(searchQuery.toLowerCase()) &&
      tag?.tag.trim() !== ""
  );
  // console.log(filteredTags)

  const handleToggleTag = (tag: any) => {
    setSelectedTags((prevTags) =>
      prevTags.some((prevTag) => prevTag.id === tag.id)
        ? prevTags.filter((prevTag) => prevTag.id !== tag.id)
        : [...prevTags, tag]
    );
    if (selectedTags.some((prevTag) => prevTag.id === tag.id)) {
      onRemoveTag(tag?.tag); // Call the onRemoveTag prop when a tag is unchecked
    } else {
      onSelectTags(tag); // Call the onSelectTags prop when a tag is checked
    }
  };

  const handleAddTags = async () => {
    // Call the parent function to add selected tags
    onSelectTags(selectedTags);
    try {
      const data = {
        businessId: userData?.companyId,
        userId: userData?.userId,
        tagIds: selectedTags?.map((item) => item.id),
        contactIds: [contactDetails?.contactId],
      };
      const response: any = await dispatch(updateContactTags(data));
      // console.log("responseTags", response);
      if (response.meta.requestStatus === "fulfilled") {
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: `${response?.payload?.message}`,
          })
        );
        const data = {
          businessId: userData?.companyId,
          contactId: contactNumber,
        };
        dispatch(fetchInboxContactDetails(data));
        // window?.location?.reload();
      } else {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `${response?.payload?.message}`,
          })
        );
      }
      onClose();
    } catch (err: any) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: `${err?.response?.data?.message}`,
        })
      );
    }
  };

  const openNewTagPopover = () => {
    const hasPermission = hasAcess("addTag");
    if (hasPermission) {
      setNewTagDialog(true);
    } else {
      setAddTagTooltipOpen(true);
      setTimeout(() => {
        setAddTagTooltipOpen(false);
      }, 2000);
    }
  };

  const handleCloseTagPopover = () => {
    setNewTagDialog(false);
  };

  // Initialize selectedTags based on initialTags array
  useEffect(() => {
    const preSelectedTags = tagsData.filter((tag: { tag: any }) =>
      contactDetails?.tags?.includes(tag?.tag)
    );
    setSelectedTags(preSelectedTags);
  }, []);

  // console.log("contactDetails", contactDetails, tagsData);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "70vh",
          width: "250px",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
              cursor: "pointer",
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              cursor: "pointer",
              backgroundColor: "#cdcdcd",
              borderRadius: "8px",
              transition: "background-color 0.2s ease-in-out",
            },
          }}
        >
          <Box px={2} pt={2}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                fontSize={14}
                fontWeight="bold"
                gutterBottom
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IoMdPricetag />
                &nbsp; Tags
              </Typography>
              <Tooltip
                title="Access Denied"
                open={addTagTooltipOpen}
                onClose={() => setAddTagTooltipOpen(false)}
              >
                <Typography
                  fontSize={14}
                  color={`${bgColors.green}`}
                  style={{ cursor: "pointer", fontWeight: "600" }}
                  onClick={openNewTagPopover}
                >
                  + Create
                </Typography>
              </Tooltip>
              <NewTagMember
                open={newTagDialog}
                handleClose={handleCloseTagPopover}
              />
            </Box>
            <Box my={1}>
              <TextField
                className={classes.searchField}
                variant="standard"
                size="small"
                onChange={handleSearchChange}
                InputProps={{
                  disableUnderline: true,
                  style: { padding: "10px", height: "38px" },
                  startAdornment: (
                    <IconButton
                      sx={{ p: 0, color: "inherit" }}
                      aria-label="search"
                    >
                      <SearchIconSvg2 />
                    </IconButton>
                  ),
                }}
                placeholder="Search tags"
              />
            </Box>
            <Box>
              {filteredTags
                ?.filter((tag: any) => tag?.isActive)
                ?.map((tag: any) => (
                  <Box key={tag.id} mx={1} sx={{ marginBottom: "-5px" }}>
                    <Tooltip title={tag.tag} arrow placement="top">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedTags.some(
                              (selectedTag) => selectedTag.id === tag.id
                            )}
                            onChange={() => handleToggleTag(tag)}
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 17 },
                              color: "gray",
                              "&.Mui-checked": {
                                color: "gray",
                              },
                            }}
                          />
                        }
                        label={
                          <span
                            style={{
                              maxWidth: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              display: "inline-block",
                            }}
                          >
                            {tag.tag}
                          </span>
                        }
                      />
                    </Tooltip>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
            padding: "10px 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // borderTop: '1px solid #e0e0e0',
          }}
        >
          <button className={classes.SaveChangesButton} onClick={handleAddTags}>
            Add Tags to Contact
          </button>
        </Box>
      </Box>
    </Popover>
  );
};

export default TagPopover;
