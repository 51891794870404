import React from "react";
const ChatInformationSvg = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_480_16684)">
        <path
          d="M10 0.3125C4.65626 0.3125 0.3125 4.65626 0.3125 10C0.3125 15.3437 4.65626 19.6875 10 19.6875C15.3437 19.6875 19.6875 15.3437 19.6875 10C19.6875 4.65626 15.3437 0.3125 10 0.3125ZM10.9375 14.7937C10.9375 15.3125 10.5188 15.7312 10 15.7312C9.48124 15.7312 9.0625 15.3125 9.0625 14.7937V8.81874C9.0625 8.29997 9.48124 7.88124 10 7.88124C10.5188 7.88124 10.9375 8.29997 10.9375 8.81874V14.7937ZM10.9375 6.05625C10.9375 6.57497 10.5188 6.99375 10 6.99375C9.48124 6.99375 9.0625 6.57497 9.0625 6.05625V5.20622C9.0625 4.6875 9.48124 4.26872 10 4.26872C10.5188 4.26872 10.9375 4.6875 10.9375 5.20622V6.05625Z"
          fill="#4B5A5A"
        />
      </g>
      <defs>
        <clipPath id="clip0_480_16684">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChatInformationSvg;
