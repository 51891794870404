import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CAMPAIGN_API } from "../../../Apis/Campaign/Campaign";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const getCampaignTitle = createAsyncThunk(
  "getCampaignTitle",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await CAMPAIGN_API.checkCampaignTitle(data);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occured"
      );
    }
  }
);

export const GetCampaignTitleSlice = createSlice({
  name: "getCampaignTitleSlice",
  initialState,
  reducers: {
    // setData: (state, action) => {
    //   state.data = action.payload;
    // },
  },
  extraReducers(builder) {
    builder
      .addCase(getCampaignTitle.pending, (state) => {
        state.status = "loading";
        state.data = [];
      })
      .addCase(getCampaignTitle.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getCampaignTitle.rejected, (state, action) => {
        state.status = "failed";
        state.data = action.payload;
      });
  },
});

export const GetCampaignTitleActions = GetCampaignTitleSlice.actions;
export default GetCampaignTitleSlice.reducer;
