import React, { FunctionComponent, useState } from "react";
import { Box, Typography, Grid, InputLabel, Link } from "@mui/material";
import PasswordTextFeildWithBorders from "../common/PasswordTextFeildWithBorders";
import ButtonComponent from "../common/ButtonComponent";
import LinkComponent from "../common/LinkComponent";
import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { ADMIN_LOGIN_API } from "../../Apis/AdminLogin/AdminLoginApi";
import { toastActions } from "../../utils/toastSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../utils/redux-hooks";

export type LoginFormContainerType = {
  loginPromptText?: string;
  frameDivLeft?: number | string; // Adjust the type according to your requirements
};

const useStyles = makeStyles({
  container: {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    padding: "40px",
    boxSizing: "border-box",
    backgroundColor: "#ffffff",
    width: "100%",
    maxWidth: "450px", // Increased from 30%
    margin: "0 20px", // Add some margin on the sides
  },
  // button: {
  //   backgroundColor: `${bgColors.green}!important`,
  //   width: "100% !important",
  //   fontSize: "24px !important",
  //   fontWeight: "Bold !important",
  //   borderRadius: "12px !important",
  // },
  button: {
    backgroundColor: `${bgColors.green}!important`,
    width: "100% !important",
    fontSize: "14px !important",
    fontWeight: "Semi Bold !important",
    borderRadius: "8px !important",
  },
  promptText: {
    marginBottom: "20px !important",
    fontWeight: "bold !important",
    textAlign: "center",
  },
  emailmsg: {
    color: `${bgColors.black3} !important`,
    fontWeight: "Regular !important",
    fontSize: "12px !important",
  },
  linkContainer: {
    fontSize: "16px",
    textAlign: "center",
  },
  backText: {
    color: `#828282 !important`,
    fontSize: "16px !important",
    textAlign: "center",
    fontWeight: "500 !important",
  },
  arrowIcon: {
    marginRight: "10px",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "600 !important",
    fontSize: "14px !important",
  },
  boldLabel: {
    // fontSize: "16px",
    fontWeight: "500",
    fontFamily: "Inter",
    // color:"#646E7B"
    color: "rgba(100, 110, 123, 0.9)",
    // rgba(100, 110, 123, 1)
  },
});

const NewPasswordForm: FunctionComponent<LoginFormContainerType> = ({
  loginPromptText,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const email = useAppSelector((state: any) => state.adminLogin.email);
  // console.log(email,'email')
  // const loginData = useAppSelector(state => state.adminLogin?.data);
  // console.log(loginData,'loginData')
  // const currentEmail = loginData?.email

  const validatePassword = (password: string) => {
    if (!password) {
      setPasswordError("Password is required.");
      return false;
    }
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    if (!passwordPattern.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character."
      );
      // setPasswordError("Please enter a valid password.");
      return false;
    }
    setPasswordError("");
    return true;
  };

  const validateConfirmPassword = (confirmPassword: string) => {
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm password is required.");
      return false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return false;
    }
    setConfirmPasswordError("");
    return true;
  };

  const handleConfirm = async () => {
    const isPasswordValid = validatePassword(password);
    const isConfirmPasswordValid = validateConfirmPassword(confirmPassword);

    if (isPasswordValid && isConfirmPasswordValid) {
      try {
        const response = await ADMIN_LOGIN_API.updatePassword({
          email,
          password,
          confirmPassword,
        });
        if (response.status === 200) {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: "Password Updated Successfully",
            })
          );
          navigate("/login");
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: response?.data?.message || "Failed to update password",
            })
          );
        }
      } catch (err: any) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: err?.response?.data?.message || "Network error",
          })
        );
      }
    }
  };

  return (
    <Box
      className={classes.container}
      sx={{ width: { xs: "90%", sm: "70%", md: "50%", lg: "30%" } }}
      px={{ xs: 2, md: 6 }}
    >
      <Typography variant="h5" className={classes.promptText}>
        {loginPromptText}
      </Typography>
      <Grid item xs={12} textAlign="center">
        <Typography variant="h6" className={classes.emailmsg}>
          Your new password should be different from previously used passwords.
        </Typography>
      </Grid>
      <Grid item xs={12} my={{ xs: 1, md: 3 }}>
        <InputLabel className={classes.blackColor}>Password</InputLabel>
        <PasswordTextFeildWithBorders
          label={<span className={classes.boldLabel}>Enter new password</span>}
          name="password"
          placeholder=""
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!passwordError}
          helperText={passwordError}
          sx={{
            "& input": {
              fontWeight: "600",
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel className={classes.blackColor}>Confirm Password</InputLabel>
        <PasswordTextFeildWithBorders
          label={<span className={classes.boldLabel}>Confirm password</span>}
          name="confirmPassword"
          placeholder=""
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={!!confirmPasswordError}
          helperText={confirmPasswordError}
          sx={{
            "& input": {
              fontWeight: "600",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} my={3}>
        <ButtonComponent
          title="Confirm"
          className={classes.button}
          onClick={handleConfirm}
        />
      </Grid>
      <Grid item xs={12} className={classes.linkContainer}>
        <Link href="/login">
          <img
            alt=""
            src="/images/BackArrow.png"
            className={classes.arrowIcon}
          />
        </Link>
        <LinkComponent
          href="/login"
          title="Back to Login"
          className={classes.backText}
        />
      </Grid>
    </Box>
  );
};

export default NewPasswordForm;
// const NewPasswordForm: FunctionComponent<LoginFormContainerType> = ({
//   loginPromptText,
//   frameDivLeft,
// }) => {
//   const classes = useStyles();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [passwordError, setPasswordError] = useState("");

//   const email = useAppSelector((state) => state.adminLogin?.email);

//   const handleConfirm = async () => {
//     const isPasswordValid = validatePassword(password);
//     const isConfirmPasswordValid = validateConfirmPassword(password, confirmPassword);

//     if (isPasswordValid && isConfirmPasswordValid) {
//       // Passwords are valid, proceed with your logic
//       try {
//         const response = await ADMIN_LOGIN_API.updatePassword({ email, password, confirmPassword })
//         if (response.status === 200) {
//           dispatch(toastActions.setToaster({ type: 'success', message: 'Password Updated Successfully' }))
//           navigate('/login')
//         }
//       } catch (err) {
//         console.log(err)
//       } finally {

//       }
//     }
//   };

//   const validatePassword = (password: string) => {
//     const passwordPattern = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
//     if (!passwordPattern.test(password)) {
//       setPasswordError(
//         "Password must be at least 8 characters long and contain at least one uppercase letter, one number, and one special character."
//       );
//       return false;
//     }
//     setPasswordError("");
//     return true;
//   };

//   const validateConfirmPassword = (password: string, confirmPassword: string) => {
//     if (password !== confirmPassword) {
//       setPasswordError("Passwords do not match.");
//       return false;
//     }
//     setPasswordError("");
//     return true;
//   };

//   return (
//     // <Grid container item xs={12} mt={15}>
//     //   <Grid xs={2}></Grid>
//     <Box
//       // xs={5}
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <Box
//         className={classes.container}
//         sx={{ width: { xs: "90%", sm: "70%", md: "50%", lg: "30%" } }}
//         px={{ xs: 2, md: 6 }}
//       >
//         <Typography variant="h5" className={classes.promptText}>
//           {loginPromptText}
//         </Typography>

//         <Grid item xs={12} textAlign="center">
//           <Typography variant="h6" className={classes.emailmsg}>
//             Your new password should be different from
//           </Typography>
//           <Typography variant="h6" className={classes.emailmsg}>
//             passwords previously used.
//           </Typography>
//         </Grid>

//         {/* <Grid spacing={7} > */}
//         <Grid item xs={12} my={{ xs: 1, md: 3 }}>
//             <InputLabel className={classes.blackColor}>Password</InputLabel>
//             <PasswordTextFeildWithBorders
//               label={<span className={classes.boldLabel}>Enter new password</span>}
//               name="password"
//               placeholder=""
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               error={!!passwordError}
//               helperText={passwordError}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <InputLabel className={classes.blackColor}>
//               Confirm Password
//             </InputLabel>
//             <PasswordTextFeildWithBorders
//               label={<span className={classes.boldLabel}>Confirm Password</span>}
//               name="confirmPassword"
//               placeholder=""
//               value={confirmPassword}
//               onChange={(e) => setConfirmPassword(e.target.value)}
//               error={!!passwordError}
//               helperText={passwordError}
//             />
//           </Grid>
//           <Grid item xs={12} my={3}>
//             <ButtonComponent
//               title="Confirm"
//               className={classes.button}
//               onClick={handleConfirm}
//             />
//           </Grid>
//           <Grid item xs={12} className={classes.linkContainer}>
//             <Link href="/#/login">
//               <img alt="" src="/BackArrow.png" className={classes.arrowIcon} />
//             </Link>
//             <LinkComponent
//               href="/#/login"
//               title="Back to Login"
//               className={classes.backText}
//             />
//           </Grid>
//         {/* </Grid> */}
//       </Box>
//     </Box>
//     // </Grid>
//   );
// };

// export default NewPasswordForm;
