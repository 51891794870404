import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CONTACTS_APIS } from "../../../Apis/Contacts/ContactsApis";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
  error: any
}

const initialState: IData = {
  status: "idle",
  data: null,
  error: null
};

// export const createContact = createAsyncThunk(
//   "createContact",
//   async (data: any) => {
//     const response = await CONTACTS_APIS.createContact(data);
//     console.log(response,'response 18')
//     return response?.data;
//   }
// );
export const createContact = createAsyncThunk(
  "createContact",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await CONTACTS_APIS.createContact(data);
      return response?.data;
    } catch (error: any) {
      // Provide a descriptive error message
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const addContactSlice = createSlice({
  name: "addContactSlice",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createContact.pending, (state) => {
        state.status = "loading";
        // state.error = ""
        state.data = [];
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.status = "succeeded";
        // state.error = ""
        state.data = action.payload;
      })
      .addCase(createContact.rejected, (state, action) => {
        state.status = "failed";
        state.data = action.payload;
        // state.error = action.error.message || "";
      });
  },
});

export const addContactActions = addContactSlice.actions;
export default addContactSlice.reducer;
