import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DoneIcon from "@mui/icons-material/Done";
import DoneAll from "@mui/icons-material/DoneAll";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ReplyIcon from "@mui/icons-material/Reply";
import ErrorIcon from "@mui/icons-material/Error";
import { CreateButtonArray } from "../../TemplateComponents/TemplateForm/functions";
import { linkifyText } from "../../../utils/extractUrls";
import { Tooltip } from "@mui/material";
import ReplyBox from "./ReplyBox";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import renderMessageContent, { maxWidthStyle } from "./renderMessageContent";
import { useAppSelector } from "../../../utils/redux-hooks";
import { convertUtcToLocal } from "../../../utils/functions";
import ChatActionButtons from "./ChatActionButtonsComponent";

interface MessageProps {
  alignment: string;
  messages: any[];
  msg: any;
  index: number;
  currentResultIndex: number;
  searchText: string;
  classes: {
    senderMessage: string;
    receiverMessage: string;
    arrowMarkRight: string;
    arrowMarkLeft: string;
    replyMessage: string;
  };
  handleOpenMediaPopover: (msg: any) => void;
  handleReplyMessage: (msg: any) => void;
  bgColors?: any;
}

const ChatInterface: React.FC<MessageProps> = ({
  alignment,
  messages,
  msg,
  index,
  currentResultIndex,
  searchText,
  classes,
  handleOpenMediaPopover,
  handleReplyMessage,
  bgColors,
}) => {
  const messageRefs = useRef<(HTMLSpanElement | null)[]>([]);
  const userData = useAppSelector((state: any) => state?.adminLogin?.data);
  useEffect(() => {
    if (searchText) {
      const highlightedMessage = messageRefs.current.find((ref) =>
        ref?.querySelector('span[style*="background-color: yellow"]')
      );
      highlightedMessage?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [searchText, currentResultIndex]);

  const buttonsArray = CreateButtonArray({
    quickReplies: msg?.quickReplies || [],
    redirectUrls: msg?.redirectUrls || [],
    urlButtonNames: msg?.urlButtonNames || [],
    phoneNumber: msg?.phoneNumber,
    callButtonName: msg?.callButtonName,
  });

  const highlightText = (text: string) => {
    if (!searchText) return text;
    const regex = new RegExp(`(${searchText})`, "gi");
    return text.replace(
      regex,
      '<span style="background-color: yellow;">$1</span>'
    );
  };

  const renderMessageStatus = () => {
    switch (msg?.status) {
      case "failed":
        return (
          <Tooltip title="Failed to send message" placement="left" arrow>
            <ErrorIcon
              style={{ fontSize: "12px", marginLeft: 3, color: bgColors?.red1 }}
            />
          </Tooltip>
        );
      case "sending":
        return (
          <AccessTimeIcon
            style={{ fontSize: "12px", marginLeft: 3, color: "black" }}
          />
        );
      case "sent":
        return (
          <DoneIcon
            style={{ fontSize: "12px", marginLeft: 3, color: "black" }}
          />
        );
      case "delivered":
        return (
          <DoneAll
            style={{ fontSize: "12px", marginLeft: 3, color: "black" }}
          />
        );
      case "read":
        return (
          <DoneAll
            style={{
              fontSize: "12px",
              marginLeft: 3,
              color: bgColors?.blue || "#009688",
            }}
          />
        );
      default:
        return null;
    }
  };
  // console.log("userData", userData);
  // console.log("messages", msg);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: alignment === "right" ? "end" : "start",
        alignItems: "center",
        my: 1,
      }}
    >
      {alignment === "left" && (
        <Box mb={1} mr={0.5} mt="auto">
          <img
            src={"/images/profile.png"}
            height={24}
            width={24}
            alt="prf"
            style={{ borderRadius: "50%" }}
          />
        </Box>
      )}
      <Box sx={{ display: "block" }}>
        <Box sx={{ display: "flex" }}>
          {alignment === "right" && (
            <Box className={classes.arrowMarkRight}>
              <Tooltip title="Click to reply">
                <ReplyIcon
                  onClick={() => handleReplyMessage(msg)}
                  sx={{
                    cursor: "pointer",
                    color: bgColors?.gray3,
                    fontSize: "16px",
                  }}
                />
              </Tooltip>
            </Box>
          )}
          <Box mr={0}>
            <Box
              className={
                alignment === "right"
                  ? classes?.senderMessage
                  : classes?.receiverMessage
              }
            >
              <span
                style={{ fontSize: "13px", wordBreak: "break-word" }}
                key={msg.id}
              >
                {
                  msg?.reply && (
                    // messages?.filter(
                    //   (item) => item?.whatsAppMessageId === msg?.replyId
                    // ).length > 0
                    //   ? messages
                    //       ?.filter(
                    //         (item) => item?.whatsAppMessageId === msg?.replyId
                    //       )
                    //       ?.map((item) => (
                    <div className={classes.replyMessage}>
                      <ReplyBox
                        maxStyle={{
                          maxHeightStyle: "",
                          maxWidthStyle: maxWidthStyle,
                        }}
                        reply={msg?.reply}
                      />
                    </div>
                  )
                  //     ))
                  // : ""
                }
                {renderMessageContent(
                  messages[index],
                  index,
                  messageRefs,
                  handleOpenMediaPopover,
                  highlightText,
                  linkifyText,
                  buttonsArray
                )}
              </span>
            </Box>
            {msg?.action && (
              <ChatActionButtons
                buttons={msg.action.buttons}
                button={msg.action.button}
                bgColors={bgColors}
              />
            )}
            <Box
              display="flex"
              sx={{
                alignItems: "center",
                justifyContent: alignment === "right" ? "end" : "start",
                ml: alignment === "right" ? "0px" : "4px",
              }}
            >
              <Typography sx={{ fontSize: "9px", color: "gray" }}>
                {new Date(
                  new Date(convertUtcToLocal(msg?.createdAt))?.getTime()
                ).toLocaleTimeString("en-UK", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Typography>

              {/* {msg?.displayTime && ( */}
              {/* <Typography sx={{ fontSize: "9px", color: "gray" }}>
                {new Date(
                  new Date(msg?.createdAt).getTime() + 5.5 * 60 * 60 * 1000
                ).toLocaleTimeString("en-UK", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Typography> */}
              {/* )} */}

              {alignment === "right" && renderMessageStatus()}
            </Box>
          </Box>
          {alignment === "left" && (
            <Box className={classes.arrowMarkLeft}>
              <Tooltip title="Click to reply">
                <ReplyIcon
                  onClick={() => handleReplyMessage(msg)}
                  sx={{
                    cursor: "pointer",
                    color: bgColors?.gray3,
                    fontSize: "16px",
                  }}
                />
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>
      {alignment === "right" && (
        <Box mb={1} ml={0.5} mt="auto">
          <img
            src={
              localStorage.getItem("userImage")
                ? localStorage.getItem("userImage")
                : userData?.userImage || "/images/profile.png"
            }
            height={20}
            width={20}
            alt="prf"
            style={{ borderRadius: "50%" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ChatInterface;
