import React from "react";

const ChatUserCloseSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99967 1.3335C4.33301 1.3335 1.33301 4.3335 1.33301 8.00016C1.33301 11.6668 4.33301 14.6668 7.99967 14.6668C11.6663 14.6668 14.6663 11.6668 14.6663 8.00016C14.6663 4.3335 11.6663 1.3335 7.99967 1.3335ZM10.4663 9.5335C10.733 9.80016 10.733 10.2002 10.4663 10.4668C10.1997 10.7335 9.79967 10.7335 9.53301 10.4668L7.99967 8.9335L6.46634 10.4668C6.19967 10.7335 5.79967 10.7335 5.53301 10.4668C5.26634 10.2002 5.26634 9.80016 5.53301 9.5335L7.06634 8.00016L5.53301 6.46683C5.26634 6.20016 5.26634 5.80016 5.53301 5.5335C5.79967 5.26683 6.19967 5.26683 6.46634 5.5335L7.99967 7.06683L9.53301 5.5335C9.79967 5.26683 10.1997 5.26683 10.4663 5.5335C10.733 5.80016 10.733 6.20016 10.4663 6.46683L8.93301 8.00016L10.4663 9.5335Z"
        fill="#4B5A5A"
      />
    </svg>
  );
};

export default ChatUserCloseSvg;
