import { Box, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { bgColors } from "../../../utils/bgColors";
// import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import {
  fetchManageNotifications,
  // PostManageNotifications,
} from "../../../redux/slices/ManageNotifications/ManageNotificationsSlice";
// import CenteredContent from "../../../components/common/comingSoon";
import LoadingComponent from "../../../components/common/LoadingComponent";
import { toastActions } from "../../../utils/toastSlice";
// import { useMutation, useQueryClient } from "react-query";
// import { MANAGE_NOTIFICATIONS_APIS } from "../../../Apis/AdminLogin/ManageNotifications/ManageNotifications";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { hasManageNotificationsPermission } from "../../../utils/permissions";
import NoAccessPage from "../../../components/common/NoAccess";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100%",
    width: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  account: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "8px 0 0 0",
  },
  switch: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "14px !important",
    color: `${bgColors.black1} !important`,
    // opacity: "60% !important",
  },
  lineStyles: {
    border: "none",
    borderTop: `1px solid ${bgColors.gray2}`,
    width: "100%",
    margin: "0 auto",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
});

const ManageNotifications = () => {
  const classes = useStyles();
  // const params = useParams();
  const dispatch = useAppDispatch();

  const userInfoSlice = useAppSelector((state:any) => state.adminLogin);
  // console.log(userInfoSlice, 'userInfoSlicee');
  const userId = userInfoSlice?.data?.userId;
  // console.log(userId, 'userId')
  const notifications = useAppSelector((state:any) => state?.manageNotifications);
  // console.log(notifications, 'notificationss')
  const notificationData = notifications?.data;
  const getuserPermissionData = useAppSelector(
    (state:any) => state.getUserPermissions?.data
  );
  const accountData = useAppSelector((state:any) => state?.accountData?.data);
  const currentPlanDetails = useAppSelector(
    (state:any) => state?.wallet?.walletAndSubscription?.data
  );
  const getNotificationStatus = notifications?.status;

  const [localNotifications, setLocalNotifications] = useState([]);

  const notificationsPermissionObject = getuserPermissionData?.profile?.find(
    (item: any) =>
      Object?.prototype?.hasOwnProperty?.call(item, "manageNotifications")
  );
  const notificationsPermissionActions = notificationsPermissionObject
    ? notificationsPermissionObject?.manageNotifications
    : [];
  const hasNotificationsPermission = hasManageNotificationsPermission(
    getuserPermissionData?.profile
  );
  // console.log("notificationsPermissionActions", notificationsPermissionActions);

  const hasAccess = (permission: any) => {
    if (notificationsPermissionActions?.includes(permission)) {
      return true;
    }
    return false;
  };
  // const queryClient = useQueryClient();

  // const { mutate: updateNotificationStatus } = useMutation(
  //   async (data: any) => {
  //     // Make API call to update notification status
  //     const response = await MANAGE_NOTIFICATIONS_APIS.postAllNotifications(
  //       data
  //     );
  //     return response.data; // Return data from the response
  //   },
  //   {
  //     onSuccess: () => {
  //       // Invalidate query to refetch updated data
  //       queryClient.invalidateQueries("notifications");
  //     },
  //     onError: (error) => {
  //       // Handle error gracefully
  //       dispatch(toastActions.setToaster({ type: "error", message: "failed" }));
  //     },
  //   }
  // );

  useEffect(() => {
    if (notificationData) {
      setLocalNotifications(notificationData);
    }
  }, [notificationData]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, checked } = event.target;
      // const data = {
      //   userId: userId,
      //   companyId: userInfoSlice?.data?.companyId,
      //   notificationName: name,
      //   isActive: checked,
      // };
      // const mutationResult = updateNotificationStatus(data);
      setLocalNotifications((prevNotifications: any) =>
        prevNotifications.map((mainMenu: any) => ({
          ...mainMenu,
          subMenus: mainMenu.subMenus.map((subMenu: any) =>
            subMenu.name === name ? { ...subMenu, status: checked } : subMenu
          ),
        }))
      );
      dispatch(
        toastActions.setToaster({
          type: "success",
          message: "Notification updated successfully.",
        })
      );
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  useEffect(() => {
    dispatch(
      fetchManageNotifications({
        userId: userId,
        companyId: userInfoSlice?.data?.companyId,
      })
    );
  }, [dispatch]);

  return (
    <>
      {currentPlanDetails?.subscriptionPlan?.isActive &&
      (accountData?.isMetaEnabled ||
        (accountData?.isMetaEnabled === false &&
          currentPlanDetails?.subscriptionPlan?.planName === "Intro")) &&
      hasNotificationsPermission ? (
        <Grid className={classes.mainContainer}>
          {getNotificationStatus === "loading" ? (
            <LoadingComponent height="100%" color={bgColors.blue} />
          ) : (
            <>
              {notificationData?.length ? (
                <Grid className={classes.bgContainer}>
                  <Box
                    py={{ xs: 1, md: 4 }}
                    px={{ xs: 1, md: 4 }}
                    className={classes.manageContainer}
                  >
                    <Typography
                      variant="h6"
                      className={classes.blackColor}
                      sx={{ display: "flex" }}
                    >
                      <span style={{ marginTop: "3px" }}>
                        <CircleNotificationsIcon sx={{ height: "20px" }} />
                      </span>
                      &nbsp;Manage Notifications
                    </Typography>
                  </Box>
                  <Box px={4} py={1}>
                    {localNotifications?.map(
                      (eachNotification: any, index: number) => (
                        <Box key={index}>
                          <Typography
                            sx={{ fontSize: { xs: 10, md: 16 } }}
                            className={classes.blackColor}
                          >
                            {eachNotification.mainMenu}
                          </Typography>
                          <Box className={classes.account}>
                            {eachNotification?.subMenus?.map(
                              (eachSub: any, index: number) => (
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  key={index}
                                >
                                  <Box>
                                    <Typography className={classes.switch}>
                                      {eachSub?.name}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <FormControlLabel
                                      label=""
                                      control={
                                        <Switch
                                          checked={eachSub?.status}
                                          // defaultChecked
                                          onChange={handleChange}
                                          name={eachSub?.name}
                                          disabled={!hasAccess("statusChanges")}
                                          color="success"
                                          sx={{
                                            "&.Mui-unchecked  .MuiSwitch-thumb":
                                              {
                                                color: "white",
                                              },
                                            "& .MuiSwitch-track": {
                                              backgroundColor: "#3CAA93",
                                            },
                                            "&.Mui-checked .MuiSwitch-thumb": {
                                              color: "green",
                                            },
                                            "&.Mui-checked + .MuiSwitch-track":
                                              {
                                                backgroundColor: "green",
                                              },
                                          }}
                                        />
                                      }
                                    />
                                  </Box>
                                </Box>
                              )
                            )}
                          </Box>
                          <hr className={classes.lineStyles} />
                        </Box>
                      )
                    )}
                  </Box>
                </Grid>
              ) : (
                <Grid className={classes.bgContainer}>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    No Notifications Data
                  </Typography>{" "}
                </Grid>
              )}
            </>
          )}
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

export default ManageNotifications;
