import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import MediaPopover from "./MediaPopover";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import ArchiveIcon from "@mui/icons-material/Archive";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import { bgColors } from "../../../utils/bgColors";
import { linkifyText } from "../../../utils/extractUrls";
import { HiExternalLink } from "react-icons/hi";
import CustomAudioPlayer from "./AudioComponent";

export default function SharedMedia({
  open,
  onClose,
  sharedMedia,
  sharedDocuments,
  sharedLinks,
}: {
  open: boolean;
  onClose: () => void;
  sharedMedia: any;
  sharedDocuments: any;
  sharedLinks: any;
}) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [media, setMedia] = React.useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleOpenMediaPopover = (event: any) => {
    // console.log("event: ", event);
    setOpenDialog(true);
    setMedia(event);
  };

  const handleCloseMediaPopover = () => {
    setOpenDialog(false);
  };

  // Helper function to get icon based on file type
  const getFileIcon = (url: string) => {
    if (url.endsWith(".pdf")) {
      return <PictureAsPdfIcon sx={{ height: "14px", color: "red" }} />;
    } else if (url.endsWith(".doc") || url.endsWith(".docx")) {
      return <DescriptionIcon sx={{ height: "14px", color: "blue" }} />;
    } else if (
      url.endsWith(".jpg") ||
      url.endsWith(".jpeg") ||
      url.endsWith(".png")
    ) {
      return <ImageIcon sx={{ height: "14px" }} />;
    } else if (url.endsWith(".zip") || url.endsWith(".rar")) {
      return <ArchiveIcon sx={{ height: "14px" }} />;
    } else {
      return <InsertDriveFileIcon sx={{ height: "14px", color: "green" }} />;
    }
  };

  // console.log("sharedMedia", sharedMedia);
  // console.log("sharedDocuments", sharedDocuments);
  // console.log("sharedLinks", sharedLinks);

  return (
    <Drawer anchor={"right"} open={open} onClose={onClose}>
      <Box sx={{ width: "30vw", padding: theme.spacing(2) }}>
        <Box
          onClick={onClose}
          sx={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* <ChatUserDetailsCloseIcon /> */}
          <CloseSvg />
        </Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="shared content tabs"
          variant="fullWidth"
          sx={{
            marginBottom: "16px",
          }}
        >
          <Tab label="Media" />
          <Tab label="Documents" />
          <Tab label="Links" />
          {/* <Tab label="Products" /> */}
        </Tabs>
        {value === 0 && (
          <>
            {sharedMedia?.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  flexGrow: 1,
                  overflowX: "hidden",
                  flexWrap: "wrap",
                }}
              >
                {sharedMedia?.map((media: any, index: any) => {
                  const isVideo = media?.mediaUrl?.endsWith(".mp4");
                  const isAudio =
                    media?.mediaUrl?.endsWith(".mp3") ||
                    media?.mediaUrl?.endsWith(".ogg") ||
                    media?.mediaUrl?.endsWith(".amr") ||
                    media?.mediaUrl?.endsWith(".arr");
                  return (
                    <Box key={index}>
                      <Box
                      // Additional styling can be added here
                      />
                      {isVideo ? (
                        <video
                          src={media?.mediaUrl}
                          style={{
                            width: "125px",
                            height: "100px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            border: "1px solid #cdcdcd",
                          }}
                          controls
                          onClick={() =>
                            handleOpenMediaPopover(media?.mediaUrl)
                          }
                        />
                      ) : isAudio ? (
                        <CustomAudioPlayer mediaUrl={media?.mediaUrl} />
                      ) : (
                        <img
                          src={media?.mediaUrl}
                          alt={`media-${index}`}
                          style={{
                            width: "125px",
                            height: "100px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            border: "1px solid #cdcdcd",
                          }}
                          onClick={() =>
                            handleOpenMediaPopover(media?.mediaUrl)
                          }
                        />
                      )}
                    </Box>
                  );
                })}
                <MediaPopover
                  open={openDialog}
                  media={media}
                  onClose={handleCloseMediaPopover}
                  sharedMedia={media}
                />
              </Box>
            ) : (
              <Typography sx={{ textAlign: "center" }}>No Media</Typography>
            )}
          </>
        )}
        {value === 1 && (
          <>
            {sharedDocuments?.length > 0 ? (
              <Box>
                {sharedDocuments?.map((document: any, index: any) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: theme.spacing(0),
                      width: "100%",
                      borderBottom: "1px solid #fff",
                      padding: "5px",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.boxShadow =
                        "0px 4px 8px rgba(0, 0, 0, 0.1)";
                      e.currentTarget.style.borderRadius = "5px";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.boxShadow = "none";
                      e.currentTarget.style.borderRadius = "none";
                    }}
                  >
                    <a
                      href={document?.mediaUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        color: theme.palette.text.primary,
                        marginLeft: theme.spacing(1),
                        width: "100%",
                      }}
                    >
                      {" "}
                      {getFileIcon(document?.mediaUrl)}
                      <Typography sx={{ fontSize: 13 }}>
                        {document?.mediaFileName}
                      </Typography>
                    </a>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography sx={{ textAlign: "center" }}>No Documents</Typography>
            )}
          </>
        )}
        {value === 2 && (
          <>
            {sharedLinks?.length > 0 ? (
              <Box>
                {sharedLinks?.map((link: any, index: number) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: theme.spacing(0),
                      fontSize: 13,
                      borderBottom: "1px solid #fff",
                      padding: "5px",
                      "&:hover": {
                        color: bgColors.blue,
                        textDecoration: "none",
                      },
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.boxShadow =
                        "0px 4px 8px rgba(0, 0, 0, 0.1)";
                      e.currentTarget.style.borderRadius = "5px";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.boxShadow = "none";
                      e.currentTarget.style.borderRadius = "none";
                    }}
                  >
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none !important",
                        // color: bgColors.blue,
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <HiExternalLink
                        style={{ marginLeft: "5px", marginRight: "5px" }}
                      />
                      <span
                        style={{ textDecoration: "none" }}
                        dangerouslySetInnerHTML={{
                          __html: linkifyText(link?.replace(/\n/g, "<br/>")),
                        }}
                      />
                    </a>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography sx={{ textAlign: "center" }}>No Links</Typography>
            )}
          </>
        )}
      </Box>
    </Drawer>
  );
}
