import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MANAGE_SUBSCRIPTION_APIS } from "../../../Apis/Subscription/Subscription";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
  getAllPlansData: any;
  freeSubscriptionPlanData: any;
  orderId: any;
  currentPlan: any;
  subscriptionDeductions: any;
  subscriptionDeductionsStatus: any;
  createOrderData: any;
  createOrderStatus: any;
  planDetailsByPlanId: any;
  planDetailsStatus: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
  getAllPlansData: null,
  freeSubscriptionPlanData: null,
  orderId: null,
  currentPlan: null,
  subscriptionDeductions: null,
  subscriptionDeductionsStatus: null,
  createOrderData: null,
  createOrderStatus: null,
  planDetailsByPlanId: null,
  planDetailsStatus: null,
};

export const fetchSubscriptionPlans = createAsyncThunk(
  "fetchSubscriptionPlans",
  async () => {
    const response = await MANAGE_SUBSCRIPTION_APIS.getAllSubscriptionPlans();
    // console.log("response", response);
    return response?.data;
  }
);

export const postSubscriptionPlan = createAsyncThunk(
  "postSubscriptionPlan",
  async (data: any, { rejectWithValue }) => {
    // console.log(data)
    try {
      const response = await MANAGE_SUBSCRIPTION_APIS.postSubscriptionPlan(
        data
      );
      // console.log("response", response);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const fetchCurrentPlanDetails = createAsyncThunk(
  "fetchCurrentPlanDetails",
  async (companyId: any, { rejectWithValue }) => {
    try {
      const response = await MANAGE_SUBSCRIPTION_APIS.getCurrentplanDetails(
        companyId
      );
      // console.log("response", response);
      return response?.data;
    } catch (error: any) {
      // console.log(error)
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const fetchSubscriptionDeductions = createAsyncThunk(
  "fetchsubscriptionDeductions",
  async (
    // { startDate, endDate }: { startDate: string; endDate: string },
    _,
    { rejectWithValue }
  ) => {
    try {
      const response = await MANAGE_SUBSCRIPTION_APIS
        .subscriptionDeductions
        // {
        // startDate,
        // endDate,
        // }
        ();
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const subscriptionInvoice = createAsyncThunk(
  "subscriptionInvoice",
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await MANAGE_SUBSCRIPTION_APIS.subscriptionInvoice(id);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const fetchAllPlans = createAsyncThunk(
  "fetchAllPlans",
  async (
    { planType, companyId }: { planType: string; companyId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await MANAGE_SUBSCRIPTION_APIS.getAllPlans(
        planType,
        companyId
      );
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const fetchFreeSubscriptionPlan = createAsyncThunk(
  "fetchFreeSubscriptionPlan",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await MANAGE_SUBSCRIPTION_APIS.getFreeSubscriptionPlan(
        data
      );
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const fetchCreateOrder = createAsyncThunk(
  "getCreateOrder",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await MANAGE_SUBSCRIPTION_APIS.getCreateOrder(data);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const fetchPlanDetailsByPlanId = createAsyncThunk(
  "getDetailsByPlanId",
  async (
    {
      PlanId,
      planType,
      companyId,
      isUpgradePlan,
    }: {
      PlanId: number;
      planType: string;
      companyId: string;
      isUpgradePlan: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await MANAGE_SUBSCRIPTION_APIS.getPlanDetailsByPlanId(
        PlanId,
        planType,
        companyId,
        isUpgradePlan
      );
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data?.message || "An error occured"
      );
    }
  }
);
export const SubscriptionSlice = createSlice({
  name: "SubscriptionSlice",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSubscriptionPlans.pending, (state) => {
        state.status = "loading";
        state.data = [];
      })
      .addCase(fetchSubscriptionPlans.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchSubscriptionPlans.rejected, (state) => {
        state.status = "failed";
        state.data = [];
      })
      .addCase(postSubscriptionPlan.pending, (state) => {
        state.status = "loading";
        // state.orderId = [];
      })
      .addCase(postSubscriptionPlan.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orderId = action.payload;
      })
      .addCase(postSubscriptionPlan.rejected, (state) => {
        state.status = "failed";
        state.orderId = [];
      })
      .addCase(fetchCurrentPlanDetails.pending, (state) => {
        state.status = "loading";
        state.currentPlan = [];
      })
      .addCase(fetchCurrentPlanDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.currentPlan = action.payload;
      })
      .addCase(fetchCurrentPlanDetails.rejected, (state, action) => {
        state.status = "failed";
        state.currentPlan = action.payload;
      })
      .addCase(fetchSubscriptionDeductions.pending, (state) => {
        state.subscriptionDeductionsStatus = "loading";
        state.subscriptionDeductions = [];
      })
      .addCase(fetchSubscriptionDeductions.fulfilled, (state, action) => {
        state.subscriptionDeductionsStatus = "succeeded";
        state.subscriptionDeductions = action.payload;
      })
      .addCase(fetchSubscriptionDeductions.rejected, (state) => {
        state.subscriptionDeductionsStatus = "failed";
        state.subscriptionDeductions = [];
      })
      .addCase(fetchAllPlans.pending, (state) => {
        state.status = "loading";
        state.getAllPlansData = [];
      })
      .addCase(fetchAllPlans.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.getAllPlansData = action?.payload;
      })
      .addCase(fetchAllPlans.rejected, (state) => {
        state.status = "failed";
        state.getAllPlansData = [];
      })
      .addCase(fetchFreeSubscriptionPlan.pending, (state) => {
        state.status = "loading";
        state.freeSubscriptionPlanData = [];
      })
      .addCase(fetchFreeSubscriptionPlan.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.freeSubscriptionPlanData = action?.payload;
      })
      .addCase(fetchFreeSubscriptionPlan.rejected, (state) => {
        state.status = "failed";
        state.freeSubscriptionPlanData = [];
      })
      .addCase(fetchCreateOrder.pending, (state) => {
        state.createOrderData = [];
        state.createOrderStatus = "loading";
      })
      .addCase(fetchCreateOrder.fulfilled, (state, action) => {
        state.createOrderData = action.payload;
        state.createOrderStatus = "succeeded";
      })
      .addCase(fetchCreateOrder.rejected, (state) => {
        state.createOrderData = [];
        state.createOrderStatus = "failed";
      })
      .addCase(fetchPlanDetailsByPlanId.pending, (state) => {
        state.planDetailsByPlanId = [];
        state.planDetailsStatus = "loading";
      })
      .addCase(fetchPlanDetailsByPlanId.fulfilled, (state, action) => {
        state.planDetailsByPlanId = action.payload;
        state.planDetailsStatus = "succeeded";
      })
      .addCase(fetchPlanDetailsByPlanId.rejected, (state) => {
        state.planDetailsByPlanId = [];
        state.planDetailsStatus = "failed";
      });
  },
});

export const SubscriptionActions = SubscriptionSlice.actions;
export default SubscriptionSlice.reducer;
