import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import ContactsIconSvg from "../../assets/svgs/ContactsIconSvg";
import ArrowDownSvg from "../../assets/svgs/ArrowDownSvg";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ContactsFilterPopover from "../../components/ContactsComponents/ContactsFilterPopOver";
// import CamapignNotificationSvg from "../../assets/svgs/CamapignNotificationSvg";
import MoveToTagsPopover from "../../components/ContactsComponents/MoveToTagsPopOver";
import NewContactPopOver from "../../components/ContactsComponents/NewContactPopOver";
// import ExportWhiteIcon from "../../assets/svgs/ExportWhiteIcon";
// import { BorderColor, Delete } from "@mui/icons-material";
import EditIconSvg from "../../assets/svgs/EditIconSvg";
import DeleteIconSvg from "../../assets/svgs/DeleteIconSvg";
import EditPopOver from "../../components/ContactsComponents/EditPopOver";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { fetchAllContacts } from "../../redux/slices/Contacts/AllContactsSlice";
import { CONTACTS_APIS } from "../../Apis/Contacts/ContactsApis";
// import ContactsPaginations from "../../components/common/PaginationComponent";
import { toast } from "react-toastify";
import ImportContactsPopUp from "../../components/ContactsComponents/ImportContactsPopUp";
import { toastActions } from "../../utils/toastSlice";
import SearchIconSvg2 from "../../assets/svgs/SearchIconSvg2";
import LoadingComponent from "../../components/common/LoadingComponent";
import { LoadingButton } from "@mui/lab";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import ImportSvg from "../../assets/svgs/importSvg";
import EditCampaign from "../../components/ScheduledComponents/EditCampaign";
import { fetchInboxContacts } from "../../redux/slices/Inbox/InboxContactsSlice";
import DeletePopUp from "../../components/common/DeletePopup";
import useDebouncedFetch from "../../utils/debounceHook";
import { LuImport } from "react-icons/lu";
import { CgExport } from "react-icons/cg";
import { MdDriveFileMoveOutline } from "react-icons/md";
import { removeContact } from "../../redux/slices/Contacts/DeleteContactSlice";
import NoAccessPage from "../../components/common/NoAccess";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          // Adjust the default padding for all TableCell components
          padding: "0px", // Set your desired padding value here
        },
      },
    },
  },
});

const useStyles = makeStyles({
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    overflow: "100%",
    // height:"100vh !important",
    height: "calc(100vh - 3px)",
    width: "100%",
    // overflowY:"hidden",
    // scrollbarWidth:"none"
  },
  manageTeamContainer: {
    display: "flex",
    alignItems: "center",
    width: "full",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    // fontWeight: "600 !important",
  },
  searchField: {
    width: "100%",
    borderRadius: "12px",
    // height: "38px",
    // backgroundColor: bgColors.white2,
    backgroundColor: "white",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "inter",
      color: "#000000 !important",
    },
  },
  SaveChangesButton: {
    // backgroundColor: bgColors.green,
    // color: bgColors.white,
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    // width: "fit-content",
    width: "140px",
    height: "32px",
    // padding: "10px",
    cursor: "pointer",
  },
  messageCount: {
    backgroundColor: bgColors?.gray5,
    color: bgColors?.black,
    borderRadius: "24px",
    height: "24px",
    width: "34px",
    fontSize: "10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tagsCount: {
    backgroundColor: "#F4F4F4",
    borderRadius: "24px",
    padding: "3px",
    height: "25px",
    width: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
  messageCountContainer: {
    border: "1px solid #F2F2F2",
    cursor: "pointer",
    borderRadius: "5px",
    paddingInline: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  MuiLoadingButtonRoot: {
    backgroundColor: "#FFF",
  },
  campaignCountContainer: {
    // border: `1px solid ${bgColors.green}`,
    cursor: "pointer",
    color: bgColors.black,
    borderRadius: "8px",
    paddingInline: "8px",
    display: "flex",
    flexDirection: "row",
    height: "34px",
    alignItems: "center",
  },
  campaignCountContainer1: {
    border: "1px solid #F2F2F2",
    color: bgColors.green,
    borderRadius: "20px",
    paddingInline: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  messageInnerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  grayColor: {
    color: "#303030",
    opacity: "60%",
    fontSize: "13px",
    // padding:"5px"
  },
  grayColor1: {
    color: "#303030",
    opacity: "90%",
    fontSize: "13px",
    // padding:"5px"
  },
  campaignColor: {
    color: bgColors.green,
    // color: "#007aff",
    fontSize: "13px",
    fontWeight: "Semi Bold",
    // padding:"5px"
  },
  iconStyles: {
    cursor: "pointer",
    paddingLeft: "5px",
    alignItems: "center",
    // marginTop: "2px",
    color: "#ffffff",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0",
    textAlign: "center",
    borderColor: "lightgray",
    "& th, & td": {
      // borderTop: '1px solid gray',
      borderBottom: "1px solid #f0f0f0",
    },
    "& th:first-child, & td:first-child": {
      borderLeft: "none",
    },
    "& th:last-child, & td:last-child": {
      borderRight: "none",
    },
  },
  teamProfileContainer: {
    display: "flex",
    alignItems: "center",
  },
  editButtonContainer: {
    border: "2px solid #DBDBDB",
    padding: "8px",
    borderRadius: "12px",
    backgroundColor: "#F4F4F4",
    width: "50px",
    // paddingBottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
});

const Contacts = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const contactsSlice = useAppSelector((state: any) => state?.contactsData);
  const userData = userProfileSlice?.data;
  const getuserPermissionData = useAppSelector(
    (state: any) => state.getUserPermissions?.data
  );
  const currentPlanDetails = useAppSelector(
    (state: any) => state?.wallet?.walletAndSubscription?.data
  );
  const accountData = useAppSelector((state: any) => state?.accountData?.data);
  const getContactTag = useAppSelector(
    (state: any) => state?.getContactTagsData?.data
  );
  const contactsData = contactsSlice?.data;
  const manageContactsObject = getuserPermissionData?.contacts;
  const debouncedFetchContacts = useDebouncedFetch(fetchAllContacts, 1500);
  // console.log(manageContactsObject, "manageContactsObject");
  const [anchorEl, setAnchorEl] = useState(null);
  const [editContact, setEditContact] = useState(null);
  const [anchorelMove, setAnchorElMove] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("Tags");
  const [openDialog, setOpenDialog] = useState(false);
  const [openCampaignDailog, setOpenCampaignDailog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [getId, setGetId] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteSelectedDialog, setDeleteSelectedDialog] = useState(false);
  const [teamData, setTeamData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [isHovered, setIsHovered] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchContactsQuery, setSearchContactsQueries] = useState<string>("");
  const [addContactTooltip, setAddContactTooltip] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [importTooltip, setImportTooltip] = useState(false);
  const [exportTooltip, setExportTooltip] = useState(false);
  const [exportByIdTooltip, setExportByIdTooltip] = useState(false);
  const [editContactsTooltip, setEditContactsTooltip] = useState(null);
  const [deleteContactsTooltip, setDeleteContactsTooltip] = useState(null);
  const [deleteContactsByIdTooltip, setDeleteContactsByIdTooltip] =
    useState(false);
  const [filterData, setFilterData] = useState<any>("");
  const [isEditContactLoading, setIsEditContactLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState<
    Array<(typeof teamData)[number]>
  >([]);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [allSelected] = useState(false);
  const [isExportAllLoading, setIsExportAllLoading] = useState(false);
  const [isExportAllByIdLoading, setIsExportAllByIdLoading] = useState(false);

  const hasAddContactPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "addNewContact")) {
        return true;
      }
    }
  };

  // const hasSendCampaignPermission = (permission: any) => {
  //   for (const profileItem of permission) {
  //     if (profileItem.hasOwnProperty("sendCampaign")) {
  //       return true;
  //     }
  //   }
  // };

  // const handleSendCampaign = () => {
  //   const hasPermission = hasSendCampaignPermission(manageContactsObject);
  //   if (hasPermission) {
  //     setOpenCampaignDailog(true);
  //   } else {
  //     setSendCampaignTooltip(true);
  //     setTimeout(() => setSendCampaignTooltip(false), 3000);
  //   }
  // };

  const hasImportPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "import")) {
        return true;
      }
    }
  };

  const hasExportPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "export")) {
        return true;
      }
    }
  };

  const hasDeleteContactPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "deleteContacts")) {
        return true;
      }
    }
  };

  const hasExportByIdPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "exportById")) {
        return true;
      }
    }
  };

  const hasEditContactPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "editContacts")) {
        return true;
      }
    }
  };

  const handleFilterClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterMove = (event: any) => {
    setAnchorElMove(event.currentTarget);
  };
  const handleCloseMove = () => {
    setAnchorElMove(null);
  };
  const handleOpenDelete = (id: any) => {
    const hasPermission = hasDeleteContactPermission(manageContactsObject);
    if (hasPermission) {
      setDeleteDialog(true);
      setGetId(id);
    } else {
      setDeleteContactsTooltip(id);
      setTimeout(() => {
        setDeleteContactsTooltip(null);
      }, 2000);
    }
  };
  const handleCloseDelete = () => {
    setDeleteDialog(false);
  };

  const handleCloseSelectedDelete = () => {
    setDeleteSelectedDialog(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOptionClick = (option: string) => {
    setSelectedFilter(option);
    handleClose();
  };

  const handleOptionClickMove = () => {
    // setSelect(option);
    handleCloseMove();
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchContacts = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchContactsQueries(event.target.value);
  };

  const filteredTags = getContactTag?.filter((tag: any) =>
    tag?.tag?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // console.log("filteredTags", getContactTag);
  // console.log(getId, "getId");

  const handleDelete = async () => {
    setIsDeleteLoading(true);

    try {
      const body = {
        BusinessId: userData?.companyId,
        userId: userData?.userId,
        data: [getId],
      };
      setCheckedItems([]);

      const deleteResponse: any = await dispatch(removeContact(body));
      if (deleteResponse?.meta?.requestStatus === "fulfilled") {
        setDeleteDialog(false);
        setGetId(null);

        const bodyData = {
          businessId: userData?.companyId,
          userId: userData?.userId,
          page: 1,
          per_page: 40,
        };
        dispatch(fetchAllContacts(bodyData));

        dispatch(
          toastActions.setToaster({
            type: "success",
            message: `${deleteResponse?.payload?.message}`,
          })
        );
      } else {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `${deleteResponse?.payload?.message}`,
          })
        );
      }
    } catch (error: any) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: `${error?.response?.data?.message}`,
        })
      );
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const handleDeleteById = async () => {
    const body = {
      BusinessId: userData?.companyId,
      userId: userData?.userId,
      data: checkedItems.map((item) => item.contactId),
    };
    setIsDeleteLoading(true);
    const deleteResponse = await CONTACTS_APIS.deleteContacts(body);
    if (deleteResponse?.status === 200) {
      setDeleteDialog(false);
      setDeleteSelectedDialog(false);
      setGetId(null);
      const bodyData = {
        businessId: userData?.companyId,
        userId: userData?.userId,
        page: 1,
        per_page: 40,
        filters: {},
      };
      dispatch(fetchAllContacts(bodyData));
      // console.log("mesage", deleteResponse?.data?.message);
      // toast.success(deleteResponse?.data?.message);
      dispatch(
        toastActions.setToaster({
          type: "success",
          message: `${deleteResponse?.data?.message}`,
        })
      );
    } else {
      toast.error(deleteResponse?.data?.message);
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: `${deleteResponse?.data?.message}`,
        })
      );
    }
    setCheckedItems([]);
    setIsDeleteLoading(false);
  };

  const handleDeleteAll = () => {
    const hasPermission = hasDeleteContactPermission(manageContactsObject);
    if (hasPermission) {
      setDeleteSelectedDialog(true);
    } else {
      setDeleteContactsByIdTooltip(true);
      setTimeout(() => {
        setDeleteContactsByIdTooltip(false);
      }, 2000);
    }
  };

  const handleOpenDialog = () => {
    const hasPermission = hasAddContactPermission(manageContactsObject);
    if (hasPermission) {
      setOpenDialog(true);
    } else {
      setAddContactTooltip(true);
      setTimeout(() => {
        setAddContactTooltip(false);
      }, 2000);
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseCampaignDailog = () => {
    setOpenCampaignDailog(false);
  };

  const handleOpenEditDialog = (row?: any) => {
    const hasPermission = hasEditContactPermission(manageContactsObject);
    if (hasPermission) {
      if (row !== undefined && row !== null) {
        setEditContact(row);
      }
      setOpenEditDialog(true);
    } else {
      setEditContactsTooltip(row);
      setTimeout(() => {
        setEditContactsTooltip(null);
      }, 2000);
    }
  };
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  // console.log("checkedItems", checkedItems.map(item => item.contactId));

  const handleExport = async () => {
    const hasPermission = hasExportPermission(manageContactsObject);
    if (hasPermission) {
      const data = {
        businessId: userData?.companyId,
        userId: userData?.userId,
      };
      try {
        setIsExportAllLoading(true);
        const exportResponse = await CONTACTS_APIS.exportContacts(data);
        // Extract the binary data from the AxiosResponse object
        const blob = new Blob([exportResponse.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = url;
        link.download = "exported_contacts.xlsx";
        link.style.display = "none";
        document.body.appendChild(link);

        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        setIsExportAllLoading(false);
        // console.log('Exported contacts:', exportResponse);
      } catch (error) {
        console.error("Error exporting contacts:", error);
        setIsExportAllLoading(false);
      }
    } else {
      setExportTooltip(true);
      setTimeout(() => {
        setExportTooltip(false);
      }, 2000);
      setIsExportAllLoading(false);
    }
  };

  const handleExportById = async () => {
    const hasPermission = hasExportByIdPermission(manageContactsObject);
    if (hasPermission) {
      if (checkedItems?.length > 0) {
        const data = {
          businessId: userData?.companyId,
          userId: userData?.userId,
          contactId: checkedItems.map((item) => item.contactId),
        };
        try {
          setIsExportAllByIdLoading(true);
          const getResponseById = await CONTACTS_APIS.exportContactsById(data);
          const blob = new Blob([getResponseById.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          console.log(getResponseById.data);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");

          link.href = url;
          link.download = "exported_contacts.xlsx";
          link.style.display = "none";
          document.body.appendChild(link);

          link.click();

          // Cleanup
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          setIsExportAllByIdLoading(false);
        } catch (error) {
          console.error("Error exporting contacts By Id:", error);
          setIsExportAllByIdLoading(false);
        }
      }
    } else {
      setExportByIdTooltip(true);
      setTimeout(() => {
        setExportByIdTooltip(false);
      }, 2000);
      setIsExportAllByIdLoading(false);
    }
  };

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setSelectAllChecked(checked);

    setTeamData((prevData: any) =>
      prevData.map((item: any) => ({
        ...item,
        isChecked: checked,
      }))
    );

    if (checked) {
      setCheckedItems(teamData);
    } else {
      setCheckedItems([]);
    }
  };

  const handleUnSelectAll = () => {
    setSelectAllChecked(false);
    setTeamData((prevData: any) =>
      prevData.map((item: any) => ({
        ...item,
        isChecked: false,
      }))
    );
    setCheckedItems([]);
  };
  // Function to handle checkbox click

  const handleCheckboxClick = (id: number) => {
    setTeamData((prevData: any) => {
      const updatedData = prevData.map((item: any) => {
        if (item.contactId === id) {
          const newItem = {
            ...item,
            isChecked: !item.isChecked,
          };
          if (newItem.isChecked) {
            setCheckedItems((prevCheckedItems) => [
              ...prevCheckedItems,
              newItem,
            ]);
          } else {
            setCheckedItems((prevCheckedItems) =>
              prevCheckedItems.filter(
                (prevItem) => prevItem.contactId !== newItem.contactId
              )
            );
          }
          return newItem;
        }
        return item;
      });

      setSelectAllChecked(updatedData.every((item: any) => item.isChecked));

      return updatedData;
    });
  };

  // console.log("selected items:", checkedItems);
  const handleDialog = () => {
    const hasPermission = hasImportPermission(manageContactsObject);
    if (hasPermission) {
      setDialog(true);
    } else {
      setImportTooltip(true);
      setTimeout(() => {
        setImportTooltip(false);
      }, 2000);
    }
  };
  // const handleOpened = () => {
  //   setDialog(false);
  // };

  const handleClosed = () => {
    setDialog(false);
  };
  // const [selectedRow] = useState(null);
  // const [hoveredRow] = useState(null);

  // const handleRowHover = (rowId: any) => {
  //   setHoveredRow(rowId);
  // };
  // useEffect(() => {
  //   if (page) {
  //     const bodyData = {
  //       businessId: userData?.companyId,
  //       userId: userData?.userId,
  //       page: page,
  //       per_page: 40,
  //       search: searchContactsQuery ? searchContactsQuery : "",
  //     };
  //     dispatch(fetchAllContacts(bodyData));
  //   }
  // }, [page]);

  useEffect(() => {
    //   const bodyData = {
    //     businessId: userData?.companyId,
    //     userId: userData?.userId,
    //     page: 1,
    //     per_page: 40,
    //     search: searchContactsQuery,
    //   };
    //   debouncedFetchContacts(bodyData);
    //   if (searchContactsQuery) {
    //   setPage(1);
    //   setTeamData([]);
    // }
    //  else {
    //   const bodyData = {
    //     businessId: userData?.companyId,
    //     userId: userData?.userId,
    //     page: 1,
    //     per_page: 40,
    //     search: "",
    //   };
    //   dispatch(fetchAllContacts(bodyData));
    // }
    if (searchContactsQuery) {
      setPage(1);
      setTeamData([]);
    }

    const bodyData = {
      businessId: userData?.companyId,
      userId: userData?.userId,
      page: page,
      per_page: 40,
      search: searchContactsQuery,
    };
    debouncedFetchContacts(bodyData);
  }, [searchContactsQuery, page]);

  const formatDate = (datetime: any) => {
    const date = new Date(datetime);
    const year = date.getFullYear();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const day = ("0" + date.getDate()).slice(-2);
    return `${day} ${month} ${year}`;
  };

  const getStatusButton = (status: any) => {
    switch (status) {
      case 0:
        return { label: "Open", color: "#1976d2" }; // Primary (Blue)
      case 1:
        return { label: "Resolved", color: "#9c27b0" }; // Secondary (Purple)
      case 2:
        return { label: "Expired", color: "#ff9800" }; // Warning (Orange)
      case 3:
        return { label: "New", color: "#4caf50" }; // Success (Green)
      default:
        return { label: "Unknown", color: "#bdbdbd" }; // Default (Gray)
    }
  };

  // useEffect(() => {
  //   if (contactsData?.data) {
  //     setTeamData(contactsData?.data);
  //   }
  // }, [contactsData?.data]);
  useEffect(() => {
    if (contactsData?.data) {
      const newData = contactsData.data.map((item: any) => ({
        ...item,
        isChecked: selectAllChecked,
      }));

      if (page === 1) {
        setTeamData(newData);
      } else {
        setTeamData((prevData: any) => [...prevData, ...newData]);
      }

      if (selectAllChecked) {
        setCheckedItems((prevCheckedItems) => [
          ...prevCheckedItems,
          ...newData,
        ]);
      }

      setIsLoadingMore(false);
    }
  }, [contactsData?.data]);

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  // const handleLoadMore = () => {
  //   setIsLoadingMore(true);
  //   // console.log(page)
  //   setPage((prevPage) => prevPage + 1);
  // };

  const tableContainerRef = useRef(null);
  const handleScroll = () => {
    if (tableContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        tableContainerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 20 &&
        !isLoadingMore &&
        teamData?.length !== contactsData?.total
      ) {
        handleLoadMore();
      }
    }
  };
  const handleLoadMore = useCallback(() => {
    // if (!isLoadingMore && teamData.length !== contactsData?.total) {
    if (
      !isLoadingMore &&
      teamData?.length !== contactsData?.total &&
      page <= Math.ceil(contactsData?.total / 40)
    ) {
      setIsLoadingMore(true);
      setPage((prevPage) => prevPage + 1);
    }
  }, [isLoadingMore, teamData.length, contactsData?.total]);

  useEffect(() => {
    const bodyData = {
      businessId: userData?.companyId,
      userId: userData?.userId,
      filtering: {
        filtering: {
          filterType: "and",
          conditions: [
            { column: "ChatStatus", operator: "equals", value: "open" },
            {
              column: filterData ? "Tags" : "",
              operator: filterData ? "contain" : "",
              value: filterData,
            },
          ],
        },
      },
    };
    dispatch(fetchInboxContacts(bodyData));
  }, [filterData]);

  return (
    <>
      {currentPlanDetails?.subscriptionPlan?.isActive &&
      ((accountData?.companyVerificationStatus === true &&
        accountData?.isMetaEnabled) ||
        (accountData?.isMetaEnabled === false &&
          accountData?.companyVerificationStatus === false &&
          currentPlanDetails?.subscriptionPlan?.planName === "Intro")) &&
      getuserPermissionData?.contacts ? (
        <Grid className={classes.bgContainer}>
          <Box
            sx={{ flexDirection: { xs: "column", md: "row" }, gap: 3 }}
            px={2}
            className={classes.manageTeamContainer}
          >
            <Box my={{ xs: 1, md: 3 }}>
              <Box style={{ display: "flex", flexDirection: "row" }}>
                <ContactsIconSvg />
                <Typography variant="h6" ml={1} className={classes.blackColor}>
                  Contacts
                </Typography>
                <Box ml={1} mt={0.5} className={classes.messageCount}>
                  <LoadingButton
                    loading={contactsSlice?.status === "loading"}
                    sx={{
                      fontSize: "12px",
                      color: "black",
                      alignItems: "center",
                      display: "flex",
                      "& .MuiCircularProgress-svg": {
                        color: "#fff",
                      },
                    }}
                  >
                    {contactsData?.total}
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                className={classes.searchField}
                variant="standard"
                size="small"
                fullWidth
                // id="password"
                onChange={handleSearchContacts}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "12px",
                    padding: "7px",
                    // border: '1px solid gray',
                    borderRadius: "14px",
                  },
                  startAdornment: (
                    <IconButton
                      sx={{ p: 0, color: "inherit" }}
                      aria-label="search"
                    >
                      {/* <SearchIconSvg /> */}
                      <SearchIconSvg2 />
                    </IconButton>
                  ),
                }}
                inputProps={{
                  style: {
                    // Additional style for placeholder
                    fontWeight: "600 !important",
                    paddingTop: "3px", // Apply font weight here
                  },
                }}
                placeholder="Search contacts"
              />
            </Box>

            {/* {allowToAddDeleteUsers && ( */}
            <Tooltip
              title="Access Denied"
              placement="top"
              open={addContactTooltip}
              onClose={() => setAddContactTooltip(false)}
            >
              {/* <Box
            ml={{ xs: "0", md: "auto" }}
            my={{ xs: 1, md: 0 }}
            onClick={handleOpenDialog}
          > */}
              <button
                className={classes.SaveChangesButton}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                  backgroundColor: isHovered
                    ? "rgba(68, 71, 70, 0.08)"
                    : "#fff",
                  cursor: isHovered ? "pointer" : "default",
                }}
                onClick={handleOpenDialog}
              >
                + Add Contact
              </button>
              {/* </Box> */}
            </Tooltip>
            {/* )} */}

            <NewContactPopOver
              open={openDialog}
              handleClose={handleCloseDialog}
            />
          </Box>
          <Box px={1.5}>
            {checkedItems.length === 0 ? (
              <Box
                // ml={2}
                display="flex"
                sx={{ textAlign: { xs: "center", md: "none" } }}
                flexDirection={{ xs: "column", md: "row" }}
              >
                <Box display="flex" flexDirection="row">
                  <Checkbox
                    checked={allSelected}
                    onChange={allSelected ? handleUnSelectAll : handleSelectAll}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 17 },
                      color: "gray",
                      "&.Mui-checked": {
                        color: "gray",
                      },
                    }}
                  />
                  <Box className={classes.messageCountContainer}>
                    <Typography
                      className={classes.grayColor1}
                      sx={{ pt: "2px", fontSize: "13px" }}
                      onClick={handleFilterClick}
                    >
                      {selectedFilter}
                    </Typography>
                    <Box
                      className={classes.iconStyles}
                      onClick={handleFilterClick}
                    >
                      <ArrowDownSvg />
                    </Box>
                    <ContactsFilterPopover
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      options={filteredTags}
                      handleOptionClick={handleOptionClick}
                      handleSearchChange={handleSearchChange}
                      setSearchQuery={setSearchQuery}
                      setPage={setPage}
                      setTeamData={setTeamData}
                    />
                  </Box>
                </Box>
                <Box mx={{ xs: 0, md: 2 }} display="flex" gap={1}>
                  <Box
                    onClick={handleDialog}
                    className={classes.campaignCountContainer}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: "rgba(68, 71, 70, 0.08)",
                      },
                    }}
                  >
                    {importTooltip ? (
                      <Tooltip
                        title="Access Denied"
                        placement="right"
                        open={importTooltip}
                        onClose={() => setImportTooltip(false)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "gray",
                          }}
                        >
                          <LuImport size={20} />
                        </Box>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Import" placement="top">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "gray",
                          }}
                        >
                          <LuImport size={20} />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>

                  {/* {allowToExportUsers && ( */}
                  <Box
                    onClick={handleExport}
                    className={classes.campaignCountContainer}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: "rgba(68, 71, 70, 0.08)",
                      },
                    }}
                  >
                    {exportTooltip ? (
                      <Tooltip
                        title="Access Denied"
                        placement="right"
                        open={exportTooltip}
                        onClose={() => setExportTooltip(false)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "gray",
                            marginBottom: "5px",
                          }}
                        >
                          {isExportAllLoading ? (
                            <CircularProgress size={16} />
                          ) : (
                            <CgExport size={20} />
                          )}
                        </Box>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Export all" placement="top">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "gray",
                            marginBottom: "5px",
                          }}
                        >
                          {isExportAllLoading ? (
                            <CircularProgress size={16} />
                          ) : (
                            <CgExport size={20} />
                          )}
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
                {dialog && (
                  <ImportContactsPopUp
                    open={dialog}
                    handleClose={handleClosed}
                  />
                )}
              </Box>
            ) : (
              <Box
                // ml={2} display="flex" flexDirection="row"
                // ml={2}
                // py={-2}
                display="flex"
                sx={{ textAlign: { xs: "center", md: "none" } }}
                flexDirection={{ xs: "column", md: "row" }}
                justifyContent={"space-between"}
              >
                <Box
                  // mx={{ xs: 0, md: 2 }}
                  sx={{
                    display: "flex",
                  }}
                >
                  <Checkbox
                    checked={selectAllChecked}
                    onChange={handleUnSelectAll}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 17 },
                      color: "gray",
                      "&.Mui-checked": {
                        color: "gray",
                      },
                    }}
                  />
                  {/* {allowToBulkTagUsers && ( */}
                  <Box
                    ml={1}
                    className={classes.campaignCountContainer}
                    sx={{
                      backgroundColor: "#fff",

                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: "rgba(68, 71, 70, 0.08)",
                      },
                    }}
                  >
                    <Tooltip title="Move to tags" placement="top">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "gray",
                        }}
                        onClick={handleFilterMove}
                      >
                        <MdDriveFileMoveOutline size={20} />
                      </Box>
                    </Tooltip>
                    <MoveToTagsPopover
                      anchorEl={anchorelMove}
                      selectedContacts={checkedItems}
                      handleClose={handleCloseMove}
                      options={filteredTags}
                      handleOptionClick={handleOptionClickMove}
                      handleSearchChange={handleSearchChange}
                    />
                  </Box>
                  <Box
                    ml={1}
                    className={classes.campaignCountContainer}
                    onClick={handleExportById}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: "rgba(68, 71, 70, 0.08)",
                      },
                    }}
                  >
                    {exportByIdTooltip ? (
                      <Tooltip
                        title="Access Denied"
                        placement="right"
                        open={exportByIdTooltip}
                        onClose={() => setExportByIdTooltip(false)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "gray",
                          }}
                        >
                          {isExportAllByIdLoading ? (
                            <CircularProgress size={16} />
                          ) : (
                            <CgExport size={20} />
                          )}
                        </Box>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Export" placement="top">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "gray",
                          }}
                        >
                          {isExportAllByIdLoading ? (
                            <CircularProgress size={16} />
                          ) : (
                            <CgExport size={20} />
                          )}
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                  <Tooltip
                    title="Access Denied"
                    placement="top"
                    open={deleteContactsByIdTooltip}
                    onClose={() => setDeleteContactsByIdTooltip(false)}
                  >
                    <Box
                      // ml={1}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: bgColors?.white,
                        borderRadius: "12px",
                        paddingInline: "8px",
                        display: "flex",
                        height: "35px",
                        flexDirection: "row",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={handleDeleteAll}
                    >
                      <Typography
                        sx={{
                          p: "0px",
                          fontSize: "13px",
                          lineHeight: 0,
                          // border: `1px solid ${bgColors.green}`,
                          cursor: "pointer",
                          color: bgColors.black,
                          borderRadius: "8px",
                          paddingInline: "8px",
                          display: "flex",
                          flexDirection: "row",
                          height: "30px",
                          alignItems: "center",
                        }}
                        className={classes.campaignColor}
                      >
                        <Tooltip title="delete" placement="top">
                          <DeleteIconSvg />
                        </Tooltip>
                      </Typography>
                    </Box>
                  </Tooltip>
                  {/* <Tooltip
                title="Access Denied"
                placement="top"
                open={sendCampaignTooltip}
                onClose={() => setSendCampaignTooltip(false)}
              >
                <Box
                  ml={{ xs: 0 }}
                  className={classes.campaignCountContainer}
                  onClick={handleSendCampaign}
                  sx={{
                    backgroundColor: "#fff",

                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "rgba(68, 71, 70, 0.08)",
                    },
                  }}
                >
                  <Tooltip title="Send campaign" placement="top">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <LuSendHorizonal size={24} />
                    </Box>
                  </Tooltip>
                </Box>
              </Tooltip> */}
                  {/* )} */}
                </Box>
                {/* <Box
              sx={{
                display: "flex",
                alignItems: 'center'
              }}
            > */}
                {/* <Box sx={{ borderRadius: "12px", pt: 0 }}> */}
                {/* </Box> */}

                {/* </Box> */}
              </Box>
            )}
          </Box>
          <ThemeProvider theme={theme}>
            <TableContainer
              component={Box}
              mt={3}
              sx={{
                height: "76vh",
                overflowY: "scroll",
                position: "relative",
                scrollBehavior: "smooth",
                // Scrollbar styling
                "&::-webkit-scrollbar": {
                  cursor: "pointer",
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  cursor: "pointer",
                  backgroundColor: "#cdcdcd",
                  borderRadius: "8px",
                  transition: "background-color 0.2s ease-in-out",
                },
              }}
              // onScroll={handleScroll}
              // ref={tableContainerRef}
              onScroll={handleScroll}
              ref={tableContainerRef}
            >
              <Table className={classes.table} sx={{ width: "100%" }}>
                <TableHead className={classes.grayColor}>
                  <TableRow>
                    <TableCell>
                      <span style={{ marginLeft: "20px" }}>Name</span>
                    </TableCell>
                    <TableCell>Chat status</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell>Tags</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {(contactsSlice?.status === "loading" ||
                  isEditContactLoading) &&
                !isLoadingMore ? (
                  <TableBody
                    sx={{
                      position: "relative",
                      margin: "20px",
                      height: "500px",
                    }}
                  >
                    <TableRow
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                        paddingLeft: "50px",
                        top: "20px",
                      }}
                    >
                      <LoadingComponent height="500px" color={bgColors.blue} />
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    {teamData?.length !== 0 && teamData !== null ? (
                      <TableBody>
                        {teamData?.map((row: any) => {
                          const { label, color } = getStatusButton(
                            row?.chatStatus
                          );
                          // const isSelected = row.contactId === selectedRow;
                          // const isHovered = row.contactId === hoveredRow;
                          return (
                            <TableRow
                              key={row.contactId}
                              // onMouseEnter={() => handleRowHover(row.contactId)}
                              // onMouseLeave={() => setHoveredRow(null)}
                              onClick={() => handleCheckboxClick(row.contactId)}
                              sx={{
                                cursor: "pointer",
                                backgroundColor: row?.isChecked
                                  ? "#DBDBDB"
                                  : "inherit",
                                transition: "box-shadow 0.3s ease",
                                "&:hover": {
                                  boxShadow:
                                    "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
                                },
                              }}
                            >
                              <TableCell className={classes.blackColor}>
                                <Box
                                  sx={{ display: "flex", marginLeft: "10px" }}
                                >
                                  <Checkbox
                                    checked={
                                      row?.isChecked ? row?.isChecked : false
                                    }
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      handleCheckboxClick(row.contactId);
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 17,
                                        color: row?.isChecked ? "gray" : "gray",
                                      },
                                      // "&.Mui-checked": {
                                      //   color: "black", // Border color when checked
                                      // },
                                      // "& .MuiCheckbox-root": {
                                      //   backgroundColor: "transparent", // Make the checkbox background transparent
                                      // },
                                      // "&.Mui-checked .MuiSvgIcon-root": {
                                      //   fill: "transparent", // Ensure the tick's background remains transparent when checked
                                      // },
                                    }}
                                  />

                                  <Tooltip
                                    title={
                                      <div>
                                        <Typography
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.name}
                                        </Typography>
                                      </div>
                                    }
                                    placement="top"
                                  >
                                    <Box
                                      className={classes.teamProfileContainer}
                                    >
                                      <span
                                        className={classes.blackColor}
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          display: "inline-block",
                                          textAlign: "left",
                                          maxWidth: "200px",
                                        }}
                                      >
                                        {row.name}
                                      </span>
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                              <TableCell className={classes.blackColor}>
                                <span style={{ color: color }}>{label}</span>
                              </TableCell>
                              <TableCell className={classes.blackColor}>
                                {row?.countryCode + " " + row?.contact}
                              </TableCell>
                              <TableCell className={classes.blackColor}>
                                {row.email}
                              </TableCell>
                              <TableCell className={classes.blackColor}>
                                {row.country}
                              </TableCell>
                              <TableCell className={classes.blackColor}>
                                {formatDate(row.createdDate)}
                              </TableCell>
                              <TableCell className={classes.blackColor}>
                                {row?.tags && row?.tags.length > 0 && (
                                  <Tooltip
                                    title={
                                      <div>
                                        {row.tags.map(
                                          (tag: any, index: number) => (
                                            <Typography
                                              key={index}
                                              style={{ fontSize: "14px" }}
                                            >
                                              {tag.tag}
                                            </Typography>
                                          )
                                        )}
                                      </div>
                                    }
                                    placement="top"
                                  >
                                    <Box>
                                      <Box
                                        sx={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          maxWidth: "200px",
                                        }}
                                      >
                                        {row.tags.map(
                                          (tag: any, index: number) => (
                                            <span
                                              key={index}
                                              style={{ fontSize: "15px" }}
                                            >
                                              {tag.tag}
                                              {index < row.tags.length - 1 &&
                                                ", "}
                                            </span>
                                          )
                                        )}
                                      </Box>
                                    </Box>
                                  </Tooltip>
                                )}
                              </TableCell>
                              <TableCell>
                                <Box sx={{ display: "flex", gap: "5px" }}>
                                  <Tooltip
                                    title="Access Denied"
                                    placement="top"
                                    open={editContactsTooltip === row}
                                    onClose={() => setEditContactsTooltip(null)}
                                  >
                                    <Box
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpenEditDialog(row);
                                      }}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <EditIconSvg />
                                    </Box>
                                  </Tooltip>
                                  <Tooltip
                                    title="Access Denied"
                                    placement="top"
                                    open={
                                      deleteContactsTooltip === row?.contactId
                                    }
                                    onClose={() =>
                                      setDeleteContactsTooltip(null)
                                    }
                                  >
                                    <Box
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpenDelete(row?.contactId);
                                      }}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <DeleteIconSvg />
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        <DeletePopUp
                          title="Selected Contacts"
                          open={deleteDialog}
                          handleClose={handleCloseDelete}
                          handleDelete={handleDelete}
                          handleLoad={isDeleteLoading}
                        />
                        {editContact && (
                          <EditPopOver
                            open={openEditDialog}
                            handleClose={handleCloseEditDialog}
                            editContact={editContact}
                            setTeamData={setTeamData}
                            setPage={setPage}
                            isEditContactLoading={isEditContactLoading}
                            setIsEditContactLoading={setIsEditContactLoading}
                          />
                        )}
                      </TableBody>
                    ) : (
                      // <TableRow
                      //   sx={{
                      //     position: "relative",
                      //   }}
                      // >
                      //   <Typography
                      //     sx={{
                      //       position: "absolute",
                      //       left: "50%",
                      //       top:'50%',
                      //       transform: "translateX(-50%)",
                      //       fontSize: "16px",
                      //       paddingLeft: "15px",
                      //       top: "20px",
                      //     }}
                      //   >
                      //     No Data Found
                      //   </Typography>
                      // </TableRow>
                      <TableRow sx={{ height: "60vh" }}>
                        <TableCell
                          colSpan={12}
                          align="center"
                          sx={{
                            width: "100%",
                            border: "none !important",
                          }}
                        >
                          No Data Found
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </Table>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                {teamData?.length !== 0 &&
                  teamData !== null &&
                  teamData.length !== contactsData?.total &&
                  contactsSlice?.status === "loading" &&
                  isLoadingMore && (
                    <>
                      <LoadingComponent height="50px" color={bgColors.blue} />
                    </>
                    // <Typography onClick={handleLoadMore} sx={{
                    //   cursor: 'pointer',
                    //   fontSize: '10px',
                    //   '&:hover': {
                    //     color: 'blue',
                    //   },
                    // }}>Click to view more contacts...</Typography>
                  )}
              </Box>
            </TableContainer>
          </ThemeProvider>
          {/* <Box mt={2}>
        {teamData?.length !== 0 && teamData !== null && (
          <ContactsPaginations total={contactsData?.total} setPage={setPage} searchQuery={searchQuery} />
        )}
      </Box> */}
          <DeletePopUp
            title="Selected Tag"
            open={deleteSelectedDialog}
            handleClose={handleCloseSelectedDelete}
            handleDelete={handleDeleteById}
            handleLoad={isDeleteLoading}
          />
          <EditCampaign
            open={openCampaignDailog}
            title="Add"
            handleClose={handleCloseCampaignDailog}
            data={null}
            setFilterData={setFilterData}
          />
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

export default Contacts;
