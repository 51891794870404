import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTOMATION_API } from "../../../Apis/AdminLogin/Automation/Automation";

export interface IData {
    updateStatus: "loading" | "succeeded" | "failed" | "idle";
    data: any;
}

const initialState: IData = {
    updateStatus: "idle",
    data: null,
};

export const updateAutoReply = createAsyncThunk(
    "autoReply/updateAutoReply",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await AUTOMATION_API.updateAutoReplyCustomMessage(data);
            return response?.data;
        } catch (error: any) {
            // Provide a descriptive error message
            return rejectWithValue(error.response || 'An error occurred');
        }
    }
);


export const updateAutoReplySlice = createSlice({
    name: "updateAutoReply",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(updateAutoReply.pending, (state) => {
                state.updateStatus = "loading";
                state.data = null;
            })
            .addCase(updateAutoReply.fulfilled, (state, action) => {
                state.updateStatus = "succeeded";
                state.data = action.payload;
            })
            .addCase(updateAutoReply.rejected, (state) => {
                state.updateStatus = "failed";
            });
    },
});


export const updateAutoReplySliceActions = updateAutoReplySlice.actions;
export default updateAutoReplySlice.reducer;
