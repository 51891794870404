import React, { FunctionComponent, useState } from "react";
import { Box, Typography, Grid, InputLabel } from "@mui/material";
import ButtonComponent from "../../components/common/ButtonComponent";
import LinkComponent from "../../components/common/LinkComponent";
import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import TextFeildWithBorderComponet from "../common/TextFieldWithBorderComponent";
import { ADMIN_LOGIN_API } from "../../Apis/AdminLogin/AdminLoginApi";
import { useAppDispatch } from "../../utils/redux-hooks";
import { toastActions } from "../../utils/toastSlice";
import { adminLoginSliceActions } from "../../redux/slices/AdminLoginSlice";
import { Link } from "@mui/material";
import ErrorMessageComponent from "../common/ErrorMessageComponent";
import LoadingComponent from "../common/LoadingComponent";

export type LoginFormContainerType = {
  loginPromptText?: string;
  frameDivLeft?: number | string;
};

const useStyles = makeStyles({
  container: {
    // width: "200%",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    // padding: "20%",
    padding: "20px",
    boxSizing: "border-box",
    backgroundColor: "#ffffff",
  },
  button: {
    backgroundColor: `${bgColors.green}!important`,
    width: "100% !important",
    fontSize: "14px !important",
    fontWeight: "Semi Bold !important",
    borderRadius: "8px !important",
  },
  link: {
    display: "block",
    textAlign: "center",
    fontSize: "17px!important",
    color: bgColors.green,
  },
  promptText: {
    marginBottom: "3% !important",
    fontWeight: "Bold !important",
    textAlign: "center",
  },
  emailmsg: {
    color: `${bgColors.black3} !important`,
    fontSize: "14px !important",
    fontWeight: "Regular !important",
  },
  linkContainer: {
    fontSize: "16px",
    textAlign: "center",
  },
  backText: {
    color: `${bgColors.black3} !important`,
    fontSize: "16px !important",
    textAlign: "center",
    fontWeight: "500 !important",
  },
  arrowIcon: {
    marginRight: "10px",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "600 !important",
    fontSize: "14px !important",
  },
  boldLabel: {
    fontWeight: "500",
    // color:"#646E7B",
    // fontSize: "16px",
    fontFamily: "Inter",
    // color:"#646E7B"
    color: "rgba(100, 110, 123, 0.9)",
  },
});

const ForgotFormContainer: FunctionComponent<LoginFormContainerType> = ({
  loginPromptText,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [err, setErr] = useState("");
  const [isForgotPasswordLoading, setIsForgotPasswordLoading] = useState(false);
  // console.log(err,'err')
  // const validateEmail = (email: string) => {
  //   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailPattern.test(email.toLowerCase())) {
  //     // setEmailError("Invalid email format...enter like sample@gmail.com");
  //     setEmailError("Please enter a valid email address");
  //     return false;
  //   }
  //   setEmailError("");
  //   return true;
  // };

  // Updated validateEmail function
  const validateEmail = (email: string) => {
    if (!email) {
      setEmailError("Email address is required");
      return false;
    }
    // const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setEmailError("Please enter a valid email address ");
      return false;
    }
    setEmailError("");
    return true;
  };

  const handleForgotPassword = async () => {
    const isEmailValid = validateEmail(email);
    // navigate('/verification-code')
    if (isEmailValid) {
      try {
        setIsForgotPasswordLoading(true);
        const response = await ADMIN_LOGIN_API.forgotPassword({ email });
        // console.log(response,'response')
        if (response?.data?.message === "OTP sent successfully.") {
          dispatch(adminLoginSliceActions.setEmail(email));
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: response?.data?.message,
            })
          );
          setIsForgotPasswordLoading(false);
          navigate("/verification-code");
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: response?.data?.message,
            })
          );
          setIsForgotPasswordLoading(false);
        }
      } catch (e: any) {
        // console.log(e,'e')
        // console.log("cbajc", e?.response?.data?.message);
        setErr(e?.response?.data?.message);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: e?.response?.data?.message,
          })
        );
      } finally {
        setIsForgotPasswordLoading(false);
      }
    }
  };

  return (
    // <Grid container item xs={12} mt={15}>
    //   <Grid xs={2}></Grid>
    <Box
      // xs={5}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        className={classes.container}
        // sx={{ width: { xs: "90%", sm: "70%", md: "50%", lg: "30%" } }}
        px={{ xs: 2, md: 6 }}
      >
        <Typography variant="h5" className={classes.promptText}>
          {loginPromptText}
        </Typography>

        <Grid item xs={12} textAlign="center">
          <Typography variant="h6" className={classes.emailmsg}>
            Please enter your registered Email Address to
          </Typography>
          <Typography variant="h6" className={classes.emailmsg}>
            reset the “password”
          </Typography>
        </Grid>

        <Grid container item spacing={3} xs={12}>
          <Grid item xs={12} mt={4}>
            <InputLabel className={classes.blackColor}>
              Email Address
            </InputLabel>
            <TextFeildWithBorderComponet
              // label="Enter your email address"
              label={
                <span className={classes.boldLabel}>
                  Enter your email address
                </span>
              }
              name="email"
              placeholder=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!emailError}
              helperText={emailError}
              sx={{
                "& input": {
                  fontWeight: "600",
                },
              }}
            />
            {err && <ErrorMessageComponent text={err} />}
          </Grid>
          {isForgotPasswordLoading ? (
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <LoadingComponent height="auto" color={bgColors?.blue} />
            </Box>
          ) : (
            <Grid item xs={12}>
              <ButtonComponent
                title="Next"
                className={classes.button}
                onClick={handleForgotPassword}
              />
            </Grid>
          )}
          <Grid item xs={12} className={classes.linkContainer}>
            <Link href="/login">
              <img
                alt=""
                src="/images/BackArrow.png"
                className={classes.arrowIcon}
              />
            </Link>
            <LinkComponent
              href="/login"
              title="Back to Login"
              className={classes.backText}
            />
          </Grid>
        </Grid>
      </Box>
      {/* </Grid> */}
    </Box>
  );
};

export default ForgotFormContainer;
