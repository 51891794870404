import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { bgColors } from "../../utils/bgColors";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Tooltip } from "@mui/material";
import TextFieldWithBorderComponent from "../../components/common/TextFieldWithBorderComponent";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { editBillingDetails } from "../../redux/slices/Wallet/WalletSlice";
import { toastActions } from "../../utils/toastSlice";
import { billingDetails } from "../../redux/slices/Wallet/WalletSlice";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100%",
    width: "100%",
    // overflowY: "auto",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    // backgroundColor: bgColors.blue,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "120px",
    height: "32px",
    gap: "0px",
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
  },
  emailContainer: {
    display: "flex",
    gap: "10px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // gap: "10px"
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "500 !important",
  },
});

const BillingDetailsEdit = () => {
  const navigation = useNavigate();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  // const editDetailsStatus = useAppSelector(
  //   (state) => state.wallet.editBillingDetailsData
  // );
  // console.log(editDetailsStatus)
  const loginData = useAppSelector((state: any) => state.adminLogin.data);
  // console.log(loginData)
  const companyId = loginData?.companyId;
  // const handleSubmit = async () => {
  // setOpenDialog(true);
  // dispatch(editBillingDetails(data));

  // {
  //     "id": "99969011-7b1d-4c2d-92a6-fba9ca31a261",
  //     "businessEmail": "ravinaga9959@gmail.com",
  //     "gstNumber": "22AAABbbbbA0000A1Z5",
  //     "companyAddress": "8888",
  //     "companyLegalName": "Ahex Tech"
  //   }'
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setBillingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setIsError((prevDetails) => ({
      ...prevDetails,
      [name]: "",
    }));
  };

  const handleSubmit = async () => {
    isValidData();
    // console.log(isValidData());
    if (isValidData()) {
      const data = {
        id: billingData.id,
        businessEmail: billingData.companyEmail,
        gstNumber: billingData.gstNumber,
        companyAddress: billingData.companyAddress,
        companyLegalName: billingData.companyName,
      };
      // console.log(data)

      const response: any = await dispatch(editBillingDetails(data));
      const res = response?.payload;
      //  console.log(res?.message,'dataa')
      if (res?.message === "Updated billing details") {
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: res?.message,
          })
        );
        dispatch(billingDetails(companyId));
        navigation("/billing-details");
      }
    }
  };

  const handleBack = () => {
    navigation("/billing-details");
  };
  const billingDetailsData = useAppSelector(
    (state: any) => state.wallet.billingDetailsData.company
  );
  // console.log(billingDetailsData);
  // const billingDetailsstatus = useAppSelector(
  //   (state) => state?.wallet?.billingDetailsstatus
  // );
  // console.log(billingDetailsstatus)
  const [billingData, setBillingDetails] = useState({
    id: billingDetailsData?.id,
    companyName: billingDetailsData?.companyLegalName,
    companyEmail: billingDetailsData?.businessEmail,
    gstNumber: billingDetailsData?.gstNumber,
    companyAddress: billingDetailsData?.companyAddress,
  });
  // console.log(billingData);

  const [isError, setIsError] = useState({
    companyName: "",
    companyEmail: "",
    gstNumber: "",
    companyAddress: "",
  });
  // console.log(isError);

  const isValidData = () => {
    const errors = { ...isError };
    let isValid = true;

    if (!billingData?.companyName?.trim()) {
      errors.companyName = "Company Name is required.";
      isValid = false;
    } else if (billingData?.companyName?.length > 75) {
      errors.companyName = "Comapny Name cannot be more than 75 characters.";
      isValid = false;
    }

    const emailPattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!billingData?.companyEmail) {
      errors.companyEmail = "Company email is required.";
      isValid = false;
    } else if (!emailPattern?.test(billingData?.companyEmail)) {
      errors.companyEmail = "Invalid company email address.";
      isValid = false;
    }

    if (!billingData?.companyAddress?.trim()) {
      errors.companyAddress = "Company address is required.";
      isValid = false;
    }

    // if (!billingData?.gstNumber || billingData?.gstNumber === "") {
    //   isValid = true;
    // } else
    if (
      billingData?.gstNumber &&
      billingData.gstNumber.length > 0 &&
      !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
        billingData.gstNumber
      )
    ) {
      errors.gstNumber = "Invalid GST Number.";
      isValid = false;
    }

    setIsError(errors);
    return isValid;
  };

  return (
    <Grid className={classes.mainContainer}>
      <Grid className={classes.bgContainer}>
        <Box pt={3}>
          <Box className={classes.manageContainer}>
            <Typography
              // ml={4}
              variant="h6"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Tooltip title="Back" sx={{ ml: 3, cursor: "pointer" }}>
                <ArrowBackIcon onClick={handleBack} />
              </Tooltip>
              &nbsp;Edit Billing Details
            </Typography>
            <Box sx={{ mr: 3, mt: -1 }}>
              <button
                onClick={handleSubmit}
                className={classes.SaveChangesButton}
              >
                Save Changes
              </button>
            </Box>
          </Box>
        </Box>
        <Box ml={3} mr={2} mt={3}>
          <Box p={4} className={classes.mainBorderStyles}>
            <Box
              // className={classes.emailContainer}
              sx={{
                display: "flex",
                gap: "10px",
                flexDirection: {
                  lg: "row",
                  sm: "row",
                  xs: "column",
                  md: "row",
                },
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: { xs: 0, lg: 5 },
              }}
            >
              <Box sx={{ width: { lg: "50%", xs: "100%" } }}>
                <Typography variant="body1" className={classes.blackColor}>
                  Legal name of Company
                </Typography>
                <TextFieldWithBorderComponent
                  margin="dense"
                  id="expiryDate"
                  size="small"
                  label="Enter company name"
                  name="companyName"
                  placeholder=""
                  value={billingData?.companyName}
                  fullWidth
                  onChange={handleInputChange}
                  error={!!isError.companyName}
                  helperText={isError.companyName}
                  sx={{
                    "& input": {
                      //   fontWeight: "600",
                    },
                  }}
                  //   value={expiryDate}
                  //   onChange={(e) => setExpiryDate(e.target.value)}
                />
              </Box>
              <Box sx={{ width: { lg: "50%", xs: "100%" } }}>
                <Typography variant="body1" className={classes.blackColor}>
                  Company Email
                </Typography>
                <TextFieldWithBorderComponent
                  margin="dense"
                  id="expiryDate"
                  size="small"
                  label="Enter email"
                  name="companyEmail"
                  placeholder=""
                  value={billingData?.companyEmail}
                  fullWidth
                  onChange={handleInputChange}
                  error={!!isError.companyEmail}
                  helperText={isError.companyEmail}
                  sx={{
                    "& input": {
                      //   fontWeight: "600",
                    },
                  }}
                  //   value={expiryDate}
                  //   onChange={(e) => setExpiryDate(e.target.value)}
                />
              </Box>
            </Box>
            <Box
              //  className={classes.emailContainer}
              sx={{
                display: "flex",
                gap: "10px",
                // flexDirection: "row",
                flexDirection: {
                  lg: "row",
                  sm: "row",
                  xs: "column",
                  md: "row",
                },
                justifyContent: "space-between",
                alignItems: "center",
                // gap: "10px"
              }}
            >
              <Box
                // sx={{ width: '50%' }}
                sx={{ width: { lg: "50%", xs: "100%" } }}
              >
                <Typography variant="body1" className={classes.blackColor}>
                  GST
                </Typography>
                <TextFieldWithBorderComponent
                  margin="dense"
                  id="expiryDate"
                  size="small"
                  label="Enter GST number"
                  name="gstNumber"
                  placeholder=""
                  value={billingData?.gstNumber}
                  fullWidth
                  onChange={handleInputChange}
                  error={!!isError.gstNumber}
                  helperText={isError.gstNumber}
                  sx={{
                    "& input": {
                      //   fontWeight: "600",
                    },
                  }}
                  //   value={expiryDate}
                  //   onChange={(e) => setExpiryDate(e.target.value)}
                />
              </Box>
              <Box sx={{ width: { lg: "50%", xs: "100%" } }}>
                <Typography variant="body1" className={classes.blackColor}>
                  Company Address
                </Typography>
                <TextFieldWithBorderComponent
                  margin="dense"
                  id="expiryDate"
                  size="small"
                  label="Enter company address"
                  name="companyAddress"
                  placeholder=""
                  value={billingData?.companyAddress}
                  fullWidth
                  onChange={handleInputChange}
                  error={!!isError.companyAddress}
                  helperText={isError.companyAddress}
                  sx={{
                    "& input": {
                      //   fontWeight: "600",
                    },
                  }}
                  //   value={expiryDate}
                  //   onChange={(e) => setExpiryDate(e.target.value)}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BillingDetailsEdit;
