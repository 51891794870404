import React from 'react'

const DeleteIconSvg = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 11C9.4 11 9 11.4 9 12V16C9 16.6 9.4 17 10 17C10.6 17 11 16.6 11 16V12C11 11.4 10.6 11 10 11ZM14 11C13.4 11 13 11.4 13 12V16C13 16.6 13.4 17 14 17C14.6 17 15 16.6 15 16V12C15 11.4 14.6 11 14 11Z" fill="#808080" />
      <path d="M13.5 2H10.5C9.1 2 8 3.1 8 4.5V5H5C4.4 5 4 5.4 4 6C4 6.6 4.4 7 5 7H5.1L5.9 18.2C6 20.4 7.7 22 9.8 22H14.2C16.3 22 18.1 20.4 18.2 18.3L18.9 7H19C19.6 7 20 6.6 20 6C20 5.4 19.6 5 19 5H16V4.5C16 3.1 14.9 2 13.5 2ZM10 4.5C10 4.2 10.2 4 10.5 4H13.5C13.8 4 14 4.2 14 4.5V5H10V4.5ZM16.9 7L16.1 18.1C16 19.2 15.2 20 14.1 20H9.8C8.7 20 7.9 19.2 7.8 18.1L7.1 7H16.9Z" fill="#808080"
      />
    </svg>
  )
}

export default DeleteIconSvg