import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LoginButton from "../../components/LoginComponents/FacebookLogin";
import EngagetoLogoSvg from "../../assets/svgs/EngagetoLogoSvg";

const useStyles = makeStyles({
  background: {
    backgroundImage: `url("/images/new-pwd-bg.png")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  logoContainer: {
    // padding: '16px',
  },
});

const EmbeddedSignUp = () => {
  const classes = useStyles();
  return (
    <Box className={classes.background}>
      <Box className={classes.logoContainer}>
        {/* <AhexMainLogoSvg /> */}
        <EngagetoLogoSvg />
      </Box>
      <Box>
        <LoginButton />
      </Box>
    </Box>
  );
};

export default EmbeddedSignUp;
