import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { ChangeEvent, MouseEvent, useRef, useState } from "react";
import LoadingComponent from "../common/LoadingComponent";
import { bgColors } from "../../utils/bgColors";
import ChatEmojiIcon from "../../assets/svgs/ChatEmojiIcon";
import EmojiPopover from "../InboxComponents/inboxDetailsComponents/EmojiPicker";
import ChatFileIcon from "../../assets/svgs/ChatFileIcon";
import { makeStyles } from "@mui/styles";
import { useAppDispatch } from "../../utils/redux-hooks";
import { toastActions } from "../../utils/toastSlice";
import { uploadFile } from "../../redux/slices/Templates/uploadFileSlice";
import CloseIconSvg from "../../assets/svgs/CloseIconSvg";
interface MessageState {
  message: string;
  selectedFile: any;
  mediaUrl: string;
}

const useStyles = makeStyles({
  inputContainer: {
    width: "100%",
    zIndex: "1",
    // position: "sticky",
    // bottom: "0",
  },
  mediaFile: {
    maxWidth: 330,
    maxHeight: 400,
    border: "1px solid #cdcdcd",
    borderRadius: "4px",
  },
  document: {
    borderRadius: "4px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    // border: "1px solid #cdcdcd",
  },
  mediaContainer: {
    position: "relative",
    alignItems: "center",
    // marginInline: "auto",
    marginLeft: "16px",
    marginRight: "24px",
    // marginBlock: "8px",
    // maxWidth: 380,
    minHeight: 80,
    maxHeight: 500,
    borderRadius: "4px",
    paddingTop: "18px",
    // paddingBottom: "14px",
    backgroundColor: "rgb(248, 248, 248)",
  },
  cursor: {
    cursor: "pointer",
  },
  replyBox: {
    backgroundColor: bgColors?.white1,
    padding: "20px",
    marginLeft: "15px",
    marginRight: "24px",
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
    fontSize: "14px",
    wordBreak: "break-word",
    display: "flex",
    textAlign: "left",
    alignItems: "center",
  },
  maxHeightStyle: { maxHeight: 300, overflowY: "auto" },
  cancelIcon: {
    color: "white !important",
    // backgroundColor: 'black !important',
    cursor: "pointer",
    // paddingLeft: "20px",
  },
  flexRowCenter: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  userMessageContainer: {
    display: "flex",
    alignItems: "center",
    // position: "relative",
  },
  inputtest: {
    "&::placeholder": {
      fontStyle: "italic",
      color: "#4B5A5A !important",
    },
  },
});

const TextFieldComponent = ({
  openTextField,
  type,
  responseMessage,
  setValidResponseMessage,
  mediaUrl,
  setMediaUrl,
  validResponseMessage,
  setResponseMessage,
}: any) => {
  // console.log(validResponseMessage, "validResponseMessage");
  // console.log(responseMessage, "responseMessage");
  // console.log(mediaUrl, "mediaUrl");
  // console.log(openTextField, "openTextField");
  // console.log(handleSendMessage, "handleSendMessage");
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textFieldRef = useRef<HTMLDivElement>(null);

  const [mediaLoader, setMediaLoader] = useState<boolean>(false);
  const [messageState, setMessageState] = useState<MessageState>({
    message: "",
    selectedFile: null,
    mediaUrl: "",
  });
  // console.log(messageState, "messageState");
  const [sendEmojiMessageTooltip, setSendEmojiMessageTooltip] = useState(false);
  const [emojiPopoverOpen, setEmojiPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [sendMediaMessageTooltip, setSendMediaMessageTooltip] = useState(false);

  const handleEmojiClick = (event: MouseEvent<HTMLDivElement>) => {
    const hasTextMessagePermission = true;
    if (hasTextMessagePermission) {
      setAnchorEl(event.currentTarget);
      setEmojiPopoverOpen(true);
    } else {
      setSendEmojiMessageTooltip(true);
      setTimeout(() => {
        setSendEmojiMessageTooltip(false);
      }, 2000);
    }
  };
  const handleCloseEmojiPopover = () => {
    setEmojiPopoverOpen(false);
  };

  const handleEmojiSelect = (emoji: any) => {
    if (textFieldRef.current) {
      const input = textFieldRef.current.querySelector("textarea");

      if (input && typeof input.setSelectionRange === "function") {
        const start = input.selectionStart;
        const end = input.selectionEnd;

        // const newMessage =
        //   messageState?.message?.slice(0, start) +
        //   emoji +
        //   messageState?.message?.slice(end);
        const newMessage =
          responseMessage?.slice(0, start) +
          emoji +
          responseMessage?.slice(end);

        // setMessageState((prevState) => ({
        //   ...prevState,
        //   message: newMessage,
        // }));
        setResponseMessage(newMessage);

        // Set the cursor position after the newly added emoji
        setTimeout(() => {
          input.setSelectionRange(start + emoji.length, start + emoji.length);
          input.focus();
        }, 0);
      }
    }
  };

  const handleFileIconClick = () => {
    const hasMediaMessagePermission = true;
    if (hasMediaMessagePermission) {
      fileInputRef.current?.click();
      // setIconColor("#4B5A5A");
    } else {
      setSendMediaMessageTooltip(true);
      setTimeout(() => {
        setSendMediaMessageTooltip(false);
      }, 2000);
    }
  };

  const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setMediaLoader(true);
    const file: File | null = event.target.files && event.target.files[0];
    // console.log(file, "file");

    if (file) {
      const fileType = file.type;
      const fileSize = file.size;
      // console.log(fileType);
      // console.log(fileSize);
      // Check file size based on type
      let isValidFileSize = false;
      let maxSizeMessage = "";

      if (fileType.startsWith("image/")) {
        isValidFileSize = fileSize <= MAX_IMAGE_SIZE;
        maxSizeMessage = "Images should not exceed 5 MB.";
      }

      if (!isValidFileSize && fileType.startsWith("image/")) {
        setMediaLoader(false);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `File size exceeds the maximum limit. ${maxSizeMessage}`,
          })
        );
        return;
      }

      const formData: any = new FormData();
      formData.append("File", file);

      const validTypes = ["image/"];

      const isValidFileType = validTypes.some(
        (type) => fileType.startsWith(type) || fileType === type
      );

      if (isValidFileType) {
        const uploadResponse: any = await dispatch(uploadFile(formData));
        // console.log("Uploaded file: ", uploadResponse);

        if (uploadResponse.meta.requestStatus === "fulfilled") {
          setMediaLoader(false);
          setMediaUrl(uploadResponse?.payload);
          // setMessageState((prevState) => ({
          //   ...prevState,
          //   selectedFile: file,
          //   mediaUrl: uploadResponse?.payload,
          // }));
        } else {
          setMediaLoader(false);
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: uploadResponse?.payload || "Error uploading file",
            })
          );
        }
      } else {
        setMediaLoader(false);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: "Please select only images.",
          })
        );
      }
    }
  };

  const handleCancel = () => {
    // setMessageState((prevState) => ({
    //   ...prevState,
    //   selectedFile: null,
    // }));
    setMediaUrl("");
    // setVideoSrc(null);
  };

  return (
    <Box>
      {mediaUrl && (
        <Box className={classes.mediaContainer}>
          {/* {messageState?.selectedFile.type.startsWith("image/") && ( */}
          <img
            src={mediaUrl}
            alt="Selected File"
            className={classes.mediaFile}
          />
          {/* )} */}
          <IconButton
            // className={classes.cancelIcon}
            onClick={handleCancel}
            sx={{
              // ml: 2,
              position: "absolute",
              // left: 1,
              top: -6,
              right: 0,
            }}
          >
            <CloseIconSvg />
          </IconButton>
        </Box>
      )}
      <Box display="flex" px={2} justifyContent="space-between">
        {mediaLoader ? (
          <Box sx={{ width: "100%" }}>
            <LoadingComponent height="100%" color={bgColors?.blue} />
          </Box>
        ) : (
          <TextField
            fullWidth
            //   disabled={
            //     messageState?.selectedFile?.type?.startsWith("audio/")
            //     // ||
            //     // hasTextMessagePermission === false
            //   }
            variant="outlined"
            size="small"
            multiline
            ref={textFieldRef}
            value={responseMessage}
            onChange={(e) => {
              setValidResponseMessage("");
              setResponseMessage(e.target.value);
            }}
            error={validResponseMessage !== ""}
            helperText={validResponseMessage !== "" ? validResponseMessage : ""}
            // onKeyDown={(event: any) =>
            //   handleKeyDown(event, messageState, resetState)
            // }
            placeholder="Write a response..."
            // placeholder={message.trim().length > 0 ? '' : ' '}
            className={`${classes.inputtest} custom-placeholder`}
            sx={{
              borderColor: bgColors?.gray2, // Idle border color
              "& .MuiInputBase-root": {
                minHeight: messageState?.selectedFile ? 200 : 40,
                padding: "2px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: bgColors?.gray2, // Idle border color
                  borderWidth: "1px",
                },
                "&:hover fieldset": {
                  // borderColor: hasTextMessagePermission ? bgColors?.gray2 : "", // Hover border color
                  borderWidth: "1px",
                },
                "&.Mui-focused fieldset": {
                  // borderColor: hasTextMessagePermission ? bgColors?.gray2 : "", // Focused border color
                  borderWidth: "1px",
                },
              },
            }}
            InputProps={{
              style: {
                backgroundColor: bgColors?.white1,
                minHeight: 40,
                maxHeight: 300,
                padding: "2px",
                fontSize: "14px",
              }, // Increase text field height
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      style={{ display: "flex", flexDirection: "row" }}
                      ml={0.5}
                      mt={0.8}
                    >
                      <>
                        <Tooltip
                          title="Access Denied"
                          open={
                            messageState?.message === "" &&
                            sendEmojiMessageTooltip
                          }
                          placement="top"
                          onClose={() => setSendEmojiMessageTooltip(false)}
                        >
                          <Box
                            pr={1}
                            className={classes.cursor}
                            onClick={(e) => {
                              if (
                                !openTextField
                                //    &&
                                //   !messageState?.selectedFile?.type?.startsWith(
                                //     "audio/"
                                //   )
                              ) {
                                handleEmojiClick(e);
                              }
                            }}
                          >
                            <ChatEmojiIcon fillColor="#4B5A5A" />
                          </Box>
                        </Tooltip>
                        <EmojiPopover
                          open={emojiPopoverOpen}
                          anchorEl={anchorEl}
                          onClose={handleCloseEmojiPopover}
                          onEmojiSelect={handleEmojiSelect}
                        />

                        <Tooltip
                          title="Access Denied"
                          open={
                            messageState?.message === "" &&
                            sendMediaMessageTooltip
                          }
                          placement="top"
                          onClose={() => setSendMediaMessageTooltip(false)}
                        >
                          <Box
                            pr={1}
                            className={classes.cursor}
                            onClick={() => {
                              if (!openTextField) {
                                handleFileIconClick();
                              }
                            }}
                          >
                            <ChatFileIcon
                              fill="#4B5A5A"
                              // fill={openTemplateDialog ? "#cdcdcd" : "#4B5A5A"}
                            />
                            <input
                              ref={fileInputRef}
                              type="file"
                              multiple
                              accept="image/*" // Allows only image files (e.g., .jpg, .png, .gif, etc.)
                              // accept=".pdf,.doc,.ppt,image/*,video/*"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                          </Box>
                        </Tooltip>
                      </>
                    </Box>
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default TextFieldComponent;
