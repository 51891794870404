import { Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import ProfileSideBar from "../../components/HomeComponents/ProfileSideBar";
import { Outlet, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import RightArrowSvg from "../../assets/svgs/RightArrowSvg";
import PersistentDrawerLeft from "../profile/profileMenuDrawer";

const useStyles = makeStyles({
  sideBar: {
    position: "sticky",
    top: 0,
    height: "100vh",
    overflowY: "auto",
  },
  outlet: {
    overflowY: "auto",
    height: "100vh",
    backgroundColor:' #F8F8F8;'
  },
});

const ProfileMainPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  // Check if the current route starts with "/profile/"
  const isProfileRoute = location.pathname.startsWith("/profile/");
  // const isInbox = location.pathname.startsWith("/inbox");
  return (
    <>
      <Grid
        item
        xs={0}
        md={2}
        sx={{ display: { xs: "none", md: "block" } }}
        className={classes.sideBar}
      >
        {isProfileRoute && <ProfileSideBar />}
      </Grid>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleOpenDrawer}
        edge="start"
        // sx={{ mr: 2, ...(open && { display: "none" }) }}
        sx={{
          display: { xs: "flex", md: "none" },
          position: "absolute",
          left: 0,
          top: 160,
          background: "#cdcdcd",
        }}
      >
        <RightArrowSvg />
      </IconButton>
      <PersistentDrawerLeft
        open={openDrawer}
        handleCloseDrawer={handleCloseDrawer}
      />
      <Grid item xs={12} md={10} className={classes.outlet}>
        <Outlet />
      </Grid>
    </>
  );
};

export default ProfileMainPage;
