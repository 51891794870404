import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowDownSvg from "../../assets/svgs/ArrowDownSvg";
import TemplatesFilterPopover from "../../components/TemplateComponents/TemplatesFilterPopover";
import React, { useCallback, useEffect, useRef, useState } from "react";
import TemplateCardView from "../../components/TemplateComponents/templateCard";
import TemplateComponent from "../../components/TemplateComponents/TemplateForm";
import SearchIconSvg2 from "../../assets/svgs/SearchIconSvg2";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { fetchAllTemplatesByCompanyId } from "../../redux/slices/Templates/AllTemplatesSlice";
import LoadingComponent from "../../components/common/LoadingComponent";
// import PaginationComponent from "../../components/common/PaginationComponent";
import useDebouncedFetch from "../../utils/debounceHook";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import { SiAuthy, SiMarketo } from "react-icons/si";
import { SiTicktick } from "react-icons/si";
import { MdDelete, MdOutlineDataUsage } from "react-icons/md";
import { IoIosCloseCircle } from "react-icons/io";
import { GiSandsOfTime } from "react-icons/gi";
import { VscIssueDraft } from "react-icons/vsc";
import { MdChecklist } from "react-icons/md";
import { BiSolidCategory } from "react-icons/bi";
import { checkTemplatesLibraryPermission } from "../../utils/permissions";
import NoAccessPage from "../../components/common/NoAccess";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100vh",
    width: "100%",
    overFlow: "hidden !important",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
    overFlow: "hidden !important",
    display: "flex",
    flexDirection: "column",
  },
  searchField: {
    width: "100%",
    borderRadius: "12px",
    // height: "38px",
    // backgroundColor: bgColors.white2,
    backgroundColor: "white",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "inter",
      color: "#000000 !important",
    },
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
  },
  messageCountContainer: {
    // border: `2px solid ${bgColors.gray5}`,
    // borderRadius: "5px",
    // padding: "3px",
    // paddingRight: "5px",
  },
  messageInnerContainer: {
    border: "2px solid #F2F2F2",
    borderRadius: "6px",
    paddingInline: "4px",
    display: "flex",
    flexDirection: "row",
  },
  iconStyles: {
    cursor: "pointer",
    paddingLeft: "5px",
  },
  rotatedIcon: {
    cursor: "pointer",
    paddingRight: "5px",
    transform: "rotate(180deg)",
  },
  SaveChangesButton: {
    // backgroundColor: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    color: bgColors.green,
    borderRadius: "8px",
    width: "120px",
    height: "32px",
    // padding: "10px",
    cursor: "pointer",
  },
});

const options = [
  {
    id: 1,
    option: "View All",
  },
  {
    id: 2,
    option: "Category",
  },
];
const subOptions = {
  Category: [
    { id: 1, option: "Marketing" },
    { id: 2, option: "Utility" },
    // { id: 3, option: "Authentication" },
  ],
  // SubCategory: subCategoriesList,
};
const TemplateLibrary = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  let templateRef = useRef<HTMLDivElement>(null);
  const [noofCols, SetNoofCols] = useState("3");

  const userData = useAppSelector((state: any) => state?.adminLogin?.data);
  const templatesSlice = useAppSelector(
    (state: any) => state?.allTemplatesData
  );
  const removeTemplateSlice = useAppSelector(
    (state: any) => state?.removeTemplateData
  );
  const createTemplateSlice = useAppSelector(
    (state: any) => state?.createTemplateData
  );
  const updateTemplateSlice = useAppSelector(
    (state: any) => state?.updateTemplateData
  );
  // const activeTemplatesData = templatesSlice?.data?.data?.filter(
  //   (item: any) => item?.status !== null
  // );
  const getuserPermissionData = useAppSelector(
    (state: any) => state.getUserPermissions?.data
  );
  const debouncedFetchTemplates = useDebouncedFetch(
    fetchAllTemplatesByCompanyId,
    1500
  );
  const templatesPermissionsArray = getuserPermissionData?.Templates;
  const hasTemplatesLibraryPermission = checkTemplatesLibraryPermission(
    templatesPermissionsArray
  );
  const libraryPermissionsObject = templatesPermissionsArray?.find(
    (item: any) => Object.prototype.hasOwnProperty.call(item, "library")
  );
  const libraryPermissionsActions = libraryPermissionsObject
    ? libraryPermissionsObject.library
    : [];

  const [
    newLibraryTemplatePermissionTooltipOpen,
    setNewLibraryTemplatePermissionTooltipOpen,
  ] = useState(false);
  const [
    editLibraryTemplatePermissionTooltipOpen,
    setEditLibraryTemplatePermissionTooltipOpen,
  ] = useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState({
    column: "",
    value: "",
  });
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editObjectData, setEditObjectData] = React.useState({
    canEdit: false,
    templateId: "",
  });
  const [pageNumber, setPageNumber] = React.useState(1);
  const [search, setSearch] = React.useState<string>("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [pageData, setPageData] = useState(templatesSlice?.data?.data || []);

  const hasAccess = (permission: any) => {
    if (libraryPermissionsActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  const handleTemplateFilter = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilterPopover = () => {
    setAnchorEl(null);
  };
  const handleOptionClick = (option: string, subOption: string) => {
    // console.log("option", option, subOption);
    // setPageData([]);
    setPageNumber(1);
    setSelectedFilter({
      column: option,
      value: subOption,
    });
    handleCloseFilterPopover();
  };
  const handleOpenDialog = () => {
    const hasPermission = hasAccess("newLibraryTemplate");
    if (hasPermission) {
      setOpenDialog(true);
      setEditObjectData({ canEdit: false, templateId: "" });
    } else {
      setNewLibraryTemplatePermissionTooltipOpen(true);
      setTimeout(() => {
        setNewLibraryTemplatePermissionTooltipOpen(false);
      }, 2000);
    }
  };
  const handleEditToggle = (action: string, templateId: string) => {
    const hasPermission = hasAccess("editLibraryTemplate");
    if (hasPermission) {
      setOpenDialog(true);
      setEditObjectData(
        action === "edit"
          ? { canEdit: true, templateId: templateId }
          : { canEdit: false, templateId: "" }
      );
    } else {
      setEditLibraryTemplatePermissionTooltipOpen(true);
      setTimeout(() => {
        setEditLibraryTemplatePermissionTooltipOpen(false);
      }, 2000);
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const tempWidth = (templateRef: any) => {
    if (!templateRef.current) return;
    const offsetWidth = templateRef.current.offsetWidth;
    const divWidth = [
      { width: 3200, cols: "10" },
      { width: 2500, cols: "9" },
      { width: 2250, cols: "8" },
      { width: 2000, cols: "7" },
      { width: 1800, cols: "6" },
      { width: 1400, cols: "5" },
      { width: 990, cols: "4" },
      { width: 900, cols: "3" },
      { width: 750, cols: "3" },
      { width: 650, cols: "2" },
      { width: 0, cols: "1" }, // catch-all case for widths below 650
    ];
    for (const { width, cols } of divWidth) {
      if (offsetWidth >= width) {
        SetNoofCols(cols);
        break;
      }
    }
  };

  useEffect(() => {
    const handleResize = () => tempWidth(templateRef);
    window.addEventListener("resize", handleResize);
    handleResize(); // initial check
    return () => window.removeEventListener("resize", handleResize);
  }, [templateRef]);

  useEffect(() => {
    const postData = {
      userId: userData?.userId,
      businessId: userData?.companyId,
      pageNumber: pageNumber,
      per_page: 20,
      filters: {
        searching: {
          value: search,
        },
        sorting: {
          column: "",
          order: "",
        },
        filtering: {
          filterType: "and",
          conditions: [
            {
              column: "Status",
              operator: "equal",
              value: "2",
            },
            {
              column: selectedFilter?.column,
              operator: "equal",
              value: selectedFilter?.value,
            },
          ],
        },
      },
    };
    if (search) {
      debouncedFetchTemplates(postData);
    } else {
      // Clear search, call API immediately
      dispatch(fetchAllTemplatesByCompanyId(postData));
    }
    if (templateRef?.current) {
      tempWidth(templateRef);
    }
  }, [
    dispatch,
    search,
    selectedFilter,
    pageNumber,
    createTemplateSlice,
    updateTemplateSlice,
    removeTemplateSlice,
  ]);

  // console.log("getuserPermissionData", getuserPermissionData);
  // console.log("removeTemplateSlice1", templatesSlice);
  // console.log("subCategoriesList", subCategoriesList, uniqueSubCategoriesSet);
  const tableContainerRef = useRef(null);
  const handleScroll = () => {
    if (tableContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        tableContainerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 20 &&
        !isLoadingMore &&
        pageData.length !== templatesSlice?.data?.total
      ) {
        handleLoadMore();
      }
    }
  };

  const handleLoadMore = useCallback(() => {
    if (
      !isLoadingMore &&
      pageData.length !== templatesSlice?.data?.total &&
      pageNumber <= Math.ceil(templatesSlice?.data?.total / 40)
    ) {
      setIsLoadingMore(true);
      setPageNumber((prevPage) => prevPage + 1);
    }
  }, [isLoadingMore, pageData.length, templatesSlice?.data?.total]);

  useEffect(() => {
    if (templatesSlice?.data?.data) {
      if (pageNumber === 1) {
        setPageData(templatesSlice?.data?.data);
      } else {
        // Ensure templatesSlice?.data?.data is an array before spreading
        const newTemplates = Array.isArray(templatesSlice?.data?.data)
          ? templatesSlice.data.data
          : [];

        setPageData((prevPageData: any) => [...prevPageData, ...newTemplates]);
      }

      setIsLoadingMore(false);
    }
  }, [templatesSlice?.data?.data]);

  const getIconComponent = (column: string, option: any) => {
    // console.log('Option.....',option);
    switch (option) {
      case column === "Category" && "1":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <SiMarketo />
            </span>
            &nbsp; Marketing
          </span>
        );
      case column === "Category" && "2":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <SiAuthy />
            </span>
            &nbsp; Utility
          </span>
        );
      // case column === "Category" && "3":
      //   return (
      //     <span style={{ display: "flex", alignItems: "center" }}>
      //       <span style={{ paddingTop: "3px" }}>
      //         <MdOutlineDataUsage />
      //       </span>
      //       &nbsp; Authentication
      //     </span>
      //   );
      // Add more cases as needed
      case "1":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <GiSandsOfTime />
            </span>
            &nbsp; Pending
          </span>
        );
      case "2":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <SiTicktick />
            </span>
            &nbsp; Approved
          </span>
        );
      case "3":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <IoIosCloseCircle />
            </span>
            &nbsp; Rejected
          </span>
        );
      case "4":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <VscIssueDraft />
            </span>
            &nbsp; Draft
          </span>
        );
      case "5":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <MdDelete />
            </span>
            &nbsp; Deleted
          </span>
        );
      case "Category":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <BiSolidCategory />
            </span>
            &nbsp; Category
          </span>
        );
      case "Status":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}></span>&nbsp; Status
          </span>
        );
      case "":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <MdChecklist />
            </span>
            &nbsp; View All
          </span>
        );
      default:
        return ""; // Default icon or content
    }
  };

  return (
    <Grid className={classes.mainContainer} ref={templateRef}>
      {hasTemplatesLibraryPermission ? (
        <Box className={classes.bgContainer}>
          <Box sx={{ height: "120px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  width: "100%",
                  alignItems: "center",
                }}
                m={{ xs: 1, md: 0 }}
              >
                <Typography
                  variant="h6"
                  ml={{ xs: 1, md: 3 }}
                  className={classes.blackColor}
                  sx={{ display: "flex" }}
                >
                  <span style={{ marginTop: "5px" }}>
                    <LocalLibraryOutlinedIcon sx={{ height: "22px" }} />
                  </span>
                  &nbsp;Library
                </Typography>
                <TextField
                  className={classes.searchField}
                  variant="standard"
                  size="small"
                  fullWidth
                  value={search}
                  onChange={(event: any) => {
                    setPageNumber(1);
                    setSearch(event?.target.value);
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { fontSize: "12px", padding: "7px", height: "38px" },
                    startAdornment: (
                      <IconButton
                        sx={{ p: 0, color: "inherit" }}
                        aria-label="search"
                      >
                        {/* <SearchIconSvg /> */}
                        <SearchIconSvg2 />
                      </IconButton>
                    ),
                  }}
                  inputProps={{
                    style: {
                      // Additional style for placeholder
                      fontWeight: "600 !important",
                      paddingTop: "3px", // Apply font weight here
                    },
                  }}
                  sx={{ mx: 4 }}
                  placeholder="Search approved templates"
                />
              </Box>
              <Box style={{ marginLeft: "auto" }} m={3}>
                <Tooltip
                  title="Access Denied"
                  placement="top"
                  open={newLibraryTemplatePermissionTooltipOpen}
                  onClose={() =>
                    setNewLibraryTemplatePermissionTooltipOpen(false)
                  }
                >
                  <button
                    className={classes.SaveChangesButton}
                    onClick={handleOpenDialog}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{
                      backgroundColor: isHovered
                        ? "rgba(68, 71, 70, 0.08)"
                        : "#fff",
                      cursor: isHovered ? "pointer" : "default", // Optional: change cursor style on hover
                    }}
                  >
                    + Add Template
                  </button>
                </Tooltip>
                <TemplateComponent
                  editObjectData={editObjectData}
                  open={openDialog}
                  handleClose={handleCloseDialog}
                />
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              ml={3}
            >
              <Box style={{ display: "flex", flexDirection: "row" }}>
                <Box className={classes.messageCountContainer}>
                  <Box className={classes.messageInnerContainer}>
                    <Typography
                      className={classes.grayColor}
                      style={{
                        padding: "3px",
                        fontSize: 12,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {getIconComponent(
                        selectedFilter?.column,
                        selectedFilter?.value
                      )}
                      &nbsp; &nbsp;|
                    </Typography>
                    <Box
                      className={
                        anchorEl ? classes.rotatedIcon : classes.iconStyles
                      }
                      onClick={handleTemplateFilter}
                    >
                      <ArrowDownSvg />
                    </Box>
                    <TemplatesFilterPopover
                      anchorEl={anchorEl}
                      handleClose={handleCloseFilterPopover}
                      options={options}
                      subOptions={subOptions}
                      handleOptionClick={handleOptionClick}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: "1", overflow: "hidden" }}>
            {templatesSlice?.status === "loading" && !isLoadingMore ? (
              <LoadingComponent height="67vh" color={bgColors.blue} />
            ) : (
              <>
                <Box sx={{ overflow: "hidden", height: "90%" }}>
                  <Box
                    sx={{ overflow: "auto", height: "100%" }}
                    mt={1}
                    onScroll={handleScroll}
                    ref={tableContainerRef}
                  >
                    <Grid
                      container
                      gap={2}
                      p={{ xs: 2, md: 3 }}
                      sx={{
                        display:
                          pageData?.length !== 0 ? "inline-block" : "flex",
                        columns: `${noofCols}`,
                        columnGap: "auto",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {pageData?.length !== 0 ? (
                        pageData?.map((item: any, index: number) => (
                          <TemplateCardView
                            templateData={item}
                            userData={userData}
                            handleEditToggle={handleEditToggle}
                            key={index}
                            hasAccessCheck={hasAccess}
                            editLibraryTemplatePermissionTooltipOpen={
                              editLibraryTemplatePermissionTooltipOpen
                            }
                            setEditLibraryTemplatePermissionTooltipOpen={
                              setEditLibraryTemplatePermissionTooltipOpen
                            }
                          />
                        ))
                      ) : (
                        <Box
                          sx={{
                            height: "50vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          No Data Found
                        </Box>
                      )}
                    </Grid>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                  }}
                >
                  {pageData?.length !== 0 &&
                    pageData !== null &&
                    pageData.length !== templatesSlice?.data?.total &&
                    !isLoadingMore && (
                      <Typography
                        onClick={handleLoadMore}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            color: "blue",
                          },
                        }}
                      >
                        {" "}
                        {templatesSlice?.data?.total !== "loading" ? (
                          // <button
                          //   style={{
                          //     cursor: 'pointer',
                          //     fontSize: "14px",
                          //     borderRadius: '5px',
                          //     border: `1px solid ${bgColors.green}`,
                          //     backgroundColor: bgColors.white,
                          //     color: bgColors.green,
                          //     padding: '5px'
                          //   }}
                          // >
                          //   Load More...
                          // </button>
                          <></>
                        ) : (
                          ""
                        )}
                      </Typography>
                    )}
                  {isLoadingMore && (
                    <LoadingComponent height="20px" color={bgColors.blue} />
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
      ) : (
        <NoAccessPage />
      )}
    </Grid>
  );
};

export default TemplateLibrary;
