import axios from "axios";
const OPTED_API_URL = process.env.REACT_APP_BASE_URL;

const optedKeywords = (data: any) => {
  return axios({
    url: `${OPTED_API_URL}/OptInManagement/OptInManagement`,
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    data,
  });
};

const getOptoutKeywords = (businessId: string) => {
  return axios({
    url: `${OPTED_API_URL}/OptInManagement/OptOutKeyword/${businessId}`,
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
};

const getOptinKeywords = (businessId: string) => {
  return axios({
    url: `${OPTED_API_URL}/OptInManagement/OptInKeyword/${businessId}`,
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
};

const deleteKeywords = (id: string, businessId: string, keyword: string) => {
  return axios({
    url: `${OPTED_API_URL}/OptInManagement/${id}/${businessId}/${keyword}`,
    method: "DELETE",
    headers: {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
};
export const OPTED_MANAGEMENT_APIS = {
  optedKeywords,
  getOptoutKeywords,
  getOptinKeywords,
  deleteKeywords,
};
