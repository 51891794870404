import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTOMATION_API } from "../../../Apis/AdminLogin/Automation/Automation";

export interface IData {
    status: "loading" | "succeeded" | "failed" | "idle";
    data: any;
  }

  const initialState: IData = {
    status: "idle",
    data: null,
  };

  export const getAutoReply = createAsyncThunk(
    "autoReply/getAutoReply",
    async (payload: any, { rejectWithValue }) => {
      try {
        const response = await AUTOMATION_API.getAutoReplyCustomMessage(payload?.companyId);
        return response?.data;
      } catch (error: any) {
        // Provide a descriptive error message
        return rejectWithValue(error.response || 'An error occurred');
      }
    }
  );


  export const getAutoReplySlice = createSlice({
    name: "getAutoReply",
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
      builder
        .addCase(getAutoReply.pending, (state) => {
          state.status = "loading";
          state.data = null;
        })
        .addCase(getAutoReply.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.data = action.payload.data;
        })
        .addCase(getAutoReply.rejected, (state) => {
          state.status = "failed";
        });
    },
  });


export const getAutoReplySliceActions = getAutoReplySlice.actions;
export default getAutoReplySlice.reducer;
