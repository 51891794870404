import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  InputLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TextFeildWithBorderComponet from "../../common/TextFieldWithBorderComponent";
import { bgColors } from "../../../utils/bgColors";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import { IoMdPricetag } from "react-icons/io";
import { createSelectResponse } from "../../../redux/slices/Workflows/createSelectResponseSlice";
import LoadingComponent from "../../common/LoadingComponent";
import { getSelectResponse } from "../../../redux/slices/Workflows/getSelectResponse";
import { toastActions } from "../../../utils/toastSlice";
import { updateSelectResponse } from "../../../redux/slices/Workflows/updateSelectResponseSlice";

const useStyles = makeStyles({
  changePasswordContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "500 !important",
  },
  signOutButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "36px",
    borderRadius: "8px",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingInline: "27px ",
    cursor: "pointer",
  },
  cancelButtonStyles: {
    backgroundColor: "#ffffff",
    color: "#000000",
    height: "36px",
    borderRadius: "8px",
    paddingInline: "27px ",
    border: `1px solid ${bgColors.gray3}`,
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
});

const AddSelectResponse = ({
  open,
  handleClose,
  handleResponseChange,
  editSelectResponseId,
  setResponse,
  response,
}: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const { createSelectResponseStatus } = useAppSelector(
    (state: any) => state.createSelectResponse
  );

  const handleChange = (e: any) => {
    setResponse(e.target.value);
    setError(false);
    setHelperText("");
  };

  const handleSave = async () => {
    const payload = {
      companyId: userData?.companyId,
      response,
    };

    const updateApiPayload = {
      selectResponseId: editSelectResponseId,
      response,
    };

    if (!response.trim()) {
      setError(true);
      setHelperText("Response cannot be empty");
      return;
    }

    try {
      let result;

      if (editSelectResponseId) {
        // If `editSelectResponseId` is present, update the existing response
        result = await dispatch(
          updateSelectResponse(updateApiPayload)
        ).unwrap();
      } else {
        // Else, create a new response
        result = await dispatch(createSelectResponse(payload)).unwrap();
      }

      if (result) {
        handleClose();
        handleResponseChange(response);
        setResponse("");
        dispatch(
          toastActions.setToaster({
            message: result.message,
            type: "success",
          })
        );
      } else {
        dispatch(
          toastActions.setToaster({
            message: result.message || "Failed to create or update response",
            type: "error",
          })
        );
      }
    } catch (err) {
      setError(true);
      setHelperText("Failed to add or update response. Please try again.");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperProps={{ sx: { minWidth: "28%", borderRadius: "20px" } }}
    >
      <DialogTitle>
        <Box mx={1} mb={0} className={classes.changePasswordContainer}>
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IoMdPricetag /> &nbsp;{editSelectResponseId ? "Update" : "Add"}{" "}
            Response
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mx={0} mt={0}>
          <Box>
            <InputLabel className={classes.blackColor}>
              Response
            </InputLabel>
            <TextFeildWithBorderComponet
              label="Enter your response"
              name="text"
              placeholder=""
              value={response}
              error={error}
              helperText={helperText}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </DialogContent>
      {createSelectResponseStatus === "loading" ? (
        <LoadingComponent height="100%" color={bgColors?.blue} />
      ) : (
        <DialogActions sx={{ paddingTop: "0px" }}>
          <Box
            m={{ xs: 1, md: 2 }}
            sx={{ marginTop: "0px !important" }}
            display="flex"
            flexDirection="row"
            gap={2}
            justifyContent={"center"}
            width="100%"
          >
            <button
              className={classes.cancelButtonStyles}
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className={classes.signOutButtonStyles}
              onClick={handleSave}
            >
              {editSelectResponseId ? "Update" : "Add"} Response
            </button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AddSelectResponse;
