import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WORKFLOW_API } from "../../../Apis/AdminLogin/Automation/Workflows";

export interface IData {
    downloadWorkflowStatus: "loading" | "succeeded" | "failed" | "idle";
    downloadWorkflowData: any;
  }

  const initialState: IData = {
    downloadWorkflowStatus: "idle",
    downloadWorkflowData: null,
  };

  export const downloadWorkflowByName = createAsyncThunk(
    "workflow/downloadWorkflowByName",
    async (payload: any, { rejectWithValue }) => {
      try {
        const response = await WORKFLOW_API.downloadWorkflowResponseListByName(payload);
        return response?.data;
      } catch (error: any) {
        // Provide a descriptive error message
        return rejectWithValue(error.response || 'An error occurred');
      }
    }
  ) as any;


  export const downloadWorkflowByNameSlice = createSlice({
    name: "downloadWorkflowByName",
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
      builder
        .addCase(downloadWorkflowByName.pending, (state) => {
          state.downloadWorkflowStatus = "loading";
          state.downloadWorkflowData = null;
        })
        .addCase(downloadWorkflowByName.fulfilled, (state, action) => {
          state.downloadWorkflowStatus = "succeeded";
          state.downloadWorkflowData = action.payload.data;
        })
        .addCase(downloadWorkflowByName.rejected, (state) => {
          state.downloadWorkflowStatus = "failed";
        });
    },
  });


export const downloadWorkflowByNameSliceActions = downloadWorkflowByNameSlice.actions;
export default downloadWorkflowByNameSlice.reducer;
