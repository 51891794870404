import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Home from "../pages/home/Home";
import ManageAccount from "../pages/profile/manageAccount/ManageAccount";
import ManageCompany from "../pages/profile/manageCompany/ManageCompany";
import ManageClients from "../pages/profile/manageClients/ManageClients";
import ManagePermissions from "../pages/profile/managePermissions/ManagePermissions";
import ManageNotifications from "../pages/profile/manageNotifications/ManageNotifications";
import HelpCenter from "../pages/profile/helpCenter/HelpCenter";
import LoginForgotPassword from "../pages/ForgotPassword/LoginForgotPassword";
import AdminLogin from "../pages/Login/AdminLogin";
import NewPassword from "../pages/ForgotPassword/NewPassword";
import VerificationCode from "../pages/Verification/VerificationCode";
import ResetPassword from "../pages/ForgotPassword/ResetPassword";
import EditManageAccount from "../pages/profile/editManageAccount/EditManageAccount";
import ManagePayments from "../pages/profile/manageAccount/ManagePayments";
import EditManageCompany from "../pages/profile/EditManageCompany/EditManageCompany";
import Subscription from "../pages/profile/Subscription/Subscription";
import ManageClientDetails from "../pages/profile/manageClients/ManageClientDetails";
import Inbox from "../pages/Inbox/Inbox";
import Campaigns from "../pages/Campaigns/Campaigns";
import Contacts from "../pages/Contacts/Contacts";
import Automation from "../pages/Automation/Automation";
import Wallet from "../pages/Wallet/Wallet";
import UpgradePlan from "../pages/profile/Subscription/UpgradePlan";
import ProtectedRoute from "../components/LoginComponents/ProtectedRoute";
import EditManageClient from "../pages/profile/EditManageClient/EditManageClient";
import Scheduled from "../pages/Campaigns/Scheduled";
import AnalyticsOverview from "../pages/Analytics/AnalyticsOverview";
import AnalyticsAgentPerformance from "../pages/Analytics/AnalyticsAgentPerformance";
import InboxAnalytics from "../pages/Analytics/InboxAnalytics";
import AdPerformance from "../pages/Analytics/AdPerformance";
import TemplateLibrary from "../pages/Templates/Library";
import AllTemplates from "../pages/Templates/All";
import BillingDetails from "../pages/Wallet/BillingDetails";
import BillingDetailsEdit from "../pages/Wallet/BillingDetails_Edit";
import { useAppDispatch, useAppSelector } from "../utils/redux-hooks";
import EmbeddedSignUp from "../pages/Login/EmbeddedSignup";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { toastActions } from "../utils/toastSlice";
import AutoReply from "../pages/Automation/AutoReply";
import WorkFlow from "../pages/Automation/WorkFlow";
import ProfilePaymentMethod from "../pages/profile/Subscription/PaymentMethod";
import DesktopOnlyMessage from "../components/common/MobileAlert";
import Bot from "../pages/Automation/Bot";
import OptinManagement from "../pages/Automation/OptinManagement";
import CommerceSettings from "../pages/Commerce/CommerceSettings";
import Catalog from "../pages/Commerce/Catalog";
// import OrderPanel from "../pages/Commerce/OrderPanel";
// import AutoCheckoutFlow from "../pages/Commerce/AutoCheckoutFlow";
import Discover from "../pages/Integrations/Discover";
import Tags from "../pages/profile/ManageTags/Tags";
import WhatsappLink from "../pages/Integrations/WhatsappLink";
import WhatsappWidget from "../pages/Integrations/WhatsappWidget";

const AppRoutes = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const loginData = useAppSelector((state: any) => state.adminLogin.data);
  const isAuthenticated = !!token;
  const currentPlanDetails = useAppSelector(
    (state: any) => state?.wallet?.walletAndSubscription?.data
  );

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  useEffect(() => {
    const hideLoadingScreen = () => {
      const loadingScreen = document.getElementById("loading-screen");
      if (loadingScreen) {
        loadingScreen.style.display = "none";
      }
    };
    hideLoadingScreen();
  }, []);

  // Axios request interceptor
  axios.interceptors.request.use(async (config) => {
    // Get token from local storage
    const token = localStorage.getItem("token");

    const api = ["login", "send-otp", "verify-otp", "update-password"];
    const includesApi = api.some((apiEndpoint) =>
      config?.url?.includes(apiEndpoint)
    );

    if (!includesApi) {
      if (token) {
        try {
          const decodedToken: any = jwtDecode(token);
          const expirationDate = moment
            .unix(decodedToken.exp)
            .format("YYYY-MM-DD HH:mm:ss");
          const currentTime = moment();

          if (currentTime.isAfter(expirationDate)) {
            // await clearStorageAndCaches();
            localStorage.clear();
            localStorage.removeItem("persist:root");
            localStorage.removeItem("token");
            sessionStorage.clear();
            // const cacheNames = await caches.keys();
            navigate("/login");
            // window.location.reload();
            dispatch(
              toastActions.setToaster({
                type: "error",
                message: "Session Expired",
              })
            );
          }
        } catch (error) {
          // Handle invalid token format or decoding issues
          // await clearStorageAndCaches();
          console.log("error", error);

          localStorage.clear();
          localStorage.removeItem("persist:root");
          localStorage.removeItem("token");
          sessionStorage.clear();
          // const cacheNames = await caches.keys();
          navigate("/login");
          // window.location.reload();
        }
      } else {
        navigate("/login");
      }
    }

    return config;
  });

  // Axios response interceptor
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        // await clearStorageAndCaches();
        localStorage.clear();
        localStorage.removeItem("persist:root");
        localStorage.removeItem("token");
        sessionStorage.clear();
        navigate("/login");
        // window.location.reload();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (location.pathname === "") {
      if (token && currentPlanDetails?.subscriptionPlan?.isActive) {
        navigate("/inbox/help");
      } else if (
        token &&
        (currentPlanDetails === undefined ||
          currentPlanDetails === null ||
          currentPlanDetails?.subscriptionPlan?.isActive === false)
      ) {
        navigate(`/profile/manage-account/${loginData?.userId}`);
      } else {
        navigate("/login");
      }
    }
  }, [navigate, token, location]);

  return isDesktopOrLaptop ? (
    <Routes>
      <Route path="/login" element={<AdminLogin />} />
      <Route path="/facebook-login" element={<EmbeddedSignUp />} />
      <Route path="/forgot-password" element={<LoginForgotPassword />} />
      <Route path="/new-password" element={<NewPassword />} />
      <Route path="/verification-code" element={<VerificationCode />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      {token && (
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
          <Route path="/" element={<Home />}>
            <Route
              path="/profile/manage-account/:id"
              element={<ManageAccount />}
            />
            <Route
              path="/profile/manage-account/payments"
              element={<ManagePayments />}
            />

            <Route
              path="/profile/manage-account/:id/edit"
              element={<EditManageAccount />}
            />

            <Route
              path="/profile/manage-company/:id"
              element={<ManageCompany />}
            />
            <Route
              path="/profile/manage-company/:id/edit"
              element={<EditManageCompany />}
            />
            <Route path="/profile/manage-clients" element={<ManageClients />} />
            <Route
              path="/profile/manage-clients/:id"
              element={<ManageClientDetails />}
            />
            <Route
              path="/profile/manage-clients/edit/:id"
              element={<EditManageClient />}
            />
            <Route
              path="/profile/manage-permissions"
              element={<ManagePermissions />}
            />
            <Route
              path="/profile/manage-notifications"
              element={<ManageNotifications />}
            />
            <Route path="/profile/manage-tags" element={<Tags />} />
            <Route path="/profile/subscription" element={<Subscription />} />
            <Route path="/profile/upgrade-plan" element={<UpgradePlan />} />
            <Route path="/profile/payment" element={<ProfilePaymentMethod />} />
            <Route path="/profile/help-center" element={<HelpCenter />} />
            <Route path="/inbox/:id/" element={<Inbox />} />
            <Route path="/campaigns/one-time" element={<Campaigns />} />
            <Route path="/campaigns/scheduled" element={<Scheduled />} />
            <Route path="/analytics/overview" element={<AnalyticsOverview />} />
            <Route
              path="/analytics/agent-performance"
              element={<AnalyticsAgentPerformance />}
            />
            <Route
              path="/analytics/inbox-analytics"
              element={<InboxAnalytics />}
            />
            <Route
              path="/analytics/ad-performance"
              element={<AdPerformance />}
            />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/automation/inbox-settings" element={<Automation />} />
            <Route path="/automation/auto-reply" element={<AutoReply />} />
            <Route path="/automation/workflows" element={<WorkFlow />} />
            <Route
              path="/automation/optin-management"
              element={<OptinManagement />}
            />
            <Route path="/automation/bot" element={<Bot />} />
            <Route path="/automation" element={<Automation />} />
            <Route path="/templates-library" element={<TemplateLibrary />} />
            <Route path="/templates-all" element={<AllTemplates />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/billing-details" element={<BillingDetails />} />
            <Route
              path="/billing-details/edit"
              element={<BillingDetailsEdit />}
            />
            <Route path="/commerce-settings" element={<CommerceSettings />} />
            <Route path="/catalog" element={<Catalog />} />
            <Route path="/integrations-discover" element={<Discover />} />
            {/* <Route
              path="/integrations/whatsapp-link"
              element={<WhatsAppLink />}
            /> */}
            <Route path="/whatsapp-link" element={<WhatsappLink />} />
            <Route path="/whatsapp-widget" element={<WhatsappWidget />} />

            {/* <Route path="/order-panel" element={<OrderPanel />} />
            <Route path="/auto-checkout-flow" element={<AutoCheckoutFlow />} /> */}
          </Route>
          {currentPlanDetails !== undefined &&
          currentPlanDetails?.subscriptionPlan?.isActive ? (
            <Route path="*" element={<Navigate to="/inbox/help" />} />
          ) : (
            <Route
              path="*"
              element={
                <Navigate to={`/profile/manage-account/${loginData?.userId}`} />
              }
            />
          )}
        </Route>
      )}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  ) : (
    <DesktopOnlyMessage />
  );
};

export default AppRoutes;
