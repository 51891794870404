import { makeStyles } from '@mui/styles';
import { Popover, Box, Button, Typography } from '@mui/material';
import { bgColors } from '../../utils/bgColors';

const useStyles = makeStyles({
  popoverContent: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
    cursor: "pointer",
  },
  redColor:{
    color:`${bgColors.red} !important`,
    cursor: "pointer", 
  }
});

const EditDeletePopover = ({ open,anchorEl, onClose, onEdit, onDelete }:any) => {
  const classes = useStyles();

  return (
    <Popover
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    >
      <Box className={classes.popoverContent}>
        <Typography variant='body1' onClick={onEdit} className={classes.blackColor}>Edit</Typography>
        <Typography variant='body1' onClick={onDelete} className={classes.redColor}>Delete</Typography>
      </Box>
    </Popover>
  )
    }
  export default EditDeletePopover;