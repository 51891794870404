import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  InputLabel,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { makeStyles } from "@mui/styles";
import TextFeildWithBorderComponet from "../common/TextFieldWithBorderComponent";
import PhoneInput from "../common/PhoneInput";
import { bgColors } from "../../utils/bgColors";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { createContactTags } from "../../redux/slices/Contacts/CreateContactTags";
import { toastActions } from "../../utils/toastSlice";
import { getContactTags } from "../../redux/slices/Contacts/getContactTags";
import { IoMdPricetag } from "react-icons/io";
import LoadingComponent from "../common/LoadingComponent";
import { editTag } from "../../redux/slices/Contacts/editTagSlice";

const useStyles = makeStyles({
  changePasswordContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "500 !important",
  },
  signOutButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    // width: "30%",
    borderRadius: "8px",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // paddingLeft: "35px",
    // paddingRight: "35px",
    paddingInline: "27px ",
    // fontWeight: "600",
    cursor: "pointer",
  },
  cancelButtonStyles: {
    backgroundColor: "#ffffff",
    color: "#000000",
    height: "40px",
    // width: "30%",
    borderRadius: "8px",
    // paddingLeft: "35px",
    // paddingRight: "35px",
    paddingInline: "27px ",
    border: `1px solid ${bgColors.gray3}`,
    fontSize: "14px",
    // fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
});

const NewTagMember = ({
  open,
  handleClose,
  isEdit,
  tagId,
  addedNewTag,
}: any) => {
  // console.log(open, isEdit, tagId, addedNewTag);
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const newTagData = useAppSelector(
    (state: any) => state?.createContactTagsData
  );
  const editTagData = useAppSelector((state: any) => state?.editTagData);
  // console.log(editTagData, "editTagData");
  const userData = userProfileSlice?.data;

  const [tagsData, setTagsData] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const handleChange = (e: any) => {
    setTagsData(e.target.value);
    setError(false);
    setHelperText("");
  };

  const handleCloseAll = () => {
    handleClose();
    setTagsData("");
    setError(false);
    setHelperText("");
  };

  const handleSave = async () => {
    if (tagsData?.trim() !== "") {
      const bodyData = {
        BusinessId: userData?.companyId,
        UserId: userData?.userId,
        data: tagsData,
      };
      const body = {
        businessId: userData?.companyId,
        userId: userData?.userId,
        search: "",
      };
      try {
        const response: any = await dispatch(createContactTags(bodyData));

        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: response?.payload?.message,
            })
          );
          await dispatch(getContactTags(body));
          handleClose();
          setTagsData("");
          addedNewTag(true, response?.meta?.arg?.data);
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: response?.payload,
            })
          );
          // addedNewTag(false, "");
        }
      } catch (error: any) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: error.payload,
          })
        );
      }
    } else {
      setError(true);
      setHelperText("Tag name is required");
    }
  };

  const handleEdit = async () => {
    if (tagsData?.trim() !== "" && tagId !== "") {
      const bodyData = {
        BusinessId: userData?.companyId,
        UserId: userData?.userId,
        data: { tag: tagsData, id: tagId },
      };
      const body = {
        businessId: userData?.companyId,
        userId: userData?.userId,
        search: "",
      };
      try {
        const response: any = await dispatch(editTag(bodyData));

        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: response?.payload?.message,
            })
          );
          await dispatch(getContactTags(body));
          handleClose();
          setTagsData("");
          addedNewTag(true, response?.meta?.arg?.data);
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: response?.payload,
            })
          );
          // addedNewTag(false, "");
        }
      } catch (error: any) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: error.payload,
          })
        );
      }
    } else {
      setError(true);
      setHelperText("Tag name is required");
    }
  };

  useEffect(() => {
    if (isEdit) {
      setTagsData(isEdit);
    }
  }, [isEdit]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseAll}
      PaperProps={{ sx: { minWidth: "28%", borderRadius: "20px" } }}
    >
      <DialogTitle>
        <Box mx={1} mb={0} className={classes.changePasswordContainer}>
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IoMdPricetag /> &nbsp;
            {isEdit ? "Edit Tag" : "Create Tag"}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mx={0} mt={0}>
          <Box>
            <InputLabel className={classes.blackColor}>Tag Name</InputLabel>
            <TextFeildWithBorderComponet
              label="Tag name"
              name="text"
              placeholder=""
              value={tagsData}
              error={error}
              helperText={helperText}
              onChange={handleChange}
              sx={{
                "& input": {
                  // fontWeight: "600"
                },
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      {newTagData?.status === "loading" || editTagData?.status === "loading" ? (
        <LoadingComponent color={bgColors?.blue} height="auto" />
      ) : (
        <DialogActions
          sx={{
            paddingTop: "0px",
          }}
        >
          <Box
            m={{ xs: 1, md: 2 }}
            sx={{
              marginTop: "0px !important",
            }}
            display="flex"
            flexDirection="row"
            gap={2}
            justifyContent={"center"}
            width="100%"
          >
            <button
              className={classes.cancelButtonStyles}
              onClick={handleCloseAll}
            >
              Cancel
            </button>
            <>
              {isEdit ? (
                <button
                  className={classes.signOutButtonStyles}
                  onClick={handleEdit}
                >
                  Edit
                </button>
              ) : (
                <button
                  className={classes.signOutButtonStyles}
                  onClick={handleSave}
                >
                  Create
                </button>
              )}
            </>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default NewTagMember;
