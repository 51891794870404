import React from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CardComponent = ({
  title,
  description,
  icon,
  handleWhatsappTools,
}: any) => (
  <Paper
    elevation={3}
    sx={{
      padding: "20px",
      // textAlign: "center",
      borderRadius: "8px",
      cursor: "pointer",
    }}
    onClick={handleWhatsappTools}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <img
        src={icon}
        alt={title}
        style={{ height: 75, marginBottom: 16, width: 67 }}
      />
      <Typography variant="h6" component="h2" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ color: "gray" }}>
        {description}
      </Typography>
    </Box>
  </Paper>
);

const WhatsAppTools = () => {
  const history = useNavigate();
  const tools = [
    {
      title: "WhatsApp Website Widget",
      description: "Drive WhatsApp sales with personalised CTAs",
      icon: "/images/whatsappWebsiteWidget.png", // replace with your actual icon URL
    },
    {
      title: "WhatsApp Link Generator",
      description: "Create shareable links & QR for your WA business number",
      icon: "/images/whatsappLinkGenerator.png", // replace with your actual icon URL
    },
  ];

  const handleWhatsappTools = () => {
    history("/whatsapp-link");
  };

  const handleStartChat = () => {
    alert("Chat started!");
  };

  return (
    <Box sx={{ flexGrow: 1, padding: "20px" }}>
      <Grid container spacing={3} justifyContent="center">
        {tools.map((tool, index) => (
          <Grid item xs={12} sm={8} md={2} lg={4} key={index}>
            <CardComponent
              title={tool.title}
              description={tool.description}
              icon={tool.icon}
              handleWhatsappTools={handleWhatsappTools}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WhatsAppTools;
