import { Box, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ChatInformationSvg from "../../assets/svgs/ChatInformationSvg";
import { makeStyles } from "@mui/styles";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { bgColors } from "../../utils/bgColors";
import ChatUserEmailSvg from "../../assets/svgs/ChatUserEmailSvg";
import ChatUserLocationSvg from "../../assets/svgs/ChatUserLocationSvg";
import ChatUserArrowDownSvg from "../../assets/svgs/ChatUserArrowDownSvg";
import ChatUserArrowSide from "../../assets/svgs/ChatUserArrowSide";
import TagPopover from "./userDetailsComponents/TagPopover";
import { Delete, Flag } from "@mui/icons-material";
import ChatUserCloseSvg from "../../assets/svgs/ChatUserCloseSvg";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { toastActions } from "../../utils/toastSlice";
import SearchIconSvg2 from "../../assets/svgs/SearchIconSvg2";
import MediaPopover from "./userDetailsComponents/MediaPopover";
import SharedMedia from "./userDetailsComponents/sharedmedia";
import { removeContactTagsFromContact } from "../../redux/slices/Contacts/DeleteContactTags";
import { createContactNotes } from "../../redux/slices/Contacts/CreateContactNotes";
import { removeContactNotes } from "../../redux/slices/Contacts/DeleteContactNotes";
import { markContactAsSpam } from "../../redux/slices/Inbox/markContactAsSpam";
import LoadingComponent from "../common/LoadingComponent";
import TextFieldWithBorderComponent from "../common/TextFieldWithBorderComponent";
import { fetchInboxContactDetails } from "../../redux/slices/Inbox/InboxContactDetails";
import CustomAudioPlayer from "./userDetailsComponents/AudioComponent";
import SharedMediaDropdownComponent from "./userDetailsComponents/sharedMediaDropdownComponent";
import TagsDropdownComponent from "./userDetailsComponents/tagsDropdownComponent";
import NotesDropdownComponent from "./userDetailsComponents/notesDropdownComponent";
import WorkflowVariablesDropdownComponent from "./userDetailsComponents/workflowVariablesDropdownComponent";

const useStyles = makeStyles({
  grayColor: {
    color: `#4B5A5A !important`,
    fontWeight: "600 !important",
  },
  grayColor2: {
    color: `#4B5A5A !important`,
    opacity: "80%",
  },
  searchField: {
    width: "100%",
    borderRadius: "12px",
    height: "45px",
    fontSize: "18px",
    backgroundColor: "transparent",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "Roboto Slab",
      color: "#4B5A5A !important",
      Opacity: "60%",
    },
  },
  spaceBetweenCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  cursor: {
    cursor: "pointer",
  },
});

// Helper function to filter images and videos
export const filterMedia = (mediaArray: any) => {
  const validExtensions = [
    ".png",
    ".jpg",
    ".jpeg",
    ".gif",
    ".mp4",
    ".mp3",
    ".aar",
    ".amr",
    ".ogg",
  ];
  return mediaArray?.filter((media: any) =>
    validExtensions?.some((ext) => media?.mediaUrl?.endsWith(ext))
  );
};

const UserDetails = ({
  details,
  status,
  messages,
  setCurrentResultIndex,
  onClickClose,
  setSearchText,
  contactNumber,
  setChatsPageNumber,
}: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const manageInboxObject = useAppSelector(
    (state: any) => state.getUserPermissions?.data?.inbox
  );
  const inboxUserObject = manageInboxObject?.find((item: any) =>
    Object.prototype.hasOwnProperty.call(item, "inboxUserDetails")
  );
  const inboxUserActions = inboxUserObject
    ? inboxUserObject?.inboxUserDetails
    : [];
  // console.log("inboxUserActions", inboxUserActions);
  const [media, setMedia] = useState(false);
  const [workflowVariables, setWorkflowVariables] = useState(false);
  const [tags, setTags] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [writtenNotes, setWrittenNotes] = useState("");
  const [, setSelectedTags] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [tagPopoverAnchorEl, setTagPopoverAnchorEl] = useState(null);
  const [addTagTooltipOpen, setAddTagTooltipOpen] = useState(false);
  const [updateTagTooltipOpen, setUpdateTagTooltipOpen] = useState("");
  const [addNotesTooltipOpen, setAddNotesTooltipOpen] = useState(false);
  const [deleteNotestooltipOpen, setDeleteNotestooltipOpen] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [seeAllTooltipOpen, setSeeAllTooltipOpen] = useState(false);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [contactDetails, setContactDetails] = useState(details);
  // console.log(contactDetails)
  const [searchResults, setSearchResults] = useState([]);
  const userDataProp = useAppSelector((state: any) => state?.adminLogin?.data);

  const containsAllWords = (obj: any, words: string[]): boolean => {
    if (typeof obj === "string") {
      const lowerCasedText = obj.toLowerCase();
      return words.every((word) => lowerCasedText.includes(word));
    } else if (Array.isArray(obj)) {
      return obj.some((item) => containsAllWords(item, words));
    } else if (obj && typeof obj === "object") {
      return Object.values(obj).some((value) => containsAllWords(value, words));
    }
    return false;
  };

  useEffect(() => {
    if (searchInput) {
      const searchText = searchInput.toLowerCase(); // Convert search input to lowercase
      const words = searchText.split(/\s+/); // Split input into words based on whitespace
      const results: any =
        Array.isArray(messages) &&
        messages.filter((message: any) => containsAllWords(message, words));

      setSearchResults(results || []);
      setCurrentResultIndex(0);
    } else {
      setSearchResults([]);
    }
  }, [searchInput, messages]);

  // console.log("setSearchResults", currentResultIndex, searchResults);

  const hasAcess = (permission: any) => {
    if (inboxUserActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  // Handle search text change
  const handleSearchTextChange = (event: any) => {
    const searchText = event.target.value;
    setSearchInput(searchText);
    setSearchText(searchText); // Pass the search text to parent component
  };

  // const handleNextResult = () => {
  //   setCurrentResultIndex(
  //     (prevIndex: number) => (prevIndex + 1) % searchResults.length
  //   );
  // };

  // const handlePreviousResult = () => {
  //   setCurrentResultIndex(
  //     (prevIndex: number) =>
  //       (prevIndex - 1 + searchResults.length) % searchResults.length
  //   );
  // };

  const handleOpenTagPopover = (event: any) => {
    const hasPermission = hasAcess("addTag");

    if (hasPermission) {
      setTagPopoverAnchorEl(event.currentTarget);
    } else {
      setAddTagTooltipOpen(true);
      setSeeAllTooltipOpen(false);
      setTimeout(() => setAddTagTooltipOpen(false), 2000);
    }
  };

  const handleCloseTagPopover = () => {
    setTagPopoverAnchorEl(null);
  };

  const handleSelectTags = (tags: any) => {
    setSelectedTags(tags);
  };

  const handleRemoveTagFromContact = async (tag: string) => {
    const hasPermission = hasAcess("updateTag");
    if (hasPermission) {
      try {
        const data = {
          businessId: userData?.companyId,
          userId: userData?.userId,
          tag: [tag],
          contactIds: [details?.contactId],
        };
        // console.log("data", data);
        const response = await dispatch(removeContactTagsFromContact(data));
        // console.log("removetag", response);
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: response?.payload?.message,
            })
          );
          const data = {
            businessId: userDataProp?.companyId,
            contactId: contactNumber,
          };
          dispatch(fetchInboxContactDetails(data));
          // window.location.reload();
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: response?.payload?.message,
            })
          );
        }
      } catch (err: any) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: err?.reponse?.data?.message,
          })
        );
      }
      handleCloseTagPopover();
    } else {
      setUpdateTagTooltipOpen(tag);
      setTimeout(() => setUpdateTagTooltipOpen(""), 2000);
    }
  };

  const handleSaveNote = async () => {
    const hasPermission = hasAcess("createNotes");
    if (hasPermission) {
      try {
        const data = {
          businessId: userData?.companyId,
          userId: userData?.userId,
          note: [writtenNotes],
          contactId: details?.contactId,
        };
        // console.log("savenotesdata", data);
        const response = await dispatch(createContactNotes(data));
        // console.log("savenotes", response);
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: response?.payload?.message,
            })
          );
          const data = {
            businessId: userDataProp?.companyId,
            contactId: contactNumber,
          };
          setWrittenNotes("");
          dispatch(fetchInboxContactDetails(data));
          // window.location.reload();
          // console.log("removetag", response);
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: response?.payload?.message,
            })
          );
        }
      } catch (err: any) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: err?.response?.data?.message,
          })
        );
      }
    } else {
      setAddNotesTooltipOpen(true);
      setTimeout(() => setAddNotesTooltipOpen(false), 2000);
    }
  };

  const handleDeleteNotes = async (noteId: string) => {
    const hasPermission = hasAcess("deleteNotes");
    if (hasPermission) {
      try {
        const data = {
          businessId: userData?.companyId,
          userId: userData?.userId,
          note: [noteId],
          contactId: details?.contactId,
        };
        // console.log("savenotesdata", data);
        const response = await dispatch(removeContactNotes(data));
        // console.log("savenotes", response);
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: response?.payload?.message,
            })
          );
          // window.location.reload();
          // console.log("removetag", response);
          const data = {
            businessId: userDataProp?.companyId,
            contactId: contactNumber,
          };
          dispatch(fetchInboxContactDetails(data));
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: response?.payload?.message,
            })
          );
        }
      } catch (err: any) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: err?.response?.data?.message,
          })
        );
      }
    } else {
      setDeleteNotestooltipOpen(noteId);
      setTimeout(() => setDeleteNotestooltipOpen(""), 2000);
    }
  };

  const handleSpamContact = async () => {
    // const hasPermission = hasCloseChatPermission(getuserPermissionData?.inbox);
    // if (hasPermission) {
    setIsLoading(true);
    try {
      const postData = {
        businessId: userData?.companyId,
        userId: userData?.userId,
        contact: details?.phoneNumber,
      };
      const spamResponse = await dispatch(markContactAsSpam(postData));
      // console.log(spamResponse);
      if (spamResponse?.meta?.requestStatus === "fulfilled") {
        // setContactDetails({
        //   ...contactDetails,
        //   isSpam: !contactDetails.isSpam,
        // });
        const data = {
          businessId: userDataProp?.companyId,
          contactId: contactNumber,
        };
        dispatch(fetchInboxContactDetails(data));
        setChatsPageNumber(1);
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: spamResponse?.payload?.message,
          })
        );
        // window?.location?.reload();
        // console.log(spamResponse);
      } else {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: spamResponse?.payload?.message,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: err?.response?.data?.message,
        })
      );
    } finally {
      setIsLoading(false);
    }
    // } else {
    //   setCloseChatTooltipOpen(true);
    //   setTimeout(() => {
    //     setCloseChatTooltipOpen(false);
    //   }, 2000);
    // }
  };

  const handleClose = () => {
    setSearchText("");
    onClickClose(false);
  };

  const handleOpenRightSidebar = () => {
    setSeeAllTooltipOpen(true);
    setTimeout(() => setSeeAllTooltipOpen(false), 2000);
    setRightSidebarOpen(true);
    const data = {
      businessId: userData?.companyId,
      contactId: contactNumber,
    };
    dispatch(fetchInboxContactDetails(data));
  };

  const handleCloseRightSidebar = () => {
    setRightSidebarOpen(false);
  };
  // console.log(details, "handle details");
  // console.log(details..slice(0, 3),"handle shareMedia details");

  const handleOpenMediaPopover = (event: any) => {
    // console.log("event: ", event);inboxUserActions
    setOpenDialog(true);
    setMedia(event);
  };

  const handleCloseMediaPopover = () => {
    setOpenDialog(false);
  };

  // Filter the sharedMedia to get documents
  const documentExtensions = [".pdf", ".doc", ".docx", ".xlsx"];
  const sharedDocuments = details?.shareMedia?.filter((media: any) =>
    documentExtensions?.some((ext) => media?.mediaUrl?.endsWith(ext))
  );

  const filteredMedia = details?.shareMedia
    ? filterMedia(details.shareMedia)
    : [];

  const filteredLinks =
    // messages?.length > 0 ? getLinksFromMessages(messages)
    details?.links?.length > 0 ? details?.links : [];

  useEffect(() => {
    if (searchInput) {
      const searchText = searchInput.toLowerCase(); // Convert search input to lowercase
      const results =
        messages?.length > 0 &&
        messages?.filter((message: any) => {
          if (typeof message?.textMessage !== "string") return false;
          const messageText = message.textMessage.toLowerCase(); // Convert message text to lowercase
          const isMatch = messageText.includes(searchText); // Perform case-insensitive search
          return isMatch;
        });

      setSearchResults(results);
      setCurrentResultIndex(0);
    } else {
      setSearchResults([]);
    }
  }, [searchInput, messages]);

  // console.log(sharedDocuments);
  // console.log(filteredMedia);
  // console.log("details", details);
  // console.log("details", details, filteredLinks, messages);

  return (
    <Box
      m={{ xs: 1, md: 2 }}
      sx={{
        height: "90vh",
        width: "94%",
        scrollbarWidth: "thin",
        overflowY: "auto",
        // overflowY: "hidden",
        zIndex: 100,
      }}
    >
      {status === "loading" ? (
        <LoadingComponent height="100%" color={bgColors?.blue} />
      ) : (
        <>
          <Box ml={0.5} className={classes.spaceBetweenCenter}>
            <Box className={classes.flexRowCenter}>
              <ChatInformationSvg />
              <Typography
                sx={{ fontSize: 16 }}
                ml={2.5}
                className={classes.grayColor}
              >
                User Details
              </Typography>
            </Box>

            <Box onClick={handleClose} className={classes.cursor}>
              {/* <ChatUserDetailsCloseIcon /> */}
              <CloseSvg />
            </Box>
          </Box>
          <Box className={classes.flexRowCenter} my={2}>
            {/* <Box> */}
            <img
              src={details?.logo || "/images/profile.png"}
              alt="profile"
              height={40}
              style={{ marginLeft: -3 }}
            />
            {/* </Box> */}
            <Box ml={1}>
              <Typography
                sx={{ fontSize: "14px" }}
                className={classes.grayColor}
              >
                {details?.name}
              </Typography>
              <Typography
                sx={{ fontSize: "12px" }}
                className={classes.grayColor2}
              >
                +{details?.phoneNumber}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.flexRowCenter}>
            <ChatUserEmailSvg />
            <Typography
              ml={2}
              sx={{ fontSize: "14px" }}
              className={classes.grayColor2}
            >
              {details?.email || "N/A"}
            </Typography>
          </Box>
          <Box mt={2} className={classes.flexRowCenter}>
            <ChatUserLocationSvg />
            <Typography
              ml={2}
              sx={{ fontSize: "14px" }}
              className={classes.grayColor2}
            >
              {details?.countryName || "N/A"}
            </Typography>
          </Box>
          <SharedMediaDropdownComponent
            media={media}
            seeAllTooltipOpen={seeAllTooltipOpen}
            handleOpenRightSidebar={handleOpenRightSidebar}
            handleOpenMediaPopover={handleOpenMediaPopover}
            rightSidebarOpen={rightSidebarOpen}
            handleCloseRightSidebar={handleCloseRightSidebar}
            filteredMedia={filteredMedia}
            sharedDocuments={sharedDocuments}
            filteredLinks={filteredLinks}
            setMedia={setMedia}
            setSeeAllTooltipOpen={setSeeAllTooltipOpen}
            details={details}
          />

          <Box my={4} className={classes.flexRowCenter}>
            <TextField
              className={classes.searchField}
              variant="standard"
              size="small"
              fullWidth
              value={searchInput}
              onChange={handleSearchTextChange}
              InputProps={{
                disableUnderline: true,
                style: { padding: "10px", fontSize: "12px" },
                startAdornment: (
                  <IconButton
                    sx={{ p: 0, color: "inherit" }}
                    aria-label="search"
                  >
                    {/* <SearchIconSvg /> */}
                    <SearchIconSvg2 />
                  </IconButton>
                ),
              }}
              placeholder="Search Messages..."
            />
            {searchInput && (
              <Box display="flex" alignItems="center" mt={1}>
                <Typography sx={{ fontSize: 10, minWidth: 80 }}>
                  {
                    // currentResultIndex + "/" +
                    searchResults?.length
                  }{" "}
                  results found!
                </Typography>
              </Box>
            )}
          </Box>

          {details?.contactExist && (
            <>
              <TagsDropdownComponent
                tags={tags}
                details={details}
                handleOpenTagPopover={handleOpenTagPopover}
                addTagTooltipOpen={addTagTooltipOpen}
                setTags={setTags}
                setAddTagTooltipOpen={setAddTagTooltipOpen}
                updateTagTooltipOpen={updateTagTooltipOpen}
                setUpdateTagTooltipOpen={setUpdateTagTooltipOpen}
                handleRemoveTagFromContact={handleRemoveTagFromContact}
              />
              <NotesDropdownComponent
                notes={openNotes}
                setNotes={setOpenNotes}
                writtenNotes={writtenNotes}
                setWrittenNotes={setWrittenNotes}
                addNotesTooltipOpen={addNotesTooltipOpen}
                setAddNotesTooltipOpen={setAddNotesTooltipOpen}
                details={details}
                handleSaveNote={handleSaveNote}
                deleteNotestooltipOpen={deleteNotestooltipOpen}
                setDeleteNotestooltipOpen={setDeleteNotestooltipOpen}
                handleDeleteNotes={handleDeleteNotes}
              />
              <WorkflowVariablesDropdownComponent
                workflowVariables={workflowVariables}
                details={details}
                setWorkflowVariables={setWorkflowVariables}
              />
            </>
          )}
          <Box
            my={4}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            onClick={() => handleSpamContact()}
          >
            <Box>
              {isLoading ? (
                <LoadingComponent height="auto" color={bgColors?.blue} />
              ) : (
                <div style={{ display: "flex" }}>
                  <Flag sx={{ color: bgColors?.red }} />
                  <Typography
                    ml={1}
                    sx={{
                      fontSize: "14px",
                      cursor: "pointer",
                      color: bgColors?.red,
                      fontWeight: "600 !important",
                    }}
                  >
                    {!details?.isSpam ? "Mark as spam" : "Mark as not spam"}
                  </Typography>
                </div>
              )}
            </Box>
          </Box>
          <MediaPopover
            open={openDialog}
            media={media}
            onClose={handleCloseMediaPopover}
            sharedMedia={media}
          />
          <TagPopover
            anchorEl={tagPopoverAnchorEl}
            contactDetails={details}
            onClose={handleCloseTagPopover}
            onSelectTags={handleSelectTags}
            onRemoveTag={handleRemoveTagFromContact}
            contactNumber={contactNumber}
          />
        </>
      )}
    </Box>
  );
};

export default UserDetails;
