import { createSlice } from "@reduxjs/toolkit";

export interface IAdminLogin {
  data: any;
  email: string;
}

const initialState: IAdminLogin = {
  data: null,
  email: "",
};

export const adminLoginSlice = createSlice({
  name: "adminLoginSlice",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
  },
});

export const adminLoginSliceActions = adminLoginSlice.actions;
export default adminLoginSlice.reducer;
