import React from 'react'

const CloseIconSvg = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="9" cy="9" r="5" fill="#4B5A5A"/>
<path d="M9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5ZM11.775 10.725C12.075 11.025 12.075 11.475 11.775 11.775C11.475 12.075 11.025 12.075 10.725 11.775L9 10.05L7.275 11.775C6.975 12.075 6.525 12.075 6.225 11.775C5.925 11.475 5.925 11.025 6.225 10.725L7.95 9L6.225 7.275C5.925 6.975 5.925 6.525 6.225 6.225C6.525 5.925 6.975 5.925 7.275 6.225L9 7.95L10.725 6.225C11.025 5.925 11.475 5.925 11.775 6.225C12.075 6.525 12.075 6.975 11.775 7.275L10.05 9L11.775 10.725Z" fill="white"/>
</svg>
  )
}

export default CloseIconSvg
