import React, { useEffect, useState } from "react";
import { TextField, Box } from "@mui/material";

const DateRangePicker = ({
  onDateRangeChange,
  disabled,
  startCalenderDate,
  endCalenderDate,
}: any) => {
  const [startDate, setStartDate] = useState(startCalenderDate || "");
  const [endDate, setEndDate] = useState(endCalenderDate || "");

  useEffect(() => {
    setStartDate(startCalenderDate || "");
  }, [startCalenderDate]);

  useEffect(() => {
    setEndDate(endCalenderDate || "");
  }, [endCalenderDate]);

  const handleStartDateChange = (event: any) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    if(newStartDate == ""){
      setEndDate("");
    }
    if (endDate && newStartDate > endDate) {
      setEndDate("");
    }
    onDateRangeChange({ startDate: newStartDate, endDate });
  };

  const handleEndDateChange = (event: any) => {
    const newEndDate = event.target.value;
    // if(newEndDate == ""){
    //   setStartDate("");
    // }
    setEndDate(newEndDate);
    onDateRangeChange({ startDate, endDate: newEndDate });
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <Box sx={{ display: "flex", gap: 0, height: "31px", alignItems: "center" }}>
      <TextField
        type="date"
        value={startDate}
        onChange={handleStartDateChange}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          max: getCurrentDate(),
        }}
        disabled={disabled}
        sx={{
          "& .MuiOutlinedInput-root": {
            border: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiInputBase-root": {
            height: "31px",
          },
          "& .MuiInputBase-input": {
            padding: "8px 14px",
          },
        }}
      />
      to
      <TextField
        type="date"
        value={endDate}
        onChange={handleEndDateChange}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          min: startDate,
          max: getCurrentDate(),
        }}
        disabled={!startDate}
        sx={{
          "& .MuiOutlinedInput-root": {
            border: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiInputBase-root": {
            height: "31px",
          },
          "& .MuiInputBase-input": {
            padding: "8px 14px",
          },
        }}
      />
      {/* <Button >Reset</Button> */}
    </Box>
  );
};

export default DateRangePicker;
