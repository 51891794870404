import { ContentState, convertFromHTML, convertToRaw } from "draft-js";
import parsePhoneNumberFromString from "libphonenumber-js";
import moment from "moment-timezone";
/**
 * Converts a UTC time string to the local system time zone.
 *
 * @param {string} utcTimeString - The UTC time string (e.g., '2024-08-01T12:00:00Z').
 * @returns {string} - The local time formatted as per the local system time zone.
 */

export const parseTextToDraft = (text: string) => {
  // console.log(text);

  // Replace markdown-like symbols with HTML tags for inline styles
  let htmlText = text
    ?.replace(/\*(.*?)\*/g, "<b>$1</b>")
    ?.replace(/_(.*?)_/g, "<i>$1</i>")
    ?.replace(/~(.*?)~/g, "<strike>$1</strike>");

  // Replace number points with HTML list items for ordered list
  htmlText = htmlText.replace(/^\d+\.\s+(.*)$/gm, "<ol><li>$1</li></ol>");

  // Replace bullet points with HTML list items for unordered list
  htmlText = htmlText?.replace(/^-\s+(.*)$/gm, "<ul><li>$1</li></ul>");

  // Wrap ordered list items in <ol> tags
  if (htmlText?.includes("<ol><li>")) {
    htmlText = htmlText?.replace(/<\/li><ol>/g, "</li>");
  }

  // Wrap unordered list items in <ul> tags
  if (htmlText?.includes("<ul><li>")) {
    htmlText = htmlText?.replace(/<\/li><ul>/g, "</li>");
  }

  // Replace new lines with HTML <br> tags for preserving line breaks
  htmlText = htmlText?.replace(/\n/g, "<br>");
  // console.log(text)
  // Convert HTML to draft content state
  const blocksFromHTML = convertFromHTML(htmlText);
  // console.log(blocksFromHTML)
  const contentState = ContentState?.createFromBlockArray(
    blocksFromHTML?.contentBlocks,
    blocksFromHTML?.entityMap
  );
  //  console.log(contentState)
  return contentState;
};

export const parseTextToHtml = (text: string) => {
  let inUl = false;
  let inOl = false;
  let htmlText = "";

  // Split the text into lines
  const lines = text?.split("\n");

  lines?.forEach((line) => {
    // Handle unordered list items
    if (line.match(/^-\s+(.*)$/)) {
      if (!inUl) {
        if (inOl) {
          htmlText += "</ol>";
          inOl = false;
        }
        inUl = true;
        htmlText += "<ul>";
      }
      htmlText += `<li>${line?.replace(/^-\s+/, "")}</li>`;
    }
    // Handle ordered list items
    else if (line?.match(/^\d+\.\s+(.*)$/)) {
      if (!inOl) {
        if (inUl) {
          htmlText += "</ul>";
          inUl = false;
        }
        inOl = true;
        htmlText += "<ol>";
      }
      htmlText += `<li>${line?.replace(/^\d+\.\s+/, "")}</li>`;
    }
    // Handle non-list items
    else {
      if (inUl) {
        htmlText += "</ul>";
        inUl = false;
      }
      if (inOl) {
        htmlText += "</ol>";
        inOl = false;
      }
      htmlText += line + "<br>";
    }
  });

  // Close any remaining open tags
  if (inUl) htmlText += "</ul>";
  if (inOl) htmlText += "</ol>";

  // Handle inline styles
  htmlText = htmlText
    ?.replace(/\*(.*?)\*/g, "<b>$1</b>")
    ?.replace(/_(.*?)_/g, "<i>$1</i>")
    ?.replace(/~(.*?)~/g, "<strike>$1</strike>");

  return htmlText;
};

export const formatContent = (contentState: ContentState): string => {
  const blocks = convertToRaw(contentState).blocks;
  // console.log(blocks)
  let formattedText = "";
  let listItemCounter = 1;

  const applyStyles = (textSegment: string, styles: string[]): string => {
    let styledText = textSegment;
    styles.forEach((style) => {
      if (style === "BOLD") styledText = ` *${styledText.trim()}* `;
      if (style === "ITALIC") styledText = ` _${styledText.trim()}_ `;
      if (style === "STRIKETHROUGH") styledText = ` ~${styledText.trim()}~ `;
    });
    return styledText;
  };

  blocks.forEach((block, index) => {
    if (index !== 0) {
      formattedText += "\n";
    }
    let blockText = "";
    const text = block.text;
    const styleRanges = block.inlineStyleRanges.sort(
      (a, b) => a.offset - b.offset
    );

    let pos = 0;

    const getActiveStyles = (pos: number) => {
      return styleRanges
        .filter(
          (range) => range.offset <= pos && pos < range.offset + range.length
        )
        .map((range) => range.style);
    };

    while (pos < text.length) {
      let nextChange = text.length;
      if (styleRanges.length > 0) {
        nextChange = Math.min(
          nextChange,
          ...styleRanges
            .map((range) => range.offset)
            .filter((offset) => offset > pos),
          ...styleRanges
            .map((range) => range.offset + range.length)
            .filter((offset) => offset > pos)
        );
      }

      const segmentText = text.slice(pos, nextChange);
      const stylesAtPos = getActiveStyles(pos);

      if (segmentText.length > 0) {
        blockText += applyStyles(segmentText, stylesAtPos);
      }

      pos = nextChange;
    }

    if (block.type === "unordered-list-item") {
      blockText = "- " + blockText.trim();
    } else if (block.type === "ordered-list-item") {
      blockText = listItemCounter + ". " + blockText.trim();
      listItemCounter++;
    } else {
      listItemCounter = 1;
    }

    formattedText += blockText;
  });
  // console.log(formattedText)
  return formattedText;
};

export const processMessages = (messages: any) => {
  let lastDisplayedTime: string | null = null;
  return messages?.map((msg: any) => {
    const messageDate = new Date(msg?.createdAt);
    const formattedTime = messageDate?.toLocaleTimeString("en-UK", {
      hour: "2-digit",
      minute: "2-digit",
    });

    const displayTime =
      !lastDisplayedTime || lastDisplayedTime !== formattedTime;
    lastDisplayedTime = formattedTime;

    return {
      ...msg,
      displayTime,
    };
  });
};

export const sumOfUnreads = (contacts: any) => {
  return contacts?.reduce(
    (total: number, contact: any) => total + (contact?.unRead || 0),
    0
  );
};

export function convertUtcToLocal(utcTimeString: string): string {
  // Get the local system time zone
  const localTimeZone = moment.tz.guess();

  // Convert the UTC time to the local system time zone
  const localTime = moment.utc(utcTimeString).clone().tz(localTimeZone);

  // Return the formatted local time
  return localTime.format();
}

export const getUniformString = (array: any) => {
  if (array.length === 0) return null;

  const firstItem = array[0];
  for (let i = 1; i < array.length; i++) {
    if (array[i]?.assignee !== firstItem?.assignee) {
      return null;
    }
  }
  return firstItem?.assignee;
};

// Function to validate phone number with country code
export const validatePhoneNumber = (
  phoneNumber: string,
  countryCode: string
) => {
  const phone = parsePhoneNumberFromString(countryCode + phoneNumber);
  // console.log("validatePhoneNumber", phone, countryCode, phoneNumber);
  return phone ? phone.isValid() : false;
};

export const validateVariablesContainedText = (type: string, body: string) => {
  // Trim spaces around variables
  const bodyText = body.replace(/(\s*)\{\{(\d+)\}\}(\s*)/g, "{{$2}}");

  // Check if body text starts or ends with a variable
  if (bodyText.startsWith("{{") || bodyText.endsWith("}}")) {
    return false;
  }

  // Check if at least one letter is present between two variables
  const variableRegex = /{{\d+}}/g;
  const matches = bodyText.match(variableRegex);
  if (matches) {
    for (let i = 0; i < matches.length - 1; i++) {
      const startIndex = bodyText.indexOf(matches[i]) + matches[i].length;
      const endIndex = bodyText.indexOf(matches[i + 1]);
      if (endIndex - startIndex <= 0) {
        return false;
      }
    }
  }

  // Split the sentence into words
  const words = body.split(/\s+/).filter((word) => word.length > 0);

  // Filter out variables like {{1}}, {{2}}, etc.
  const nonVariableWords = words.filter((word) => !/{{\d+}}/.test(word));

  // Calculate the word count excluding variables
  const wordCount = nonVariableWords.length;

  // Calculate the number of variables
  const variableCount = matches ? matches.length : 0;
  // console.log("count", bodyText, variableCount, words, wordCount);

  // Ensure the number of variables is less than half of the word count
  if (type === "body" && variableCount >= wordCount / 2) {
    return false;
  } else if (type === "header" && variableCount > 1) {
    return false;
  }

  return true;
};

export function formatLastMessage(message: string) {
  let formattedMessage = message.replace(/\n/g, " "); // Replace new lines with spaces

  formattedMessage = formattedMessage.replace(
    /(\d+(\.\d+)?%)/g,
    "<strong>$1</strong>"
  );
  formattedMessage = formattedMessage.replace(
    /🔹\s(.+)/g,
    "🔹 <strong>$1</strong>"
  );
  formattedMessage = formattedMessage.replace(
    /(https?:\/\/[^\s]+)/g,
    "<strong>$1</strong>"
  );
  formattedMessage = formattedMessage.replace(
    /\*(.*?)\*/g,
    "<strong>$1</strong>"
  );

  return formattedMessage;
}

// Utility function to convert a string to camelCase
export const toCamelCase = (str: string): string => {
  return str
    .replace(/_./g, (match) => match.charAt(1).toUpperCase())
    .replace(/^(.)/, (match) => match.toLowerCase());
};

// Function to recursively transform object keys to camelCase
export const transformKeysToCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(transformKeysToCamelCase); // Recursively transform each item in the array
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc: any, key: string) => {
      const camelCaseKey = toCamelCase(key);
      acc[camelCaseKey] = transformKeysToCamelCase(obj[key]);
      return acc;
    }, {});
  }
  return obj; // Return the value if it's not an object or array
};

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const updateVariables = (
  type: string,
  templateState: any,
  buttonIndex?: number
) => {
  // Get existing variables from the template state
  const existingVariables = templateState?.variables || [];

  // Filter variables based on the type (body, header, or url Button)
  const variablesOfType =
    existingVariables.filter((variable: any) => variable.type === type) || [];

  // Determine the new variable count
  const existingVariableCount = variablesOfType.length;
  const newCount = existingVariableCount + 1;

  // Create a new variable object
  const newVariable = {
    type: type,
    id: `{{${newCount}}}`,
    value: "",
    field: "",
    fallBackValue: "",
  };

  // Create the new variables array based on the type
  let newVariables: any;
  if (type === "body") {
    const newBodyVariables = [...variablesOfType, newVariable];
    newVariables = existingVariables
      .filter((variable: any) => variable.type !== "body")
      .concat(newBodyVariables);
  } else if (type === "header") {
    const newHeaderVariables = [...variablesOfType, newVariable];
    newVariables = existingVariables
      .filter((variable: any) => variable.type !== "header")
      .concat(newHeaderVariables);
  } else if (type === "url Button") {
    // Insert new variable at the specified index if provided, otherwise at the end
    const newUrlButtonVariables =
      buttonIndex !== undefined
        ? [
            ...variablesOfType.slice(0, buttonIndex),
            newVariable,
            ...variablesOfType.slice(buttonIndex),
          ]
        : [...variablesOfType, newVariable];

    newVariables = existingVariables
      .filter((variable: any) => variable.type !== "url Button")
      .concat(newUrlButtonVariables);
  }

  console.log("new", newVariables);
  // Return the updated variables
  return { newVariables, newCount };
};

// Helper function to count variables in a string
export const countVariables = (text: string) => {
  // Regex to match both {{x}} and {x} patterns
  const variablePattern = /{{\d+}}|{\d+}/g;
  const matches = text.match(variablePattern) || [];
  return matches.length;
};

export const countTemplateVariables = (templateData: any) => {
  const headerText = templateData?.header || "";
  const bodyText = templateData?.body || "";

  // Count variables in the header
  const headerVariablesCount = countVariables(headerText);

  // Count variables in the body
  const bodyVariablesCount = countVariables(bodyText);

  // Count variables in each URL button individually
  const urlButtonVariablesCount =
    templateData?.buttons
      ?.filter((button: any) => button.buttonType === "URL")
      .map((button: any, index: number) => ({
        buttonIndex: index, // Identify the URL button
        buttonName: button.buttonName, // Give a meaningful name
        buttonValue: button.buttonValue, // Give a meaningful name
        variablesCount: countVariables(button.buttonValue || ""),
      })) || [];

  return {
    headerVariablesCount,
    bodyVariablesCount,
    urlButtonVariablesCount, // Array with count for each URL button
  };
};

export const validateUrl = (url: string) => {
  const urlRegex = new RegExp(
    "^(https?:\\/\\/)?" + // optional protocol
      "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)*([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*\\.[a-zA-Z]{2,})|" + // domain name or subdomain
      "localhost|" + // OR localhost
      "\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|" + // OR IPv4
      "\\[([a-fA-F\\d:.]+)\\])" + // OR IPv6
      "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+{{\\d*}}]*)*" + // port and path, allowing {{x}} placeholders
      "(\\?[;&a-zA-Z\\d%_.~+=-{{\\d*}}]*)?" + // query string, allowing {{x}} placeholders
      "(\\#[-a-zA-Z\\d_\\/?{{\\d*}}]*)?$", // fragment locator with placeholders
    "i"
  );
  return urlRegex.test(url);
};
