import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ADMIN_MANAGE_COMPANY_APIS } from "../../../Apis/ManageCompany/ManageCompany";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const updateCompanyDetails = createAsyncThunk(
  "updateCompanyDetails",
  async (data: any) => {
    const response = await ADMIN_MANAGE_COMPANY_APIS.editCompanyDetails(data);
    return response?.data;
  }
);

export const editedCompanyDetailsSlice = createSlice({
  name: "editedCompanyDetailsSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateCompanyDetails.pending, (state) => {
        state.status = "loading";
        state.data = [];
      })
      .addCase(updateCompanyDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(updateCompanyDetails.rejected, (state) => {
        state.status = "failed";
        state.data = [];
      });
  },
});

export const updateCompanyDetailsSliceActions =
  editedCompanyDetailsSlice.actions;
export default editedCompanyDetailsSlice.reducer;
