import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ADMIN_MANAGE_COMPANY_APIS } from "../../../Apis/ManageCompany/ManageCompany";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const fetchGetAllTeamMembers = createAsyncThunk(
  "fetchGetAllTeamMembers",
  async () => {
    const response = await ADMIN_MANAGE_COMPANY_APIS.getAllTeamMembrers();
    return response?.data;
  }
);

export const getAllTeamMembersSlice = createSlice({
  name: "getAllTeamMembersSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGetAllTeamMembers.pending, (state) => {
        state.status = "loading";
        state.data = [];
      })
      .addCase(fetchGetAllTeamMembers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchGetAllTeamMembers.rejected, (state) => {
        state.status = "failed";
        state.data = [];
      });
  },
});

export const getAllTeamMembersSliceActions = getAllTeamMembersSlice.actions;
export default getAllTeamMembersSlice.reducer;
