import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WORKFLOW_API } from "../../../Apis/AdminLogin/Automation/Workflows";

export interface IData {
  selectResponseStatus: "loading" | "succeeded" | "failed" | "idle";
  selectResponseData: any;
  error: string | null;
}

const initialState: IData = {
  selectResponseStatus: "idle",
  selectResponseData: null,
  error: null,
};

// export const getSelectResponse = createAsyncThunk<
//   any,
//   void,
//   { rejectValue: string }
// >("workflow/getSelectResponse", async (payload:any, { rejectWithValue }) => {
//   try {
//     const response = await WORKFLOW_API.getSelectResponseData(payload.companyId);
//     return response.data;
//   } catch (error: any) {
//     return rejectWithValue(error.response?.data?.message || 'An error occurred');
//   }
// });

export const getSelectResponse = createAsyncThunk(
    "workflow/getSelectResponse",
    async (payload: any, { rejectWithValue }) => {
      try {
        const response = await WORKFLOW_API.getSelectResponseData(payload?.companyId);
        return response?.data;
      } catch (error: any) {
        // Provide a descriptive error message
        return rejectWithValue(error.response || 'An error occurred');
      }
    }
  ) as any;

export const getSelectResponseSlice = createSlice({
  name: "getSelectResponse",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSelectResponse.pending, (state) => {
        state.selectResponseStatus = "loading";
        state.selectResponseData = null;
        state.error = null;
      })
      .addCase(getSelectResponse.fulfilled, (state, action: PayloadAction<any>) => {
        state.selectResponseStatus = "succeeded";
        state.selectResponseData = action.payload;
        state.error = null;
      })
      .addCase(getSelectResponse.rejected, (state, action) => {
        state.selectResponseStatus = "failed";
        // state.error = action.payload || 'An error occurred';
      });
  },
});

export const getSelectResponseSliceActions = getSelectResponseSlice.actions;
export default getSelectResponseSlice.reducer;