import {
  TextField,
  TextFieldProps,
  InputBaseProps,
  InputLabelProps,
} from "@mui/material";
import React, { useState } from "react";
import { bgColors } from "../../utils/bgColors";

interface IProps {
  label: any;
  name: string;
  placeholder: string;
  value?: string;
  fontweight?: string;
}

type TextFieldComponentProps = TextFieldProps & IProps;

const TextFieldWithBorderComponent: React.FC<TextFieldComponentProps> = ({
  label,
  name,
  placeholder,
  size = "small",
  fullWidth = true,
  value,
  fontweight,
  onChange,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);
  // const [inputValue, setInputValue] = useState(value); // State to track input value

  // useEffect(() => {
  //   // Update inputValue if value prop changes
  //   setInputValue(value);
  // }, [value]);

  // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

  //   const cursorPosition = event.target.selectionStart; // Get cursor position
  //   setInputValue(event.target.value);
  //   // Restore cursor position after updating inputValue

  // };

  const inputProps: InputBaseProps = {
    style: {
      borderRadius: "8px",
      fontSize: "14px",
      fontWeight: fontweight,
      backgroundColor: focused
        ? bgColors.gray4
        : hovered
        ? bgColors.gray2
        : bgColors.white,
    },
  };

  const labelProps: InputLabelProps = {
    shrink: value !== "" || focused, // Shrink if there's a value or if focused
    style: { fontSize: 14 },
  };

  return (
    <TextField
      label={label}
      fullWidth={fullWidth}
      placeholder={placeholder}
      name={name}
      size={size}
      margin="dense"
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      InputProps={inputProps}
      InputLabelProps={labelProps}
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
};

export default TextFieldWithBorderComponent;
