import React from 'react'

const WalletIconSvg = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.6667 9.33333V6.66667C26.6667 5.196 25.4707 4 24.0001 4H6.66675C4.46141 4 2.66675 5.79467 2.66675 8V24C2.66675 26.9347 5.05875 28 6.66675 28H26.6667C28.1374 28 29.3334 26.804 29.3334 25.3333V12C29.3334 10.5293 28.1374 9.33333 26.6667 9.33333ZM24.0001 21.3333H21.3334V16H24.0001V21.3333ZM6.66675 9.33333C6.32344 9.31798 5.99929 9.17079 5.76178 8.92242C5.52428 8.67406 5.39172 8.34365 5.39172 8C5.39172 7.65635 5.52428 7.32594 5.76178 7.07758C5.99929 6.82921 6.32344 6.68202 6.66675 6.66667H24.0001V9.33333H6.66675Z" fill="#4B5A5A"/>
    </svg>
    
  )
}

export default WalletIconSvg
