import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CONTACT_TAGS_APIS } from "../../../Apis/Contacts/ContactTagsApis";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const getContactTags = createAsyncThunk(
  "getContactTags",
  async (data: any) => {
    const response = await CONTACT_TAGS_APIS.getContactTagsDetails(data);
    return response?.data;
  }
);

export const getContactTagsSlice = createSlice({
  name: "getContactTagsSlice",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getContactTags.pending, (state) => {
        state.status = "loading";
        //   state.error = ""
        state.data = [];
      })
      .addCase(getContactTags.fulfilled, (state, action) => {
        state.status = "succeeded";
        //   state.error = ""
        state.data = action.payload;
      })
      .addCase(getContactTags.rejected, (state) => {
        state.status = "failed";
        state.data = [];
        //   state.error = action.error.message || "";
      });
  },
});

export const getContactTagsActions = getContactTagsSlice.actions;
export default getContactTagsSlice.reducer;
