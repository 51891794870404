import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WORKFLOW_API } from "../../../Apis/AdminLogin/Automation/Workflows";

export interface IData {
    status: "loading" | "succeeded" | "failed" | "idle";
    data: any;
  }

  const initialState: IData = {
    status: "idle",
    data: null,
  };

  export const deleteWorkflow = createAsyncThunk(
    "workflow/deleteWorkflow",
    async (payload: any, { rejectWithValue }) => {
      try {
        const response = await WORKFLOW_API.deleteWorkflow(payload);
        return response?.data;
      } catch (error: any) {
        // Provide a descriptive error message
        return rejectWithValue(error.response || 'An error occurred');
      }
    }
  );


  export const deleteWorkflowSlice = createSlice({
    name: "deleteWorkflow",
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
      builder
        .addCase(deleteWorkflow.pending, (state) => {
          state.status = "loading";
          state.data = null;
        })
        .addCase(deleteWorkflow.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.data = action.payload;
        })
        .addCase(deleteWorkflow.rejected, (state) => {
          state.status = "failed";
        });
    },
  });


export const deleteWorkflowSliceActions = deleteWorkflowSlice.actions;
export default deleteWorkflowSlice.reducer;
