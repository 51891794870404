import React from 'react'
import TypographyComponent from './TyphographyComponent'
import { colors } from '@mui/material'

interface IErrorMessageComponent {
    text: string
}

const ErrorMessageComponent:React.FC<IErrorMessageComponent> = ({text}) => {
  return (
    <TypographyComponent color={colors.red["800"]} title={text}/>
  )
}

export default ErrorMessageComponent;