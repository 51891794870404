import { Box } from "@mui/material";
import LoginFormContainer from "../../components/LoginComponents/LoginFormContainer";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import EngagetoLogoSvg from "../../assets/svgs/EngagetoLogoSvg";

const useStyles = makeStyles({
  background: {
    backgroundImage: `url("/images/new-pwd-bg.png")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  logoContainer: {
    // padding: '16px',
  },
  formContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const AdminLogin = () => {
  const classes = useStyles();

  useEffect(() => {
    // Clear local storage
    localStorage.clear();

    // Clear session storage
    sessionStorage.clear();

    // Clear cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    // Clear cache storage (Service Workers)
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => caches.delete(name));
      });
    }

    // Unregister service workers
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => registration.unregister());
      });
    }
  }, []);
  return (
    <Box className={classes.background}>
      <Box className={classes.logoContainer}>
        {/* <img alt="profile" src="/AhexLogo.png" /> */}
        {/* <AhexMainLogoSvg /> */}
        <EngagetoLogoSvg />
      </Box>
      <Box className={classes.formContainer}>
        <LoginFormContainer loginPromptText="Log In" />
      </Box>
    </Box>
  );
};

export default AdminLogin;
