/* global process */

import axios from "axios";

/* login api */
const USER_API_URL = process.env.REACT_APP_BASE_URL;
const login = (email: string, password: string) => {
  return axios({
    url: `${USER_API_URL}/Authentication/login`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    },
    data: JSON.stringify({ email: email, password: password }),
  });
};

const forgotPassword = ({ email }: { email: string }) => {
  return axios({
    url: `${USER_API_URL}/ForgotPassword/send-otp`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ email: email }),
  });
};

const verifyOtp = ({ otp, email }: { otp: string; email: string }) => {
  return axios({
    url: `${USER_API_URL}/ForgotPassword/verify-otp`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ otp: otp, email: email }),
  });
};

const updatePassword = ({
  email,
  password,
  confirmPassword,
}: {
  email: string;
  password: string;
  confirmPassword: string;
}) => {
  return axios({
    url: `${USER_API_URL}/ForgotPassword/update-password`,
    method: "POST",
    headers: {
      // "Accept": "/",
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      email: email,
      newPassword: password,
      confirmPassword: confirmPassword,
    }),
  });
};

export const ADMIN_LOGIN_API = {
  login,
  forgotPassword,
  verifyOtp,
  updatePassword,
};
