import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WORKFLOW_API } from "../../../Apis/AdminLogin/Automation/Workflows";

export interface IData {
    deleteSelectResponseStatus: "loading" | "succeeded" | "failed" | "idle";
    deleteSelectResponseData: any;
  }

  const initialState: IData = {
    deleteSelectResponseStatus: "idle",
    deleteSelectResponseData: null,
  };

  export const deleteSelectResponse = createAsyncThunk(
    "workflow/deleteSelectResponse",
    async (payload: any, { rejectWithValue }) => {
      try {
        const response = await WORKFLOW_API.deleteSelectResponse(payload?.selectResponseId);
        return response?.data;
      } catch (error: any) {
        // Provide a descriptive error message
        return rejectWithValue(error.response || 'An error occurred');
      }
    }
  );


  export const deleteSelectResponseSlice = createSlice({
    name: "deleteSelectResponse",
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
      builder
        .addCase(deleteSelectResponse.pending, (state) => {
          state.deleteSelectResponseStatus = "loading";
          state.deleteSelectResponseData = null;
        })
        .addCase(deleteSelectResponse.fulfilled, (state, action) => {
          state.deleteSelectResponseStatus = "succeeded";
          state.deleteSelectResponseData = action.payload;
        })
        .addCase(deleteSelectResponse.rejected, (state) => {
          state.deleteSelectResponseStatus = "failed";
        });
    },
  });


export const deleteSelectResponseSliceActions = deleteSelectResponseSlice.actions;
export default deleteSelectResponseSlice.reducer;
