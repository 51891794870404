import "../App.css";

// export const reactDraftWysiwygToolbarOptionsarticle = {
//     options: ['emoji', 'inline', ],
//     inline: {
//       options: ['bold', 'italic', 'strikethrough', ],
//       bold: { className: 'iconBorder' },
//       italic: { className: 'iconBorder' },
//       strikethrough: { className: 'iconBorder' },
//     },
//     emoji: {
//       className: 'iconBorder',
//       popupClassName: 'emoji_class',
//       emojis: [
//         '😀',
//         '😁',
//         '😂',
//         '😃',
//         '😉',
//         '😋',
//         '😎',
//         '😍',
//         '😗',
//         '🤗',
//         '🤔',
//         '😣',
//         '😫',
//         '😴',
//         '😌',
//         '🤓',
//         '😛',
//         '😜',
//         '😠',
//         '😇',
//         '😷',
//         '😈',
//         '👻',
//         '😺',
//         '😸',
//         '😹',
//         '😻',
//         '😼',
//         '😽',
//         '🙀',
//         '🙈',
//         '🙉',
//         '🙊',
//         '👼',
//         '👮',
//         '🕵',
//         '💂',
//         '👳',
//         '🎅',
//         '👸',
//         '👰',
//         '👲',
//         '🙍',
//         '🙇',
//         '🚶',
//         '🏃',
//         '💃',
//         '⛷',
//         '🏂',
//         '🏌',
//         '🏄',
//         '🚣',
//         '🏊',
//         '⛹',
//         '🏋',
//         '🚴',
//         '👫',
//         '💪',
//         '👈',
//         '👉',
//         '👉',
//         '👆',
//         '🖕',
//         '👇',
//         '🖖',
//         '🤘',
//         '🖐',
//         '👌',
//         '👍',
//         '👎',
//         '✊',
//         '👊',
//         '👏',
//         '🙌',
//         '🙏',
//         '🐵',
//         '🐶',
//         '🐇',
//         '🐥',
//         '🐸',
//         '🐌',
//         '🐛',
//         '🐜',
//         '🐝',
//         '🍉',
//         '🍄',
//         '🍔',
//         '🍤',
//         '🍨',
//         '🍪',
//         '🎂',
//         '🍰',
//         '🍾',
//         '🍷',
//         '🍸',
//         '🍺',
//         '🌍',
//         '🚑',
//         '⏰',
//         '🌙',
//         '🌝',
//         '🌞',
//         '⭐',
//         '🌟',
//         '🌠',
//         '🌨',
//         '🌩',
//         '⛄',
//         '🔥',
//         '🎄',
//         '🎈',
//         '🎉',
//         '🎊',
//         '🎁',
//         '🎗',
//         '🏀',
//         '🏈',
//         '🎲',
//         '🔇',
//         '🔈',
//         '📣',
//         '🔔',
//         '🎵',
//         '🎷',
//         '💰',
//         '🖊',
//         '📅',
//         '✅',
//         '❎',
//         '💯'
//       ]
//     },
//     image: {
//       className: 'iconBorder',
//       urlEnabled: false,
//       uploadEnabled: false,
//       alignmentEnabled: false,

//       previewImage: false,
//       inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
//       alt: { present: false, mandatory: false },
//       defaultSize: {
//         height: 'auto',
//         width: 'auto',
//       },
//     },

//   };

export const reactDraftWysiwygToolbarOptionsarticle = {
  options: ["inline", "list", "emoji"],
  inline: {
    options: ["bold", "italic", "strikethrough"],
    bold: { className: "iconBorder" },
    italic: { className: "iconBorder" },
    strikethrough: { className: "iconBorder" },
  },
  list: {
    options: ["unordered", "ordered"],
    unordered: { className: "iconBorder" },
    ordered: { className: "iconBorder" },
  },
  emoji: {
    className: "iconBorder",
    popupClassName: "emoji_class",
    emojis: [
      "😀",
      "😁",
      "😂",
      "😃",
      "😉",
      "😋",
      "😎",
      "😍",
      "😗",
      "🤗",
      "🤔",
      "😣",
      "😫",
      "😴",
      "😌",
      "🤓",
      "😛",
      "😜",
      "😠",
      "😇",
      "😷",
      "😈",
      "👻",
      "😺",
      "😸",
      "😹",
      "😻",
      "😼",
      "😽",
      "🙀",
      "🙈",
      "🙉",
      "🙊",
      "👼",
      "👮",
      "🕵",
      "💂",
      "👳",
      "🎅",
      "👸",
      "👰",
      "👲",
      "🙍",
      "🙇",
      "🚶",
      "🏃",
      "💃",
      "⛷",
      "🏂",
      "🏌",
      "🏄",
      "🚣",
      "🏊",
      "⛹",
      "🏋",
      "🚴",
      "👫",
      "💪",
      "👈",
      "👉",
      "👆",
      "🖕",
      "👇",
      "🖖",
      "🤘",
      "🖐",
      "👌",
      "👍",
      "👎",
      "✊",
      "👊",
      "👏",
      "🙌",
      "🙏",
      "🐵",
      "🐶",
      "🐇",
      "🐥",
      "🐸",
      "🐌",
      "🐛",
      "🐜",
      "🐝",
      "🍉",
      "🍄",
      "🍔",
      "🍤",
      "🍨",
      "🍪",
      "🎂",
      "🍰",
      "🍾",
      "🍷",
      "🍸",
      "🍺",
      "🌍",
      "🚑",
      "⏰",
      "🌙",
      "🌝",
      "🌞",
      "⭐",
      "🌟",
      "🌠",
      "🌨",
      "🌩",
      "⛄",
      "🔥",
      "🎄",
      "🎈",
      "🎉",
      "🎊",
      "🎁",
      "🎗",
      "🏀",
      "🏈",
      "🎲",
      "🔇",
      "🔈",
      "📣",
      "🔔",
      "🎵",
      "🎷",
      "💰",
      "🖊",
      "📅",
      "✅",
      "❎",
      "💯",
    ],
  },
};
