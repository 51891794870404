import React from "react";
import { Typography, TypographyProps } from "@mui/material";

const TypographyComponent: React.FC<TypographyProps> = ({
  title = "",
  component = "span",
  ...rest
}) => {
  return (
    <Typography
      component={component}
      sx={{ fontSize: "12px", fontWeight: "400", color: "#d32f2f" }}
      {...rest}
    >
      {title}
    </Typography>
  );
};

export default TypographyComponent;
