import React from "react";

const FilterIconSvg = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.79051 2.86553L6.88021 9.54568C7.49791 10.3564 7.83203 11.3446 7.83203 12.3605V19.977C7.83203 20.2363 8.13553 20.3798 8.33882 20.2164L10.8263 18.2176C11.1976 17.9192 11.4133 17.4707 11.4133 16.9969V12.5113C11.4133 11.4033 11.8107 10.3313 12.5346 9.48695L18.1833 2.89771C18.5832 2.43117 18.2487 1.71436 17.631 1.71436H2.36881C1.77059 1.71443 1.42997 2.39233 1.79051 2.86553Z"
        stroke="#4B5A5A"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterIconSvg;
