import {
  // Avatar,
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { bgColors } from "../../utils/bgColors";
import ArrowDownSvg from "../../assets/svgs/ArrowDownSvg";
// import CampaignFilterPopover from "../../components/CampaingnsComponents/CampaignFilterPopOver";
import CampaignFilterPopOvers from "../../components/CampaingnsComponents/CampaignFilterPopovers";
// import AddNewCampaign from "../../components/CampaingnsComponents/AddNewCampaign";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
// import { fetchManagePermissions } from "../../redux/slices/ManagePermissions/ManagePermissionsSlice";
import { getCampaign } from "../../redux/slices/Campaign/GetCampaignSlice";
// import moment from "moment";
import LoadingComponent from "../../components/common/LoadingComponent";
// import PaginationComponent from "../../components/common/PaginationComponent";
import EditCampaign from "../../components/ScheduledComponents/EditCampaign";
import { fetchInboxContacts } from "../../redux/slices/Inbox/InboxContactsSlice";
import SearchIconSvg2 from "../../assets/svgs/SearchIconSvg2";
import useDebouncedFetch from "../../utils/debounceHook";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventIcon from "@mui/icons-material/Event";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import { checkOnetimeCampaignsPermission } from "../../utils/permissions";
import NoAccessPage from "../../components/common/NoAccess";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          // Adjust the default padding for all TableCell components
          padding: "0px", // Set your desired padding value here
        },
      },
    },
  },
});
const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100vh",
    width: "100%",
    overFlow: "hidden !important",
  },
  searchField: {
    width: "100%",
    borderRadius: "12px",
    // height: "38px",
    // backgroundColor: bgColors.white2,
    backgroundColor: "white",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "inter",
      color: "#000000 !important",
    },
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
    overflow: "hidden !important",
    display: "flex",
    flexDirection: "column",
  },
  manageTeamContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "30px",
    width: "full",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    // fontWeight: "600 !important",
  },
  SaveChangesButton: {
    // backgroundColor: bgColors.green,
    // color: bgColors.white,
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "120px",
    height: "32px",
    // padding: "10px",
    cursor: "pointer",
  },
  messageCountContainer: {
    // border: "1px solid #F2F2F2",
    // borderRadius: "6px",
    // padding: "8px",
    // width: "30px",
    // paddingBottom: 6,
  },
  messageInnerContainer: {
    border: "2px solid #F2F2F2",
    borderRadius: "6px",
    paddingInline: "4px",
    // width: "10px",
    // paddingBottom: 0,
    // minWidth: "130px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  grayColor: {
    color: "#303030",
    opacity: "60%",
    fontSize: "20px",
    // padding:"5px"
  },
  spaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  iconStyles: {
    cursor: "pointer",
    paddingLeft: "5px",
    alignItems: "center",
    // marginTop: "2px",
    color: "#ffffff",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0",
    textAlign: "center",
    borderColor: "lightgray",
    "& th, & td": {
      // borderTop: '1px solid gray',
      borderBottom: "1px solid #f0f0f0",
      height: "35.8px",
    },
    "& th:first-child, & td:first-child": {
      borderLeft: "none",
    },
    "& th:last-child, & td:last-child": {
      borderRight: "none",
    },
  },
  teamProfileContainer: {
    display: "flex",
    alignItems: "center",
  },
});

// const options = [
//   {
//     id: 1,
//     option: "All",
//   },
//   {
//     id: 2,
//     option: "completed",
//   },
//   {
//     id: 3,
//     option: "Incompleted",
//   },
//   {
//     id: 4,
//     option: "Scheduled",
//   },
// ];

const options1 = [
  {
    id: 1,
    option: "All",
  },
  {
    id: 2,
    option: "Last 7 days",
  },
  {
    id: 3,
    option: "Last 14 days",
  },
  {
    id: 4,
    option: "Last 30 days",
  },
];

const Campaigns = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const debouncedFetchCampaigns = useDebouncedFetch(getCampaign, 1500);
  const userInfoSlice = useAppSelector((state:any) => state.adminLogin);
  const userInfo = userInfoSlice?.data;
  // const permissions = useAppSelector((state) => state.managePermissions.data);
  // const allowAcessToCreateCampaigns = permissions?.[3]?.access;
  const getuserPermissionData = useAppSelector(
    (state:any) => state.getUserPermissions?.data
  );
  const getTotalCampaign = useAppSelector(
    (state:any) => state?.getCampaign?.data?.total
  );
  const getCampaignData = useAppSelector(
    (state:any) => state?.getCampaign?.data?.data
  );
  // console.log(getCampaignData)
  const getCampaignStatus = useAppSelector(
    (state:any) => state?.getCampaign?.status
  );
  const campaignsPermissionsArray = getuserPermissionData?.campaigns;

  const oneTimeCampaignsPermissionsObject = campaignsPermissionsArray?.find(
    (item: any) => Object?.prototype?.hasOwnProperty?.call(item, "oneTime")
  );
  // console.log(
  //   oneTimeCampaignsPermissionsObject,
  //   "oneTimeCampaignsPermissionsObject"
  // );
  const oneTimeCampaignsPermissionsActions = oneTimeCampaignsPermissionsObject
    ? oneTimeCampaignsPermissionsObject.oneTime
    : [];
  const hasOnetimeCampaignsPermission = checkOnetimeCampaignsPermission(
    campaignsPermissionsArray
  );

  const [isHovered, setIsHovered] = useState(false);
  const [addNewCampaignTooltip, setAddNewCampaignTooltip] = useState(false);
  // console.log(debouncedFetchCampaigns)
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  // const [selectedFilter, setSelectedFilter] = React.useState("View All");
  const [selectedFilter1, setSelectedFilter1] = React.useState("All");
  // console.log(selectedFilter1)
  const [originalOption, setOriginalOption] = useState("All");
  const [searchCampaignQuery, setSearchCampaignQuery] = useState<string>("");
  // console.log(searchCampaignQuery)
  const [chatStatusFilter, setChatStatusFilter] = useState<any>("");
  // console.log(chatStatusFilter)
  const [filterData, setFilterData] = useState<any>("");
  // console.log(filterData)
  const [page, setPage] = useState(1);
  // console.log(page)
  const [hoveredRow, setHoveredRow] = useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [pageData, setPageData] = useState(getCampaignData || []);

  // console.log(getCampaignStatus);
  const hasAccess = (permission: any) => {
    if (oneTimeCampaignsPermissionsActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const bodyData = {
      businessId: userInfo?.companyId,
      userId: userInfo?.userId,

      filtering: {
        filtering: {
          filterType: "and",
          conditions: [
            {
              column:
                !chatStatusFilter ||
                (chatStatusFilter === "All" && chatStatusFilter)
                  ? ""
                  : "ChatStatus",
              operator: "equals",
              value: chatStatusFilter === "All" ? "" : chatStatusFilter,
            },
            {
              column: filterData ? "Tags" : "",
              operator: filterData ? "contain" : "",
              value: filterData,
            },
          ],
        },
      },
    };
    dispatch(fetchInboxContacts(bodyData));
  }, [filterData, chatStatusFilter]);

  // const getMc = getPermissionData?.profile
  // const scheduledObject = getMc?.find((item: any) => item.hasOwnProperty('scheduled '))
  // const scheduledActions = scheduledObject ? scheduledObject.scheduled  : [];
  // console.log("manageAccountActions", scheduledActions);

  // const handleFilterClick = (event: any) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleFilterClick1 = (event: any) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleOptionClick = (option: string) => {
    // console.log(option)
    const formattedOption = option.toLowerCase().replace(/\s+/g, "");
    // console.log(formattedOption)
    // setSelectedFilter(option);
    setPageData([]);
    setPage(1);
    setSelectedFilter1(formattedOption);
    setOriginalOption(option);
    handleClose1();
  };
  // const handleOptionClick1 = (option: string) => {
  //   setSelectedFilter1(option);
  //   handleClose1();
  // };
  const handleOpenDialog = () => {
    const hasPermissionToCreateCampaign = hasAccess("newCampaign");
    if (hasPermissionToCreateCampaign) {
      setOpenDialog(true);
    } else {
      setAddNewCampaignTooltip(true);
      setTimeout(() => {
        setAddNewCampaignTooltip(false);
      }, 2000);
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // console.log('anchorEl',anchorEl,openDialog)

  // React.useEffect(() => {
  //   // dispatch(fetchManagePermissions({ roleId: roleId, companyId }));
  // }, [dispatch, loginData]);

  useEffect(() => {
    if (getCampaignData) {
      if (page === 1) {
        setPageData(getCampaignData);
      } else {
        setPageData((prevPageData: any) => [
          ...prevPageData,
          ...getCampaignData,
        ]);
      }
      setIsLoadingMore(false);
    }
  }, [getCampaignData]);

  useEffect(() => {
    if (searchCampaignQuery || filterData) {
      setPage(1);
    }
  }, [searchCampaignQuery, filterData]);

  React.useEffect(
    () => {
      const data = {
        businessId: userInfo?.companyId,
        userId: userInfo?.userId,
        page: page,
        perPage: 40,
        filters: {
          searching: {
            value: searchCampaignQuery?.length > 0 ? searchCampaignQuery : "",
          },
          filtering: {
            filterType: "and",
            conditions: [
              {
                column: "dateSetLive",
                operator: selectedFilter1,
                // value: new Date(),
                value: new Date().toISOString().slice(0, 10),
              },
            ],
          },
        },
      };
      if (searchCampaignQuery) {
        debouncedFetchCampaigns(data);
      } else {
        dispatch(getCampaign(data));
      }
    },
    //  [dispatch, userInfo?.companyId, userInfo?.userId, openDialog, page, searchCampaignQuery, selectedFilter1]);
    [page, debouncedFetchCampaigns, searchCampaignQuery, selectedFilter1]
  );

  // React.useEffect(
  //   () => {
  //     const data = {
  //       businessId: userInfo?.companyId,
  //       userId: userInfo?.userId,
  //       page: 1,
  //       perPage: 10,
  //       filters: {
  //         searching: {
  //           value: searchCampaignQuery,
  //         },
  //         filtering: {
  //           filterType: "and",
  //           conditions: [
  //             {
  //               column: "dateSetLive",
  //               operator: selectedFilter1,
  //               value: new Date(),
  //             },
  //           ],
  //         },
  //       },
  //     };
  //     // dispatch(getCampaign(data));
  //     if (searchCampaignQuery?.length > 0) {
  //       debouncedFetchCampaigns(data);
  //     } else {
  //       dispatch(getCampaign(data));
  //     }

  //   },
  //   //  [dispatch, userInfo?.companyId, userInfo?.userId, openDialog, page, searchCampaignQuery, selectedFilter1]);
  //   [
  //     searchCampaignQuery, debouncedFetchCampaigns
  //   ]
  // );

  const handleSearchCampaigns = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchCampaignQuery(event.target.value);
  };

  const handleRowHover = (rowId: any) => {
    setHoveredRow(rowId);
  };

  // const formatDate = (datetime: any) => {
  //   const date = new Date(datetime);
  //   const year = date.getFullYear();
  //   const monthNames = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];
  //   const month = monthNames[date.getMonth()];
  //   const day = ("0" + date.getDate()).slice(-2);
  //   const hours = ("0" + date.getHours()).slice(-2);
  //   const minutes = ("0" + date.getMinutes()).slice(-2);
  //   const seconds = ("0" + date.getSeconds()).slice(-2);
  //   return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
  // };

  const formatDate = (datetime: any) => {
    const date = new Date(datetime);
    // Convert UTC to IST by adding 5 hours and 30 minutes
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);

    const year = date.getFullYear();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
  };

  // const handleLoadMore = () => {
  //   setIsLoadingMore(true);
  //   let currentPage = page +1
  //   setPage(currentPage);
  //   // setPageData((prevPageData:any) => [...prevPageData, ...getCampaignData]);
  //   // console.log(page)
  //   // setPage((prevPage) => prevPage + 1);
  // };
  const tableContainerRef = useRef(null);
  const handleScroll = () => {
    if (tableContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        tableContainerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 20 &&
        !isLoadingMore &&
        pageData?.length !== getTotalCampaign
      ) {
        handleLoadMore();
      }
    }
  };
  const handleLoadMore = useCallback(() => {
    if (
      !isLoadingMore &&
      pageData?.length !== getTotalCampaign &&
      page <= Math.ceil(getTotalCampaign / 40)
    ) {
      setIsLoadingMore(true);
      setPage((prevPage) => prevPage + 1);
    }
  }, [isLoadingMore, pageData?.length, getTotalCampaign]);
  return (
    <>
      {hasOnetimeCampaignsPermission ? (
        <Grid
          className={classes.mainContainer}
          // sx={{ height: getCampaignData?.length > 2 ? "100" : "100%" }}
        >
          <Box className={classes.bgContainer}>
            <Box sx={{ height: "120px" }}>
              <Box className={classes.manageTeamContainer}>
                <Box ml={2}>
                  <Typography
                    variant="h6"
                    className={classes.blackColor}
                    sx={{ display: "flex" }}
                  >
                    <span style={{ paddingTop: "3px" }}>
                      <AccessTimeIcon sx={{ height: "22px" }} />
                    </span>
                    &nbsp;Onetime
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: { xs: "block", md: "flex" },
                    width: "100%",
                    alignItems: "center",
                  }}
                  m={{ xs: 1, md: 0 }}
                >
                  <TextField
                    className={classes.searchField}
                    variant="standard"
                    size="small"
                    // id="password"
                    fullWidth
                    onChange={handleSearchCampaigns}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: "12px",
                        padding: "7px",
                        height: "38px",
                        margin: 0,
                      },
                      startAdornment: (
                        <IconButton
                          sx={{ p: 0, color: "inherit" }}
                          aria-label="search"
                        >
                          {/* <SearchIconSvg /> */}
                          <SearchIconSvg2 />
                        </IconButton>
                      ),
                    }}
                    inputProps={{
                      style: {
                        // Additional style for placeholder
                        fontWeight: "600 !important",
                        paddingTop: "3px", // Apply font weight here
                      },
                    }}
                    placeholder="Search onetime campaigns"
                  />
                </Box>
                <Box
                  style={{ marginLeft: "auto" }}
                  m={3}
                  sx={{
                    backgroundColor: "#fff",

                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "rgba(68, 71, 70, 0.08)",
                    },
                  }}
                >
                  <Tooltip
                    title="Access Denied"
                    placement="left"
                    open={addNewCampaignTooltip}
                    onClose={() => setAddNewCampaignTooltip(false)}
                  >
                    <button
                      className={classes.SaveChangesButton}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      style={{
                        // backgroundColor:"#fff",
                        backgroundColor: isHovered
                          ? "rgba(68, 71, 70, 0.08)"
                          : "#fff",
                        cursor: isHovered ? "pointer" : "default", // Optional: change cursor style on hover
                        // Add other styles if needed
                      }}
                      onClick={handleOpenDialog}
                    >
                      + Add Campaign
                    </button>
                  </Tooltip>
                </Box>
                <EditCampaign
                  title="Add"
                  data={null}
                  open={openDialog}
                  handleClose={handleCloseDialog}
                  setFilterData={setFilterData}
                  setChatStatusFilter={setChatStatusFilter}
                />
              </Box>
              <Box display="flex" flexDirection="row">
                <Box className={classes.messageCountContainer}>
                  {/* <Box className={classes.messageInnerContainer}>
              <Typography
                variant="body2"
                className={classes.grayColor}
                style={{ padding: "7px" }}
              >
                {selectedFilter}
              </Typography>
              <Box className={classes.iconStyles} onClick={handleFilterClick}>
                <ArrowDownSvg />
              </Box>
              <CampaignFilterPopover
                anchorEl={anchorEl}
                handleClose={handleClose}
                options={options}
                handleOptionClick={handleOptionClick}
              />
            </Box> */}
                </Box>
                <Box
                  mx={2}
                  className={classes.messageCountContainer}
                  sx={{ cursor: "pointer" }}
                  onClick={handleFilterClick1}
                >
                  <Box className={classes.messageInnerContainer}>
                    <Typography
                      variant="body2"
                      className={classes.grayColor}
                      style={{ padding: "5px" }}
                    >
                      {selectedFilter1 === "All" ? (
                        <span
                          style={{
                            display: "flex",
                            fontSize: 12,
                            alignItems: "center",
                          }}
                        >
                          <ChecklistRtlIcon sx={{ height: "15px" }} />{" "}
                          {selectedFilter1}
                        </span>
                      ) : (
                        <span
                          style={{
                            fontSize: 12,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <EventIcon sx={{ height: "15px" }} /> {originalOption}
                        </span>
                      )}
                    </Typography>
                    <Box
                      className={classes.iconStyles}
                      sx={{ marginRight: "5px" }}
                    >
                      <ArrowDownSvg />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box style={{ flex: "1", overflow: "hidden" }}>
              <ThemeProvider theme={theme}>
                <TableContainer
                  component={Box}
                  mt={2}
                  sx={{
                    height: "100%",
                    scrollBehavior: "smooth",
                    // width: "99%",
                  }}
                  onScroll={handleScroll}
                  ref={tableContainerRef}
                >
                  <Table
                    className={classes.table}
                    sx={{ fontSize: "14px !important" }}
                  >
                    <TableHead className={classes.grayColor}>
                      <TableRow>
                        <TableCell sx={{ width: "35%", paddingLeft: "20px" }}>
                          Title
                        </TableCell>
                        <TableCell>Created by</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Attempted</TableCell>
                        <TableCell>Sent</TableCell>
                        <TableCell>Delivered</TableCell>
                        <TableCell>Read</TableCell>
                        <TableCell>Replied</TableCell>
                        <TableCell>Date Set Live</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getCampaignStatus === "loading" && !isLoadingMore ? (
                        // <LoadingComponent  height="100%" color={bgColors.blue} />
                        <TableBody
                          sx={{
                            // position: "relative",
                            margin: "20px",
                            // height: "500px",
                          }}
                        >
                          <TableRow
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              position: "absolute",
                              left: "60%",
                              transform: "translateX(-50%)",
                              paddingLeft: "50px",
                              top: "20px",
                              height: "100%",
                            }}
                          >
                            <LoadingComponent
                              height="500px"
                              color={bgColors.blue}
                            />
                          </TableRow>
                        </TableBody>
                      ) : (
                        <>
                          {pageData?.length ? (
                            <>
                              {pageData?.map((row: any, index: number) => {
                                const isHovered = index === hoveredRow;
                                return (
                                  <TableRow
                                    key={index}
                                    onMouseEnter={() => handleRowHover(index)}
                                    onMouseLeave={() => setHoveredRow(null)}
                                    sx={{
                                      cursor: "pointer",
                                      boxShadow: isHovered ? 3 : 0,
                                      transition: "box-shadow 0.3s",
                                    }}
                                  >
                                    <TableCell
                                      style={{
                                        width: "35%",
                                        fontSize: "14px !important",
                                      }}
                                    >
                                      <Tooltip
                                        title={
                                          <Typography
                                            style={{ fontSize: "14px" }}
                                          >
                                            <span>{row?.campaignTitle}</span>
                                          </Typography>
                                        }
                                        placement="top"
                                      >
                                        <Box>
                                          <Box
                                            sx={{
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              maxWidth: "250px",
                                            }}
                                          >
                                            <span
                                              style={{ marginLeft: "20px" }}
                                            >
                                              {row?.campaignTitle}
                                            </span>
                                          </Box>
                                        </Box>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell className={classes.blackColor}>
                                      {row?.createdby}
                                    </TableCell>
                                    <TableCell className={classes.blackColor}>
                                      {row?.state === 1
                                        ? "Completed"
                                        : row?.state === 2
                                        ? "InComplete"
                                        : "Scheduled"}
                                    </TableCell>
                                    <TableCell
                                      sx={{ textAlign: "center" }}
                                      className={classes.blackColor}
                                    >
                                      {row?.attempted}
                                    </TableCell>
                                    <TableCell
                                      sx={{ textAlign: "center" }}
                                      className={classes.blackColor}
                                    >
                                      {row?.sent}
                                    </TableCell>
                                    <TableCell
                                      sx={{ textAlign: "center" }}
                                      className={classes.blackColor}
                                    >
                                      {row?.delivered}
                                    </TableCell>
                                    <TableCell
                                      sx={{ textAlign: "center" }}
                                      className={classes.blackColor}
                                    >
                                      {row?.read}
                                    </TableCell>
                                    <TableCell
                                      sx={{ textAlign: "center" }}
                                      className={classes.blackColor}
                                    >
                                      {row.replied}
                                    </TableCell>
                                    <TableCell className={classes.blackColor}>
                                      {/* {moment(row?.dateSetLive).format(
                                  "MMMM D, YYYY"
                                )} */}
                                      {formatDate(row?.dateSetLive)}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </>
                          ) : (
                            // <Typography sx={{ marginLeft: "28px" }}>
                            //   No Campaign Found
                            // </Typography>
                            <TableRow sx={{ height: "60vh" }}>
                              <TableCell
                                colSpan={12}
                                align="center"
                                sx={{
                                  width: "100%",
                                  border: "none !important",
                                }}
                              >
                                No Campaign Found
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                    </TableBody>
                  </Table>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "20px",
                    }}
                  >
                    {pageData?.length !== 0 &&
                      pageData !== null &&
                      pageData?.length !== getTotalCampaign &&
                      !isLoadingMore && (
                        <Typography
                          onClick={handleLoadMore}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              color: "blue",
                            },
                          }}
                        >
                          {getCampaignStatus !== "loading" ? (
                            // <button
                            //   style={{
                            //     cursor: 'pointer',
                            //     fontSize: "14px",
                            //     borderRadius: '5px',
                            //     border: `1px solid ${bgColors.green}`,
                            //     backgroundColor: bgColors.white,
                            //     color: bgColors.green,
                            //     padding: '5px'
                            //   }}
                            // >
                            //   Load More...
                            // </button>
                            <></>
                          ) : (
                            ""
                          )}
                        </Typography>
                      )}
                    {isLoadingMore && (
                      <LoadingComponent height="50px" color={bgColors.blue} />
                    )}
                  </Box>
                </TableContainer>
              </ThemeProvider>
            </Box>
          </Box>
          {/* <Box sx={{ paddingTop: "10px", paddingBottom: "5px" }}>
        {" "}
        <PaginationComponent setPage={setPage} total={getTotalCampaign} />
      </Box> */}
          <CampaignFilterPopOvers
            anchorEl={anchorEl1}
            handleClose={handleClose1}
            options={options1}
            handleOptionClick={handleOptionClick}
          />
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

export default Campaigns;
