import React from "react";
import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { makeStyles } from "@mui/styles";
import { bgColors } from "../../../utils/bgColors";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import TemplatePreviewLayout from "../../TemplateComponents/TemplateForm/templatePreviewLayout";
import { CreateButtonArray } from "../../TemplateComponents/TemplateForm/functions";
import { convertUtcToLocal, parseTextToHtml } from "../../../utils/functions";

const useStyles = makeStyles(() => ({
  replyBoxText: {
    flexGrow: 1,
    backgroundColor: bgColors?.gray2,
    padding: "10px",
    borderRadius: "5px",
    "&::-webkit-scrollbar": {
      cursor: "pointer",
      width: "6px" /* Width of the scrollbar */,
    },
    "&::-webkit-scrollbar-thumb": {
      cursor: "pointer",
      backgroundColor: "#cdcdcd" /* Color of the scrollbar thumb */,
      borderRadius: "6px" /* Rounded corners for the scrollbar thumb */,
      transition:
        "background-color 0.2s ease-in-out" /* Optional: Add a transition effect for smooth hover */,
    },
  },
}));

interface ReplyBoxProps {
  reply: any;
  replyToggle?: boolean;
  maxStyle?: { maxHeightStyle: any; maxWidthStyle: any };
  handleCancelReply?: () => void;
}

const ReplyBox: React.FC<ReplyBoxProps> = ({
  reply,
  replyToggle,
  maxStyle,
  handleCancelReply,
}) => {
  const classes = useStyles();
  const buttonsArray = CreateButtonArray({
    quickReplies: reply?.quickReplies || [],
    redirectUrls: reply?.redirectUrls || [],
    urlButtonNames: reply?.urlButtonNames || [],
    phoneNumber: reply?.phoneNumber,
    callButtonName: reply?.callButtonName,
  });
  const renderMedia = () => {
    if (reply?.mediaMimeType === null) return null;

    switch (reply?.mediaMimeType) {
      case "image/jpeg":
      case "image/png":
      case "image/gif":
        return (
          <Box sx={maxStyle?.maxWidthStyle}>
            <img
              src={reply?.mediaUrl}
              alt="Image"
              style={{ maxWidth: "330px" }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: parseTextToHtml(reply?.mediaCaption || ""),
              }}
            />
          </Box>
        );
      case "video/mp4":
        return (
          <Box sx={maxStyle?.maxWidthStyle}>
            <video controls style={{ maxWidth: "330px" }}>
              <source src={reply?.mediaUrl} type="video/mp4" />
            </video>
            <div
              dangerouslySetInnerHTML={{
                __html: parseTextToHtml(reply?.mediaCaption || ""),
              }}
            />
          </Box>
        );
      case "audio/mpeg":
      case "audio/ogg":
      case "audio/amr":
      case "audio/aar":
        return (
          <audio controls style={{ maxWidth: "330px" }}>
            <source src={reply?.mediaUrl} />
          </audio>
        );
      case "application/pdf":
        return (
          <Box sx={maxStyle?.maxWidthStyle}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PictureAsPdfIcon fontSize="large" />
              <Typography sx={{ px: 1, fontSize: 12 }}>
                {reply?.mediaFileName}
              </Typography>
            </Box>
            <div
              dangerouslySetInnerHTML={{
                __html: parseTextToHtml(reply?.mediaCaption || ""),
              }}
            />
          </Box>
        );
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return (
          <Box sx={maxStyle?.maxWidthStyle}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InsertDriveFileIcon fontSize="large" />
              <Typography sx={{ px: 1, fontSize: 12 }}>
                {reply?.mediaFileName}
              </Typography>
            </Box>
            <div
              dangerouslySetInnerHTML={{
                __html: parseTextToHtml(reply?.mediaCaption || ""),
              }}
            />
          </Box>
        );
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return (
          <Box sx={maxStyle?.maxWidthStyle}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InsertDriveFileIcon fontSize="large" />{" "}
              <Typography sx={{ px: 1, fontSize: 12 }}>
                {reply?.mediaFileName}
              </Typography>
            </Box>
            <div
              dangerouslySetInnerHTML={{
                __html: parseTextToHtml(reply?.mediaCaption || ""),
              }}
            />
          </Box>
        );
      default:
        return (
          <Box sx={maxStyle?.maxWidthStyle}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InsertDriveFileIcon fontSize="large" />{" "}
              <Typography sx={{ px: 1, fontSize: 12 }}>
                {reply?.mediaFileName}
              </Typography>
            </Box>
            <div
              dangerouslySetInnerHTML={{
                __html: parseTextToHtml(reply?.mediaCaption || ""),
              }}
            />
          </Box>
        );
    }
  };

  return (
    <Box
      className={`${classes.replyBoxText} + ${
        maxStyle ? maxStyle?.maxHeightStyle : ""
      }`}
    >
      {replyToggle && (
        <Box
          sx={{
            justifyContent: "space-between",
            width: "100%",
            display: "flex",
            mb: 1,
          }}
        >
          <ShortcutIcon fontSize="small" sx={{ transform: "scaleX(-1)" }} />
          <CloseIcon
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={handleCancelReply}
          />
        </Box>
      )}
      {reply?.templateBody !== null && (
        <Box sx={maxStyle?.maxWidthStyle ? maxStyle?.maxWidthStyle : ""}>
          <TemplatePreviewLayout
            header={reply?.templateHeader}
            body={reply?.templateBody}
            footer={reply?.templateFooter}
            mediaType={reply?.templateMediaType}
            mediaFile={reply?.templateMediaFile}
            buttons={buttonsArray}
          />
        </Box>
      )}
      {renderMedia()}
      <Typography sx={{ fontSize: "12px", maxWidth: { xs: 200, md: 580 } }}>
        {reply?.textMessage}
      </Typography>
      <Typography sx={{ mt: 2, fontSize: "10px", display: "flex" }}>
        {location?.href?.includes(reply?.from) ? reply?.from : "You"},&nbsp;
        <Typography sx={{ fontSize: "10px" }}>
          {new Date(
            new Date(convertUtcToLocal(reply?.createdAt))?.getTime()
          ).toLocaleDateString("en-UK", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
          &nbsp;at&nbsp;
          {new Date(
            new Date(convertUtcToLocal(reply?.createdAt))?.getTime()
          ).toLocaleTimeString("en-UK", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </Typography>
      </Typography>
      {/* <IconButton className={classes.cancelIcon} onClick={handleCancelReply}>
          <CloseIcon />
        </IconButton> */}
    </Box>
  );
};

export default ReplyBox;
