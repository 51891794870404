import React, { useState } from "react";
import {
  Dialog,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import { createWorkflowCustomMessage } from "../../../redux/slices/Workflows/createWorkflowCustomMessageSlice";
import { toastActions } from "../../../utils/toastSlice";
import { getWorkflowList } from "../../../redux/slices/Workflows/getWorkflowListSlice";
import NavBar from "./Navbar";
import ListDetails from "./ListDetails";
import CreateList from "./CreateList";
import { updateWorkflowList } from "../../../redux/slices/Workflows/updateWorkflowListSlice";

const SelectListDialog = ({
  open,
  onClose,
  onSelect,
  workflowListData,
  handleSelectedListChange,
  expandedStep,
}: any) => {
  const [openAccordion, setOpenAccordion] = useState(null);
  const dispatch = useAppDispatch();
  //@ts-ignore
  const userProfileSlice = useAppSelector((state) => state?.adminLogin);
  const userData = userProfileSlice?.data;

  const handleListSelect = (list: any) => {
    setOpenAccordion((prev: any) =>
      prev === list.listName ? null : list.listName
    );
  };

  const handleAddInput = (currentInputs: any) => {
    if (currentInputs < 10) {
      const updatedList = {
        ...workflowListData.find(
          (list: any) => list.listName === openAccordion
        ),
      };
      updatedList.inputs = [
        ...updatedList.inputs,
        { title: "", description: "" },
      ];
      handleSelectedListChange(expandedStep, updatedList);
    }
  };

  const handleUpdateList = async (updatedList: any) => {
    handleSelectedListChange(expandedStep, updatedList);
    onSelect(updatedList);
    await updateWorkflowList(updatedList);
    onClose();
  };

  // const handleSelectAndUpdate = () => {
  //   const updatedList = {
  //     ...editingList,
  //     inputs: editingList.inputs.map((input: any) => ({
  //       title: input.text,
  //       description: input.description,
  //     })),
  //   };
  //   onSelect(updatedList);
  //   handleSelectedListChange(expandedStep, updatedList);
  //   onClose();
  // };

  const handleCreateList = async (newList: any) => {
    if (!newList.listName || newList.listName.trim() === "") {
      dispatch(
        toastActions.setToaster({
          message: "List name cannot be empty",
          type: "error",
        })
      );
      return;
    }

    const isDuplicate = workflowListData.some(
      (list: any) =>
        list.listName.toLowerCase() === newList.listName.toLowerCase()
    );
    if (isDuplicate) {
      dispatch(
        toastActions.setToaster({
          message: "A list with this name already exists",
          type: "error",
        })
      );
      return;
    }

    if (!newList.inputs || newList.inputs.length === 0) {
      dispatch(
        toastActions.setToaster({
          message: "List must have at least one input",
          type: "error",
        })
      );
      return;
    }

    // Check if all inputs have a title
    const hasEmptyInput = newList.inputs.some(
      (input: any) => !input.title || input.title.trim() === ""
    );
    if (hasEmptyInput) {
      dispatch(
        toastActions.setToaster({
          message: "All inputs must have a title",
          type: "error",
        })
      );
      return;
    }

    const payload = {
      companyId: userData?.companyId,
      ...newList,
    };

    try {
      const result = await dispatch(
        createWorkflowCustomMessage(payload)
      ).unwrap();
      if (result.success) {
        dispatch(
          toastActions.setToaster({
            message: "Workflow list created successfully",
            type: "success",
          })
        );
        dispatch(getWorkflowList(payload));
        onClose();
      } else {
        dispatch(
          toastActions.setToaster({
            message: result.message || "Failed to create workflow list",
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        toastActions.setToaster({
          message: "An error occurred while creating the workflow list",
          type: "error",
        })
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        borderRadius: "16px",
        "& .MuiPaper-root": {
          borderRadius: "16px",
          overflow: "hidden",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "90vh",
        }}
      >
        <NavBar onClose={onClose} />
        <Box
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            padding: "16px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginBottom: "16px",
              fontWeight: 500,
              fontSize: "16px",
              paddingLeft: "12px",
            }}
          >
            Available Lists
          </Typography>
          <Box sx={{ height: "70vh", overflowY: "scroll", padding: "12px" }}>
            <Accordion
              sx={{
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                mb: "16px",
                width: "100%",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  borderRadius: "8px",
                  bgcolor: "#f9f9f9",
                  padding: "0 16px",
                }}
              >
                <Typography
                  sx={{
                    color: "green",
                    fontWeight: 600,
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  + Create List
                </Typography>
              </AccordionSummary>
              <CreateList onCreate={handleCreateList} />
            </Accordion>
            {workflowListData?.map((list: any) => (
              <Accordion
                expanded={openAccordion === list.listName}
                key={list.id}
                onChange={() => handleListSelect(list)}
                sx={{
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  mb: "16px",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    borderRadius: "8px",
                    bgcolor: "#f9f9f9",
                    padding: "0 16px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {list.listName}
                  </Typography>
                </AccordionSummary>
                <ListDetails
                  list={list}
                  onUpdate={handleUpdateList}
                  onAddInput={handleAddInput}
                />
              </Accordion>
            ))}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SelectListDialog;
