import React from "react";
import { Box, Grid } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import ChatSideBar from "../../components/InboxComponents/ChatSideBar";
import InboxDetails from "../../components/InboxComponents/InboxDetails";
import { useEffect } from "react";
import LoadingComponent from "../../components/common/LoadingComponent";
import { bgColors } from "../../utils/bgColors";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import {
  getExpectedWalletDetails,
  getWalletDetails,
} from "../../redux/slices/Wallet/WalletSlice";
import NoAccessPage from "../../components/common/NoAccess";

const useStyles = makeStyles({
  sideBar: {
    position: "sticky",
    top: 0,
    height: "100vh",
    overflowY: "auto",
    boxShadow: "1px 2px 4px #CDCDCD",
  },
  outlet: {
    overflowY: "auto",
    height: "100vh",
  },
});

const Inbox = ({
  contactsListLoading,
  connection,
  contacts,
  messages,
  chat,
  filterData,
  chatsPageNumber,
  setChatsPageNumber,
  setMessages,
  searchInput,
  setSearchInput,
  setFilterData,
  setContactNumber,
  contactNumber,
  handleLoadMore,
}: any) => {
  const classes = useStyles();
  const location = useLocation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isInbox = location.pathname.startsWith("/inbox");
  const loginData = useAppSelector((state: any) => state?.adminLogin?.data);
  const expectedWalletSlice = useAppSelector((state: any) => state?.wallet);
  const currentPlanDetails = useAppSelector(
    (state: any) => state?.wallet?.walletAndSubscription?.data
  );
  const accountData = useAppSelector((state: any) => state?.accountData?.data);
  const walletBalance =
    expectedWalletSlice?.expectedAmount?.data?.expectedWalletBalance;
  // const walletAmount = useAppSelector((state: any) => state.wallet.amount);
  // const walletBalance = walletAmount?.data?.wallet?.balance;

  useEffect(() => {
    // const contactNumberFromParams =
    //   contacts.find((contact: any) => contact.contact === contactNumber)
    //     ?.contact || "help";
    // if (contactNumberFromParams) {
    dispatch(getWalletDetails());
    dispatch(getExpectedWalletDetails(loginData?.companyId));
    setContactNumber(params?.id);
    // }
  }, []);

  useEffect(() => {
    if (searchInput) {
      navigate("/inbox/help");
    }
  }, [searchInput]);

  // const [connection, setConnection] = useState<any>(null);
  // const [isConnected, setIsConnected] = useState<boolean>(false);
  // const [contacts, setContacts] = useState<Contact[]>([]);
  // const [messages, setMessages] = useState<any>();
  // const [chat, setChat] = useState<any>();
  // const [searchInput, setSearchInput] = useState<string>("");
  // const [filterData, setFilterData] = useState<any>({ filters: [], sort: {} });

  // const operations = {
  //   searching: {
  //     value: searchInput,
  //   },
  //   sorting: filterData?.sort,
  //   filtering: {
  //     filterType: "and",
  //     conditions: filterData?.filters,
  //   },
  // };
  // useEffect(() => {
  //   const hubConnection: any = new HubConnectionBuilder()
  //     .withUrl("https://webhooks.engageto.com/conversations")
  //     .withAutomaticReconnect()
  //     // .configureLogging(LogLevel.Information)
  //     .build();
  //   setConnection(hubConnection);
  //   hubConnection
  //     .start()
  //     .then(() => {
  //       // console.log("connected", userData?.userId, businessId, operations);
  //       setIsConnected(true);
  //       hubConnection.invoke(
  //         "SendUserData",
  //         userData?.userId,
  //         businessId,
  //         operations
  //       );
  //       hubConnection.on("RenderContacts", () => {
  //         hubConnection.invoke(
  //           "SendUserData",
  //           userData?.userId,
  //           businessId,
  //           operations
  //         );
  //       });
  //       hubConnection.on("SendContacts", (data: Contact[]) => {
  //         // console.log("data", data);
  //         setContacts(data);
  //       });
  //       hubConnection
  //         .invoke(
  //           "ContactConversationHistory",
  //           contactNumber,
  //           businessId,
  //           userData?.userId
  //         )
  //         .then(() => {
  //           // console.log("ContactConversationHistory1");
  //         })
  //         .catch((error: any) => {});
  //       hubConnection.on("SendConversations", (data: any) => {
  //         // console.log("sendConversations", data);
  //         setChat(data);
  //         setMessages(data?.data);
  //       });
  //     })
  //     .catch(() => {
  //       setIsConnected(false);
  //     });

  //   hubConnection.onreconnecting((error: any) => {});
  //   hubConnection.onreconnected((connectionId: any) => {
  //     hubConnection.invoke(
  //       "SendUserData",
  //       userData?.userId,
  //       businessId,
  //       operations
  //     );
  //   });
  // }, []);

  // useEffect(() => {
  //   if (connection && isConnected) {
  //     // console.log("connected1");
  //     connection
  //       .invoke(
  //         "ContactConversationHistory",
  //         contactNumber,
  //         businessId,
  //         userData?.userId
  //       )
  //       .then(() => {})
  //       .catch((error: any) => {});
  //     // console.log("ContactConversationHistory2");
  //     connection.on("SendConversations", (data: any) => {
  //       // console.log("sendConversations", data);
  //       setChat(data);
  //       setMessages(data?.data);
  //     });
  //   }
  //   const handleNewMessage = async (data: any) => {
  //     console.log("ReceiveMessageFromServer", data, contactNumber);
  //     // Check if the contactNumber matches either from or to
  //     if (contactNumber === data[0]?.from || contactNumber === data[0]?.to) {
  //       setMessages((prevConversations: any) => {
  //         // console.log("prevConversations", prevConversations, contactNumber);
  //         // Check if the message already exists in the messages state
  //         const index = prevConversations?.findIndex(
  //           (message: any) => message?.id === data[0]?.id
  //         );
  //         // console.log("index: " + index);
  //         // If the message doesn't exist, mark as read and append it
  //         if (index !== -1) {
  //           // If the message is found, replace it
  //           const updatedConversations = [...prevConversations];
  //           updatedConversations[index] = data[0];
  //           return updatedConversations;
  //         } else {
  //           // If the message is not found, add it to the list
  //           connection.invoke("MarkAsRead", data[0]?.id, businessId);
  //           return [...prevConversations, data[0]];
  //         }
  //         // return prevConversations;
  //       });
  //     }

  //     if(data[0]?.from?.length < 13){
  //       if (navigator.serviceWorker && navigator.serviceWorker.controller) {
  //         navigator.serviceWorker.controller.postMessage({
  //           type: 'NEW_MESSAGE',
  //           message: {
  //             title: `${data[0]?.name}`,
  //             body: `${data[0]?.textMessage}`, // Customize the body content
  //             icon: '/AhexLogo.png', // Specify an appropriate icon
  //             data: {
  //              url:  `/inbox/${data[0]?.from}?open`
  //             }
  //           }
  //         });
  //       }
  //     }

  //   };

  //   const setupMessageHandler = async () => {
  //     if (connection && isConnected) {
  //       connection.on("ReceiveMessageFromServer", handleNewMessage);
  //     }
  //   };

  //   setupMessageHandler();

  //   return () => {
  //     if (connection) {
  //       connection.off("ReceiveMessageFromServer", handleNewMessage);
  //     }
  //   };
  // }, [connection, isConnected, contactNumber]);

  // useEffect(() => {
  //   if (connection && isConnected) {
  //     // console.log("searching connection");
  //     connection.invoke(
  //       "SendUserData",
  //       userData?.userId,
  //       businessId,
  //       operations
  //     );
  //     connection.on("SendContacts", (data: Contact[]) => {
  //       // console.log("data", data);
  //       setContacts(data);
  //     });
  //   }
  // }, [searchInput, filterData]);

  // // Clean up the connection when the component is unmounted
  // useEffect(() => {
  //   return () => {
  //     if (connection) {
  //       connection.stop();
  //     }
  //   };
  // }, [connection]);
  // console.log("expectedWalletBalance", walletBalance);

  return (
    <>
      {currentPlanDetails?.subscriptionPlan?.isActive &&
      ((accountData?.companyVerificationStatus === true &&
        accountData?.isMetaEnabled) ||
        (accountData?.isMetaEnabled === false &&
          accountData?.companyVerificationStatus === false &&
          currentPlanDetails?.subscriptionPlan?.planName === "Intro")) ? (
        <Grid container>
          {contactsListLoading?.allContactsList ||
          expectedWalletSlice?.expectedWalletstatus === "loading" ? (
            <Box sx={{ height: "100vh", width: "100%" }}>
              <LoadingComponent height="100%" color={bgColors?.blue} />
            </Box>
          ) : (
            <>
              <Grid
                item
                xs={0}
                sx={{ display: { xs: "none", md: "block" } }}
                md={3}
                className={classes.sideBar}
              >
                {isInbox && (
                  <ChatSideBar
                    contacts={contacts}
                    searchInput={searchInput}
                    filterData={filterData}
                    chatsPageNumber={chatsPageNumber}
                    setChatsPageNumber={setChatsPageNumber}
                    setSearchInput={setSearchInput}
                    setFilterData={setFilterData}
                    setContactNumber={setContactNumber}
                    paginatedContactsLoading={
                      contactsListLoading?.paginatedContactsList
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} md={9} className={classes.outlet}>
                <InboxDetails
                  walletBalance={walletBalance}
                  messages={messages}
                  setMessages={setMessages}
                  chat={chat}
                  contactNumber={contactNumber}
                  contacts={contacts}
                  hubConnection={connection}
                  onLoadMore={handleLoadMore}
                  setChatsPageNumber={setChatsPageNumber}
                />
              </Grid>
            </>
          )}
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

export default Inbox;
