import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AUTOMATION_API } from "../../../Apis/AdminLogin/Automation/Automation";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const getContactColumns = createAsyncThunk(
  "getContactColumns",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await AUTOMATION_API.getContactColumns();
      return response?.data;
    } catch (error: any) {
      // Provide a descriptive error message
      return rejectWithValue(error.response || "An error occurred");
    }
  }
);

export const getContactColumnsSlice = createSlice({
  name: "getContactColumnsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContactColumns.pending, (state) => {
        state.status = "loading";
        state.data = null;
      })
      .addCase(getContactColumns.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getContactColumns.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const getContactColumnsActions = getContactColumnsSlice.actions;
export default getContactColumnsSlice.reducer;
