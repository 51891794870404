import { Box, Grid, Typography } from "@mui/material";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { bgColors } from "../../utils/bgColors";
import CustomMessageInput from "../../components/IntegrationsComponents/CustomTesxtField";
import { makeStyles } from "@mui/styles";
import PhoneInput from "../../components/common/PhoneInput";
import { useAppSelector } from "../../utils/redux-hooks";
import WhatsAppPreview from "../../components/IntegrationsComponents/WhatsAppPreview";
import { validatePhoneNumber } from "../../utils/functions";
import WhatsAppLinkPopup from "../../components/IntegrationsComponents/WhatsAppLinkPopUp";
import EmojiPopover from "../../components/InboxComponents/inboxDetailsComponents/EmojiPicker";
import ChatEmojiIcon from "../../assets/svgs/ChatEmojiIcon";

// import CustomMessageInput from "./CustomTesxtField";
const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    marginTop: "20px",
    width: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "50%",
    width: "100%",
    // position: "relative", // To allow the button to be positioned absolutely within the grid
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
});

const WhatsappLink: React.FC = () => {
  const classes = useStyles();

  const textFieldRef = useRef<HTMLDivElement>(null);

  const [phoneNumber, setPhoneNumber] = useState({
    phoneNumber: "",
    countryCode: "+91",
  });
  // console.log(phoneNumber, "phoneNumber");
  const [validPhoneNumber, setValidPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  // console.log(message, "message");
  const [emojiPopoverOpen, setEmojiPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [isTyping, setIsTyping] = useState(true);
  const [open, setOpen] = useState(false);

  const countryCodeArray = useAppSelector(
    (state: any) => state.getAllCoutryCodes?.data
  );
  const codeArray =
    countryCodeArray &&
    countryCodeArray?.map((item: any) => ({
      countryCode: item?.countryCode,
      countryImage: item?.countryImage,
    }));

  const handleCountryCodeChange = (value: string) => {
    // console.log("value", value);
    setPhoneNumber({ ...phoneNumber, countryCode: value });
  };

  const handlePhoneChange = (value: string) => {
    setPhoneNumber({ ...phoneNumber, phoneNumber: value });
    setValidPhoneNumber("");
  };

  const handleInputChange = (event: any) => {
    setMessage(event.target.value); // Update typed message immediately
  };
  const handleGenerateLink = () => {
    const isValidPhone = /^\d+$/.test(phoneNumber?.phoneNumber);
    if (!phoneNumber?.phoneNumber?.trim()) {
      setValidPhoneNumber("Country code and Phone number is required.");
      return;
    }
    if (
      phoneNumber?.phoneNumber?.trim() &&
      !validatePhoneNumber(phoneNumber?.phoneNumber, phoneNumber?.countryCode)
    ) {
      setValidPhoneNumber(
        "This phone number format is not recognized. Please check the country code and phone number."
      );
      return;
    }

    if (phoneNumber?.phoneNumber?.trim() && !isValidPhone) {
      setValidPhoneNumber("Phone number should only contain numbers.");
      return;
    }
    setOpen(true);
  };

  const whatsappRedirect = `https://api.whatsapp.com/send?phone=${phoneNumber?.countryCode.replace(
    "+",
    ""
  )}${phoneNumber?.phoneNumber}&text=${message}`;

  const handleClose = () => setOpen(false);
  const handleCloseEmojiPopover = () => {
    setEmojiPopoverOpen(false);
  };
  const handleEmojiSelect = (emoji: any) => {
    if (textFieldRef.current) {
      const input = textFieldRef.current.querySelector("textarea");

      if (input && typeof input.setSelectionRange === "function") {
        const start = input.selectionStart;
        const end = input.selectionEnd;

        // const newMessage =
        //   messageState?.message?.slice(0, start) +
        //   emoji +
        //   messageState?.message?.slice(end);
        const newMessage =
          message?.slice(0, start) + emoji + message?.slice(end);

        // setMessageState((prevState) => ({
        //   ...prevState,
        //   message: newMessage,
        // }));
        setMessage(newMessage);

        // Set the cursor position after the newly added emoji
        setTimeout(() => {
          input.setSelectionRange(start + emoji.length, start + emoji.length);
          input.focus();
        }, 0);
      }
    }
  };
  const handleEmojiClick = (event: MouseEvent<HTMLDivElement>) => {
    const hasTextMessagePermission = true;
    if (hasTextMessagePermission) {
      setAnchorEl(event.currentTarget);
      setEmojiPopoverOpen(true);
    } else {
      // setSendEmojiMessageTooltip(true);
      setTimeout(() => {
        // setSendEmojiMessageTooltip(false);
      }, 2000);
    }
  };

  useEffect(() => {
    // Add a delay before updating the `message` state
    setIsTyping(false);
    setTimeout(() => {
      setIsTyping(true); // Update the message after the delay
    }, 2000); // 2 seconds delay
  }, [message]); // Effect runs whenever `typedMessage` changes

  return (
    <Grid className={classes.mainContainer}>
      <Grid className={classes.bgContainer}>
        <Box className={classes.manageContainer}>
          <Box ml={4} mt={2} mb={1}>
            <Typography variant="h6" className={classes.blackColor}>
              WhatsApp Link Generator
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              border: `1px solid ${bgColors?.gray2} `,
              backgroundColor: bgColors?.gray2,
              borderRadius: "10px",
              padding: "30px",
              margin: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            {" "}
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#4dc247",
              }}
            >
              Create WhatsApp Link
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "roboto",
                color: "#6E6E6E",
              }}
            >
              Enter the Whatsapp Mobile Number and Pre-filled text and generate
              your Whatsapp Link Instantly
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "50px",
              marginTop: "20px",
            }}
          >
            <Box>
              <Box
                sx={{
                  marginLeft: "15px",
                  marginBottom: "30px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                  }}
                >
                  WhatsApp Phone Number
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                    color: "#6E6E6E",
                  }}
                >
                  Select your country code & Type your WhatsApp phone number
                </Typography>
                <PhoneInput
                  width="50px"
                  gapRequired="58px"
                  phoneNumWidth="10px"
                  countryCodeArray={codeArray}
                  // fontweight="bold"
                  name="phoneNumber"
                  // disabled={true}
                  onCountryCodeChange={handleCountryCodeChange}
                  onChange={handlePhoneChange}
                  countryCode={phoneNumber?.countryCode || ""}
                  propsValue={phoneNumber?.phoneNumber || ""}
                  error={validPhoneNumber === "" ? false : true}
                  helperText={validPhoneNumber}
                />
                {validPhoneNumber && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ fontSize: 12 }}
                  >
                    {validPhoneNumber}
                  </Typography>
                )}
              </Box>

              <Box>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      fontFamily: "roboto",
                      marginLeft: "15px",
                    }}
                  >
                    Custom Message
                  </Typography>
                  <Box
                    onClick={(e) => {
                      handleEmojiClick(e);
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <ChatEmojiIcon fillColor="#FFD700" />
                  </Box>
                </Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                    color: "#6E6E6E",
                    marginLeft: "15px",
                  }}
                >
                  Type your custom message with emojis & WhatsApp text
                  formatting
                </Typography>
                <Box>
                  <CustomMessageInput
                    textFieldRef={textFieldRef}
                    message={message}
                    handleInputChange={handleInputChange}
                  />
                </Box>
              </Box>

              <Box>
                <button
                  style={{
                    color: "white",
                    backgroundColor: "#4dc247",
                    padding: "8px",
                    borderRadius: "6px",
                    marginLeft: "15px",
                    marginTop: "30px",
                    cursor: "pointer",
                  }}
                  onClick={handleGenerateLink}
                >
                  Generate Link
                </button>
              </Box>
            </Box>

            <WhatsAppPreview
              countryCode={phoneNumber?.countryCode}
              phoneNumber={phoneNumber?.phoneNumber}
              message={message}
              isTyping={isTyping}
            />
          </Box>
        </Box>
        <WhatsAppLinkPopup
          open={open}
          onClose={handleClose}
          whatsappUrl={whatsappRedirect}
        />
        <EmojiPopover
          open={emojiPopoverOpen}
          anchorEl={anchorEl}
          onClose={handleCloseEmojiPopover}
          onEmojiSelect={handleEmojiSelect}
        />
      </Grid>
    </Grid>
  );
};

export default WhatsappLink;
