import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReactDevicePreview from "react-device-preview";
import PhoneIcon from "@mui/icons-material/Phone";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import ReplyAllIcon from "@mui/icons-material/Reply";
import LaunchIcon from "@mui/icons-material/Launch";
import { bgColors } from "../../utils/bgColors";
import { useAppSelector } from "../../utils/redux-hooks";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const ITEM_HEIGHT = 48;
const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: "20ch",
          backgroundColor: "#f5f5f5", // Example background color
          position: "absolute",
          top: "50px", // Adjust top position as needed
          left: "20px", // Adjust left position as needed
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#d3d3d3", // Example for selected item
          },
          "&:hover": {
            backgroundColor: "#e0e0e0", // Example for hover
          },
        },
      },
    },
  },
});

const options = ["Add to contacts", "More information"];

const DevicePreview = () => {
  const companyData = useAppSelector((state: any) => state.companyData);
  // console.log(companyData);
  const companyDetails =
    companyData?.data?.company && companyData?.data?.company;
  // console.log(companyDetails);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginRight: "-170px",
        // overflow: "hidden",
        padding: "20px",
      }}
    >
      <ReactDevicePreview device="iphonex" color="black" scale="0.6">
        <Box
          sx={{
            height: "100%",
            // display: "flex",
            // flexDirection: "column",
            // justifyContent:"center",
            // marginLeft:"auto",
            // marginRight:"auto",
            // width: "100%"
          }}
        >
          {/* iPhone X-like status bar */}
          <Box
            style={{
              background: "#f0f0f0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 20px",
            }}
          >
            {/* Left side */}
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2">22:30</Typography>
            </Box>

            {/* Right side */}
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2">4G</Typography>
              <Typography variant="body2" sx={{ marginLeft: "8px" }}>
                80%
              </Typography>
            </Box>
          </Box>

          {/* Center Profile Circle */}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "110px",
              }}
            >
              <IconButton aria-label="Back" sx={{ marginBottom: "50px" }}>
                <Tooltip title="Back" placement="right">
                  <ArrowBackIcon />
                </Tooltip>
              </IconButton>
              <Box
                sx={{
                  width: 80, // Adjusted the size for better visibility in center
                  height: 80, // Adjusted the size for better visibility in center
                  borderRadius: "50%",
                  overflow: "hidden",
                  border: "2px solid #ccc",
                }}
              >
                <img
                  src={
                    companyDetails?.companyLogoLink || "/images/companyLogo.png"
                  }
                  alt="Profile"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
              {/* <IconButton aria-label="More" sx={{ marginBottom: "50px" }}>
              <MoreVertIcon />
            </IconButton> */}
              {/* <div> */}
              <ThemeProvider theme={theme}>
                <IconButton
                  aria-label="more"
                  sx={{ marginBottom: "50px" }}
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {options.map((option) => (
                    <MenuItem
                      key={option}
                      selected={option === "Pyxis"}
                      onClick={handleClose}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
                {/* </div> */}
              </ThemeProvider>
            </Box>

            <Typography
              sx={{ color: "black", fontSize: "15px", fontWeight: 100 }}
            >
              {/* Ahex-CRM */}
              {companyDetails?.businessName || ""}
            </Typography>
            <Typography>
              {/* +91 7093583329 */}
              {companyDetails?.countryCode && companyDetails?.phoneNumber
                ? companyDetails?.countryCode +
                    " " +
                    companyDetails?.phoneNumber || ""
                : ""}
            </Typography>
            <Typography>Other</Typography>
            <Tooltip title="Share" placement="right">
              <IconButton aria-label="Share">
                <Box
                  sx={{
                    border: "1px solid black",
                    borderRadius: "10px",
                    margin: "10px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                >
                  <ShortcutIcon sx={{ color: "green" }} />
                  <Typography>Share</Typography>
                </Box>{" "}
              </IconButton>
            </Tooltip>
            <Divider sx={{ width: "100%", margin: "10px 0", color: "black" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "110px",
              }}
            >
              <Typography sx={{ color: "gray" }}>
                This is a business account.
              </Typography>
              {/* <InfoIcon sx={{ color: "green" }}/> */}
              <IconButton aria-label="Info">
                <InfoOutlinedIcon sx={{ color: "green", cursor: "pointer" }} />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent:"space-evenly",
              gap: "30px",
              marginLeft: "20px",
            }}
          >
            <Typography
              sx={{
                marginTop: "10px",
                //  marginRight: "60px"
              }}
            >
              {/* Ahex Technologies is worked-focued unit of 100+ people specialist in object-oriented programming languages,
                            database architects and analytics with strong mathematical backgrounds */}
              {companyDetails?.description || ""}
            </Typography>
            <Typography
              sx={{
                // marginTop: "10px",
                // marginRight: "10px",
                color: "green",
              }}
            >
              {/* Unit 301 & 302, 3rs Floor, Mangna park view Towers,near Urdu university Road,Sri Shyam Nagar ,telecom nagar
                            Extension, Gachibowli, Hyderbad, Telangana 500032 */}
              {companyDetails?.companyAddress || ""}
            </Typography>

            <Typography sx={{ color: "green" }}>
              {/* Sales@ahex.co.in */}
              {companyDetails?.businessEmail || ""}
            </Typography>
            <Typography sx={{ color: "green" }}>
              {/* https://ahex.co.in */}
              {companyDetails?.businessWebsite || ""}
            </Typography>
          </Box>
        </Box>
      </ReactDevicePreview>
    </Box>
  );
};

export default DevicePreview;
