import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import WhatsAppChatConfig from "../../components/IntegrationsComponents/WhtasappChatConfig";
import WhatsAppWidgetConfig from "../../components/IntegrationsComponents/WhtasappWidgetConfig";
import WhatsappUrlConfig from "../../components/IntegrationsComponents/WhatsappUrlConfig";
import ChatWithUs from "../../components/IntegrationsComponents/CTAButton";
import AiSensyChatPopup from "../../components/IntegrationsComponents/CTAButton";
import EngageChatPopup from "../../components/IntegrationsComponents/CTAButton";
interface UrlField {
  id: number;
  sourceUrl: string;
  preFilledMessage: string;
  onScreenMessage: string;
  removeChecked: boolean;
  capitalizeChecked: boolean;
}

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    marginTop: "20px",
    width: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
    position: "relative", // To allow the button to be positioned absolutely within the grid
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  whatsappButtonContainer: {
    // position: "absolute",
    position: "fixed", // Change to fixed to keep it in view when scrolling
    bottom: "20px",
    right: "20px", // Positions the button in the right-bottom corner
    zIndex: 1000, // Ensures the button is above other elements
  },
});

const WhatsappWidget = () => {
  const classes = useStyles();

  const [chatConfig, setChatConfig] = useState({
    phoneNumber: "",
    ctaText: "Chat with us",
    buttonBackground: "#4dc247",
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    borderRadius: 24,
    defaultMessage: "Hi",
    position: "bottom-right",
  });

  const [widgetconfig, setWidgetConfig] = useState({
    brandName: "Engageto",
    brandSubtitle: "online",
    brandColor: "#4dc247",
    widgetCtaText: "",
    brandImageUrl: "",
    defaultOnScreenMessage: `Hi,\nHow can I help you?`,
    openWidgetOnMobileScreen: "yes",
    openWidgetByDefault: "yes",
    reOpenWidgetByDefault: "yes",
  });

  const [urlconfig, setUrlConfig] = useState({
    sourceUrl: "",
    preFilledMessage: "",
    onScreenMessage: "",
    removeChecked: true,
    capitalizeChecked: true,
    brandImageUrl: "",
  });

  const [addUrl, setAddUrl] = useState(1);
  const [isAddUrl, setIsAddUrl] = useState(false);

  // Handle value changes
  const handleChatChange = (field: string) => (event: any) => {
    setChatConfig({ ...chatConfig, [field]: event.target.value });
  };

  // // Handle color change
  // const handleChatColorChange = (color: any) => {
  //   console.log(color, "color");
  //   setChatConfig({ ...chatConfig, buttonBackground: `#${color.hex}` });
  // };
  // Handle color change
  const handleChatColorChange = (color: any) => {
    // console.log(color, "color");
    if (color) {
      // Check if color is a string (hex value) or an object
      const newColor =
        typeof color === "string" ? color : color.css.backgroundColor;
      setChatConfig({ ...chatConfig, buttonBackground: newColor });
    }
  };

  const handleWidgetChange = (field: any) => (event: any) => {
    setWidgetConfig({ ...widgetconfig, [field]: event.target.value });
  };

  const handleWidgetColorChange = (color: any) => {
    setWidgetConfig({ ...widgetconfig, brandColor: `#${color.hex}` });
  };

  const handleUrlChange = (field: string) => (event: any) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setUrlConfig({ ...urlconfig, [field]: value });
  };

  const [urlFields, setUrlFields] = useState<UrlField[]>([
    {
      id: 1,
      sourceUrl: "",
      preFilledMessage: "",
      onScreenMessage: "",
      removeChecked: false,
      capitalizeChecked: false,
    },
  ]);

  const handleAddUrl = () => {
    const newId = Math.max(...urlFields.map((field) => field.id), 0) + 1;
    setUrlFields([
      ...urlFields,
      {
        id: newId,
        sourceUrl: "",
        preFilledMessage: "",
        onScreenMessage: "",
        removeChecked: false,
        capitalizeChecked: false,
      },
    ]);
  };

  const handleRemoveUrl = (id: number) => {
    setUrlFields((fields) => fields.filter((field) => field.id !== id));
  };

  const handleUrlFieldChange =
    (id: number, field: keyof UrlField) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value =
        field === "removeChecked" || field === "capitalizeChecked"
          ? event.target.checked
          : event.target.value;

      setUrlFields((fields) =>
        fields.map((f) => (f.id === id ? { ...f, [field]: value } : f))
      );
    };
  return (
    <Grid className={classes.mainContainer}>
      <Grid className={classes.bgContainer}>
        <Box className={classes.manageContainer}>
          <Box ml={4} mt={2} mb={1}>
            <Typography variant="h6" className={classes.blackColor}>
              WhatsApp Website Widget
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            border: `1px solid ${bgColors?.gray2} `,
            backgroundColor: bgColors?.gray2,
            borderRadius: "10px",
            padding: "30px",
            margin: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          {" "}
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#4dc247",
            }}
          >
            Create WhatsApp Button
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "roboto",
              color: "#6E6E6E",
            }}
          >
            Install the button on your website via simple JavaScript code.
            (Click on Generate Snippet to get the installation code for your
            website) Embed the generated script in footer of your
            webpage(recommended).
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              // fontFamily: "Poppins",
            }}
          >
            Check live preview of your WhatsApp Button at Bottom-Right of this
            page
          </Typography>
        </Box>
        <WhatsAppChatConfig
          config={chatConfig}
          handleChange={handleChatChange}
          handleColorChange={handleChatColorChange}
        />
        <WhatsAppWidgetConfig
          config={widgetconfig}
          handleChange={handleWidgetChange}
          handleColorChange={handleWidgetColorChange}
        />
        {/* <WhatsappUrlConfig
          config={urlconfig}
          handleChange={handleUrlChange}
          isAddUrl={isAddUrl}
          addUrl={addUrl}
          handleRemoveUrl={handleRemoveUrl}
        /> */}
        <WhatsappUrlConfig
          urlFields={urlFields}
          onAddUrl={handleAddUrl}
          onRemoveUrl={handleRemoveUrl}
          onUrlFieldChange={handleUrlFieldChange}
        />
        <Box className={classes.whatsappButtonContainer}>
          {/* <ChatWithUs/> */}
          <EngageChatPopup
            chatConfig={chatConfig}
            widgetconfig={widgetconfig}
            urlFields={urlFields}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default WhatsappWidget;
