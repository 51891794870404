import {
  Box,
  InputAdornment,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useRef, ChangeEvent, MouseEvent } from "react";
import ChatEmojiIcon from "../../../assets/svgs/ChatEmojiIcon";
import ChatFileIcon from "../../../assets/svgs/ChatFileIcon";
import SendIcon from "../../../assets/svgs/SendIcon";
import ChatTemplate from "../../../assets/svgs/ChatTemplate";
import CloseIconSvg from "../../../assets/svgs/CloseIconSvg";
import EmojiPopover from "./EmojiPicker";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import { bgColors } from "../../../utils/bgColors";
import ReplyBox from "./ReplyBox";
import { uploadFile } from "../../../redux/slices/Templates/uploadFileSlice";
import { toastActions } from "../../../utils/toastSlice";
import LoadingComponent from "../../common/LoadingComponent";

const useStyles = makeStyles({
  inputContainer: {
    width: "100%",
    zIndex: "1",
    // position: "sticky",
    // bottom: "0",
  },
  mediaFile: {
    maxWidth: 330,
    maxHeight: 400,
    border: "1px solid #cdcdcd",
    borderRadius: "4px",
  },
  document: {
    borderRadius: "4px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    // border: "1px solid #cdcdcd",
  },
  mediaContainer: {
    position: "relative",
    alignItems: "center",
    // marginInline: "auto",
    marginLeft: "16px",
    marginRight: "24px",
    // marginBlock: "8px",
    // maxWidth: 380,
    minHeight: 80,
    maxHeight: 500,
    borderRadius: "4px",
    paddingTop: "18px",
    // paddingBottom: "14px",
    backgroundColor: "rgb(248, 248, 248)",
  },
  cursor: {
    cursor: "pointer",
  },
  replyBox: {
    backgroundColor: bgColors?.white1,
    padding: "20px",
    marginLeft: "15px",
    marginRight: "24px",
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
    fontSize: "14px",
    wordBreak: "break-word",
    display: "flex",
    textAlign: "left",
    alignItems: "center",
  },
  maxHeightStyle: { maxHeight: 300, overflowY: "auto" },
  cancelIcon: {
    color: "white !important",
    // backgroundColor: 'black !important',
    cursor: "pointer",
    // paddingLeft: "20px",
  },
  flexRowCenter: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  userMessageContainer: {
    display: "flex",
    alignItems: "center",
    // position: "relative",
  },
  inputtest: {
    "&::placeholder": {
      fontStyle: "italic",
      color: "#4B5A5A !important",
    },
  },
});

interface MessageState {
  message: string;
  selectedFile: any;
  mediaUrl: string;
}

const ChatInput = ({
  walletBalance,
  openTemplateDialog,
  handleSendMessage,
  handleOpenTemplatePopup,
  hasSendTextMessagePermission,
  hasSendMediaPermission,
  hasSendTemplatePermission,
  reply,
  replyToggle,
  setReply,
}: {
  walletBalance: number;
  openTemplateDialog: boolean;
  handleSendMessage: (
    messageState: MessageState,
    resetState: () => void
  ) => void;
  handleOpenTemplatePopup: () => void;
  hasSendTextMessagePermission: (value: any) => boolean;
  hasSendMediaPermission: (value: any) => boolean;
  hasSendTemplatePermission: (value: any) => boolean;
  reply: any;
  replyToggle: boolean;
  setReply: ({ toggle, messageObj }: { toggle: any; messageObj: any }) => void;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textFieldRef = useRef<HTMLDivElement>(null);

  const getuserPermissionData = useAppSelector(
    (state:any) => state.getUserPermissions?.data
  );
  const manageInboxObject = getuserPermissionData?.inbox;

  const [messageState, setMessageState] = useState<MessageState>({
    message: "",
    selectedFile: null,
    mediaUrl: "",
  });
  // const [iconColor, setIconColor] = useState("rgba(0, 0, 0, 0.35)");
  const [mediaLoader, setMediaLoader] = useState<boolean>(false);
  const [emojiPopoverOpen, setEmojiPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [sendEmojiMessageTooltip, setSendEmojiMessageTooltip] = useState(false);
  const [sendMessageTooltip, setSendMessageTooltip] = useState(false);
  const [sendMediaMessageTooltip, setSendMediaMessageTooltip] = useState(false);
  const [sendTemplateMessageTooltip, setSendTemplateMessageTooltip] =
    useState(false);

  const hasTextMessagePermission =
    hasSendTextMessagePermission(manageInboxObject);
  const hasMediaMessagePermission = hasSendMediaPermission(manageInboxObject);
  const hasTemplateMessagePermission =
    hasSendTemplatePermission(manageInboxObject);

  const handleEmojiClick = (event: MouseEvent<HTMLDivElement>) => {
    if (hasTextMessagePermission) {
      setAnchorEl(event.currentTarget);
      setEmojiPopoverOpen(true);
    } else {
      setSendEmojiMessageTooltip(true);
      setTimeout(() => {
        setSendEmojiMessageTooltip(false);
      }, 2000);
    }
  };

  const handleCloseEmojiPopover = () => {
    setEmojiPopoverOpen(false);
  };

  const handleFileIconClick = () => {
    if (hasMediaMessagePermission) {
      fileInputRef.current?.click();
      // setIconColor("#4B5A5A");
    } else {
      setSendMediaMessageTooltip(true);
      setTimeout(() => {
        setSendMediaMessageTooltip(false);
      }, 2000);
    }
  };

  const handleEmojiSelect = (emoji: any) => {
    if (textFieldRef.current) {
      const input = textFieldRef.current.querySelector("textarea");

      if (input && typeof input.setSelectionRange === "function") {
        const start = input.selectionStart;
        const end = input.selectionEnd;

        const newMessage =
          messageState?.message?.slice(0, start) +
          emoji +
          messageState?.message?.slice(end);

        setMessageState((prevState) => ({
          ...prevState,
          message: newMessage,
        }));

        // Set the cursor position after the newly added emoji
        setTimeout(() => {
          input.setSelectionRange(start + emoji.length, start + emoji.length);
          input.focus();
        }, 0);
      }
    }
  };

  const handleCancel = () => {
    setMessageState((prevState) => ({
      ...prevState,
      selectedFile: null,
    }));
    // setVideoSrc(null);
  };

  const handleCancelReply = () => {
    setReply({ toggle: false, messageObj: null });
  };

  const handleOpenTemplateDialog = () => {
    // const hasPermission = hasSendTemplatePermission(manageInboxObject);
    if (hasTemplateMessagePermission) {
      handleOpenTemplatePopup();
    } else {
      setSendTemplateMessageTooltip(true);
      setTimeout(() => {
        setSendTemplateMessageTooltip(false);
      }, 2000);
    }
  };

  const handleSendClick = () => {
    if (hasTextMessagePermission || hasMediaMessagePermission) {
      handleSendMessage(messageState, resetState);
      setReply({ toggle: false, messageObj: null });
    } else {
      setSendMessageTooltip(true);
      setTimeout(() => {
        setSendMessageTooltip(false);
      }, 2000);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    messageState: any,
    resetState: () => void
  ) => {
    // if (hasTextMessagePermission && hasMediaMessagePermission) {
    if (event.key === "Enter" && !event.shiftKey) {
      if (messageState?.message?.trim().length !== 0) {
        handleSendMessage(messageState, resetState);
      }
      event.preventDefault(); // Prevent default action to avoid newline insertion
    } else if (event.key === "Enter" && event.shiftKey) {
      setMessageState((prevState) => ({
        ...prevState,
        message: prevState?.message + "\n",
      }));
      event.preventDefault();
    }
    // } else {
    //   setSendMessageTooltip(true);
    //   setSendEmojiMessageTooltip(true);
    //   setSendMediaMessageTooltip(true);
    //   setTimeout(() => {
    //     setSendMessageTooltip(false);
    //     setSendEmojiMessageTooltip(false);
    //     setSendMediaMessageTooltip(false);
    //   }, 2000);
    // }
  };

  const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes
  const MAX_AUDIO_SIZE = 16 * 1024 * 1024; // 5 MB in bytes
  const MAX_VIDEO_SIZE = 16 * 1024 * 1024; // 16 MB in bytes
  const MAX_DOC_SIZE = 100 * 1024 * 1024; // 100 MB in bytes

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setMediaLoader(true);
    const file: File | null = event.target.files && event.target.files[0];
    // console.log(file);

    if (file) {
      const fileType = file.type;
      const fileSize = file.size;

      // Check file size based on type
      let isValidFileSize = false;
      let maxSizeMessage = "";

      if (fileType.startsWith("image/")) {
        isValidFileSize = fileSize <= MAX_IMAGE_SIZE;
        maxSizeMessage = "Images should not exceed 5 MB.";
      } else if (fileType.startsWith("audio/")) {
        isValidFileSize = fileSize <= MAX_AUDIO_SIZE;
        maxSizeMessage = "Audios should not exceed 16 MB.";
      } else if (fileType === "video/mp4") {
        isValidFileSize = fileSize <= MAX_VIDEO_SIZE;
        maxSizeMessage = "Videos should not exceed 16 MB.";
      } else if (
        fileType === "application/pdf" ||
        fileType === "text/plain" ||
        fileType.includes("officedocument")
      ) {
        isValidFileSize = fileSize <= MAX_DOC_SIZE;
        maxSizeMessage = "Documents should not exceed 100 MB.";
      }

      if (!isValidFileSize) {
        setMediaLoader(false);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `File size exceeds the maximum limit. ${maxSizeMessage}`,
          })
        );
        return;
      }

      const formData: any = new FormData();
      formData.append("File", file);

      const validTypes = [
        "image/",
        "video/mp4",
        "audio/mpeg",
        "audio/ogg",
        "audio/amr",
        "audio/aar",
        "application/pdf",
        "text/plain",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];

      const isValidFileType = validTypes.some(
        (type) => fileType.startsWith(type) || fileType === type
      );

      if (isValidFileType) {
        const uploadResponse: any = await dispatch(uploadFile(formData));
        // console.log("Uploaded file: ", uploadResponse);

        if (uploadResponse.meta.requestStatus === "fulfilled") {
          setMediaLoader(false);
          setMessageState((prevState) => ({
            ...prevState,
            selectedFile: file,
            mediaUrl: uploadResponse?.payload,
          }));
        } else {
          setMediaLoader(false);
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: uploadResponse?.payload || "Error uploading file",
            })
          );
        }
      } else {
        setMediaLoader(false);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: "Please select only PNG, MP4, MP3 or PDF files.",
          })
        );
      }
    }
  };

  const resetState = () => {
    setMessageState({
      message: "",
      selectedFile: null,
      mediaUrl: "",
    });
    // setVideoSrc(null);
  };

  // console.log("sendMessageTooltip", sendMessageTooltip, messageState);

  return (
    <Box className={classes.inputContainer}>
      {replyToggle && (
        <Box className={classes.replyBox}>
          <ReplyBox
            maxStyle={{
              maxHeightStyle: classes?.maxHeightStyle,
              maxWidthStyle: "",
            }}
            reply={reply}
            replyToggle={replyToggle}
            handleCancelReply={handleCancelReply}
          />
        </Box>
      )}
      {messageState?.selectedFile && (
        <Box className={classes.mediaContainer}>
          {messageState?.selectedFile.type.startsWith("image/") && (
            <img
              src={messageState?.mediaUrl}
              alt="Selected File"
              className={classes.mediaFile}
            />
          )}
          {
            // videoSrc &&
            messageState.selectedFile &&
              messageState.selectedFile.type === "video/mp4" && (
                <video controls className={classes.mediaFile}>
                  <source src={messageState.mediaUrl} type="video/mp4" />
                </video>
              )
          }
          {messageState.selectedFile &&
            messageState.selectedFile.type.startsWith("audio/") && (
              <audio controls className={classes.mediaFile}>
                <source src={messageState.mediaUrl} />
              </audio>
            )}
          {(messageState?.selectedFile.type === "application/pdf" ||
            messageState?.selectedFile.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            messageState?.selectedFile.type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            messageState?.selectedFile.type === "text/plain") && (
            <Box p={1} mx={2} className={classes?.document}>
              {/* <ChatFileIcon /> */}
              <a
                href={messageState?.mediaUrl}
                // download={messageState?.selectedFile?.name}
                target="_blank"
                style={{ fontSize: "14px", textDecoration: "none" }}
                rel="noreferrer"
              >
                {messageState?.selectedFile?.name}
              </a>
            </Box>
          )}
          <IconButton
            // className={classes.cancelIcon}
            onClick={handleCancel}
            sx={{
              // ml: 2,
              position: "absolute",
              // left: 1,
              top: -6,
              right: 0,
            }}
          >
            <CloseIconSvg />
          </IconButton>
        </Box>
      )}

      <Box display="flex" px={2} justifyContent="space-between">
        {/* <Twemoji> */}
        {mediaLoader ? (
          <Box sx={{ width: "100%" }}>
            <LoadingComponent height="100%" color={bgColors?.blue} />
          </Box>
        ) : (
          <TextField
            fullWidth
            disabled={
              messageState?.selectedFile?.type?.startsWith("audio/") ||
              hasTextMessagePermission === false
            }
            variant="outlined"
            size="small"
            multiline
            ref={textFieldRef}
            value={messageState?.message}
            onChange={(e) =>
              setMessageState((prevState) => ({
                ...prevState,
                message: e.target.value,
              }))
            }
            onKeyDown={(event: any) =>
              handleKeyDown(event, messageState, resetState)
            }
            placeholder="Write a response..."
            // placeholder={message.trim().length > 0 ? '' : ' '}
            className={`${classes.inputtest} custom-placeholder`}
            sx={{
              borderColor: bgColors?.gray2, // Idle border color
              "& .MuiInputBase-root": {
                minHeight: messageState?.selectedFile ? 200 : 40,
                padding: "2px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: bgColors?.gray2, // Idle border color
                  borderWidth: "1px",
                },
                "&:hover fieldset": {
                  borderColor: hasTextMessagePermission ? bgColors?.gray2 : "", // Hover border color
                  borderWidth: "1px",
                },
                "&.Mui-focused fieldset": {
                  borderColor: hasTextMessagePermission ? bgColors?.gray2 : "", // Focused border color
                  borderWidth: "1px",
                },
              },
            }}
            InputProps={{
              style: {
                backgroundColor: bgColors?.white1,
                minHeight: 40,
                maxHeight: 300,
                padding: "2px",
                fontSize: "14px",
              }, // Increase text field height
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      style={{ display: "flex", flexDirection: "row" }}
                      ml={0.5}
                      mt={0.8}
                    >
                      <>
                        <Tooltip
                          title="Access Denied"
                          open={
                            messageState?.message === "" &&
                            sendEmojiMessageTooltip
                          }
                          placement="top"
                          onClose={() => setSendEmojiMessageTooltip(false)}
                        >
                          <Box
                            pr={1}
                            className={classes.cursor}
                            onClick={(e) => {
                              if (
                                !openTemplateDialog &&
                                !messageState?.selectedFile?.type?.startsWith(
                                  "audio/"
                                )
                              ) {
                                handleEmojiClick(e);
                              }
                            }}
                          >
                            <ChatEmojiIcon
                              //  fillColor="rgba(0, 0, 0, 0.35)"
                              fillColor={
                                openTemplateDialog ||
                                messageState?.selectedFile?.type?.startsWith(
                                  "audio/"
                                )
                                  ? "#cdcdcd"
                                  : "#4B5A5A"
                              }
                            />
                          </Box>
                        </Tooltip>
                        <EmojiPopover
                          open={emojiPopoverOpen}
                          anchorEl={anchorEl}
                          onClose={handleCloseEmojiPopover}
                          onEmojiSelect={handleEmojiSelect}
                        />
                        <Tooltip
                          title="Access Denied"
                          open={
                            messageState?.message === "" &&
                            sendMediaMessageTooltip
                          }
                          placement="top"
                          onClose={() => setSendMediaMessageTooltip(false)}
                        >
                          <Box
                            pr={1}
                            className={classes.cursor}
                            onClick={() => {
                              if (!openTemplateDialog) {
                                handleFileIconClick();
                              }
                            }}
                          >
                            <ChatFileIcon
                              //  fill={iconColor}
                              fill={openTemplateDialog ? "#cdcdcd" : "#4B5A5A"}
                            />
                            <input
                              ref={fileInputRef}
                              type="file"
                              multiple
                              // accept=".pdf,.doc,.ppt,image/*,video/*"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                          </Box>
                        </Tooltip>
                      </>

                      <Tooltip
                        title="Access Denied"
                        open={
                          messageState?.message === "" &&
                          sendTemplateMessageTooltip
                        }
                        placement="top"
                        onClose={() => setSendTemplateMessageTooltip(false)}
                      >
                        <Box
                          pr={1}
                          className={
                            messageState?.message?.length === 0 &&
                            replyToggle === false &&
                            (messageState?.selectedFile === null ||
                              messageState?.selectedFile === undefined)
                              ? classes.cursor
                              : ""
                          }
                          onClick={() => {
                            if (
                              messageState?.message?.length === 0 &&
                              replyToggle === false &&
                              walletBalance > 0 &&
                              (messageState?.selectedFile === null ||
                                messageState?.selectedFile === undefined)
                            ) {
                              handleOpenTemplateDialog();
                            }
                          }}
                        >
                          <ChatTemplate
                            // fillColor="rgba(0, 0, 0, 0.35)"
                            fillColor={
                              messageState?.message?.length === 0 &&
                              replyToggle === false &&
                              walletBalance > 0 &&
                              (messageState?.selectedFile === null ||
                                messageState?.selectedFile === undefined)
                                ? "#4B5A5A"
                                : "#cdcdcd"
                            }
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        )}
        {/* </Twemoji> */}
        <Tooltip
          title="Access Denied"
          open={sendMessageTooltip}
          placement="top"
          onClose={() => setSendMessageTooltip(false)}
        >
          <Box
            px={1}
            textAlign="right"
            onClick={() =>
              hasMediaMessagePermission || hasTextMessagePermission
                ? handleSendClick()
                : ""
            }
            sx={{
              display: mediaLoader ? "none" : "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              cursor: "pointer",
            }}
          >
            <SendIcon />
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default ChatInput;
