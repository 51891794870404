import React from 'react'

const LogOut = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8">
<path d="M8.16675 7C8.47617 7 8.77291 6.87709 8.9917 6.6583C9.2105 6.4395 9.33341 6.14276 9.33341 5.83334C9.33341 5.52392 9.2105 5.22717 8.9917 5.00838C8.77291 4.78959 8.47617 4.66667 8.16675 4.66667H5.83341C5.524 4.66667 5.22725 4.78959 5.00846 5.00838C4.78966 5.22717 4.66675 5.52392 4.66675 5.83334V22.1667C4.66675 22.4761 4.78966 22.7728 5.00846 22.9916C5.22725 23.2104 5.524 23.3333 5.83341 23.3333H8.16675C8.47617 23.3333 8.77291 23.2104 8.9917 22.9916C9.2105 22.7728 9.33341 22.4761 9.33341 22.1667C9.33341 21.8573 9.2105 21.5605 8.9917 21.3417C8.77291 21.1229 8.47617 21 8.16675 21H7.00008V7H8.16675ZM24.2901 13.3233L21.0001 8.65667C20.8217 8.40515 20.5509 8.2345 20.247 8.18204C19.9431 8.12957 19.6308 8.19954 19.3784 8.37667C19.2522 8.46509 19.1448 8.57762 19.0624 8.70778C18.9799 8.83793 18.9241 8.98313 18.898 9.13499C18.872 9.28684 18.8763 9.44235 18.9107 9.59254C18.9451 9.74272 19.0089 9.88461 19.0984 10.01L21.1051 12.8333H11.6667C11.3573 12.8333 11.0606 12.9563 10.8418 13.175C10.623 13.3938 10.5001 13.6906 10.5001 14C10.5001 14.3094 10.623 14.6062 10.8418 14.825C11.0606 15.0438 11.3573 15.1667 11.6667 15.1667H21.0001L18.9001 17.9667C18.8082 18.0892 18.7413 18.2287 18.7032 18.3771C18.6652 18.5255 18.6568 18.68 18.6785 18.8317C18.7001 18.9833 18.7515 19.1292 18.8295 19.2611C18.9076 19.3929 19.0108 19.5081 19.1334 19.6C19.3354 19.7515 19.581 19.8333 19.8334 19.8333C20.0145 19.8333 20.1932 19.7912 20.3552 19.7102C20.5172 19.6292 20.6581 19.5116 20.7667 19.3667L24.2667 14.7C24.4162 14.5027 24.499 14.263 24.5031 14.0155C24.5073 13.768 24.4327 13.5256 24.2901 13.3233Z" fill="#303030"/>
</g>
</svg>

  )
}

export default LogOut