/* global process */

import axios from "axios";
import { encrypt } from "../../../utils/encrypt";

const AUTOMATION_API_URL = process.env.REACT_APP_BASE_URL;

const getWorkingHours = async (data: {
  businessId: string;
  userId: string;
}): Promise<any> => {
  try {
    // Encrypt individual query parameters
    const encryptedBusinessId = encrypt(data.businessId);
    const encryptedUserId = encrypt(data.userId);

    // Make the API request with encrypted query parameters
    const response = await axios({
      url: `${AUTOMATION_API_URL}/WorkingHours/get_working_hours?BusinessId=${encodeURIComponent(
        encryptedBusinessId
      )}&UserId=${encodeURIComponent(encryptedUserId)}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response.data;

    //  // Construct the query string
    //  const queryString = `BusinessId=${data.businessId}&UserId=${data.userId}`;

    //  // Encrypt the entire query string
    //  const encryptedQueryString = encrypt(queryString);

    //  // Make the API request with the encrypted payload as a single query parameter
    //  const response = await axios({
    //    url: `${AUTOMATION_API_URL}/WorkingHours/get_working_hours?${encodeURIComponent(
    //      encryptedQueryString
    //    )}`,
    //    method: "GET",
    //    headers: {
    //      "Content-Type": "application/json",
    //      Authorization: localStorage.getItem("token"),
    //    },
    //  });
  } catch (error) {
    console.error("Error fetching working hours:", error);
    throw error;
  }
};

const getContactColumns = () => {
  return axios({
    url: `${AUTOMATION_API_URL}/get_contact_column_names}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const createWorkingHours = (data: any) => {
  return axios({
    url: `${AUTOMATION_API_URL}/WorkingHours/save_working_hours?BusinessId=${data?.businessId}&UserId=${data?.userId}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data?.data,
  });
};

const updateInboxSettings = (data: any) => {
  return axios({
    url: `${AUTOMATION_API_URL}/update_inboxsettings?BusinessId=${data?.businessId}&UserId=${data?.userId}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data?.inboxSettings,
  });
};

const getInboxSettings = (data: any) => {
  return axios({
    url: `${AUTOMATION_API_URL}/get_inboxsettings?BusinessId=${data?.businessId}&UserId=${data?.userId}&Feature=${data?.feature}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const createAutoReplyCustomMessage = (data: any) => {
  return axios({
    url: `${AUTOMATION_API_URL}/AutoReplyMessage/CreateAutoReplyMessage`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  });
};

const addVariableName = (data: any) => {
  return axios({
    url: `${AUTOMATION_API_URL}/AutoReplyMessage/AddVeriableName`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  });
};

const getAutoReplyCustomMessage = (companyId: any) => {
  return axios({
    url: `${AUTOMATION_API_URL}/AutoReplyMessage/GetAutoReplyMessage?companyId=${companyId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const updateAutoReplyCustomMessage = (data: any) => {
  return axios({
    url: `${AUTOMATION_API_URL}/AutoReplyMessage/CreateAutoReplyMessage`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  });
};

const deleteAutoReplyCustomMessage = (data: any) => {
  return axios({
    url: `${AUTOMATION_API_URL}/AutoReplyMessage/DeleteAutoReplyMessage?companyId=${data?.companyId}&autoReplyAutomationId=${data?.autoReplyAutomationId}`,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

export const AUTOMATION_API = {
  getWorkingHours,
  createWorkingHours,
  updateInboxSettings,
  getContactColumns,
  getInboxSettings,
  createAutoReplyCustomMessage,
  getAutoReplyCustomMessage,
  updateAutoReplyCustomMessage,
  deleteAutoReplyCustomMessage,
  addVariableName,
};
