import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Box, Typography, Grid, InputLabel } from "@mui/material";
import ButtonComponent from "../common/ButtonComponent";
import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import TextFeildWithBorderComponet from "../common/TextFieldWithBorderComponent";
import PasswordTextFeildWithBorders from "../common/PasswordTextFeildWithBorders";
import { ADMIN_LOGIN_API } from "../../Apis/AdminLogin/AdminLoginApi";
import ErrorMessageComponent from "../common/ErrorMessageComponent";
import { useAppDispatch } from "../../utils/redux-hooks";
import { toastActions } from "../../utils/toastSlice";
import { adminLoginSliceActions } from "../../redux/slices/AdminLoginSlice";
import LoadingComponent from "../common/LoadingComponent";
import localStorage from "redux-persist/es/storage";
import { fetchUserPermission } from "../../redux/slices/ManagePermissions/GetUserPermissionSlice";
// import { fetchCurrentPlanDetails } from "../../redux/slices/Subscription/SubscriptionSlice";
import { getWalletAndSubscription } from "../../redux/slices/Wallet/WalletSlice";
import { fetchAccountDetails } from "../../redux/slices/ManageAccount/AccountDetailsSlice";
import { fetchAccountMetaStatus } from "../../redux/slices/ManageAccount/AccountMetaStatusSlice";

export type LoginFormContainerType = {
  loginPromptText?: string;
  frameDivLeft?: number | string;
};

const useStyles = makeStyles({
  container: {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    paddingBlock: "3%",
    marginBlock: "4%",
    boxSizing: "border-box",
    backgroundColor: "#ffffff",
  },
  button: {
    backgroundColor: `${bgColors.green}!important`,
    width: "100% !important",
    fontSize: "14px !important",
    fontWeight: "Semi Bold !important",
    borderRadius: "8px !important",
  },
  link: {
    fontSize: "16px!important",
    color: bgColors.green,
    cursor: "pointer",
  },
  promptText: {
    marginBottom: "4% !important",
    fontWeight: "bold !important",
    textAlign: "left",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "600 !important",
    fontSize: "14px !important",
  },
  // boldLabel: {
  //   fontWeight: "500",
  //   color:"#646E7B"
  // },
  boldLabel: {
    // fontSize:"18px",
    fontWeight: "500",
    fontFamily: "inter",
    // color:"#646E7B"
    color: "rgba(100, 110, 123, 0.9)",
    // rgba(100, 110, 123, 1)
  },
});

const LoginFormContainer: FunctionComponent<LoginFormContainerType> = ({
  loginPromptText,
}) => {
  const emailInputRef: any = useRef(null);
  const passwordInputRef: any = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [erorr, setError] = useState("");
  // console.log(erorr,'erorr')
  const [loading, setLoading] = useState(false);

  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };
  const handleUserName = (e: any) => {
    setUserName(e.target.value);
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email.toLowerCase())) {
      setEmailError("Invalid Email Address");
      return false;
    }

    setEmailError("");
    return true;
  };

  const validatePassword = (password: string) => {
    // const passwordPattern =
    //   /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    if (password === "") {
      setPasswordError("Password Cannot Be Empty");
      return false;
    }
    // else if (password.length < 8) {
    //   setPasswordError("Password is Too Short");
    //   return false;
    // } else if (!passwordPattern.test(password)) {
    //   setPasswordError("Password is Too Weak");
    //   return false;
    // }
    setPasswordError("");
    return true;
  };

  const emptyFeilds = () => {
    setUserName("");
    setPassword("");
    setEmailError("");
    setPasswordError("");
  };

  // console.log("password", password, user);

  const handleLogin = async () => {
    // setLoading(true);
    const isEmailValid = validateEmail(userName);
    const isPasswordValid = validatePassword(password);
    if (isEmailValid && isPasswordValid) {
      try {
        setLoading(true);
        const response = await ADMIN_LOGIN_API.login(userName, password);
        // console.log("response", response);

        if (response?.status === 200) {
          localStorage.setItem("token", `Bearer ${response?.data?.token}`);

          // console.log(expirationTime, 'exp');
          dispatch(adminLoginSliceActions.setData(response?.data));
          await dispatch(
            fetchUserPermission({
              roleId: response?.data?.roleId,
              companyId: response?.data?.companyId,
            })
          );
          await dispatch(fetchAccountMetaStatus(response?.data?.companyId));
          const accountData = await dispatch(
            // fetchCurrentPlanDetails(response?.data?.companyId)
            fetchAccountDetails(response?.data?.userId)
          );
          const currentPlanDetails = await dispatch(
            // fetchCurrentPlanDetails(response?.data?.companyId)
            getWalletAndSubscription(response?.data?.companyId)
          );
          // console.log("currentPlanDetailssss", currentPlanDetails);

          dispatch(
            toastActions.setToaster({
              type: "success",
              message: `${response?.data?.message}`,
            })
          );
          setError("");
          emptyFeilds();
          if (
            currentPlanDetails?.payload?.data?.subscriptionPlan?.isActive &&
            accountData?.payload?.companyVerificationStatus
          ) {
            navigate(`/inbox/help`);
          } else {
            navigate(`/profile/manage-account/${response?.data?.userId}`);
          }
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: `${response?.data?.message}`,
            })
          );
        }
      } catch (err: any) {
        // console.log("cbajc", err);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `${err?.response?.data?.message || err?.message}`,
          })
        );
        setError(
          err?.response?.data?.message || err?.message || "Error while login"
        );
        // emptyFeilds();
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && userName.length > 0 && password.length > 0) {
        handleLogin();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [userName, password, handleLogin]);

  // useEffect(() => {
  //   // Check if the input field is autofilled by the browser
  //   if (
  //     userName !== "" &&
  //     emailInputRef.current &&
  //     document.activeElement !== emailInputRef.current
  //   ) {
  //     emailInputRef.current.focus();
  //   }
  //   // Check if password input field is autofilled by the browser
  //   if (
  //     password !== "" &&
  //     passwordInputRef.current &&
  //     document.activeElement !== passwordInputRef.current
  //   ) {
  //     passwordInputRef.current.focus();
  //   }
  // }, [userName, password]);

  return (
    <Box
      className={classes.container}
      sx={{ width: { xs: "90%", sm: "70%", md: "50%", lg: "30%" } }}
      px={{ xs: 2, md: 6 }}
    >
      <Typography variant="h5" className={classes.promptText}>
        {loginPromptText}
      </Typography>
      <Grid container>
        <Grid item xs={12} my={{ xs: 1, md: 3 }}>
          <InputLabel className={classes.blackColor}>Email</InputLabel>
          <form noValidate autoComplete="off">
            <TextFeildWithBorderComponet
              // label="Enter your email address"
              label={
                <span className={classes.boldLabel}>
                  Enter your email address
                </span>
              }
              name="username"
              placeholder=""
              onChange={(e) => handleUserName(e)}
              value={userName}
              error={!!emailError}
              helperText={emailError}
              autoFocus
              sx={{
                "& input": {
                  fontWeight: "600",
                },
              }}
              autoComplete="username"
              inputRef={emailInputRef}
            />
          </form>
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={classes.blackColor}>Password</InputLabel>
          <PasswordTextFeildWithBorders
            // label="Enter your password"
            label={
              <span className={classes.boldLabel}>Enter your password</span>
            }
            name="password"
            placeholder=""
            onChange={(e) => handlePassword(e)}
            value={password}
            error={!!passwordError}
            helperText={passwordError}
            sx={{
              "& input": {
                fontWeight: "600",
              },
            }}
            inputRef={passwordInputRef}
            autoComplete="current-password"
          />
          {erorr && <ErrorMessageComponent text={erorr} />}
        </Grid>
        <Box style={{ width: "100%", textAlign: "right" }}>
          <span
            className={classes.link}
            onClick={() => navigate("/forgot-password")}
            style={{
              fontFamily: "inter",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            Forgot Password?
          </span>
        </Box>
        {loading ? (
          <Grid item xs={12} textAlign="center">
            <LoadingComponent height="100%" color={bgColors.blue} />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} mt={2}>
              <ButtonComponent
                onClick={handleLogin}
                title="Log In"
                className={classes.button}
                type="submit"
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default LoginFormContainer;
