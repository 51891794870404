import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  // TextField,
  Box,
  InputBaseProps,
  // SelectChangeEvent,
} from "@mui/material";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import TextFieldWithBorderComponent from "./TextFieldWithBorderComponent";
// import { useLocation } from "react-router";

interface CountryType {
  countryCode?: string;
  countryImage?: string;
}

interface Props {
  countryCodeArray: CountryType[];
  name: string;
  onChange?: (value: any) => void; // Callback function to handle value change
  onCountryCodeChange?: (value: any) => void; // Callback function to handle value change
  propsValue?: string;
  countryCode?: string;
  error?: boolean;
  helperText?: string;
  width: string;
  gapRequired: string;
  phoneNumWidth: string;
  fontweight?: string;
  disabled?: boolean;
  cntyPhnWidth?: any;
}

const PhoneInput: React.FC<Props> = ({
  countryCodeArray,
  onChange,
  onCountryCodeChange,
  propsValue = "",
  countryCode = "",
  error = false,
  fontweight = "",
  disabled = false,
  cntyPhnWidth = {},
}) => {
  // const location = useLocation();

  const defaultCountryCode = countryCode;
  const defaultPhoneNumber = propsValue;
  const [countryCodeValue, setCountryCodeValue] = useState<string | null>(
    defaultCountryCode || "+ 91"
  );
  const [phoneNumberValue, setPhoneNumberValue] =
    useState<string>(defaultPhoneNumber);
  let defValueWidth: any =
    cntyPhnWidth && JSON.stringify(cntyPhnWidth) !== "{}"
      ? cntyPhnWidth
      : { cntyWidth: 2.5, phnWidth: 9 };

  useEffect(() => {
    if (defaultPhoneNumber && defaultCountryCode) {
      setPhoneNumberValue(defaultPhoneNumber);
      setCountryCodeValue(defaultCountryCode);
    }
  }, [defaultPhoneNumber, defaultCountryCode]);

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(/\s/g, "");
    if (onChange) {
      onChange(newValue); // Combine countryCode with new phoneNumber
    }
    setPhoneNumberValue(newValue);
  };

  const handleCountryCodeChange = (event: any) => {
    const newValue = event.target.value;
    if (onCountryCodeChange) {
      onCountryCodeChange(newValue);
    }
    setCountryCodeValue(newValue);
  };

  const theme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            "&:focus": {
              backgroundColor: "transparent",
            },
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            fontWeight: fontweight,
          },
          icon: {
            right: "7px",
          },
          outlined: {
            paddingLeft: "7px",
          },
        },
      },
    },
  });

  const inputProps: InputBaseProps = {
    style: {
      borderRadius: "8px",
      fontSize: "14px",
      fontWeight: fontweight,
    },
    inputProps: {
      pattern: "[0-9]*",
      inputMode: "numeric",
      onKeyPress: (event) => {
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
          event.preventDefault();
        }
      },
    },
  };

  return (
    <>
      {/* <style>
        {`
          ::-webkit-scrollbar {
            width: 6px;
          }

          ::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          ::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
          }

          ::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        `}
      </style> */}
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        flexDirection="row"
        sx={{ alignItems: "center", width: "100%" }}
      >
        <Grid item xs={defValueWidth.cntyWidth} mt={0}>
          <FormControl fullWidth>
            <ThemeProvider theme={theme}>
              <Select
                id="phoneNumber-Prefix"
                value={countryCodeValue}
                disabled={disabled}
                onChange={handleCountryCodeChange}
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                  },
                }}
                error={error}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={
                        countryCodeArray?.find(
                          (country) => country?.countryCode === selected
                        )?.countryImage ||
                        "https://avatar9.s3.ap-south-1.amazonaws.com/8ff66494-7626-468f-abc4-61246168dfc0.png"
                      }
                      alt="Country Flag"
                      style={{
                        height: "20px",
                        marginRight: "5px",
                      }}
                    />
                    <span>{selected ? "" : "+91"}</span>
                    {selected}
                  </Box>
                )}
                sx={{
                  fontSize: 12,
                  marginTop: "8px",
                  marginBottom: "4px",
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    maxHeight: "37px",
                    borderRadius: "8px",
                    minWidth: "70px",
                  },
                  // "& .MuiPaper-root": {
                  //   "&::-webkit-scrollbar": {
                  //     width: "6px",
                  //   },
                  //   "&::-webkit-scrollbar-track": {
                  //     background: "#f1f1f1",
                  //   },
                  //   "&::-webkit-scrollbar-thumb": {
                  //     background: "#888",
                  //     borderRadius: "10px",
                  //   },
                  //   "&::-webkit-scrollbar-thumb:hover": {
                  //     background: "#555",
                  //   },
                  // },
                }}
              >
                {countryCodeArray?.map((option) => (
                  <MenuItem key={option.countryCode} value={option.countryCode}>
                    <img
                      src={option?.countryImage}
                      style={{ marginRight: "5px", height: 20, width: 20 }}
                    />
                    {option.countryCode}
                  </MenuItem>
                ))}
              </Select>
            </ThemeProvider>
          </FormControl>
        </Grid>

        <Grid item xs={defValueWidth.phnWidth} ml="auto">
          <TextFieldWithBorderComponent
            label="Enter phone number"
            name="phoneNumber"
            disabled={disabled}
            placeholder=""
            error={error}
            value={phoneNumberValue}
            onChange={handlePhoneChange}
            size="small"
            InputProps={inputProps}
            InputLabelProps={{
              style: {
                fontSize: 14,
                marginLeft: "1.5px",
              },
            }}
            sx={{
              "& input": {},
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PhoneInput;
