import {
  createAsyncThunk,
  createSlice,
  // isRejectedWithValue,
} from "@reduxjs/toolkit";
import { ANALYTICS_API } from "../../../Apis/Analytics/Analytics";

export interface IData {
  agentStatus: "loading" | "succeeded" | "failed" | "idle";
  agentData: any;
}

const initialState: IData = {
  agentStatus: "idle",
  agentData: null,
};

export const fetchAgentPerformanceByCompanyId = createAsyncThunk(
  "fetchAgentPerformanceByCompanyId",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await ANALYTICS_API.getAgentPerformance(data);
      // console.log('response',response.data.data);
      return response?.data?.data;
    } catch (error: any) {
      // Provide a descriptive error message
      return rejectWithValue(
        error?.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const agentPerformanceSlice = createSlice({
  name: "agentPerformanceByCompanyIdSlice",
  initialState,
  reducers: {
    // setData: (state, action) => {
    //   state.agentData = action.payload;
    // },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAgentPerformanceByCompanyId.pending, (state) => {
        state.agentStatus = "loading";
        //   state.error = ""
        state.agentData = null;
      })
      .addCase(fetchAgentPerformanceByCompanyId.fulfilled, (state, action) => {
        state.agentStatus = "succeeded";
        //   state.error = ""
        state.agentData = action.payload;
      })
      .addCase(fetchAgentPerformanceByCompanyId.rejected, (state) => {
        state.agentStatus = "failed";
        //   state.error = action.error.message || "";
      });
  },
});

export const agentPerformanceActions = agentPerformanceSlice.actions;
export default agentPerformanceSlice.reducer;
