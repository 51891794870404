import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MANAGE_PERMISSIONS_APIS } from "../../../Apis/AdminLogin/ManagePermissions/ManagePermissions";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
  admin:any,
  owner:any
}

const initialState: IData = {
  status: "idle",
  data: null,
  admin:null,
  owner:null
};

export const fetchUserPermission = createAsyncThunk(
  "fetchUserPermission",
  async ({ roleId, companyId, }: { roleId: any; companyId: any,  }, { rejectWithValue }) => {
    try {
      const response = await MANAGE_PERMISSIONS_APIS.getAllUserPermissions(roleId,companyId);
    // console.log("response", response);
    return response?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }

  }
);

export const getUserPermissionSlice = createSlice({
  name: "getUserPermissionSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserPermission.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserPermission.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchUserPermission.rejected, (state, action) => {
        state.status = "failed";
        state.data = action.error.message;
      });
  },
});
export const getUserPermissionSliceActions = getUserPermissionSlice.actions
export default getUserPermissionSlice.reducer
