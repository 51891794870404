import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { bgColors } from "../../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import EditDeletePopover from "../../../components/ManageClientsComponents/EditAndDeletePopover";
import EditIconSvg from "../../../assets/svgs/EditIconSvg";
import DeleteIconSvg from "../../../assets/svgs/DeleteIconSvg";
import AddNewClientMember from "../../../components/ManageClientsComponents/AddNewClientMember";
import { MANAGE_CLIENTS } from "../../../Apis/AdminLogin/ManageClients/ManageClient";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import { toastActions } from "../../../utils/toastSlice";
import { fetchGetAllTeamMembersByCompanyId } from "../../../redux/slices/ManageCompany/GetAllTeamMembersByCompanyId";
import EditTeamMember from "../../../components/ManageCompanyComponents/EditTeamMember";
import { ADMIN_MANAGE_COMPANY_APIS } from "../../../Apis/ManageCompany/ManageCompany";
import LoadingComponent from "../../../components/common/LoadingComponent";
import SearchIconSvg2 from "../../../assets/svgs/SearchIconSvg2";
import DeletePopUp from "../../../components/common/DeletePopup";
import useDebouncedFetch from "../../../utils/debounceHook";
import GroupsIcon from "@mui/icons-material/Groups";
import PeopleIcon from "@mui/icons-material/People";
// import { fetchCompanyDetails } from "../../../redux/slices/ManageCompany/CompanyDetailsSlice";
// import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import { hasManageClientsPermission } from "../../../utils/permissions";
import NoAccessPage from "../../../components/common/NoAccess";

const useStyles = makeStyles({
  mainContainer: {
    // backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100vh",
    width: "100%",
  },

  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "94%",
    width: "100%",
    overflow: "auto",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
  },
  profileContainer: {
    display: "flex",
    flexDirection: "row",
  },
  blackColor: {
    // color: `${bgColors.black1} !important`,
    // fontWeight: "600 !important",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
    // fontWeight: "600 !important",
  },
  emailContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  planButton: {
    backgroundColor: bgColors.green1,
    color: bgColors.green,
    fontWeight: "700",
    paddingTop: 6,
    paddingRight: 22,
    paddingBottom: 6,
    paddingLeft: 22,
    borderRadius: 25,
  },
  basicPlanButton: {
    backgroundColor: bgColors.blue3,
    color: bgColors.blue2,
    fontWeight: "700",
    paddingTop: 6,
    paddingRight: 22,
    paddingBottom: 6,
    paddingLeft: 22,
    borderRadius: 25,
  },
  proPlanButton: {
    backgroundColor: bgColors.yellow2,
    color: bgColors.yellow,
    fontWeight: "700",
    paddingTop: 6,
    paddingRight: 22,
    paddingBottom: 6,
    paddingLeft: 22,
    borderRadius: 25,
  },
  enterPricePlanButton: {
    backgroundColor: bgColors.red2,
    color: bgColors.red1,
    fontWeight: "700",
    paddingTop: 6,
    paddingRight: 22,
    paddingBottom: 6,
    paddingLeft: 22,
    borderRadius: 25,
  },
  editButtonContainer: {
    marginLeft: "auto",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
  },
  profileTextContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  manageTeamContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "20px",
    width: "full",
  },
  SaveChangesButton: {
    border: `1px solid ${bgColors.green}`,
    color: bgColors.green,
    borderRadius: "8px",
    width: "120px",
    height: "32px",
    // padding: "10px",
    cursor: "pointer",
  },
  searchField: {
    width: "100%",
    borderRadius: "12px",
    // backgroundColor: bgColors.white2,
    backgroundColor: "white",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "inter",
      color: "#000000 !important",
    },
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 8px",
    borderColor: "lightgray",
    "& th, & td": {
      // borderTop: '1px solid gray',
      borderBottom: "1px solid #f0f0f0",
      padding: "3px",
    },
    "& th:first-child, & td:first-child": {
      paddingLeft: "20px",
    },
    "& th:last-child, & td:last-child": {
      paddingRight: "20px",
    },
  },
  teamProfileContainer: {
    display: "flex",
    alignItems: "center",
  },
});

export const capitalizeWords = (str: any) => {
  // Replace underscores with spaces, then capitalize each word
  return str
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char: any) => char.toUpperCase());
};

const ManageClientDetails = () => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { clientDetails } = location.state || {};
  // console.log(clientDetails, "clientDetails")

  // const loginData = useAppSelector((state) => state?.adminLogin?.data);
  // const getPermissionData = loginData?.permissions;
  const getuserPermissionData = useAppSelector(
    (state: any) => state.getUserPermissions?.data
  );
  const hasClientPermission = hasManageClientsPermission(
    getuserPermissionData?.profile
  );
  const manageClientObject = getuserPermissionData?.profile?.find((item: any) =>
    Object?.prototype?.hasOwnProperty?.call(item, "manageClients")
  );
  // const clientsByIdSlice = useAppSelector((state) => state.companyData);
  // const clientsById = clientsByIdSlice?.data && clientsByIdSlice?.data;
  // const permissions = useAppSelector((state) => state.managePermissions.data);
  const manageClientActions = manageClientObject
    ? manageClientObject?.manageClients
    : [];
  const getAllTeamMembers = useAppSelector(
    (state: any) => state?.getAllTeamMembersByCompanyId?.data
  );
  // console.log(getAllTeamMembers)
  const debouncedFetchClientMembers = useDebouncedFetch(
    fetchGetAllTeamMembersByCompanyId,
    1500
  );

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isDeleteTeamMemberLoading, setIsDeleteTeamMemberLoading] =
    useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [dialogDEL, setDialogDEL] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [clientsById, setClientsById] = useState<any>(null);
  // console.log(clientsById)
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [editTeamMember, setEditTeamMember] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [editClientTooltip, setEditClientTooltip] = useState(false);
  const [deleteClientTooltip, setDeleteClientTooltip] = useState(false);
  const [addMemberTooltip, setAddMemberTooltip] = useState(false);
  const [editMemberTooltip, setEditMemberTooltip] = useState(null);
  const [deleteMemberTooltip, setDeleteMemberTooltip] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // const earliestCreationDate = getAllTeamMembers?.reduce(
  //   (earliest: any, member: any) => {
  //     return new Date(member.creationDate) < new Date(earliest)
  //       ? member.creationDate
  //       : earliest;
  //   },
  //   getAllTeamMembers[0]?.creationDate
  // );

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // console.log("client", clientsById);

  const hasAcess = (permission: any) => {
    if (manageClientActions?.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  // console.log("getAllTeamMembers", clientDetails);
  const handleDeleteDialog = () => {
    setDeleteDialog(true);
  };

  // useEffect(() => {
  //   const getT =  getAllTeamMembers?.users?.map((each) => each?.)
  // })

  const handleDeleteCloseDialog = () => {
    setDeleteDialog(false);
  };

  const handleDeleteDailog = () => {
    const hasPermission = hasAcess("deleteClient");
    if (hasPermission) {
      setDeleteDialog(true);
    } else {
      setDeleteClientTooltip(true);
      setTimeout(() => {
        setDeleteClientTooltip(false);
      }, 2000);
    }
  };

  const handleOpenDialog = () => {
    const hasPermission = hasAcess("addMember");
    if (hasPermission) {
      setOpenDialog(true);
    } else {
      setAddMemberTooltip(true);
      setTimeout(() => {
        setAddMemberTooltip(false);
      }, 2000);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const postData = {
      search: searchQuery,
      companyId: location?.state?.clientDetails?.id,
    };
    if (searchQuery) {
      debouncedFetchClientMembers(postData);
    } else {
      dispatch(fetchGetAllTeamMembersByCompanyId(postData));
    }
  }, [dispatch, searchQuery, debouncedFetchClientMembers]);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    // setClientDetail(c);
    const hasPermission = hasAcess("editClient");

    if (hasPermission) {
      history(`/profile/manage-clients/edit/${clientsById?.id}`, {
        state: { clientDetails: clientsById },
      });
    } else {
      setEditClientTooltip(true);
      setTimeout(() => {
        setEditClientTooltip(false);
      }, 2000);
    }
  };
  // const loginData = useAppSelector((state) => state?.adminLogin?.data);
  const handleDeleteTeamMember = async () => {
    // console.log("delete team member", deleteId);
    setIsDeleteTeamMemberLoading(true);
    if (deleteId) {
      try {
        const response = await ADMIN_MANAGE_COMPANY_APIS.deleteTeamMember(
          deleteId
        );
        if (response.status === 200) {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: response?.data?.message,
            })
          );
          const postData = {
            search: searchQuery,
            companyId: location?.state?.clientDetails?.id,
          };
          dispatch(fetchGetAllTeamMembersByCompanyId(postData));
          handleCloseDEL();
          // window.location.reload();
        }
      } catch (err: any) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: err?.response?.data?.message,
          })
        );
        // window.location.reload();
      }
    }
    setIsDeleteTeamMemberLoading(false);
  };

  const handleDelete = () => {
    handleDeleteDialog();
  };

  const handleDialog = (row: any) => {
    const hasPermission = hasAcess("editMember");
    if (hasPermission) {
      setDialog(true);
      if (row !== undefined && row !== null) {
        setEditTeamMember(row);
      }
    } else {
      setEditMemberTooltip(row);
      setTimeout(() => {
        setEditMemberTooltip(null);
      }, 2000);
    }
  };
  const handleDialogDEL = (row: any) => {
    const hasPermission = hasAcess("deleteMember");
    if (hasPermission) {
      if (row !== undefined && row !== null) {
        setDeleteId(row?.id);
      }
      setDialogDEL(true);
    } else {
      setDeleteMemberTooltip(row);
      setTimeout(() => {
        setDeleteMemberTooltip(null);
      }, 2000);
    }
  };

  const handleClose = () => {
    setDialog(false);
  };
  const handleCloseDEL = () => {
    setDialogDEL(false);
  };

  // console.log(location.state.clientDetails?.id, "sacln")

  const getClientsById = async () => {
    const data = {
      clientId: location?.state?.clientDetails?.id,
    };
    try {
      // dispatch(fetchCompanyDetails(clientId));
      const getRes = await MANAGE_CLIENTS.getClientsById(data);
      // console.log("getResById", getRes)
      setClientsById(getRes?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getClientsById();
  }, []);

  const handleDeleteClient = async () => {
    setIsDeleteLoading(true);
    try {
      const data = {
        clientId: location?.state?.clientDetails?.id,
      };
      const deleteRes = await MANAGE_CLIENTS.deleteClient(data);

      if (deleteRes?.status === 200) {
        history("/profile/manage-clients");
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: "Client Deleted Successfully",
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsDeleteLoading(false);
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return date?.toISOString().split("T")[0];
  };
  const formatDate2 = (datetime: any) => {
    const date = new Date(datetime);
    const year = date.getFullYear();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const day = ("0" + date.getDate()).slice(-2);
    return `${day} ${month} ${year}`;
  };

  // React.useEffect(() => {
  //   // dispatch(fetchManagePermissions({ roleId: roleId, companyId }));
  // }, [dispatch, loginData]);
  // console.log("getAllTeamMembers", getAllTeamMembers);

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowHover = (rowId: any) => {
    setHoveredRow(rowId);
  };
  const splitCamelCaseAndJoin = (str: string): string => {
    // Replace special characters with a space
    const cleanedStr = str.replace(/[^a-zA-Z0-9]+/g, " ");
    // Split the string at capital letters and join with a space
    const splitStr = cleanedStr.replace(/([a-z])([A-Z])/g, "$1 $2");
    // Capitalize the first letter of each word
    const result = splitStr.replace(/\b\w/g, (char: string) =>
      char.toUpperCase()
    );
    return result;
  };

  return (
    <>
      {hasClientPermission ? (
        <Grid className={classes.mainContainer}>
          <Grid className={classes.bgContainer}>
            <Box className={classes.manageContainer}>
              <Typography m={3} variant="h6" sx={{ display: "flex" }}>
                <span style={{ marginTop: "5px" }}>
                  <PeopleIcon sx={{ height: "22px" }} />
                </span>
                &nbsp;View Client Details
              </Typography>
            </Box>
            {!clientsById ? (
              // clientsByIdSlice?.status === "loading"
              <Box sx={{ height: "70vh" }}>
                <LoadingComponent height="100%" color={bgColors.blue} />
              </Box>
            ) : (
              <>
                <Box m={3}>
                  <Box p={3} className={classes.mainBorderStyles}>
                    <Box className={classes.profileContainer}>
                      <Box>
                        <img
                          alt="profile"
                          style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "14px",
                          }}
                          src={
                            clientsById?.companyLogoLink ||
                            "/images/companyLogo.png"
                          }
                        />
                      </Box>
                      <Box className={classes.profileTextContainer}>
                        <Box ml={3} mt={1}>
                          <Typography
                            variant="body2"
                            className={classes.blackColor}
                          >
                            {clientsById?.businessName}
                          </Typography>
                          {/* <Typography
                        variant="body2"
                        mt={2}
                        className={classes.grayColor}
                      >
                        {clientsById?.businessCategory || "Agency"}
                      </Typography> */}
                          <Typography
                            variant="body2"
                            mt={2}
                            className={classes.grayColor}
                          >
                            {clientsById?.businessCategory
                              ? splitCamelCaseAndJoin(
                                  clientsById?.businessCategory
                                )
                              : ""}
                          </Typography>
                        </Box>
                        <Box>
                          <Box ml={3}>
                            <button
                              className={
                                clientDetails.currentPlan === "INTRO PLAN"
                                  ? classes.planButton
                                  : clientDetails.currentPlan === "BASIC PLAN"
                                  ? classes.basicPlanButton
                                  : clientDetails.currentPlan === "PRO PLAN"
                                  ? classes.proPlanButton
                                  : classes.enterPricePlanButton
                              }
                            >
                              {/* INTRO PLAN */}
                              {clientDetails.currentPlan
                                ? clientDetails.currentPlan.toUpperCase()
                                : "INTRO PLAN"}
                            </button>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className={classes.editButtonContainer}
                        // onClick={handlePopoverOpen}
                      >
                        {/* <ThreeDots /> */}

                        <Tooltip
                          title="Access Denied"
                          open={editClientTooltip}
                          placement="top"
                          onClose={() => setEditClientTooltip(false)}
                        >
                          <Box mr={1} onClick={handleEdit}>
                            <EditIconSvg />
                          </Box>
                        </Tooltip>
                        <Tooltip
                          title="Access Denied"
                          open={deleteClientTooltip}
                          placement="top"
                          onClose={() => setDeleteClientTooltip(false)}
                        >
                          <Box onClick={handleDeleteDailog}>
                            <DeleteIconSvg />
                          </Box>
                        </Tooltip>
                      </Box>
                      <EditDeletePopover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                      />
                    </Box>
                    <DeletePopUp
                      title="Client"
                      open={deleteDialog}
                      handleClose={handleDeleteCloseDialog}
                      handleDelete={handleDeleteClient}
                      handleLoad={isDeleteLoading}
                    />

                    <Box
                      mt={{ xs: 2, md: 3 }}
                      // mr={3}
                      sx={{
                        justifyContent: "space-between",
                        display: { xs: "block", md: "flex" },
                      }}
                    >
                      {/* <Box
                    mb={{ xs: 2, md: 0 }}
                    sx={{
                      width: "34%",
                    }}
                  >
                    <Typography
                      variant="body2"
                      mb={1}
                      className={classes.grayColor}
                    >
                      Client Name
                    </Typography>
                    <Typography variant="body2" className={classes.blackColor}>
                      {clientsById?.name}
                    </Typography>
                  </Box> */}
                      <Box
                        mb={{ xs: 2, md: 0 }}
                        // mx={{ xs: 2, md: 4 }}
                        sx={{
                          width: "40%",
                        }}
                      >
                        <Typography
                          variant="body2"
                          mb={1}
                          className={classes.grayColor}
                        >
                          Company Email
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.blackColor}
                        >
                          {clientsById?.businessEmail}
                        </Typography>
                      </Box>
                      <Box
                        mb={{ xs: 2, md: 0 }}
                        sx={{
                          width: "34%",
                        }}
                      >
                        <Typography
                          variant="body2"
                          mb={1}
                          className={classes.grayColor}
                        >
                          Phone
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.blackColor}
                        >
                          {clientsById?.countryCode && clientsById?.phoneNumber
                            ? clientsById?.countryCode +
                              " " +
                              clientsById?.phoneNumber
                            : ""}
                        </Typography>
                      </Box>
                      <Box
                        mb={{ xs: 2, md: 0 }}
                        sx={{
                          width: "23%",
                        }}
                      >
                        <Typography
                          variant="body2"
                          mb={1}
                          className={classes.grayColor}
                        >
                          Created On
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.blackColor}
                        >
                          {clientsById?.creationDate
                            ? formatDate(clientsById?.creationDate)
                            : ""}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      mt={{ xs: 2, md: 3 }}
                      // mr={3}
                      sx={{
                        justifyContent: "space-between",
                        display: { xs: "block", md: "flex" },
                      }}
                    >
                      <Box
                        mb={{ xs: 2, md: 0 }}
                        // mx={{ xs: 2, md: 4 }}
                        sx={{
                          width: "40%",
                        }}
                      >
                        <Typography
                          variant="body2"
                          mb={1}
                          className={classes.grayColor}
                        >
                          Client ID
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.blackColor}
                        >
                          {clientsById?.id}
                        </Typography>
                      </Box>
                      <Box
                        mb={{ xs: 2, md: 0 }}
                        sx={{
                          width: "34%",
                        }}
                      >
                        <Typography
                          variant="body2"
                          mb={1}
                          className={classes.grayColor}
                        >
                          Country
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.blackColor}
                        >
                          {clientsById?.countryName}
                        </Typography>
                      </Box>
                      <Box
                        mb={{ xs: 2, md: 0 }}
                        sx={{
                          width: "23%",
                        }}
                      ></Box>
                    </Box>
                    <Box mt={3}>
                      <Typography
                        variant="body2"
                        mb={1}
                        className={classes.grayColor}
                      >
                        Company Address
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.blackColor}
                      >
                        {clientsById?.address}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box m={3} className={classes.manageTeamContainer}>
                  <Typography
                    variant="h6"
                    sx={{ flexShrink: 0, display: "flex", width: "170px" }}
                    className={classes.blackColor}
                  >
                    <span style={{ marginTop: "5px" }}>
                      <GroupsIcon sx={{ height: "25px", marginTop: "-2px" }} />
                    </span>
                    &nbsp;Manage Team
                  </Typography>
                  <TextField
                    className={classes.searchField}
                    variant="standard"
                    size="small"
                    value={searchQuery}
                    fullWidth
                    onChange={handleSearchChange}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        padding: "7px",
                        fontSize: "12px",
                        fontWeight: "600 !important",
                        height: "38px",
                        // border: '1px solid gray',
                        borderRadius: "14px",
                      },
                      startAdornment: (
                        <IconButton
                          sx={{ p: 0, color: "inherit", paddingTop: "3px" }}
                          aria-label="search"
                        >
                          {/* <SearchIconSvg /> */}
                          <SearchIconSvg2 />
                        </IconButton>
                      ),
                    }}
                    inputProps={{
                      style: {
                        // Additional style for placeholder
                        fontWeight: "600 !important",
                        padding: "0px",
                        // Apply font weight here
                      },
                    }}
                    placeholder="Search team members"
                  />
                  <Tooltip
                    title="Access Denied"
                    placement="top"
                    open={addMemberTooltip}
                    onClose={() => setAddMemberTooltip(false)}
                  >
                    <Box onClick={handleOpenDialog}>
                      <button
                        className={classes.SaveChangesButton}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{
                          backgroundColor: isHovered
                            ? "rgba(68, 71, 70, 0.08)"
                            : "#fff",
                          cursor: isHovered ? "pointer" : "default",
                        }}
                      >
                        + Add Member
                      </button>
                    </Box>
                  </Tooltip>
                  <AddNewClientMember
                    open={openDialog}
                    clientId={clientsById?.id}
                    handleClose={handleCloseDialog}
                  />
                </Box>
                <Box>
                  <TableContainer component={Box} mt={3}>
                    <Table className={classes.table}>
                      <TableHead className={classes.grayColor}>
                        <TableRow>
                          <TableCell>Member</TableCell>
                          <TableCell>Created by</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Contact</TableCell>
                          <TableCell>Created On</TableCell>
                          <TableCell>Role</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getAllTeamMembers?.length !== 0 &&
                          getAllTeamMembers
                            ?.filter((each: any) => each?.status == true)
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map(
                              (row: any) => (
                                //  {
                                // const isFirstCreated =
                                //   row.creationDate === earliestCreationDate;
                                // return
                                <TableRow
                                  key={row.id}
                                  onMouseEnter={() => handleRowHover(row.id)}
                                  onMouseLeave={() => setHoveredRow(null)}
                                  // onClick={() => handleRowSelect(row.contactId)}
                                  sx={{
                                    cursor: "pointer",
                                    boxShadow: row.id === hoveredRow ? 3 : 0,
                                    transition: "box-shadow 0.3s",
                                  }}
                                >
                                  <TableCell>
                                    <Box
                                      className={classes.teamProfileContainer}
                                    >
                                      <Avatar
                                        alt={row.member}
                                        src={
                                          row?.image
                                            ? row?.image
                                            : "/images/profile.png"
                                        }
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                      />
                                      <Typography
                                        className={classes.blackColor}
                                        style={{
                                          marginLeft: "4px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {row?.name}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{ fontSize: "12px" }}
                                    className={classes.blackColor}
                                  >
                                    {row?.createdBy}
                                  </TableCell>
                                  <TableCell
                                    sx={{ fontSize: "12px" }}
                                    className={classes.blackColor}
                                  >
                                    {row?.emailAddress}
                                  </TableCell>
                                  <TableCell
                                    sx={{ fontSize: "12px" }}
                                    className={classes.blackColor}
                                  >
                                    {row?.countryCode && row?.phoneNumber
                                      ? row?.countryCode +
                                          " " +
                                          row?.phoneNumber || ""
                                      : ""}
                                    {/* {row?.countryCode + " " + row?.phoneNumber} */}
                                  </TableCell>
                                  <TableCell
                                    sx={{ fontSize: "12px" }}
                                    className={classes.blackColor}
                                  >
                                    {row?.creationDate
                                      ? formatDate2(row?.creationDate)
                                      : ""}
                                  </TableCell>
                                  <TableCell
                                    sx={{ fontSize: "12px" }}
                                    className={classes.blackColor}
                                  >
                                    {row.roleId}
                                  </TableCell>
                                  {/* <TableCell>
                                {clientDetails?.createdAt ===
                                  row?.creationDate ? (
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Tooltip title="Edit">
                                        <Box
                                          mr={1}
                                        onClick={() => handleOpenEditDialog(row)}
                                        >
                                          <EditOutlined sx={{ color: "#cdcdcd" }} />
                                        </Box>
                                      </Tooltip>
                                      <Tooltip
                                        title="Delete"
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => handleOpenDeleteDialog(row)}
                                      >
                                        <Box>
                                          <DeleteOutlineOutlined
                                            sx={{ color: "#cdcdcd" }}
                                          />
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Tooltip
                                      title="Access Denied"
                                      placement="top"
                                      open={editMemberTooltip === row}
                                      onClose={() => setEditMemberTooltip(null)}
                                    >
                                      <Box mr={1} onClick={() => handleDialog(row)}>
                                        <EditIconSvg />
                                      </Box>
                                    </Tooltip>
                                    <EditClientMember
                          open={dialog}
                          handleClose={handleClose}
                        />
                                    <Tooltip
                                      title="Access Denied"
                                      open={deleteMemberTooltip === row}
                                      onClose={() => setDeleteMemberTooltip(null)}
                                      placement="top"
                                    >
                                      <Box onClick={() => handleDialogDEL(row)}>
                                        <DeleteIconSvg />
                                      </Box>
                                    </Tooltip>
                                    <DeletePopUpTeam
                          open={dialogDEL}
                          handleClose={handleCloseDEL}
                        />
                                  </Box>
                                )}
                              </TableCell> */}
                                  <TableCell>
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        cursor:
                                          //  isFirstCreated
                                          //   ? "not-allowed"
                                          //   :
                                          "pointer",
                                      }}
                                    >
                                      <Tooltip
                                        title="Access Denied"
                                        open={editMemberTooltip === row}
                                        onClose={() =>
                                          setEditMemberTooltip(null)
                                        }
                                        placement="top"
                                      >
                                        <Box
                                          mr={1}
                                          onClick={() =>
                                            // !isFirstCreated &&
                                            handleDialog(row)
                                          }
                                          // sx={{
                                          //   opacity: isFirstCreated ? 0.3 : 1,
                                          // }}
                                        >
                                          <EditIconSvg />
                                        </Box>
                                      </Tooltip>
                                      <Tooltip
                                        title="Access Denied"
                                        open={deleteMemberTooltip === row}
                                        onClose={() =>
                                          setDeleteMemberTooltip(null)
                                        }
                                        placement="top"
                                      >
                                        <Box
                                          onClick={() =>
                                            // !isFirstCreated &&
                                            handleDialogDEL(row)
                                          }
                                          // sx={{
                                          //   opacity: isFirstCreated ? 0.3 : 1,
                                          // }}
                                        >
                                          <DeleteIconSvg />
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              )
                              // }
                            )}
                        <EditTeamMember
                          usedFor="client"
                          data={editTeamMember}
                          open={dialog}
                          handleClose={handleClose}
                        />

                        <DeletePopUp
                          title="Team Member"
                          open={dialogDEL}
                          handleDelete={handleDeleteTeamMember}
                          handleClose={handleCloseDEL}
                          handleLoad={isDeleteTeamMemberLoading}
                        />
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <br />
                </Box>
              </>
            )}
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={
              getAllTeamMembers?.filter((each: any) => each?.status)
                ? getAllTeamMembers?.filter((each: any) => each?.status).length
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

export default ManageClientDetails;
