// Helper function to detect URLs and replace them with anchor tags
export const linkifyText = (text: any) => {
  const urlRegex = /((https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?)/g;
  return text?.replace(urlRegex, (url: any) => {
    // Ensure the URL has a protocol; if not, add 'http://'
    const href = url.startsWith("http") ? url : `http://${url}`;
    return `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
};

// Helper function to filter URLs
export const filterLinks = (linkMessages: { textMessage: string }[]) => {
  // Enhanced regex to capture URLs with various domain extensions
  const urlRegex = /((https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?)/g;
  return linkMessages?.filter((linkMessage: { textMessage: string }) =>
    urlRegex.test(linkMessage?.textMessage)
  );
};

// Helper function to extract URLs from a text message
export const extractUrls = (text: string) => {
  const urlRegex = /((https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?)/g;
  return text.match(urlRegex) || [];
};

// Function to get only the links from the messages
export const getLinksFromMessages = (messages: { textMessage: string }[]) => {
  const filteredLinks = filterLinks(messages);
  const allLinks = filteredLinks.flatMap((linkMessage) =>
    extractUrls(linkMessage.textMessage)
  );
  return allLinks;
};
