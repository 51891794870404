import React, { useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  Button,
  Popover,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { styled } from "@mui/system";

const ChatContainer = styled(Box)({
  width: "300px",
  borderRadius: "10px",
  overflow: "hidden",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
});

const Header = styled(Box)({
  backgroundColor: "#006d5b",
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const ChatBody = styled(Box)({
  height: "200px",
  padding: "10px",
  backgroundImage: `url("/images/whatsappBG.jpg")`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  overflowY: "auto",
});

const MessageBubble = styled(Box)({
  backgroundColor: "white",
  borderRadius: "10px",
  padding: "10px",
  maxWidth: "80%",
  marginBottom: "10px",
});

const StartChatButton = styled(Button)<{ bgColor: string }>(({ bgColor }) => ({
  backgroundColor: bgColor,
  color: "white",
  width: "100%",
  "&:hover": {
    backgroundColor: "#128C7E",
  },
}));

const PopupTrigger = styled(Button)<{
  open: boolean;
  theme?: any;
  bgColor: string;
  borderRadius: string;
  position: string;
}>(({ open, theme, bgColor, borderRadius, position }) => ({
  position: "fixed",
  bottom: "20px",
  // right: "20px",
  left: position === "bottom-left" ? "20px" : "auto",
  right: position === "bottom-right" ? "20px" : "auto",
  // borderRadius: open ? "50%" : "24px",
  borderRadius: `${borderRadius}px`,
  padding: open ? "12px" : "8px 16px",
  minWidth: open ? "48px" : "auto",
  width: open ? "48px" : "auto",
  height: open ? "48px" : "auto",
  backgroundColor: bgColor,
  // "#25D366",
  color: "white",
  textTransform: "none",
  boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
  "&:hover": {
    backgroundColor: "#128C7E",
  },
  zIndex: theme.zIndex.modal - 1,
}));

interface EngageChatPopupProps {
  chatConfig: any;
  widgetconfig: any;
  urlFields: any;
}

const EngageChatPopup: React.FC<EngageChatPopupProps> = ({
  chatConfig,
  widgetconfig,
  urlFields,
}) => {
  // console.log(chatConfig, "chatConfig");
  // console.log(widgetconfig, "widgetconfig");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };
  const dynamicMargins = {
    marginBottom: chatConfig?.marginBottom
      ? `${chatConfig.marginBottom}px`
      : "0px",
    marginLeft: chatConfig?.marginLeft ? `${chatConfig.marginLeft}px` : "0px",
    marginRight: chatConfig?.marginRight
      ? `${chatConfig.marginRight}px`
      : "0px",
  };
  return (
    <Box>
      <PopupTrigger
        onClick={handleClick}
        open={isOpen}
        bgColor={chatConfig?.buttonBackground}
        borderRadius={chatConfig?.borderRadius}
        position={chatConfig?.position}
        sx={dynamicMargins} // Apply dynamic margins here
      >
        {isOpen ? (
          <WhatsAppIcon />
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <WhatsAppIcon />
            <Typography>{chatConfig?.ctaText}</Typography>
          </Box>
        )}
      </PopupTrigger>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        disableScrollLock={true}
        sx={{
          pointerEvents: "none",
          "& .MuiPopover-paper": {
            pointerEvents: "auto",
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        PaperProps={{
          style: { overflow: "visible" },
        }}
      >
        <ChatContainer
          sx={{
            ...dynamicMargins, // Applying dynamic margins here
            position: "relative",
            zIndex: 1,
            backgroundColor: "white",
          }}
        >
          <Header>
            <Box display="flex" alignItems="center">
              {widgetconfig?.brandImageUrl ? (
                <img
                  src={widgetconfig.brandImageUrl}
                  alt={widgetconfig?.brandName || "Brand Logo"}
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: "50%",
                    marginRight: "8px",
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    bgcolor: "orange",
                    width: 32,
                    height: 32,
                    marginRight: 1,
                  }}
                >
                  E
                </Avatar>
              )}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="subtitle1" color="white">
                  {widgetconfig?.brandName}
                </Typography>
                <Typography sx={{ fontSize: "12px", color: "white" }}>
                  {widgetconfig?.brandSubtitle}
                </Typography>
              </Box>
            </Box>
            <IconButton
              size="small"
              sx={{ color: "white" }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Header>
          <ChatBody>
            <MessageBubble>
              <Typography variant="body2">
                {" "}
                {widgetconfig?.brandName}
              </Typography>
              <Typography variant="body1">
                <span
                  dangerouslySetInnerHTML={{
                    __html: widgetconfig?.defaultOnScreenMessage.replaceAll(
                      "\n",
                      "<br />"
                    ),
                  }}
                />
              </Typography>
            </MessageBubble>
          </ChatBody>
          <Box p={1}>
            <StartChatButton
              variant="contained"
              fullWidth
              bgColor={chatConfig?.buttonBackground}
            >
              Start chat
            </StartChatButton>
          </Box>
          <Typography
            variant="caption"
            align="center"
            display="block"
            sx={{ bgcolor: "#f0f0f0", py: 0.5 }}
          >
            ⚡ by Engageto
          </Typography>
        </ChatContainer>
      </Popover>
    </Box>
  );
};

export default EngageChatPopup;
