export const  bgColors = {
    "green": "#00934F",
    "green1":" #3CAA931A",
    "blue": "#2999cf",
    "blue2":"#1795D2",
    "blue3":"#1795D21A",
    "yellow":"#FF9D0D",
    "yellow2":"#FF9D0D1A",
    'gray1': "#707070",
    "gray2":"#f0eff1",
    "gray3":"#878794", 
    "gray4": "#fafafa",
    "gray5":"#DBDBDB",
    "white": "#ffffff",
    "white1":"#F8F8F8",
    "white2":"#F2F2F2",
    "black": "#000000",
    "black1":"#303030",
    "black2":"#3C3C3C",
    "black3":"#707070",
    "red":"#FF7171",
    "red1":"#F64E60",
    "red2":"#F64E601A",
    "gray9":"#F5F7F7",
}