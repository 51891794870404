import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  TextFieldProps,
  InputLabelProps,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { bgColors } from "../../utils/bgColors";

interface IProps {
  label: any;
  name: string;
  placeholder: string;
}

type PasswordTextFeildProps = TextFieldProps & IProps;

const PasswordTextFeildWithBorders: React.FC<PasswordTextFeildProps> = ({
  label,
  name = "",
  placeholder = "",
  size = "small",
  fullWidth = true,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [focused, setFocused] = useState(false);
  const [, setHovered] = useState(false);

  const handleToggleVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // const inputProps: InputBaseProps = {
  //   style: {
  //     borderRadius: "8px",
  //     fontSize: "12px",
  //     backgroundColor: focused
  //       ? bgColors.gray4
  //       : hovered
  //       ? bgColors.gray2
  //       : bgColors.white,
  //   },
  // };
  const labelProps: InputLabelProps = {
    style: { fontSize: 14 },
  };
  return (
    <TextField
      label={label}
      fullWidth={fullWidth}
      placeholder={placeholder}
      name={name}
      size={size}
      margin="dense"
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      type={showPassword ? "text" : "password"}
      InputLabelProps={labelProps}
      InputProps={{
        sx: {
          backgroundColor: focused ? bgColors.gray4 : bgColors.white,
          borderRadius: "8px",
          fontSize: 14,
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleToggleVisibility} edge="end">
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export default PasswordTextFeildWithBorders;
