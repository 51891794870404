import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, Popover, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "@mui/styles";
import { toastActions } from "../../utils/toastSlice";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { removeTemplate } from "../../redux/slices/Templates/DeleteTemplateSlice";
import LoadingComponent from "../common/LoadingComponent";
import { bgColors } from "../../utils/bgColors";
import DeletePopUp from "../common/DeletePopup";
import CampaignIcon from "@mui/icons-material/Campaign";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { parseTextToHtml } from "../../utils/functions";
const useStyles = makeStyles({
  container: {
    boxShadow: "2px 2px 4px 2px #cdcdcd",
    borderRadius: "8px",
    width: "220px",
    padding: "10px",
    textAlign: "center",
    height: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  popoverContent: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    textAlign: "left",
  },
});

const TemplateCardView = ({
  templateData,
  userData,
  handleEditToggle,
  hasAccessCheck,
  editLibraryTemplatePermissionTooltipOpen,
  setEditLibraryTemplatePermissionTooltipOpen,
}: any) => {
  // console.log(templateData)
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const removeTemplateSlice = useAppSelector(
    (state: any) => state?.removeTemplateData
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [bodyText, setBodyText] = useState("");
  const [isLibDeleteTemplteLoading, setIsLibDeleteTemplteLoading] =
    useState(false);
  const [
    deleteLibraryTemplatePermissionTooltipOpen,
    setDeleteLibraryTemplatePermissionTooltipOpen,
  ] = useState(false);
  const [
    startCampaignPermissionTooltipOpen,
    setStartCampaignPermissionTooltipOpen,
  ] = useState(false);

  // const parseTextToHTML = (text: string) => {
  //   // Replace markdown-like symbols with HTML tags
  //   const htmlText = text
  //     .replace(/\*(.*?)\*/g, '<b>$1</b>')
  //     .replace(/_(.*?)_/g, '<i>$1</i>')
  //     .replace(/~(.*?)~/g, '<strike>$1</strike>');

  //   return htmlText;
  // };

  // const parseTextToHTML = (text: string) => {
  //   // console.log(text);

  //   // Replace markdown-like symbols with HTML tags for inline styles
  //   let htmlText = text
  //     .replace(/\*(.*?)\*/g, "<b>$1</b>")
  //     .replace(/_(.*?)_/g, "<i>$1</i>")
  //     .replace(/~(.*?)~/g, "<strike>$1</strike>");

  //   // Replace number points with HTML list items for ordered list
  //   htmlText = htmlText.replace(/^(\d+\.\s.*)$/gm, "<li>$1</li>");
  //   htmlText = htmlText.replace(/<li>(\d+\.\s)/g, "<li>"); // Remove the numbers inside the <li> tags
  //   if (htmlText.includes("<li>")) {
  //     htmlText = `<ol>${htmlText}</ol>`;
  //   }

  //   // Replace bullet points with HTML list items for unordered list
  //   htmlText = htmlText.replace(/^-\s+(.*)$/gm, "<li>$1</li>");
  //   if (htmlText.includes("<li>") && !htmlText.includes("<ol>")) {
  //     htmlText = `<ul>${htmlText}</ul>`;
  //   }

  //   // Preserve line breaks in paragraphs by replacing new lines with <br> tags
  //   htmlText = htmlText.replace(/\n/g, "<br>");

  //   return htmlText;
  // };

  const handleDeletePopup = () => {
    const hasPermission = hasAccessCheck("deleteLibraryTemplate");
    if (hasPermission) {
      setOpenDeletePopup(true);
      handleClose();
    } else {
      setDeleteLibraryTemplatePermissionTooltipOpen(true);
      setTimeout(() => {
        setDeleteLibraryTemplatePermissionTooltipOpen(false);
      }, 2000);
    }
  };

  const handleDeletePopupClose = () => {
    setOpenDeletePopup(false);
  };

  const handleMoreHorizClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStartCampaign = () => {
    const hasPermission = hasAccessCheck("startLibraryCampaign");
    if (hasPermission) {
      navigate("/campaigns/one-time");
    } else {
      setStartCampaignPermissionTooltipOpen(true);
      setTimeout(() => {
        setStartCampaignPermissionTooltipOpen(false);
      }, 2000);
    }
  };

  const handleDeleteTemplate = async () => {
    setIsLibDeleteTemplteLoading(true);
    const deleteData = {
      businessId: userData?.companyId,
      userId: userData?.userId,
      templateId: templateData?.templateId,
    };

    try {
      const deleteResponse: any = await dispatch(removeTemplate(deleteData));
      if (deleteResponse?.meta?.requestStatus === "fulfilled") {
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: deleteResponse?.payload?.message,
          })
        );
      } else {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: deleteResponse?.payload,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: err?.message,
        })
      );
    }
    setIsLibDeleteTemplteLoading(false);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const htmlText: any = parseTextToHtml(templateData.body);
    setBodyText(htmlText);
  }, [templateData.body]);

  return (
    <Grid
      className={classes.container}
      sx={{ breakInside: "avoid", margin: "0 0 1.5rem" }}
    >
      <Box className={classes.header}>
        <Tooltip title={templateData?.templateName}>
          <Typography
            sx={{
              color: "#707070",
              fontWeight: "bold",
              fontSize: "14px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              textAlign: "left",
              maxHeight: 22,
            }}
          >
            {templateData?.templateName}
          </Typography>
        </Tooltip>
        <MoreVertIcon
          sx={{ cursor: "pointer", height: "16px", color: "#777" }}
          onClick={handleMoreHorizClick}
        />
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box className={classes.popoverContent}>
          <Tooltip
            title="Access Denied"
            placement="top"
            open={startCampaignPermissionTooltipOpen}
            onClose={() => setStartCampaignPermissionTooltipOpen(false)}
          >
            <Typography
              sx={{
                cursor: "pointer",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={handleStartCampaign}
            >
              <CampaignIcon sx={{ height: "20px" }} /> &nbsp; Start Campaign
            </Typography>
          </Tooltip>
          <Tooltip
            title="Access Denied"
            placement="top"
            open={editLibraryTemplatePermissionTooltipOpen}
            onClose={() => setEditLibraryTemplatePermissionTooltipOpen(false)}
          >
            <Typography
              sx={{
                cursor: "pointer",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                handleEditToggle("edit", templateData?.templateId);
                handleClose();
              }}
            >
              {" "}
              <EditIcon sx={{ height: "20px" }} />
              &nbsp; Edit Template
            </Typography>
          </Tooltip>
          {removeTemplateSlice?.status === "loading" ? (
            <LoadingComponent height="auto" color={bgColors?.blue} />
          ) : (
            <Tooltip
              title="Access Denied"
              placement="top"
              open={deleteLibraryTemplatePermissionTooltipOpen}
              onClose={() =>
                setDeleteLibraryTemplatePermissionTooltipOpen(false)
              }
            >
              <Typography
                sx={{
                  color: "red",
                  cursor: "pointer",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleDeletePopup}
              >
                <DeleteOutlineIcon sx={{ height: "20px" }} />
                &nbsp; Delete
              </Typography>
            </Tooltip>
          )}
        </Box>
      </Popover>
      <Box sx={{ my: 1, border: "0.5px solid #f2f2f2" }}></Box>
      <Typography sx={{ fontSize: "12px", mb: 2, textAlign: "left" }}>
        {templateData?.header}
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          mb: 2,
          textAlign: "left",
          overflowWrap: "break-word",
        }}
        dangerouslySetInnerHTML={{ __html: bodyText }}
      ></Typography>
      <Typography sx={{ fontSize: "12px", mb: 2, textAlign: "left" }}>
        {templateData?.footer}
      </Typography>

      {templateData?.mediaAwsUrl ? (
        templateData?.mediaAwsUrl?.includes("mp4") ? (
          <Box>
            <iframe
              src={templateData?.mediaAwsUrl}
              style={{ height: 140, width: "100%" }}
            ></iframe>
          </Box>
        ) : templateData?.mediaAwsUrl?.includes("pdf") ? (
          <Box>
            <iframe
              src={templateData?.mediaAwsUrl}
              style={{ height: 140, width: "100%" }}
            ></iframe>
          </Box>
        ) : (
          <Box>
            <img
              src={templateData?.mediaAwsUrl}
              alt=""
              style={{ height: 140, width: "100%" }}
            />
          </Box>
        )
      ) : (
        <></>
      )}
      <DeletePopUp
        title="Template"
        open={openDeletePopup}
        handleClose={handleDeletePopupClose}
        handleDelete={handleDeleteTemplate}
        handleLoad={isLibDeleteTemplteLoading}
      />
    </Grid>
  );
};

export default TemplateCardView;
