import React from "react";

const SearchIconSvg2 = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 38 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M31.7908 29.5425L27.4075 25.175C29.1444 22.9622 30.0868 20.2297 30.0833 17.4167C30.0833 14.9114 29.3405 12.4625 27.9486 10.3794C26.5568 8.29643 24.5785 6.67291 22.264 5.7142C19.9495 4.75549 17.4026 4.50465 14.9455 4.99339C12.4884 5.48214 10.2315 6.68852 8.45999 8.45999C6.68852 10.2315 5.48214 12.4884 4.99339 14.9455C4.50465 17.4026 4.75549 19.9495 5.7142 22.264C6.67291 24.5785 8.29643 26.5568 10.3794 27.9486C12.4625 29.3405 14.9114 30.0833 17.4167 30.0833C20.2297 30.0868 22.9622 29.1444 25.175 27.4075L29.5425 31.7908C29.6897 31.9392 29.8648 32.057 30.0578 32.1374C30.2507 32.2178 30.4577 32.2592 30.6667 32.2592C30.8757 32.2592 31.0826 32.2178 31.2756 32.1374C31.4685 32.057 31.6436 31.9392 31.7908 31.7908C31.9392 31.6436 32.057 31.4685 32.1374 31.2756C32.2178 31.0826 32.2592 30.8757 32.2592 30.6667C32.2592 30.4577 32.2178 30.2507 32.1374 30.0578C32.057 29.8648 31.9392 29.6897 31.7908 29.5425ZM7.91667 17.4167C7.91667 15.5378 8.47384 13.701 9.51771 12.1388C10.5616 10.5765 12.0453 9.35885 13.7812 8.63982C15.5171 7.92078 17.4272 7.73265 19.27 8.09921C21.1129 8.46577 22.8056 9.37056 24.1342 10.6992C25.4628 12.0278 26.3676 13.7205 26.7341 15.5633C27.1007 17.4061 26.9126 19.3163 26.1935 21.0522C25.4745 22.7881 24.2569 24.2718 22.6946 25.3156C21.1323 26.3595 19.2956 26.9167 17.4167 26.9167C14.8971 26.9167 12.4808 25.9158 10.6992 24.1342C8.91756 22.3526 7.91667 19.9362 7.91667 17.4167Z"
          fill="#4B5A5A"
        />
      </g>
    </svg>
  );
};

export default SearchIconSvg2;
