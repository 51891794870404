import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET_MANAGE_ACCOUNT } from "../../../Apis/ManageAccountApis/GetManangeAccount";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const fetchAllCountryCodes = createAsyncThunk(
  "fetchAllCountryCodes",
  async () => {
    const response = await GET_MANAGE_ACCOUNT.getAllCountyCodes();

    return response?.data;
  }
);

export const getAllCountryCodesSlice = createSlice({
  name: "getAllCountryCodesSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllCountryCodes.pending, (state) => {
        state.status = "loading";
        //   state.error = ""
        state.data = [];
      })
      .addCase(fetchAllCountryCodes.fulfilled, (state, action) => {
        state.status = "succeeded";
        //   state.error = ""
        state.data = action.payload;
        //   state.EstaActivo = true;
        //   state.filterData = action.payload.filter((each:any) => _.get(each,'EstaActivo'))
      })
      .addCase(fetchAllCountryCodes.rejected, (state) => {
        state.status = "failed";
        state.data = [];
        //   state.error = action.error.message || "";
      });
  },
});

export const getAllCountryCodesSliceActions = getAllCountryCodesSlice.actions;
export default getAllCountryCodesSlice.reducer;
