import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Popover,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { bgColors } from "../../../utils/bgColors";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import { fetchGetAllTeamMembersByCompanyId } from "../../../redux/slices/ManageCompany/GetAllTeamMembersByCompanyId";

const useStyles = makeStyles({
  popoverContainer: {
    // padding: "8px",
    paddingLeft: "16px",
    paddingRight: "16px",
    // width: "300px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  },
  closeButton: {
    color: bgColors.black1,
  },
  title: {
    color: bgColors.black1,
    fontWeight: "bold",
  },
  selectContainer: {
    marginBottom: "16px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "100px",
    height: "32px",
    // padding: "5px",
    cursor: "pointer",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "rgba(68, 71, 70, 0.08)",
    },
  },
  textColor: {
    color: "#3C3C3C",
    fontWeight: "600",
  },
  resetBtnStyles: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "70px",
    height: "32px",
    // padding: "5px",
    cursor: "pointer",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "rgba(68, 71, 70, 0.08)",
    },
  },
});

const ChatFilterMainPopover = ({
  anchorEl,
  filterData,
  handleClose,
  onApplyFilter,
}: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const loginData = useAppSelector((state:any) => state?.adminLogin?.data);
  const getAllTeamMembersData = useAppSelector(
    (state:any) => state?.getAllTeamMembersByCompanyId?.data
  );
  const teamMembersArray = getAllTeamMembersData?.filter(
    (item: any) => item?.status === true
  );
  const tagsArray = useAppSelector((state:any) => state.getContactTagsData.data);

  const [isReset, setIsReset] = useState(false);
  const [filters, setFilters] = useState({
    status: "All",
    tags: "All",
    assignee: "All",
    sort: { column: "All", order: "" },
  });

  const handleChange = (filterName: string) => (event: any) => {
    setFilters({ ...filters, [filterName]: event.target.value });
  };
  const handleSortChange = (event: any) => {
    setFilters({
      ...filters,
      sort: {
        column: event?.target?.value,
        order: "",
      },
    });
  };

  const handleApplyFilter = () => {
    // console.log("app,ied filters");
    const filterData = [
      {
        column: "ChatStatus",
        operator: "equals",
        value: filters?.status,
      },
      {
        column: "Tags",
        operator: "contain",
        value: filters?.tags,
      },
      {
        column: "Assignee",
        operator: "equals",
        value: filters?.assignee,
      },
    ];
    const Data = filterData?.filter((filter) => filter?.value !== "All");
    onApplyFilter({
      filters: Data,
      sort: {
        column: filters?.sort?.column !== "All" ? filters?.sort?.column : "",
        order: filters?.sort?.order,
      },
    });
    handleClose();
  };

  const handleResetFilter = () => {
    setFilters({
      status: "All",
      tags: "All",
      assignee: "All",
      sort: { column: "All", order: "" },
    });
    setIsReset(true);
    handleClose();
  };

  useEffect(() => {
    if (isReset) {
      handleApplyFilter();
      setIsReset(false);
    }
  }, [filters, isReset]);

  useEffect(() => {
    const postData = {
      search: "",
      companyId: loginData?.companyId,
    };
    dispatch(fetchGetAllTeamMembersByCompanyId(postData));
  }, [dispatch]);

  useEffect(() => {
    if (
      filterData &&
      filterData?.filters?.length !== 0 &&
      !filterData?.filters?.some((f: any) => f?.column === "IsSpam") &&
      !filterData?.filters?.some(
        (f: any) => f?.column === "Assignee" && f?.value === ""
      )
    ) {
      const newFilters = {
        status:
          filterData?.filters?.find((f: any) => f?.column === "ChatStatus")
            ?.value || "All",
        tags:
          filterData?.filters?.find((f: any) => f?.column === "Tags")?.value ||
          "All",
        assignee:
          filterData?.filters?.find((f: any) => f?.column === "Assignee")
            ?.value || "All",
        sort: {
          column: filterData.sort.column || "All",
          order: filterData.sort.order || "",
        },
      };
      setFilters(newFilters);
    }
  }, [filterData]);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box className={classes.popoverContainer}>
        <Box className={classes.headerContainer}>
          <Typography sx={{ fontSize: "14px" }} className={classes.title}>
            Select filters
          </Typography>
          <IconButton onClick={handleClose} className={classes.closeButton}>
            {/* <ChatFilterCloseIconSvg /> */}
            <CloseSvg />
          </IconButton>
        </Box>
        <Box className={classes.selectContainer}>
          <Typography
            sx={{ fontSize: "14px" }}
            mb={1}
            className={classes.textColor}
          >
            Chat Status
          </Typography>
          <Select
            value={filters?.status}
            onChange={handleChange("status")}
            fullWidth
            variant="outlined"
            size="small"
            sx={{ fontSize: "12px" }}
          >
            <MenuItem value="All" sx={{ fontSize: "12px" }}>
              All
            </MenuItem>
            <MenuItem value="open" sx={{ fontSize: "12px" }}>
              Open
            </MenuItem>
            <MenuItem value="new" sx={{ fontSize: "12px" }}>
              New
            </MenuItem>
            <MenuItem value="expired" sx={{ fontSize: "12px" }}>
              Expired
            </MenuItem>
            <MenuItem value="Resolved" sx={{ fontSize: "12px" }}>
              Resolved
            </MenuItem>
          </Select>
        </Box>
        <Box className={classes.selectContainer}>
          <Typography
            sx={{ fontSize: "14px" }}
            mb={1}
            className={classes.textColor}
          >
            Tags
          </Typography>
          <Select
            value={filters?.tags}
            onChange={handleChange("tags")}
            fullWidth
            variant="outlined"
            size="small"
            sx={{ fontSize: "12px" }}
          >
            <MenuItem value="All" sx={{ fontSize: "12px" }}>
              All
            </MenuItem>
            {/* Map through tagsArray and create MenuItem for each tag */}
            {tagsArray?.length !== 0 ? (
              tagsArray?.map((tag: any) => (
                <MenuItem
                  key={tag?.id}
                  value={tag?.id}
                  sx={{ fontSize: "12px" }}
                >
                  {tag?.tag}
                </MenuItem>
              ))
            ) : (
              <Typography sx={{ fontSize: "12px" }}>No Data</Typography>
            )}
          </Select>
        </Box>
        <Box className={classes.selectContainer}>
          <Typography
            sx={{ fontSize: "14px" }}
            mb={1}
            className={classes.textColor}
          >
            Assignee
          </Typography>
          <Select
            value={filters?.assignee}
            onChange={handleChange("assignee")}
            fullWidth
            variant="outlined"
            size="small"
            sx={{ fontSize: "12px" }}
          >
            <MenuItem value="All" sx={{ fontSize: "12px" }}>
              All
            </MenuItem>
            {/* <MenuItem
              value="unassigned"
              sx={{ fontStyle: "italic", fontSize: "12px" }}
            >
              Unassigned
            </MenuItem> */}
            {/* Map through teamMembersArray and create MenuItem for each member */}
            {teamMembersArray?.length !== 0 ? (
              teamMembersArray?.map((member: any) => (
                <MenuItem
                  key={member?.id}
                  value={member?.id}
                  sx={{ fontSize: "12px" }}
                >
                  {member?.name !== null ? member?.name : member?.phoneNumber}
                </MenuItem>
              ))
            ) : (
              <Typography sx={{ fontSize: "12px" }}>No Data</Typography>
            )}
          </Select>
        </Box>
        <Box className={classes.selectContainer}>
          <Typography
            sx={{ fontSize: "14px" }}
            mb={1}
            className={classes.textColor}
          >
            Sort
          </Typography>
          <Select
            value={filters?.sort?.column}
            onChange={handleSortChange}
            fullWidth
            variant="outlined"
            size="small"
            sx={{ fontSize: "12px" }}
          >
            <MenuItem value="All" sx={{ fontSize: "12px" }}>
              All
            </MenuItem>
            <MenuItem value="Name" sx={{ fontSize: "12px" }}>
              Name
            </MenuItem>
            <MenuItem value="LastMessageAt" sx={{ fontSize: "12px" }}>
              Last Message
            </MenuItem>
          </Select>
        </Box>
        <Box className={classes.buttonContainer}>
          <Box m={2}>
            <button
              className={classes.resetBtnStyles}
              onClick={handleResetFilter}
            >
              Reset
            </button>
          </Box>
          <Box m={2}>
            <button
              className={classes.SaveChangesButton}
              onClick={handleApplyFilter}
            >
              Apply Filters
            </button>
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};

export default ChatFilterMainPopover;
