import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { bgColors } from "../../../utils/bgColors";
// import RedDeleteSvg from "../../../assets/svgs/RedDeleteSvg";
// import AddCardDialog from "../../../components/ManageAccountComponents/AddCardDialogComponent";
import { useAppDispatch } from "../../../utils/redux-hooks";
import { fetchGetAllPaymentsCards } from "../../../redux/slices/ManageAccount/GetPaymentsCards";
// import LoadingComponent from "../../../components/common/LoadingComponent";
// import { GET_MANAGE_ACCOUNT } from "../../../Apis/ManageAccountApis/GetManangeAccount";
// import { toastActions } from "../../../utils/toastSlice";
import CenteredContent from "../../../components/common/comingSoon";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100%",
    width: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
  },
  manageContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  SaveChangesButton: {
    backgroundColor: bgColors.green,
    color: bgColors.white,
    borderRadius: "20px",
    width: "150px",
    height: "38px",
    padding: "10px",
    cursor: "pointer",
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
    display: "flex",
    flexDirection: "row",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
  },
  resetBtnContainer: {
    border: `2px solid ${bgColors.red}`,
    borderRadius: "20px",
    width: "120px",
    height: "25px",
    padding: "10px",
    textAlign: "center",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  changePasswordText: {
    color: bgColors.red,
    fontWeight: "600",
  },
  buttonStyles: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "auto",
  },
});

const ManagePayments = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  // const [openDialog, setOpenDialog] = useState(false);
  // const getCards = useAppSelector((state) => state?.getAllPaymentsCard);
  // const cardsData = getCards && getCards?.data;

  // const handleAddNewCard = () => {
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };
  useEffect(() => {
    dispatch(fetchGetAllPaymentsCards());
  }, []);

  // const handleDeleteCard = async (id: string) => {
  //   try {
  //     const response = await GET_MANAGE_ACCOUNT.deletePaymentCard({ id });
  //     if (response.status === 200) {
  //       dispatch(fetchGetAllPaymentsCards());
  //       dispatch(
  //         toastActions.setToaster({
  //           type: "success",
  //           message: `${response?.data?.message}`,
  //         })
  //       );
  //     }
  //   } catch (err) {
  //     // console.log("err", err);
  //   }
  // };

  return (
    <Grid className={classes.mainContainer}>
      <CenteredContent />
      {/* <Grid className={classes.bgContainer}>
        <Box p={3} className={classes.manageContainer}>
          <Typography ml={3} variant="h6">
            Saved Payment Method
          </Typography>
          <Box onClick={handleAddNewCard}>
            <button className={classes.SaveChangesButton}>
              + Add Card
            </button>
          </Box>
        </Box>
        {getCards.status === "loading" ? (
          <LoadingComponent height="100vh" color={bgColors.blue} />
        ) : (
          cardsData &&
          cardsData?.map((item: any) => {
            return (
              <Box px={3} id={item?.cardId} m={2}>
                <Box ml={2} className={classes.mainBorderStyles}>
                  <Box style={{ padding: "20px" }}>
                    <img src="/Paytm.jpg" alt="paytm" />
                  </Box>
                  <Box mt={3}>
                    <Typography variant="h6" className={classes.blackColor}>
                      {item?.cardholderName}
                    </Typography>
                    <Typography
                      variant="body2"
                      mt={2}
                      className={classes.grayColor}
                    >
                      {item?.cardNumber}
                    </Typography>
                  </Box>
                  <Box mr={2} className={classes.buttonStyles} onClick={() => handleDeleteCard(item?.cardId)}>
                    <Box className={classes.resetBtnContainer} p={2}>
                      <Typography
                        variant="body2"
                        className={classes.changePasswordText}
                      >
                        Remove
                      </Typography>
                      <RedDeleteSvg />
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })
        )}
        <AddCardDialog open={openDialog} handleClose={handleCloseDialog} />
      </Grid> */}
    </Grid>
  );
};

export default ManagePayments;
