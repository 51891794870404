import React from "react";
import { Grid } from "@mui/material";
import ResetPasswordContainer from "../../components/LoginComponents/ResetPasswordContainer";
import { makeStyles } from "@mui/styles";
import EngagetoLogoSvg from "../../assets/svgs/EngagetoLogoSvg";

const useStyles = makeStyles({
  background: {
    backgroundImage: `url("/images/new-pwd-bg.png")`,
    height: "100vh",
  },
});

const ResetPassword = () => {
  const classes = useStyles();
  return (
    <Grid container item xs={12} className={classes.background}>
      <Grid item xs={12} sm={6} md={4} ml={8} mt={8}>
        <EngagetoLogoSvg />
      </Grid>
      <Grid item xs={12} sm={6} md={4} mt={10}>
        <ResetPasswordContainer loginPromptText="Reset Password!" />
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
