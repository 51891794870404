// CreateList.js
import React, { useState } from "react";
import {
  AccordionDetails,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import InputPair from "./InputPair";

const CreateList = ({ onCreate }: any) => {
  const [listName, setListName] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [inputs, setInputs] = useState([{ title: "", description: "" }]);

  const handleInputChange = (index: number, field: any, value: any) => {
    const newInputs: any = [...inputs];
    newInputs[index][field] = value;
    setInputs(newInputs);
  };

  const handleAddInput = () => {
    if (inputs.length < 10) {
      setInputs([...inputs, { title: "", description: "" }]);
    }
  };

  const handleCreate = () => {
    onCreate({ listName, buttonName, inputs });
  };

  return (
    <AccordionDetails sx={{ overflowY: "scroll", p: "16px" }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              List Name
            </Typography>
            <TextField
              label="List Name"
              variant="outlined"
              fullWidth
              size="small"
              value={listName}
              onChange={(e) => setListName(e.target.value)}
              sx={{
                height: "40px",
                borderRadius: "10px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Button Name
            </Typography>
            <TextField
              label="Button Name"
              variant="outlined"
              fullWidth
              size="small"
              value={buttonName}
              onChange={(e) => setButtonName(e.target.value)}
              sx={{
                height: "40px",
                borderRadius: "10px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: "10px" }}>
        <Typography variant="subtitle1">Input(s)</Typography>
        <Typography variant="caption">
          Total number of inputs cannot be more than 10
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {inputs.map((input, index) => (
            <Grid item xs={12} key={index}>
              <InputPair
                input={input}
                onChange={handleInputChange}
                index={index}
              />
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              color: "green",
              fontWeight: 600,
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            Choose from custom Auto Reply
          </Typography>
          <Typography variant="caption">OR</Typography>
          <Typography
            onClick={handleAddInput}
            sx={{
              color: inputs.length >= 10 ? "gray" : "green",
              fontWeight: 600,
              fontSize: "14px",
              cursor: inputs.length >= 10 ? "not-allowed" : "pointer",
              pointerEvents: inputs.length >= 10 ? "none" : "auto",
            }}
          >
            + Add custom input
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          fullWidth
          variant="outlined"
          style={{
            color: "green",
            fontWeight: "600",
            border: "1px solid green",
            borderRadius: "8px",
            textTransform: "capitalize",
            width: "200px",
            fontSize: "14px",
            cursor: "pointer",
            marginTop: "40px",
          }}
          onClick={handleCreate}
        >
          Create and Select
        </Button>
      </Box>
    </AccordionDetails>
  );
};

export default CreateList;
