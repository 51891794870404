import React, { useState } from "react";
import { Box, Select, MenuItem, Typography, IconButton } from "@mui/material";
import SelectListDialog from "./SelectListDialog";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import { updateWorkflowList } from "../../../redux/slices/Workflows/updateWorkflowListSlice";
import { toastActions } from "../../../utils/toastSlice";
import TextFieldWithBorderComponent from "../../common/TextFieldWithBorderComponent";
import DeleteIconSvg from "../../../assets/svgs/DeleteIconSvg";
import LoadingComponent from "../../common/LoadingComponent";
import { bgColors } from "../../../utils/bgColors";
import { ButtonOptions } from "./functions";

interface ButtonsAndListComponentProps {
  expandedStep: number;
  handleStepDataChange: (step: number, data: any) => void;
  workflowData: any;
  handleSelectedListChange: (step: number, list: any) => void;
  handleButtonsChange: (step: number, buttons: any) => void;
}

const ButtonsAndListComponent: React.FC<ButtonsAndListComponentProps> = ({
  expandedStep,
  handleStepDataChange,
  workflowData,
  handleSelectedListChange,
  handleButtonsChange,
}) => {
  const dispatch = useAppDispatch();

  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const { workflowListData, workflowListStatus } = useAppSelector(
    (state: any) => state.getWorkflowList
  );

  const [selectedButtonValue, setSelectedButtonValue] = useState(() => {
    const flowResponseType = workflowData[expandedStep - 1]?.flowResponseType;
    if (flowResponseType === 1) return "button";
    if (flowResponseType === 2) return "list";
    return "";
  });

  const [selectedList, setSelectedList] = useState<any>(null);
  const [openListDialog, setOpenListDialog] = useState(false);

  const handleButtonChange = (event: any) => {
    const value = event.target.value as string;
    setSelectedButtonValue(value);

    let flowResponseType = 0;
    let autoReplyWorkflowButtons = null;
    let workflowListId = null;

    if (value === "button") {
      flowResponseType = 1;
      autoReplyWorkflowButtons = workflowData[expandedStep - 1]
        ?.autoReplyWorkflowButtons || [""];
    } else if (value === "list") {
      flowResponseType = 2;
      workflowListId = workflowData[expandedStep - 1]?.workflowListId;
    }

    handleStepDataChange(expandedStep, {
      flowResponseType,
      autoReplyWorkflowButtons,
      workflowListId,
    });
  };

  const handleListSelect = async (listData: any) => {
    setSelectedList(listData);
    setOpenListDialog(false);

    const payload = {
      id: listData.id,
      companyId: userData?.companyId,
      listName: listData.listName,
      buttonName: listData.buttonName,
      inputs: listData.inputs,
    };
    try {
      const result = await dispatch(updateWorkflowList(payload)).unwrap();
      if (result.success) {
        dispatch(
          toastActions.setToaster({
            message: "Workflow list updated successfully",
            type: "success",
          })
        );
      } else {
        dispatch(
          toastActions.setToaster({
            message: result.message || "Failed to update workflow list",
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        toastActions.setToaster({
          message: "An error occurred while updating the workflow list",
          type: "error",
        })
      );
    }
  };

  const handleRemoveButton = (index: number) => {
    const updatedButtons = (
      workflowData[expandedStep - 1]?.autoReplyWorkflowButtons || []
    ).filter((_: any, i: number) => i !== index);
    handleButtonsChange(expandedStep, updatedButtons);
  };

  const handleAddButton = () => {
    const currentButtons =
      workflowData[expandedStep - 1]?.autoReplyWorkflowButtons || [];
    const updatedButtons = [...currentButtons, ""];
    handleButtonsChange(expandedStep, updatedButtons);
  };

  const handleButtonTextChange = (index: number, value: string) => {
    const updatedButtons = [
      ...(workflowData[expandedStep - 1]?.autoReplyWorkflowButtons || []),
    ];
    updatedButtons[index] = value;
    handleButtonsChange(expandedStep, updatedButtons);
  };

  return (
    <div>
      <Typography sx={{ fontSize: 14, mt: 2, mb: 2 }}>
        Add buttons or lists to your response
      </Typography>
      <Select
        fullWidth
        displayEmpty
        value={selectedButtonValue}
        onChange={handleButtonChange}
        sx={{
          mb: 2,
          height: "36px",
          fontSize: "14px",
          borderRadius: "10px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
          },
        }}
      >
        {ButtonOptions.map((option) => (
          <MenuItem
            key={option.value}
            sx={{ fontSize: 12 }}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>

      {selectedButtonValue === "button" && (
        <Box>
          {workflowData[expandedStep - 1]?.autoReplyWorkflowButtons?.map(
            (buttonText: string, index: number) => (
              <React.Fragment key={index}>
                <Typography sx={{ mr: 2, fontSize: "14px" }}>
                  Button {index + 1}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    mt: 1,
                  }}
                >
                  <TextFieldWithBorderComponent
                    label={`Button text ${index + 1}`}
                    value={buttonText}
                    name="buttonText"
                    onChange={(e) =>
                      handleButtonTextChange(index, e.target.value)
                    }
                    placeholder={`Button text ${index + 1}`}
                    fullWidth
                    size="small"
                  />
                  <IconButton
                    onClick={() => handleRemoveButton(index)}
                    size="small"
                    sx={{ ml: 1 }}
                  >
                    <DeleteIconSvg />
                  </IconButton>
                </Box>
              </React.Fragment>
            )
          )}
          {workflowData[expandedStep - 1]?.autoReplyWorkflowButtons?.length <
            3 && (
            <Typography
              onClick={handleAddButton}
              style={{
                color: "green",
                fontWeight: "600",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              + Add another button
            </Typography>
          )}
        </Box>
      )}

      {selectedButtonValue === "list" && (
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          {workflowListStatus === "loading" ? (
            <LoadingComponent height="100%" color={bgColors?.blue} />
          ) : (
            <Typography
              onClick={() => setOpenListDialog(true)}
              style={{
                color: "green",
                fontWeight: "600",
                fontSize: "14px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <span style={{ fontSize: "20px" }}>≡</span>
              {selectedList ? selectedList.listName : "Select a List"}
            </Typography>
          )}
        </Box>
      )}

      <SelectListDialog
        open={openListDialog}
        onClose={() => setOpenListDialog(false)}
        onSelect={handleListSelect}
        workflowListData={workflowListData}
        handleSelectedListChange={handleSelectedListChange}
        expandedStep={expandedStep}
      />
    </div>
  );
};

export default ButtonsAndListComponent;
