import React from "react";
import { Box, Popover, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
// import { fetchGetAllTeamMembersByCompanyId } from "../../redux/slices/ManageCompany/GetAllTeamMembersByCompanyId";
import { fetchInboxContactAssignment } from "../../../redux/slices/Inbox/InboxContactAssignment";
import { toastActions } from "../../../utils/toastSlice";
// import { fetchInboxContactConversations } from "../../redux/slices/Inbox/InboxContactConversations";
// import { useNavigate } from "react-router-dom";
import { getUniformString } from "../../../utils/functions";
import LoadingComponent from "../../common/LoadingComponent";
import { bgColors } from "../../../utils/bgColors";

const useStyles = makeStyles({
  flexRowSpaceBetween: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      boxShadow: "2px 2px 4px 4px rgba(0, 0, 0, 0.1)",
    },
    // width: "100px",
    // justifyContent: "space-between",
  },
  imageStyles: {
    width: "22px",
    height: "22px",
    borderRadius: "50%",
  },
  textStyles: {
    color: "#3C3C3C",
    fontWeight: "600",
    marginLeft: "10px",
    width: "100px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
});

const AssignToPopover = ({ contact, anchorEl, onClose }: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const loginData = useAppSelector((state: any) => state?.adminLogin?.data);
  const getAllTeamMembersSlice = useAppSelector(
    (state: any) => state?.getAllTeamMembersByCompanyId
  );
  const getAllTeamMembersData = getAllTeamMembersSlice?.data;

  const teamMembersData = getAllTeamMembersData?.filter(
    (item: any) => item?.status === true
  );

  const contactsArray = contact?.map((item: any) => item?.contact);
  const assignee = contact?.map((item: any) =>
    item?.assignTo || item.assignee ? item?.assignTo || item?.assignee : ""
  );
  const sameAssignee = getUniformString(contact);

  const handleContactAssignment = async (teamMember: any) => {
    try {
      const postData = {
        businessId: loginData?.companyId,
        teamMemberId: teamMember?.id,
        contact: contactsArray,
      };
      // console.log("postData", postData);
      const response: any = await dispatch(
        fetchInboxContactAssignment(postData)
      );
      // console.log("assignRes", response);
      if (response.meta.requestStatus === "fulfilled") {
        // if (contact?.length === 1) {
        //   navigate(`/inbox/${contact[0]?.contact}?open`);
        // }
        // window?.location?.reload();
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: `${response?.payload?.message}`,
          })
        );
      } else {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `${response?.error?.message}`,
          })
        );
      }
      onClose();
      // console.log("responseeee", response);
    } catch (err: any) {
      // console.log("error", err);
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: `${err?.response?.data?.message}`,
        })
      );
    }
  };

  // console.log("contact", contact, assignee, sameAssignee);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {getAllTeamMembersSlice?.status === "loading" ? (
        <LoadingComponent color={bgColors?.blue} height="auto" />
      ) : (
        <Box py={1}>
          {teamMembersData?.map((item: any) => (
            <Box
              key={item.id}
              className={classes.flexRowSpaceBetween}
              mx={2}
              my={0.5}
              px={1}
              py={0.5}
              onClick={() => handleContactAssignment(item)}
            >
              <img
                src={
                  sameAssignee !== undefined
                    ? sameAssignee?.includes(item?.id)
                      ? "/images/TICK.png"
                      : item.image || "/images/profile.png"
                    : assignee?.includes(item?.id)
                    ? "/images/TICK.png"
                    : item.image || "/images/profile.png"
                }
                alt={`${item.image}`}
                className={classes.imageStyles}
              />
              <Typography
                sx={{ fontSize: "12px", ml: 2 }}
                className={classes.textStyles}
              >
                {item.name !== null ? item.name : item?.phoneNumber}{" "}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Popover>
  );
};

export default AssignToPopover;
