import React, { useState } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import ChatUserArrowDownSvg from "../../../assets/svgs/ChatUserArrowDownSvg";
import ChatUserArrowSide from "../../../assets/svgs/ChatUserArrowSide";
import { makeStyles } from "@mui/styles";
import TextFieldWithBorderComponent from "../../common/TextFieldWithBorderComponent";
import { bgColors } from "../../../utils/bgColors";

const useStyles = makeStyles({
  grayColor: {
    color: `#4B5A5A !important`,
    fontWeight: "600 !important",
  },
  flexRowCenterSpaceBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  saveButtonStyles: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "70px",
    height: "36px",
    padding: "5px",
    cursor: "pointer",
    backgroundColor: "#fff",
    marginTop: "3px",
  },
  notesContainer: {
    backgroundColor: "#F2F2F2",
    borderRadius: "14px",
    padding: "10px",
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

interface NotesComponentProps {
  notes: boolean;
  setNotes: React.Dispatch<React.SetStateAction<boolean>>;
  writtenNotes: string;
  setWrittenNotes: React.Dispatch<React.SetStateAction<string>>;
  addNotesTooltipOpen: boolean;
  setAddNotesTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>;
  details: any; // Replace with appropriate type
  handleSaveNote: () => void;
  deleteNotestooltipOpen: string;
  setDeleteNotestooltipOpen: React.Dispatch<React.SetStateAction<string>>;
  handleDeleteNotes: (id: string) => void;
}

const NotesComponent: React.FC<NotesComponentProps> = ({
  notes,
  setNotes,
  writtenNotes,
  setWrittenNotes,
  addNotesTooltipOpen,
  setAddNotesTooltipOpen,
  details,
  handleSaveNote,
  deleteNotestooltipOpen,
  setDeleteNotestooltipOpen,
  handleDeleteNotes,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box
        my={2}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => setNotes(!notes)}
      >
        {notes ? <ChatUserArrowDownSvg /> : <ChatUserArrowSide />}
        <Typography
          ml={2}
          sx={{ fontSize: "14px" }}
          className={classes.grayColor}
          style={{ cursor: "pointer" }}
        >
          Notes
        </Typography>
      </Box>

      {notes && (
        <>
          <Box className={classes.flexRowCenterSpaceBetween}>
            <TextFieldWithBorderComponent
              size="small"
              name="note"
              label="Write a note.."
              value={writtenNotes}
              onChange={(e: any) => setWrittenNotes(e.target.value)}
              placeholder=""
            />
            <Box
              ml={1}
              style={{
                borderRadius: "30px",
              }}
            >
              <Tooltip
                title="Access Denied"
                placement="left"
                open={addNotesTooltipOpen}
                onClose={() => setAddNotesTooltipOpen(false)}
              >
                <button
                  className={classes.saveButtonStyles}
                  onClick={() =>
                    writtenNotes?.length !== 0
                      ? handleSaveNote()
                      : alert("Empty notes")
                  }
                >
                  Save
                </button>
              </Tooltip>
            </Box>
          </Box>
          {details?.note?.length !== 0 &&
            details?.note?.map((item: any, index: number) => (
              <Box className={classes.notesContainer} key={index}>
                <Typography sx={{ fontSize: "12px", alignItems: "center" }}>
                  <li>{item?.note}</li>
                </Typography>
                <Tooltip
                  title="Access Denied"
                  placement="top"
                  open={deleteNotestooltipOpen === item?.id}
                  onClose={() => setDeleteNotestooltipOpen("")}
                >
                  <Delete
                    fontSize="small"
                    sx={{ ml: "auto", cursor: "pointer" }}
                    onClick={() => handleDeleteNotes(item?.id)}
                  />
                </Tooltip>
              </Box>
            ))}
        </>
      )}
    </>
  );
};

export default NotesComponent;
