import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET_MANAGE_ACCOUNT } from "../../../Apis/ManageAccountApis/GetManangeAccount";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const fetchGetAllPaymentsCards = createAsyncThunk(
  "fetchGetAllPaymentsCards",
  async () => {
    const response = await GET_MANAGE_ACCOUNT.getAllPaymentsCards();
    return response?.data;
  }
);

export const getAllPaymentsCardsSlice = createSlice({
  name: "getAllPaymentsCardsSlice",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGetAllPaymentsCards.pending, (state) => {
        state.status = "loading";
        state.data = [];
      })
      .addCase(fetchGetAllPaymentsCards.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchGetAllPaymentsCards.rejected, (state) => {
        state.status = "failed";
        state.data = [];
      });
  },
});

export const getAllPaymentsCardsSliceActions = getAllPaymentsCardsSlice.actions;
export default getAllPaymentsCardsSlice.reducer;
