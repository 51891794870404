import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../utils/redux-hooks";
import { createCampaign } from "../../redux/slices/Campaign/CreateCampaignSlice";
import { createWorkingHours } from "../../redux/slices/Automation/createWorkingHoursSlice";
import { toastActions } from "../../utils/toastSlice";
import { getWorkingHours } from "../../redux/slices/Automation/GetWorkingHoursSlice";
import CloseSvg from "../../assets/svgs/CloseSvg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIconSvg from "../../assets/svgs/DeleteIconSvg";
import { bgColors } from "../../utils/bgColors";
import LoadingComponent from "../common/LoadingComponent";
import moment from "moment";

export function WorkingHoursPoup({ open, handleClose, workingHoursData }: any) {
  const [workingHours, setWorkingHours] = useState<any>([]);
  const [nextId, setNextId] = useState<any>(1);
  const dispatch = useAppDispatch();
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [isTimeLoading, setIsTimeLoading] = useState(false);
  const getLoginData = useAppSelector((state: any) => state.adminLogin.data);
  const { data } = useAppSelector((state: any) => state.getWorkingHours);
  const daysOfWeek = [
    { id: 0, day: "Sunday" },
    { id: 1, day: "Monday" },
    { id: 2, day: "Tuesday" },
    { id: 3, day: "Wednesday" },
    { id: 4, day: "Thursday" },
    { id: 5, day: "Friday" },
    { id: 6, day: "Saturday" },
  ];

  const timeOptions = [
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
    "7:00 PM",
    "8:00 PM",
    "9:00 PM",
    "10:00 PM",
    "11:00 PM",
    // "12:00 AM",
    "1:00 AM",
    "2:00 AM",
    "3:00 AM",
    "4:00 AM",
    "5:00 AM",
    "6:00 AM",
    "7:00 AM",
    "8:00 AM",
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 AM",
  ];

  const formatTime = (time: string) => {
    const [hours, minutes] = time.split(":");
    const parsedHours = parseInt(hours);
    const formattedHours =
      parsedHours === 0
        ? 12
        : parsedHours > 12
        ? parsedHours - 12
        : parsedHours;
    const period = parsedHours >= 12 ? "PM" : "AM";
    return `${formattedHours}:${minutes} ${period}`;
  };

  useEffect(() => {
    if (Array.isArray(workingHoursData?.daysOfWeek)) {
      const formattedData = workingHoursData?.daysOfWeek?.map((item: any) => ({
        id: nextId + item.dayOfWeek,
        day: item.dayOfWeek,
        startTime: formatTime(item.from),
        endTime: formatTime(item.to),
      }));

      setWorkingHours(formattedData);
    } else {
      console.error("workingHoursData is not an array or is undefined");
    }
  }, [workingHoursData]);

  const handleAddHours = () => {
    // Find the index of the last day in the daysOfWeek array
    const lastDayIndex = daysOfWeek.findIndex(
      (day) => day.day === workingHours[workingHours?.length - 1]?.day
    );

    // Calculate the index of the next day
    let nextDayIndex = (lastDayIndex + 1) % daysOfWeek.length;

    // Get the next day from daysOfWeek based on the calculated index
    let nextDay = daysOfWeek[nextDayIndex].day;

    // Check if the next day already exists in the working hours
    let dayExists = workingHours?.some(
      (hours: any) => hours.day === nextDayIndex
    );

    // If the next day already exists, find the next non-existing day
    if (dayExists) {
      let found = false;
      let i = nextDayIndex;
      while (!found) {
        i = (i + 1) % daysOfWeek.length;
        if (!workingHours?.some((hours: any) => hours.day === i)) {
          nextDayIndex = i;
          nextDay = daysOfWeek[nextDayIndex].day;
          dayExists = false;
          found = true;
        }
      }
    }

    // Add the next day only if it doesn't already exist
    if (!dayExists) {
      // Set default start and end times
      setWorkingHours((prevHours: any) => [
        ...prevHours,
        {
          id: nextId,
          day: nextDayIndex,
          startTime: "10:00 AM",
          endTime: "6:00 PM",
        },
      ]);
      setNextId(nextId + 1);
    }
  };

  const isAllDaysPresent = workingHours?.length === daysOfWeek?.length;

  // const formatTimeTo24Hours = (time: string) => {
  //   const [hours, minutes, period] = time.split(/:| /);
  //   const parsedHours = parseInt(hours);
  //   const isPM = period.toLowerCase() === "pm";
  //   const formattedHours = isPM && parsedHours !== 12 ? parsedHours + 12 : parsedHours;
  //   return `${formattedHours.toString().padStart(2, "0")}:${minutes}:00`;
  // };

  // const formatTimeTo24Hours = (time: string) => {
  //   const [hours, minutes, period] = time.split(/:| /);
  //   const parsedHours = parseInt(hours);
  //   const isPM = period.toLowerCase() === "pm";
  //   const formattedHours = isPM && parsedHours !== 12 ? parsedHours + 12 : parsedHours;
  //   return `${formattedHours.toString().padStart(2, "0")}:${minutes}:00`;
  // };
  const formatTimeTo24Hours = (time: string) => {
    const [hours, minutes, period] = time.split(/:| /);
    let parsedHours = parseInt(hours);
    const isPM = period.toLowerCase() === "pm";
    if (isPM && parsedHours !== 12) {
      parsedHours += 12;
    } else if (!isPM && parsedHours === 12) {
      parsedHours = 0;
    }
    return `${parsedHours.toString().padStart(2, "0")}:${minutes}:00`;
  };

  const handleSave = async () => {
    try {
      setIsTimeLoading(true);
      const convertedData = {
        timeZone: moment.tz.guess(), // You can replace this with the actual timezone value if needed
        daysOfWeek: workingHours?.map((hours: any) => ({
          dayOfWeek: hours.day,
          from: formatTimeTo24Hours(hours.startTime),
          to: formatTimeTo24Hours(hours.endTime),
        })),
      };
      const data = {
        businessId: getLoginData?.companyId,
        userId: getLoginData?.userId,
        data: convertedData,
      };
      const data1 = {
        businessId: getLoginData?.companyId,
        userId: getLoginData?.userId,
      };
      const getRes = await dispatch(createWorkingHours(data));
      if (getRes?.meta?.requestStatus === "fulfilled") {
        handleClose();
        setIsTimeLoading(false);
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: getRes?.payload?.message,
          })
        );
        dispatch(getWorkingHours(data1));
      } else {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: "Something went wrong",
          })
        );
      }
    } catch (error) {
      console.log("working hours error", error);
      setIsTimeLoading(false);
    }
  };

  const handleDayChange = (id: number, value: string) => {
    setWorkingHours((prevHours: any) =>
      prevHours.map((hours: any) =>
        hours.id === id ? { ...hours, day: value } : hours
      )
    );
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(value)
        ? prevSelectedDays.filter((day) => day !== value)
        : [...prevSelectedDays, value]
    );
  };

  const handleTimeChange = (id: number, field: string, value: string) => {
    setWorkingHours((prevHours: any) =>
      prevHours.map((hours: any) =>
        hours.id === id ? { ...hours, [field]: value } : hours
      )
    );
  };

  const handleDeleteHours = (id: number) => {
    const dayToDelete = workingHours?.find(
      (hours: any) => hours.id === id
    )?.day;
    setWorkingHours((prevHours: any) =>
      prevHours.filter((hours: any) => hours.id !== id)
    );
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.filter((day) => day !== dayToDelete)
    );
  };

  //   console.log("selectedDays", selectedDays);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // sx={{width:'300px'}}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          // cursor: "pointer",
          backgroundColor: "white",
          color: "black",
          // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
            color: "black",
          }}
        >
          <AccessTimeIcon />
          <Typography variant="h6">Setup your working hours</Typography>
        </Box>
        <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
          <CloseIcon />
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            padding: "10px",
            color: "gray",
            fontSize: "small",
          }}
        >
          Auto Reply messages will be triggered based on the below working
          hours. To understand how business working hours affect Auto Replies,
          read here. (timings will match your system timings)
        </DialogContentText>
        <Box>
          {workingHours?.map((hours: any) => (
            <Box
              key={hours}
              marginBottom="10px"
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <FormControl
                sx={{
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "10px 32px 10px 10px",
                    },
                }}
                style={{ minWidth: "140px" }}
              >
                <Select
                  value={hours.day}
                  onChange={(e) =>
                    handleDayChange(hours.id, e.target.value as string)
                  }
                  sx={{
                    // mr: 1,
                    // width: "40%",
                    height: "36px",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                >
                  {daysOfWeek.map((day) => (
                    <MenuItem
                      key={day?.id}
                      value={day?.id}
                      disabled={workingHours?.some(
                        (hours: any) => hours.day === day.id
                      )}
                      sx={{ minWidth: "100px", minHeight: "30px" }}
                    >
                      {day?.day}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "10px 32px 10px 10px",
                    },
                  // minWidth: "100px"
                }}
              >
                <Select
                  value={hours.startTime}
                  onChange={(e) =>
                    handleTimeChange(
                      hours.id,
                      "startTime",
                      e.target.value as string
                    )
                  }
                  sx={{
                    // mr: 1,
                    // width: "40%",
                    height: "40px",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                >
                  <MenuItem value="">Select Start Time</MenuItem>
                  {timeOptions?.map((time) => {
                    // console.log("time", time);
                    return (
                      <MenuItem key={time} value={time}>
                        {time}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "10px 32px 10px 10px",
                    },
                  // minWidth: "100px"
                }}
              >
                <Select
                  value={hours.endTime}
                  onChange={(e) =>
                    handleTimeChange(
                      hours.id,
                      "endTime",
                      e.target.value as string
                    )
                  }
                  sx={{
                    // mr: 1,
                    // width: "40%",
                    height: "40px",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                >
                  <MenuItem value="">Select End Time</MenuItem>
                  {timeOptions.map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                  gap: "3px",
                }}
              >
                <Tooltip title={data?.timeZone} placement="top">
                  <Typography>
                    {moment.tz(data?.timeZone).format("z").slice(0, 3)}
                  </Typography>
                </Tooltip>
                <Tooltip title={data?.timeZone} placement="top">
                  <Typography>({data?.timeZone.slice(0, 3)}..)</Typography>
                </Tooltip>
              </Box>
              <Button
                onClick={() => handleDeleteHours(hours.id)}
                sx={{ marginRight: "10px" }}
              >
                <DeleteIconSvg />
              </Button>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Button
            onClick={handleAddHours}
            disabled={isAllDaysPresent}
            sx={{ color: bgColors.green }}
          >
            + Add hours
          </Button>
          <DialogActions>
            {isTimeLoading ? (
              <LoadingComponent height="auto" color={bgColors?.blue} />
            ) : (
              <Button
                onClick={handleSave}
                sx={{
                  color: bgColors.green,
                  border: `1px solid ${bgColors.green}`,
                  borderRadius: "8px",
                  width: "80px",
                  marginTop: "10px",
                  height: "32px",
                  padding: "5px",
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  "&:hover": {
                    backgroundColor: "rgba(68, 71, 70, 0.08)",
                  },
                }}
              >
                Save
              </Button>
            )}
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
